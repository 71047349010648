import { message } from "antd"
import axios from "axios";
import { useState, useEffect } from "react";
import config from "src/Config";
import { FilmProjectPageForm, SeriesProjectPageForm } from "src/Pages/Partner/Projects";
import { LoadingSpinner, Navbar, SeriesProfile } from "src/Components";
import { useParams } from "react-router-dom";
import { RecoilRoot } from "recoil";
import FilmProfile from "src/Components/FilmProfile";

const SeriesProfilePage = () => {

    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [profile, setProfile] = useState<FilmProjectPageForm | SeriesProjectPageForm>();

    // Fetch profile data if already present
    useEffect(() => {
        (() => {
            axios.get(`${config.api_url}/projects/${id}`).then(
                res => {
                    setProfile(res.data);
                    setLoading(false);
                },
                err => {
                    message.error(`${err.message} - ${err.response.data.message}`);
                    setError("Profile not found");
                    setLoading(false);
                }
            )
        })();
        return () => { };
    }, []);

    if (loading) {
        return <LoadingSpinner />
    } else {
        return (
            <RecoilRoot>
                <div className="container-fluid">
                    <Navbar></Navbar>
                    <div className="container" style={{ minHeight: "100vh", paddingBottom: "50px" }}>
                        <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
                            <div>
                                {
                                    error ?
                                        <p className="text-danger text-center">{error}</p>
                                        : (
                                            (profile?.kind === "film") ?
                                                // <SeriesProfile profile={profile!} /> :
                                                <FilmProfile profile={profile as FilmProjectPageForm} /> :
                                                <SeriesProfile profile={profile as SeriesProjectPageForm} />
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </RecoilRoot>
        );
    }
}


export default SeriesProfilePage
