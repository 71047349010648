import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Button, Checkbox, DatePicker, Form, Input, message, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormListFieldData } from "antd/lib/form/FormList";
import { Content } from "antd/lib/layout/layout";
import TextArea from "antd/lib/input/TextArea";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ProfessionalProfilePageForm } from ".";
import { LoadingSpinner, ImageUploader } from "../../../Components";
import config from "../../../Config";
import axios from "axios";
import moment from "moment";
import { Moment } from "moment";
import { MasterService } from "../../../Services/master.service";
import { UploadFile } from "antd/lib/upload/interface";
import { debounce } from "lodash";
import { Subject } from "rxjs";
import { wait } from "src/utils/wait";
import { removeSocialMediaRoot } from "src/utils/removeSocialMediaRoot";

const searchSubject$ = new Subject<string>();

const disabledDate = (current: Moment) => {
  // Can not select days before today and today
  return current && current.valueOf() > Date.now();
};

const CreateProfessionalProfile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [imageURL, setImageURL] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<Boolean>(true);
  const [filmResults, setFilmResults] = useState<{ value: string; label: string; _id: string }[]>();
  const [submitLoading, setSubmitLoading] = useState<boolean | { delay?: number | undefined }>(false);
  const [professionalProfile, setProfessionalProfile] = useState<ProfessionalProfilePageForm>({} as any);
  const [urlAvailableError, setURLAvailableError] = useState<
    ["" | "validating" | "success" | "warning" | "error" | undefined, string]
  >(["", ""]);
  const [socialMediaPlatforms, setSocialMediaPlatforms] = useState<{ index: number; name: string }[]>([]);

  const [skills, setSkills] = useState<string[]>();
  const [companyRole, setCompanyRole] = useState<string[]>();
  const [filmCategory, setFilmCategory] = useState<string[]>();
  const [crewRole, setCrewRole] = useState<string[]>();
  const [social, setSocial] = useState<string[]>();
  const [filmCredits, setFilmCredits] = useState<{ index: number; type: "producer" | "director" | "crew" | "cast" }[]>(
    []
  );

  useEffect(() => {
    const listener = searchSubject$.subscribe(async (query) => {
      const result = await axios.get(config.api_url + "/projects/search/" + query);

      setFilmResults(
        result.data.map((el: any) => ({
          value: `${el.englishTitle} (${el.customURL})`,
          label: `${el.englishTitle} (${el.customURL})`,
          _id: el._id,
        }))
      );
    });

    return () => listener.unsubscribe();
  }, [setFilmResults]);

  useEffect(() => {
    MasterService.getList(["skill", "companyRole", "filmCategory", "crewRole", "social"]).then(
      axios.spread((skills, companyRole, filmCategory, crewRole, social) => {
        setSkills(skills.values);
        setCompanyRole(companyRole.values);
        setFilmCategory(filmCategory.values);
        setCrewRole(crewRole.values);
        setSocial(social.values);
      })
    );
  }, []);

  useEffect(() => {
    setImageURL(imageURL);
    form.setFieldsValue({
      ["imageURL"]: imageURL || "",
    });
  }, [imageURL]);

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      const res = await axios.get(config.api_url + "/professional-profile");
      let tmpProfessionalProfile = res.data;

      if (!tmpProfessionalProfile) {
        const userRes = await axios.get(config.api_url + "/user/me");
        const name = userRes.data.firstname + " " + (userRes.data.lastname ?? "");
        const customURL = name.toLowerCase().replaceAll(" ", "-");
        wait(500).then(() => form.validateFields(["customURL"])); // Run validation after form has loaded

        tmpProfessionalProfile = { customURL };
      }

      tmpProfessionalProfile?.qualification?.map((qual: any) => {
        qual.year = moment(qual.year);
        console.log(qual.year);
      });

      const newFilms: { index: number; type: "producer" | "director" | "crew" | "cast" }[] = [];
      tmpProfessionalProfile.film?.map((film: any, index: number) => {
        film.year = moment(film.year);
        newFilms.push({ index, type: film.category as "producer" | "director" | "crew" | "cast" });
      });
      setFilmCredits(newFilms);

      tmpProfessionalProfile?.experience?.map((exp: any) => {
        exp.period[0] = moment(exp.period[0], "DD-MM-YYYY");
        if (exp.current === true) {
          exp.period[1] = moment();
        } else {
          exp.period[1] = moment(exp.period[1], "DD-MM-YYYY");
        }
      });

      const platforms: { index: number; name: string }[] = [];
      (tmpProfessionalProfile as ProfessionalProfilePageForm).social = (
        (tmpProfessionalProfile as ProfessionalProfilePageForm).social ?? []
      ).map((social, index) => {
        platforms.push({ index, name: social.platform.toLowerCase() });
        return { ...social, link: removeSocialMediaRoot(social.link) };
      });
      console.log(platforms);
      setSocialMediaPlatforms(platforms);

      if (tmpProfessionalProfile.imageURL) {
        setImageURL(tmpProfessionalProfile.imageURL);
      }
      setProfessionalProfile(tmpProfessionalProfile);
      form.resetFields();
      setLoading(false);
    })();
    return () => {};
  }, []);

  const checkCustomURL = useCallback(
    debounce(async (value) => {
      if (value.length === 0) {
        setURLAvailableError(["error", "Custom URL is Mandatory!"]);
        return message.error("Custom URL is Mandatory!");
      }
      if (value.length < 5) {
        setURLAvailableError(["error", "Custom URL cannot be less than 5 characters!"]);
        return message.error("Custom URL is Mandatory!");
      }
      if (value.length > 25) {
        setURLAvailableError(["error", "Custom URL cannot be greater than 25 characters!"]);
        return message.error("Custom URL is Mandatory!");
      }
      const pattern = new RegExp("^[0-9a-zA-Z-]+$");
      if (!pattern.test(value)) {
        setURLAvailableError(["error", "Special characters not allowed!"]);
        return message.error("Special characters not allowed!");
      }
      axios.get(config.api_url + `/professional-profile/${value}?present=${professionalProfile._id}`).then(
        (res) => {
          if (res.data.available === false) {
            setURLAvailableError(["error", "URL already taken, please try another url!"]);
          } else {
            setURLAvailableError(["success", "URL is available"]);
          }
        },
        (err) => {
          message.error(err.message);
          console.error(err);
        }
      );
    }, 1000),
    [professionalProfile]
  );

  const getPrefix = (platform: string) => {
    switch (platform) {
      case "facebook":
        return "https://facebook.com/";
      case "linkedin":
        return "https://linkedin.com/in/";
      case "instagram":
        return "https://instagram.com/";
      case "x":
        return "https://x.com/";
      case "whatsapp":
        return "https://wa.me/";
      default:
        return "";
    }
  };

  const onFinish = async (formValues: ProfessionalProfilePageForm) => {
    // console.debug(values);
    setSubmitLoading(true);
    // const values: ProfessionalProfilePageForm = {};
    const values = { ...formValues };
    console.log(values);

    values.imageURL = imageURL ?? "";
    values.customURL = values.customURL?.toLocaleLowerCase();
    if (values.qualification)
      values.qualification = values.qualification.map((qual: any) => {
        return { ...qual, year: qual.year.format("YYYY") };
      });
    if (values.experience)
      values.experience = values.experience.map((exp: any) => {
        return { ...exp, period: [exp.period[0].format("DD-MM-YYYY"), exp.period[1].format("DD-MM-YYYY")] };
      });
    if (values.film)
      values.film = values.film.map((f: any) => {
        return { ...f, year: f.year.format("YYYY") };
      });
    if (values.social)
      values.social = (values.social ?? []).map((social, i) => {
        return {
          platform: social.platform,
          link: getPrefix(social.platform.toLowerCase()) + social.link,
        };
      });

    // console.debug('Received values of form: ', values);

    // Make POST request to submit form data and create professional profile in the database
    axios.post(config.api_url + "/professional-profile", values).then(
      (_res) => {
        message.success(`Professional Profile updated successfully`);
        setSubmitLoading(false);
        navigate("/partner/professional-profile", { replace: true });
      },
      (err) => {
        message.error(`Failed to update your professional profile! ${err.response?.data?.error}`);
        setSubmitLoading(false);
        console.error(err.response?.data?.error);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Content style={{ margin: "24px 0" }}>
      <h1 className="title">Professional Profile</h1>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          labelAlign="left"
          initialValues={professionalProfile}
        >
          <Form.Item name="imageURL" hidden>
            <Input type="hidden" name="imageURL" value={imageURL} />
          </Form.Item>
          <Form.Item label="Profile Picture">
            <ImageUploader
              name="imageURL"
              url={imageURL ? [imageURL] : []}
              action={config.api_url + "/file"}
              onRemove={(file: UploadFile) => setImageURL(undefined)}
              onComplete={(url: UploadFile[]) => {
                setImageURL(url[0].response.url);
              }}
            />
          </Form.Item>
          <Form.Item
            name="customURL"
            label="Custom URL"
            validateStatus={urlAvailableError[0]}
            help={urlAvailableError[1]}
            hasFeedback
            rules={[
              {
                type: "string",
                required: true,
                message: "Please input your custom url!",
              },
              {
                min: 5,
                message: "Min. requirement is 5 characters!",
              },
              {
                max: 25,
                message: "Kindly restrict your custom url to 25 characters!",
              },
              {
                validator: (_, value) => {
                  setURLAvailableError(["validating", ""]);
                  checkCustomURL(value);
                  return Promise.resolve();
                },
                message: "Please input your custom url!",
              },
              {
                pattern: new RegExp("^[0-9a-zA-Z-]+$"),
                message: "Only alphanumeric characters allowed for custom url!",
              },
            ]}
          >
            <Input size="large" prefix="https://pocketfilms.in/profile/" placeholder="Custom URL" />
          </Form.Item>
          {/* Qualications section start */}
          <Form.Item name="qualification" label="Qualifications" className="d-flex">
            <Form.List name="qualification">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                        gap: 8,
                        alignItems: "baseline",
                      }}
                    >
                      <Form.Item
                        style={{ flex: 1 }}
                        name={[name, "institute"]}
                        rules={[
                          { required: true, message: "Enter Institute name" },
                          {
                            pattern: new RegExp("^[0-9a-zA-Z,. ]*$"),
                            message: "Special Characters allowed are . ,",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Institute Name" />
                      </Form.Item>
                      <Form.Item
                        name={[name, "degree"]}
                        rules={[
                          { required: true, message: "Enter Degree name" },
                          {
                            pattern: new RegExp("^[0-9a-zA-Z. ]*$"),
                            message: "No special characters allowed",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Degree Name" />
                      </Form.Item>
                      <Form.Item
                        name={[name, "year"]}
                        rules={[
                          {
                            required: true,
                            message: "Enter Year of passing",
                          },
                        ]}
                      >
                        <DatePicker format={"YYYY"} size="large" picker="year" placeholder="Year of passing" />
                      </Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </div>
                  ))}
                  <Form.Item>
                    {fields.length < 5 ? (
                      <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                        Add Qualification
                      </Button>
                    ) : null}
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          {/* Qualications section end */}
          <Form.Item name="skills" label="Key Skills">
            <Select
              size="large"
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select your skills"
              showSearch
            >
              {skills?.map((skill) => (
                <Select.Option key={skill} value={skill}>
                  {skill}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* Work Experience section start */}
          <Form.Item name="experience" label="Work Experience" className="d-flex">
            <Form.List name="experience">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <WorkExperienceFields
                      key={key}
                      name={name}
                      remove={remove}
                      fields={fields}
                      companyRole={companyRole || []}
                    />
                  ))}
                  <Form.Item>
                    {fields.length < 5 ? (
                      <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                        Add Work Experience
                      </Button>
                    ) : null}
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          {/* Work Experience section end */}
          <Form.Item
            name="about"
            label="About Me"
            rules={[
              {
                type: "string",
              },
              {
                max: 250,
                message: "Max. limit is 250 characters",
              },
              {
                pattern: new RegExp("^[0-9a-zA-Z,.?!()'-:;\"$`~_# \n]*$"),
                message: "Only ,.?!()'-:;\"$`~_#` are allowed as special characters",
              },
            ]}
          >
            <TextArea
              size="large"
              placeholder="Tell us something about you that is not covered in the above details. Do not share your contact details or website links"
              rows={4}
            ></TextArea>
          </Form.Item>

          {/* <Form.Item
              name="awards"
              label="Awards / Nominations"
              rules={[
                {
                  type: "string",
                  pattern: new RegExp("^[0-9a-zA-Z!\"&'(), ]*$")
                }
              ]}>
              <Input size="large" placeholder="Enter your Awards / Nominations" ></Input>
            </Form.Item> */}

          {/* Film Credits section start */}
          <Form.Item name="film" label="Film Credits" className="d-flex">
            <Form.List name="film">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name }, i) => (
                    <div className="mb-2" key={key}>
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          alignItems: "baseline",
                        }}
                      >
                        <Form.Item
                          style={{ flex: 1 }}
                          name={[name, "title"]}
                          rules={[
                            { required: true, message: "Enter Film title" },
                            {
                              max: 100,
                              message: "Max. limit is 100 characters",
                            },
                          ]}
                        >
                          <AutoComplete
                            options={filmResults}
                            onSearch={(searchString) => searchSubject$.next(searchString)}
                          >
                            <Input.Search size="large" placeholder="Film Name" />
                          </AutoComplete>
                          {/* <Input size="large" placeholder="Name of the film in which you got official credit" /> */}
                        </Form.Item>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          alignItems: "stretch",
                        }}
                      >
                        <Form.Item
                          name={[name, "category"]}
                          rules={[
                            {
                              required: true,
                              message: "Select Role Category",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            placeholder="Select Film Category"
                            onSelect={(value: "producer" | "director" | "cast" | "crew"): void => {
                              const newFilms = [...filmCredits];
                              if (i < newFilms.length) {
                                newFilms[i].type = value;
                              } else {
                                newFilms.push({ index: i, type: value });
                              }
                            }}
                            allowClear
                          >
                            <Select.Option value="director">Director</Select.Option>
                            <Select.Option value="producer">Producer</Select.Option>
                            <Select.Option value="cast">Cast</Select.Option>
                            <Select.Option value="crew">Crew</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{
                            flex: 1,
                          }}
                          name={[name, "role"]}
                          rules={[{ required: true, message: "Select your Role" }]}
                        >
                          {filmCredits[i]?.type === "crew" ? (
                            <Select size="large" placeholder="Select your Role in the Film" allowClear showSearch>
                              {crewRole?.map((role) => (
                                <Select.Option key={role} value={role}>
                                  {role}
                                </Select.Option>
                              ))}
                            </Select>
                          ) : filmCredits[i]?.type === "producer" || filmCredits[i]?.type === "director" ? (
                            <TextArea placeholder={filmCredits[i]?.type === "producer" ? "Filmography" : "Biography"} />
                          ) : (
                            <Input placeholder="Character" />
                          )}
                        </Form.Item>
                        <Form.Item
                          name={[name, "year"]}
                          rules={[
                            {
                              required: true,
                              message: "Select Year of Film",
                            },
                          ]}
                        >
                          <DatePicker format={"YYYY"} size="large" picker="year" placeholder="Select Year of Film" />
                        </Form.Item>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(name);
                            const newFilms = [...filmCredits]
                              .filter(({ index }) => index !== i)
                              .map((film) => ({ ...film, index: film.index > i ? film.index - 1 : film.index }));
                            setFilmCredits(newFilms);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                        setFilmCredits((filmCredits) => [
                          ...filmCredits,
                          { index: filmCredits.length, type: "director" },
                        ]);
                      }}
                      style={{ width: "60%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Film Credit
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          {/* Film Credits section end */}

          {/* Social Media Links section start */}
          <Form.Item name="social" label="Social Media Links" className="d-flex">
            <Form.List name="social">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name }, i) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                        gap: 8,
                        alignItems: "baseline",
                      }}
                    >
                      <Form.Item
                        name={[name, "platform"]}
                        rules={[
                          {
                            required: true,
                            message: "Select social media platform",
                          },
                        ]}
                      >
                        {/* <Select size="large" onChange={handleSocialPlatformChange} style={{ width: 150 }}> */}
                        <Select
                          size="large"
                          style={{ width: 150 }}
                          onSelect={(value: string) => {
                            const newSocialMediaPlatforms = [...socialMediaPlatforms];
                            const idx = newSocialMediaPlatforms.findIndex((platform) => platform.index === i);
                            if (idx < 0) {
                              newSocialMediaPlatforms.push({ index: i, name: value.toLowerCase() });
                            } else {
                              newSocialMediaPlatforms[idx].name = value.toLowerCase();
                            }
                            setSocialMediaPlatforms(newSocialMediaPlatforms);
                          }}
                        >
                          {social?.map((s) => (
                            <Select.Option key={s} value={s}>
                              {s}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        style={{ flex: 1 }}
                        name={[name, "link"]}
                        rules={[
                          {
                            required: true,
                            message: "Enter your social media profile link",
                          },
                        ]}
                      >
                        {/* <Input size="large" prefix={socialPrefix} placeholder="Link" disabled={socialPrefix === ""} /> */}
                        <Input
                          size="large"
                          placeholder="Link"
                          prefix={getPrefix(socialMediaPlatforms.find((platform) => platform.index === i)?.name ?? "")}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(name);
                          const newSocialMediaPlatforms = [...socialMediaPlatforms]
                            .filter(({ index }) => index !== i)
                            .map((el) => ({ ...el, index: el.index > i ? el.index - 1 : el.index }));

                          setSocialMediaPlatforms(newSocialMediaPlatforms);
                        }}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    {fields.length < 5 ? (
                      <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                        Add Social Media Link
                      </Button>
                    ) : null}
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          {/* Social Media Links section end */}
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" loading={submitLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
};

const WorkExperienceFields = (props: {
  fields: FormListFieldData[];
  name: number;
  remove: Function;
  companyRole: string[];
}) => {
  const [current, setCurrent] = React.useState(false);
  const { RangePicker } = DatePicker;

  const handleCurrentWorkingChange = (e: CheckboxChangeEvent) => {
    setCurrent(e.target.checked);
  };

  return (
    <div className="mb-2">
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item name={[props.name, "workType"]} rules={[{ required: true, message: "Select type" }]}>
          <Select size="large" placeholder="Select Type">
            <Select.Option value="job">Job</Select.Option>
            <Select.Option value="freelance">Freelance</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          name={[props.name, "company"]}
          rules={[
            { required: true, message: "Please enter the company name" },
            {
              pattern: new RegExp("^[0-9a-zA-Z.\\- ]*$"),
              message: "Only ,- are allowed as special characters",
            },
            {
              max: 100,
              message: "Max. limit is 100 characters",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Company Name" />
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          style={{ flex: 1 }}
          name={[props.name, "position"]}
          rules={[{ required: true, message: "Select your Role / Position" }]}
        >
          <Select size="large" placeholder="Select your Role / Position" mode="multiple" showSearch allowClear>
            {props.companyRole?.map((role) => (
              <Select.Option key={role} value={role}>
                {role}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={[props.name, "period"]}
          rules={[{ required: true, message: "Select period of work" }]}
          shouldUpdate
        >
          <RangePicker format={"DD-MM-YYYY"} size="large" disabledDate={disabledDate} disabled={[false, current]} />
        </Form.Item>
        <Form.Item name={[props.name, "current"]} valuePropName="checked" shouldUpdate>
          <Checkbox onChange={handleCurrentWorkingChange} checked={current}>
            Currently working
          </Checkbox>
        </Form.Item>
        <MinusCircleOutlined className="dynamic-delete-button" onClick={() => props.remove(props.name)} />
      </div>
    </div>
  );
};

export default CreateProfessionalProfile;
