import { Button, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import config from "../../../Config";
import { BusinessProfilePageForm } from ".";
import { BusinessProfile, LoadingSpinner } from "../../../Components";

const ViewBusinessProfile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [businessProfile, setBusinessProfile] = useState<BusinessProfilePageForm>();

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      axios.get(config.api_url + "/business-profile").then(
        (res) => {
          if (res.data) setBusinessProfile(res.data);
          else setError("Profile not found");
          setLoading(false);
        },
        (err) => {
          message.error(`${err.message} - ${err.response.data.message}`);
          setError("Profile not found");
          setLoading(false);
        }
      );
    })();
    return () => {};
  }, []);

  const createProfile: VoidFunction = () => {
    setLoading(true);
    axios.get(config.api_url + "/user/me").then(
      (res) => {
        let user = res.data;
        if (user.complete === false) {
          setLoading(false);
          message.warning("Please complete your profile first!", 5);
          return navigate("/account?next=/partner/business-profile/edit");
        }
        setLoading(false);
        return navigate("/partner/business-profile/edit");
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setLoading(false);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        <div className="d-flex justify-content-between mb-2">
          <h1 className="title">Business Profile</h1>
          {businessProfile ? (
            <Button className="btn-outline-danger" onClick={createProfile}>
              Edit Profile
            </Button>
          ) : null}
        </div>
        <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
          <div>
            {error ? (
              <div className="d-flex justify-content-center align-items-center">
                <Button size="large" type="primary" onClick={createProfile}>
                  Create Profie
                </Button>
              </div>
            ) : (
              <BusinessProfile profile={businessProfile || {}} isOwner />
            )}
          </div>
        </div>
      </Content>
    );
};

export default ViewBusinessProfile;
