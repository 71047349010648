import { Button, message } from "antd";
import form from "antd/lib/form";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import config from "../../../Config";
import { ProfessionalProfilePageForm } from ".";
import { LoadingSpinner, ProfessionalProfile } from "../../../Components";

const ViewProfessionalProfile = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [professionalProfile, setProfessionalProfile] = useState<ProfessionalProfilePageForm>();

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      await axios.get(config.api_url + "/professional-profile").then(
        (res) => {
          if (res.data) setProfessionalProfile(res.data);
          else setError("Profile not found");
          setLoading(false);
        },
        (err) => {
          message.error(`${err.message} - ${err.response.data.message}`);
          setError("Profile not found");
          setLoading(false);
        }
      );
    })();
    return () => {};
  }, []);

  const createProfile: VoidFunction = () => {
    if (!auth.user?.complete) {
      message.warning("Please complete your profile first!", 5);
      return navigate("/account?next=/partner/professional-profile/edit");
    }
    return navigate("/partner/professional-profile/edit");
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        <div className="d-flex justify-content-between mb-2">
          <h1 className="title">Professional Profile</h1>
          {professionalProfile ? (
            <Button className="btn-outline-danger" onClick={createProfile}>
              Edit Profile
            </Button>
          ) : null}
        </div>
        <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
          <div>
            {error ? (
              <div className="d-flex justify-content-center align-items-center">
                <Button size="large" type="primary" onClick={createProfile}>
                  Create Profie
                </Button>
              </div>
            ) : (
              <ProfessionalProfile isOwner={true} profile={{ ...professionalProfile, user: auth.user } as any} />
            )}
          </div>
        </div>
      </Content>
    );
};

export default ViewProfessionalProfile;
