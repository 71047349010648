import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import config from "./Config";

interface User {
  readonly _id: string;
  readonly firstname?: string;
  readonly lastname?: string;
  readonly gender?: string;
  readonly email: string;
  readonly mobile: { code: string; phone: string; short: string } | null;
  readonly birthdate?: Date | null;
  readonly status: string;
  readonly address1?: string;
  readonly address2?: string;
  readonly country?: string;
  readonly state?: string;
  readonly city?: string;
  readonly pincode?: string;
  readonly complete: boolean;
  readonly hasProfile: boolean;
  readonly hasCompany: boolean;
  readonly verifiedEmail: boolean;
  readonly verifiedMobile: boolean;
  readonly notifications: {
    title: string;
    content: string;
    link: string;
    status: string;
  }[];
}

interface AuthContextType {
  user: User | null;
  signin: (callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

const JWTAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
    JWTAuthProvider.isAuthenticated = true;
    callback();
  },
  signout(callback: VoidFunction) {
    JWTAuthProvider.isAuthenticated = false;
    callback();
  },
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get(config.api_url + "/user/me", { withCredentials: true })
      .then((res) => {
        setUser(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.status === 401) {
          // null but won't redirect
        }
      });
  }, []);

  const signin = (callback: VoidFunction) => {
    JWTAuthProvider.signin(() => {
      axios
        .get(config.api_url + "/user/me", { withCredentials: true })
        .then((res) => {
          setUser(res.data);
          callback();
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            window.location.href = process.env.REACT_APP_AUTH_FQDN || "http://localhost:3000";
          }
        });
    });
  };

  const signout = (callback: VoidFunction) => {
    JWTAuthProvider.signout(() =>
      axios.post(config.api_url + "/logout", {}, { withCredentials: true }).then(() => {
        setUser(null);
        callback();
      })
    );
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!auth.user) {
      auth.signin(() => {
        if (!auth.user && !loading) {
          window.location.href = process.env.REACT_APP_AUTH_FQDN || "http://localhost:3000";
        } else {
          setLoading(false);
        }
      });
    } else if (auth.user.status === "unverified") {
      window.location.href = `${process.env.REACT_APP_AUTH_FQDN}/verify`;
    } else {
      setLoading(false);
    }
  }, [auth.user]);

  if (loading) {
    return <div>Loading...</div>; // Or a more sophisticated loading indicator
  }

  return children;
};

export { AuthProvider, useAuth, RequireAuth, type User };
