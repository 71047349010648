import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { LoadingSpinner } from "src/Components";
import axios from "axios";
import config from "src/Config";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ChangePasswordForm {
  password?: string;
  newPassword: string;
  retypeNewPassword?: string;
}

const ChangePassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState<ChangePasswordForm>();

  useEffect(() => {
    setLoading(true);
    axios.get(config.api_url + "/user/me").then(
      (res) => {
        let data = res.data;
        setAccount(data);
        setLoading(false);
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setLoading(false);
      }
    );
  }, []);

  const onFinish = (values: ChangePasswordForm) => {
    // If mobile already exists in the account, then delete the field for any update
    if (values?.retypeNewPassword) {
      delete values.retypeNewPassword;
    }
    console.debug("Received values of form: ", values);

    // Make POST request to submit form data and create user in the database
    axios.post(config.api_url + "/user/password", values).then(
      (res) => {
        // console.debug(res.data);
        message.success(`Password updated successfully`);
        navigate("/partner", { replace: true });
      },
      (err) => {
        message.error(
          `Failed to update your profile! ${err.response?.data?.error}`
        );
        console.log(err.response?.data?.error);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <Form
        initialValues={account}
        onFinish={onFinish}
        layout="horizontal"
        wrapperCol={{ span: 14 }}
        labelCol={{ span: 4 }}
        labelAlign="left"
        form={form}
      >
        {"password" in account! ? (
          <Form.Item
            name="password"
            label="Current Password"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please input your current password!",
              },
              {
                min: 8,
                max: 24,
                message:
                  "Please input your Password between 8 to 24 characters!",
              },
              {
                pattern: /^[a-zA-Z0-9<>!@#$%^&{}[\]()?_+-=]+$/,
                message: "Invalid characters entered!",
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder="Password"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        ) : null}
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please input your new password!",
            },
            {
              min: 8,
              max: 24,
              message: "Please input your Password between 8 to 24 characters!",
            },
            {
              pattern: /^[a-zA-Z0-9<>!@#$%^&{}[\]()?_+-=]+$/,
              message: "Invalid characters entered!",
            },
          ]}
        >
          <Input.Password
            size="large"
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          name="retypeNewPassword"
          label="Retype New Password"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please re-enter your new password!",
            },
            {
              min: 8,
              max: 24,
              message: "Please input your Password between 8 to 24 characters!",
            },
            {
              pattern: /^[a-zA-Z0-9<>!@#$%^&{}[\]()?_+-=]+$/,
              message: "Invalid characters entered!",
            },
            {
              validator: (_, value) => {
                if (value === form.getFieldValue("newPassword")) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error("Passwords do not match!"));
                }
              },
            },
          ]}
        >
          <Input.Password
            size="large"
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    );
  }
};

export default ChangePassword;
