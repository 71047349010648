import { Outlet } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Navbar, Footer, Sidebar } from '../Components';

const Pages = () => {

    return (
        <RecoilRoot>
            <div className="container-fluid">
                <Navbar></Navbar>
                <div className="row" style={{ minHeight: "100vh", paddingBottom: "50px" }}>
                    <div className='col-lg-3'>
                        <Sidebar></Sidebar>
                    </div>
                    <div className='col-lg-9'>
                        <div className="container">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </RecoilRoot>
    )
}

export default Pages
