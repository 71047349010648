import { Content } from "antd/lib/layout/layout";
import { RecoilRoot } from "recoil";
import { Navbar, Footer, Sidebar } from "../../Components";

import EditAccount from "./edit-account";
import { Tabs } from "antd";
import { useState } from "react";
import ChangePassword from "./change-password";

const AccountPage = () => {
  const [currentTabKey, setCurrentTabKey] = useState<number>(1);

  return (
    <RecoilRoot>
      <div className="container-fluid">
        <Navbar></Navbar>
        <div
          className="row"
          style={{ minHeight: "100vh", paddingBottom: "50px" }}
        >
          <div className="col-lg-3">
            <Sidebar></Sidebar>
          </div>
          <div className="col-lg-9">
            <div className="container">
              <Content style={{ margin: "24px 0" }}>
                <h1 className="title">Account</h1>
                <div
                  className="site-layout-background"
                  style={{ padding: 24, minHeight: 360 }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    activeKey={currentTabKey.toString()}
                    onTabClick={(key) => {
                      setCurrentTabKey(parseInt(key));
                    }}
                  >
                    <Tabs.TabPane tab="Edit Account" key="1">
                      <EditAccount></EditAccount>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Change Password" key="2">
                      <ChangePassword></ChangePassword>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </Content>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </RecoilRoot>
  );
};
// };

export default AccountPage;
