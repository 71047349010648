import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, message, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import { LoadingSpinner } from "src/Components";
import config from "src/Config";
import axios from "axios";
import CreateFilmBasicSection from "./basicDetails";
import CreateFilmTechnicalSection from "./technicalDetails";
import CreateFilmCensorSection from "./ratingDetails";
import CreateFilmCrewSection from "./crewDetails";
import CreateFilmAwardSection from "./awardDetails";
import CreateFilmPosterSection from "./posterDetails";
import CreateFilmReviewSection from "./review";
import { FilmProjectPageForm } from "..";
import moment, { Moment } from "moment";
import confirm from "antd/lib/modal/confirm";
import { startCase, camelCase, reduce } from "lodash";
import useModal from "antd/lib/modal/useModal";
import { capitalize } from "src/utils/capitalize";

const CreateFilmProject = () => {
  let { id } = useParams();

  const navigate = useNavigate();
  const [modal, contextHolder] = useModal();
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState<any[]>([]);
  const [error, setError] = useState<boolean>(true);
  const [addendumMode, setAddendumMode] = useState<boolean>(false);
  const [currentTabKey, setCurrentTabKey] = useState<number>(1);
  const [loading, setLoading] = useState<Boolean>(true);
  const [submitText, setSubmitText] = useState<string>("Create Project");
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [film, setFilm] = useState<FilmProjectPageForm>();

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      if (id === undefined) {
        return setLoading(false);
      }
      const res = await axios.get(config.api_url + `/projects/films/${id}`);
      let tmpFilm = res.data;

      if (tmpFilm?.kind !== "film") {
        message.error(`The Project is of type ${tmpFilm.kind}`);
        navigate("/partner/projects");
        return setLoading(false);
      }

      if (tmpFilm?.status === "published") {
        setSubmitText("Update Project");
        if (tmpFilm.distribution) {
          const res = await axios.get(config.api_url + `/distributions/${tmpFilm.distribution}`);
          if (res.data!.status! === "active") {
            setAddendumMode(true);
          }
        }
      }
      if (tmpFilm?.completionDate) {
        tmpFilm.completionDate = moment(tmpFilm.completionDate, "DD-MM-YYYY");
      }
      if (tmpFilm?.awards?.length > 0) {
        for (let i in tmpFilm.awards) {
          tmpFilm.awards[i].year = moment(tmpFilm.awards[i].year);
        }
      }

      tmpFilm.stills = tmpFilm.stills.filter((s: string) => s !== null);

      setFilm(tmpFilm);
      setLoading(false);
    })();
    return () => {};
  }, []);

  useEffect(() => {
    formErrors.length > 0 ? setError(true) : setError(false);
    console.log(formErrors);
  }, [formErrors]);

  // Scroll to top on tab change
  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentTabKey > 4) {
      setTimeout(() => {
        let formErrorsTmp: {
          name: (string | number)[];
          errors: string[];
          warnings: string[];
        }[] = form.getFieldsError().filter((err) => err.errors.length);
        setFormErrors(formErrorsTmp);
        setError(formErrorsTmp.length > 0 ? true : false);
      }, 1000);
    }
  }, [currentTabKey]);

  const deleteDraft = () => {
    confirm({
      content: "Do you wish to delete this Project?",
      onOk() {
        setSubmitLoading(true);
        if (film?.status === "draft") {
          if (id === undefined) {
            id = film._id;
          }
          axios.delete(config.api_url + `/projects/${id}`).then(
            (res) => {
              // console.log(res);
              message.success("Project deleted successfully");
              setSubmitLoading(false);
              navigate("/partner/projects");
            },
            (err) => {
              message.error(err);
              console.error(err);
              setSubmitLoading(false);
            }
          );
        } else {
          message.error("Film can be deleted only in draft status");
          setSubmitLoading(false);
        }
      },
    });
  };

  const onFinish = async (values: any) => {
    setSubmitLoading(true);
    let formErrorsTmp: {
      name: (string | number)[];
      errors: string[];
      warnings: string[];
    }[] = form.getFieldsError().filter((err) => err.errors.length);
    setFormErrors(formErrorsTmp);
    setError(formErrorsTmp.length > 0 ? true : false);
    form.validateFields();
    onNext();

    console.debug("Received values of form: ", values);
    setSubmitLoading(false);
  };

  const onNext = async () => {
    window.scrollTo(0, 0);
    setSubmitLoading(true);
    if (currentTabKey > 3) {
      let formErrorsTmp: {
        name: (string | number)[];
        errors: string[];
        warnings: string[];
      }[] = form.getFieldsError().filter((err) => err.errors.length);
      setFormErrors(formErrorsTmp);
      setError(formErrorsTmp.length > 0 ? true : false);
      // console.log(formErrorsTmp);
    }
    const values = { ...form.getFieldsValue() };
    values.kind = "film";
    if (!values.title || !values.englishTitle || !values.customURL) {
      setSubmitLoading(false);
      return message.error("Please add Film title, English Title and Custom URL before saving it");
    }
    values.title = startCase(camelCase(values?.title!));
    values.englishTitle = startCase(camelCase(values?.englishTitle!));
    values.customURL = values.customURL?.toLowerCase();
    if (values?.completionDate) {
      values.completionDate = values.completionDate?.format("DD-MM-YYYY");
    }
    if (values?.awards?.length > 0) {
      let awards = [];
      for (let i in values.awards) {
        awards.push({
          ...values.awards[i],
          ...{ year: values.awards[i].year?.format("YYYY") },
        });
      }
      values.awards = awards;
    }
    // values.director = values.director?.filter((d: { name: string }) => d.name !== ("" || undefined));
    // values.producer = values.producer?.filter((d: { name: string }) => d.name !== ("" || undefined));
    // values.crew = values.crew
    //   ?.filter((d: { name: string; role: string }) => d.name !== ("" || undefined) || d.role !== ("" || undefined))
    //   .map((d: { name: string; role: string }) => {
    //     d.name = startCase(camelCase(d.name));
    //     d.role = startCase(camelCase(d.role));
    //     return d;
    //   });
    // values.cast = values.cast
    //   ?.filter(
    //     (d: { name: string; character: string }) => d.name !== ("" || undefined) || d.character !== ("" || undefined)
    //   )
    //   .map((d: { name: string; character: string }) => {
    //     d.name = startCase(camelCase(d.name));
    //     d.character = startCase(camelCase(d.character));
    //     return d;
    //   });
    values.awards = values.awards?.filter(
      (d: { name?: string; country?: string; year?: string; category?: string[]; role?: string; status: string }) =>
        d.name !== ("" || undefined) ||
        d.country !== ("" || undefined) ||
        d.year !== ("" || undefined) ||
        d.role !== ("" || undefined) ||
        d.status !== ("" || undefined)
    );
    // values.links = values.links?.filter((d: { link: string }) => d.link !== ("" || undefined))
    if (currentTabKey === 7) {
      values.status = "published";
    }

    axios.post(config.api_url + "/projects/films", { ...film, ...values }).then(
      (res) => {
        setFilm(res.data);
        setSubmitLoading(false);

        if (currentTabKey === 7) {
          if (film?.status === "published") {
            message.success("Film updated!");
          } else {
            message.success(`${capitalize(film?.kind ?? "Film/Series")} published!`);
            modal.confirm({
              title: "Film published!",
              content: `Thank you for sharing the details of your ${capitalize(
                film?.kind ?? "film/series"
              )}. We invite you to submit your ${capitalize(
                film?.kind ?? "film/series"
              )} for distribution across platforms for viewership and monetization. Kindly visit www.pocketfilms.in/faqs for distribution terms and submission process.`,
              okText: "Submit for Distribution",
              cancelText: "Continue",
              onOk: () => {
                navigate(`/partner/distributions/create/${id ? id : film?._id}`);
              },
              onCancel: () => {
                navigate("/partner/projects");
              },
            });
          }
          if ("distribution" in film!) {
            return navigate("/partner/projects");
          }
          // confirm({
          //   content: "Do you wish to submit your Project for distribution?",
          //   okText: "Yes",
          //   onOk() {
          //     navigate(`/partner/distributions/create/${id ? id : film?._id}`);
          //   },
          //   cancelText: "No",
          //   onCancel() {
          //     navigate("/partner/projects");
          //   },
          // });
        } else {
          setCurrentTabKey(currentTabKey + 1);
          message.info("Saved progress");
        }
      },
      (err) => {
        setSubmitLoading(false);
        message.error("Error: " + err.response.data.message, 10);
        console.error(err);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        {contextHolder}
        <div className="d-flex justify-content-between">
          <h1 className="title">{film === undefined ? "Add a Film" : `Edit Film (${film?.status})`}</h1>
          {film?.status === "draft" ? (
            <Button onClick={deleteDraft} danger>
              Delete
            </Button>
          ) : null}
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          wrapperCol={{ span: 18 }}
          labelCol={{ span: 6 }}
          labelAlign="left"
          initialValues={film}
          scrollToFirstError={true}
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={currentTabKey.toString()}
            onTabClick={(key) => {
              return parseInt(key) < 7 ? setCurrentTabKey(parseInt(key)) : null;
            }}
          >
            <Tabs.TabPane forceRender={true} tab="Basic Details" key="1">
              Basic Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateFilmBasicSection id={film?._id} form={form} addendumMode={addendumMode} />
                <div className="text-end">
                  <Button type="primary" htmlType="button" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Technical Details" key="2">
              Technical Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateFilmTechnicalSection id={film?._id} addendumMode={addendumMode} />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      setCurrentTabKey(currentTabKey - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="button" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Censor Ratings" key="3">
              Censor Ratings
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateFilmCensorSection id={film?._id} />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      setCurrentTabKey(currentTabKey - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="button" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Cast and Crew Details" key="4">
              Cast and Crew Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateFilmCrewSection id={film?._id} form={form} />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      setCurrentTabKey(currentTabKey - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Awards" key="5">
              Awards (optional)
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateFilmAwardSection id={film?._id} form={form} />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      setCurrentTabKey(currentTabKey - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Posters and Other Details" key="6">
              Posters and Other Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateFilmPosterSection
                  id={film?._id}
                  form={form}
                  stills={film?.stills || []}
                  verticalPosterURL={film?.verticalPosterURL || ""}
                  horizontalPosterURL={film?.horizontalPosterURL || ""}
                />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      setCurrentTabKey(currentTabKey - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Review" key="7">
              Review
              <div className="site-layout-background" style={{ paddingTop: 24, minHeight: 360 }}>
                <CreateFilmReviewSection
                  id={film?._id}
                  form={form}
                  formErrors={formErrors}
                  navigate={setCurrentTabKey}
                />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      setCurrentTabKey(currentTabKey - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <SubmitButton onNext={onNext} error={error} submitLoading={submitLoading} submitText={submitText} />
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Content>
    );
};

const SubmitButton = (props: {
  error: boolean;
  onNext: MouseEventHandler<HTMLElement>;
  submitLoading: boolean;
  submitText: String;
}) => {
  return (
    <Button
      disabled={props.error}
      key={props.error ? 1 : 0}
      type="primary"
      htmlType="button"
      onClick={props.onNext}
      loading={props.submitLoading}
    >
      {props.submitLoading ? "Saving" : props.submitText}
    </Button>
  );
};

export default CreateFilmProject;
