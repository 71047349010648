import { Link } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { Footer, Navbar } from "src/Components";

const FAQsPage = () => {
  return (
    <RecoilRoot>
      <div className="container-fluid">
        <Navbar />

        <div
          className="container my-5 text-light"
          style={{
            backgroundColor: "#13131A",
            borderRadius: "20px",
            padding: "40px",
          }}
        >
          <h1 className="mb-5">Frequently Asked Questions</h1>

          <ol className="list-group list-group-numbered">
            <li className="list-group-item mt-4">
              <strong>Is it compulsory to sign up?</strong>
              <br />
              Welcome to the Content Partner Portal (CPP) on Pocket Films. This portal is for all creators who want to
              submit their films to Pocket Films for distribution and monetization. It is compulsory to sign up before
              you can move forward.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>What are the sign up options?</strong>
              <br />
              You can create your account on Pocket Films by either one of the following methods:
              <br />
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  By Email: Enter the details requested on the form. Please note that the email id will be your unique
                  reference in our system and all communications and verifications (at a later stage) will be with that
                  email id only. This email ID cannot be changed later.
                </li>
                <li>
                  Via Google: You can use your Google credentials to sign up. Once you click on the link it will ask you
                  to select / login to your google account. In the event, any mandatory details are not available, the
                  same will be requested. Once you have logged in through Google, you can easily login the next time to
                  Pocket Films via the Google account
                </li>
                <li>
                  By Mobile: You can also create your account with our Mobile number, however, do note that you will
                  need to submit your email id before you can submit any project.
                </li>
              </ol>
            </li>
            <li className="list-group-item mt-4">
              <strong>What are the benefits of subscribing to newsletters / mobile updates?</strong>
              <br />
              By subscribing to the newsletters and/or the mobile updates you give us the permission to reach out to you
              to share some information such as updates on new film releases, updates on the industry events which may
              be relevant to you, information about film festivals, etc. (Coming soon)
              <br />
              Do note, irrespective of your preference, we reserve the right to reach out to you on your email and/or
              mobile with regards to any activity in relation to Pocket Films.
            </li>
            <li className="list-group-item mt-4">
              <strong>What are the benefits of distributing through Pocket Films?</strong>
              <br />
              As a creator / right holder of content, we believe you would like your film(s) to reach out to a wide
              audience while also generating revenue. This is where Pocket Films' Distribution comes into play. Once
              approved, we will release your film on our own OTT platform and apps (coming soon) as well as on other
              platforms and mediums.
              <br />
              Thus, the benefits of distributing through us are:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>Getting your film in front of audiences globally</li>
                <li>
                  Being available on all platforms and formats like internet, mobile, TV, cinemas, in-flight
                  entertainment, etc.
                </li>
                <li>
                  Possibility of getting your film screened at select international festivals as Pocket Films' curated
                  content package
                </li>
                <li>
                  Generating income from all of the above distributions activities undertaken by Pocket Films on a
                  revenue share basis.
                </li>
                <li>
                  Getting a team of professionals who are focussed on providing the best reach and visibility for your
                  content while optimising revenues.
                </li>
              </ol>
            </li>
            <li className="list-group-item mt-4">
              <strong>On which platforms will my film be available?</strong>
              <br />
              The platforms on which you want to make your film(s) available via Pocket Films is entirely upto you.
              Pocket Films is the No. 1 distributor of short films in the country, the No. 1 partner for YouTube in
              India for short films, the preferred partner for Facebook, Disney+ Hotstar and is available on MX Player,
              Vodafone and many other platforms, domestic and international. Pocket Films is also continuously exploring
              options of partnering with more platforms. As mentioned earlier, Pocket Films will soon also launch its
              own OTT and apps.
              <br />
              When you submit your film for distribution you can choose from the multiple options available, such as:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>
                  All digital platforms including but not limited to internet, mobile, IPTV, etc. (Preferred, as it
                  enables Pocket Films to maximise distribution and monetization for your film(s))
                </li>
                <li>
                  PF owned platforms (its website, mobile apps, apps on other platforms, app-in-app, integrations,
                  etc.). This option is mandatory.
                </li>
                <li>PF branded channels on other platforms including but not limited to YouTube, Facebook, etc.</li>
                <li>Syndication across platforms excluding YouTube and Facebook.</li>
                <li>TV and broadcast</li>
                <li>Theatrical, festival, public/private screenings</li>
              </ol>
              The availability of the film also depends upon the assessment by the Pocket Films' content team as well as
              selection by the publishing platforms, if applicable.
            </li>
            <li className="list-group-item mt-4">
              <strong>How will revenue be generated for my film?</strong>
              <br />
              Typically, and most often, the monetisation for our content is through advertisements and on a revenue
              sharing basis. Some platforms, however, have different means of monetisation for eg. based on minutes of
              your content watched by their subscribers or some fixed payment for your content for a certain period. For
              each platform that we distribute your content, we receive a report from them with regards to the income
              generated on your film on their platform and what our share for the same is. You are entitled to your
              share of revenue based on the revenue received by us from such platform(s).
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>What is my share in the revenue?</strong>
              <br />
              As a creator you are entitled to a revenue share @ 37.50% of the gross revenues received by us.
              <br />
              Gross Revenues mean the revenue we receive from the platform for your content. All costs not directly
              related to the distribution activity will be borne by Pocket Films. Gross Revenues and calculation of
              revenue share has been explained in detail in the agreement.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>How and when will I receive the payment due to me?</strong>
              <br />
              Revenue reports will be generated for every calendar quarter (Jan-Mar, Apr-June, etc.) and the same will
              be shared or made available to you 60-75 days after the end of each respective calendar quarter. You will
              be required to raise an invoice for the amount due to you and the payment will be transferred to your bank
              account.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>Which rights will I be granting to Pocket Films?</strong>
              <br />
              For all revenue sharing agreement you will be granting only distribution rights to Pocket Films. The
              Intellectual Property Rights or IPR, of the film always remains with you.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>For how many years is the distribution agreement valid?</strong>
              <br />
              The agreement is initially for a period of 5 years. The agreement will automatically be renewed for
              another period of 5 years unless you exercise the option to not renew the agreement. This option needs to
              be exercised 60 days prior to the date on which the agreement would expire.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>Can the distribution agreement be terminated?</strong>
              <br />
              Both parties have the right to terminate the agreement. The detailed terms applicable for termination are
              detailed in the agreement
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>Can I submit my film to festivals after I grant distribution rights to Pocket Films?</strong>
              <br />
              Yes, you can submit your film for participation in film festivals after granting us the distribution
              rights. You simply need to ensure that while participating to such festival, you do not grant them any
              distribution rights that would impede or contradict the rights granted to Pocket Films.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>Is the distribution agreement exclusive?</strong>
              <br />
              As detailed above, you would be granting the rights to us for specific platforms, however, the rights so
              granted will be exclusive for all such platforms. Eg. if you have granted us the rights for PF Branded
              Channels (which include YouTube, Facebook, etc.) then it would mean that you have granted exclusive rights
              to us for YouTube, Facebook, etc.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>What is the process to be followed to submit a film to Pocket Films?</strong>
              <br />
              To submit your film to Pocket Films kindly follow these steps:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>After signing in click on “Create a New Project” or on “My Projects” on the left menu.</li>
                <li>
                  Please note that you will be asked to complete your contact details and primary information, if not
                  already provided earlier.
                </li>
                <li>
                  Click on 'Add Film’. It will open the form wherein you are required to submit the details of your
                  film. You are requested to submit maximum details accurately as it helps us in reviewing your
                  submission for distribution.
                </li>
                <li>
                  Please ensure that you enter all the mandatory fields marked with ‘*’. Upon completion of submitting
                  all the details, you will come on the review page. Kindly review all the information you have provided
                  before submitting the project. Please note that items marked in RED are mandatory fields not filled by
                  you or they have some error. Kindly rectify the error by selecting the respective tab from above.
                  Please note that you will not be able to proceed further if all mandatory fields are not filled.
                </li>
                <li>
                  Once reviewed click on ‘Submit’. If you are submitting your first film, you will be asked if you want
                  to submit your film for distribution as ‘Individual’ or ‘Business’. Select ‘Business’ only if the
                  rights of the film are owned by the company, you are representing the company and the company is
                  authorized to receive the revenue share amount in its bank account. Please note that once you have
                  made your selection, the same will be permanently linked to your registered email id and mobile
                  number. All subsequent submissions will be treated similarly. The option once selected cannot be
                  changed later.
                </li>
                <li>
                  If you have note created a Business Profile and you select ‘Business’ you will be required to complete
                  the Business Profile form. If you have registered your business earlier, then it will directly move to
                  Distribution.
                </li>
                <li>
                  Under Distributions, you will be required to upload your films, trailer, vertical video, vertical
                  trailer, subtitle files, censor certificate and music cue sheet. Please note, Video (your complete
                  film) is the only mandatory field here, however, we urge you to provide as much content and
                  information as possible for better distribution of your film.
                </li>
                <li>
                  The next tab is Rights Management, you can select the following:
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      Territory (meaning the territory for which you are granting the rights to Pocket Films for
                      distribution). The options are Worldwide (recommended), Worldwide Excluding (where you are
                      granting the rights for the entire world except the countries you specify) or Specific Country
                      (where you are granting the rights to us only for a specific country). Kindly enter the correct
                      details as it would significantly impact the distribution of your film.
                    </li>
                    <li>
                      Platforms for which you are granting the rights to us. We recommend selecting All to improve the
                      visibility and monetization opportunities for your film. The details explanation for Platforms is
                      given above.
                    </li>
                    <li>
                      Monetisation Model is currently only AVoD (Advertising-based Video on Demand). However, in future,
                      we aim to offer SVoD and TVoD options as well. As of now, the monetisation model will be All by
                      default.
                    </li>
                    <li>Signature: You may then type your signature and submit the form.</li>
                    <li>Your submission is now complete and our team will review and revert.</li>
                  </ol>
                </li>
              </ol>
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>How much time will the team take to review my submission?</strong>
              <br />
              The team will revert within 7-10 working days. If the team approves your film then you will receive a copy
              of the agreement. If the team has any queries, they will raise a ticket. If a ticket has been raised you
              will receive a notification (the bell icon on the top right corner of the site). Kindly respond to the
              tickets at the earliest with the necessary documents / clarifications to enable to team to complete the
              review at the earliest.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>After submission, can I raise a ticket if I have any query or for follow-up?</strong>
              <br />
              Raising a ticket is the best way to communicate with the team and will get the fastest response. You can
              definitely raise a ticket with regards to the particular film at any time after submission.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>What are the technical qualifications and file size limitations we need to keep in mind?</strong>
              <br />
              <table className="table table-dark table-bordered border-light">
                <tr>
                  <th>Assets</th>
                  <th>Technical Specifications Required</th>
                </tr>
                <tr>
                  <td>All video files (Trailer, Film, Vertical Trailer and Film)</td>
                  <td>
                    Shoot in HD - 1080p. Min 25-30 MBPS video bitrate.
                    <br />
                    Aspect Ratio 16:9. For vertical videos, aspect ratio should be 4:5
                    <br />
                    Audio - 44100 hz. LUFS (Loudness Unit Full Scale) to be maintained between -15 to -17
                    <br />
                    Max file size for Video (Film) - 35 GB
                    <br />
                    Max file size for Trailers - 5 GB
                  </td>
                </tr>
                <tr>
                  <td>Posters</td>
                  <td>
                    One vertical poster 1920 x 1080 pixels - 600 DPI
                    <br />
                    One vertical poster 1200 x 1600 pixels - 600 DPI
                    <br />
                    Other images - 1920 x 1080 pixels - 600 DPI
                  </td>
                </tr>
                <tr>
                  <td>Subtitles file</td>
                  <td>
                    We need the subtitle file in .SRT format. Burnt-in subtitles are not preferred. You may select the
                    language of the subtitles, however, English language subtitles are default
                  </td>
                </tr>
                <tr>
                  <td>Censor Certificate</td>
                  <td>This is NOT mandatory. If you have a certificate, you can upload the same. Max File size 5 MB</td>
                </tr>
                <tr>
                  <td>Music Cue Sheet</td>
                  <td>This is NOT mandatory. If you have it, you can upload the same. Max File size 5 MB</td>
                </tr>
                <tr>
                  <td>Disclaimer</td>
                  <td>
                    This disclaimer should be added at the beginning of the video and should not be more than 2 seconds
                    in duration.{" "}
                    <Link
                      to="/files/disclaimer.jpg"
                      className="text-white fw-bold text-decoration-underline"
                      style={{ background: "None" }}
                      target={"_blank"}
                      download
                    >
                      Click here
                    </Link>{" "}
                    to download.
                  </td>
                </tr>
                <tr>
                  <td>Smoking, Alcohol & Drugs</td>
                  <td>
                    As required by law, Smoking, Alcohol, and Drug warning signs should be added to the relevant scenes
                    and be visible till the said action is visible.{" "}
                    <Link
                      to="/files/warning-signs.zip"
                      className="text-white fw-bold text-decoration-underline"
                      style={{ background: "None" }}
                      target={"_blank"}
                      download
                    >
                      Click here
                    </Link>{" "}
                    to download as Ashtons
                  </td>
                </tr>
              </table>
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>Can I review the draft agreement?</strong>
              <br />
              Yes, you can. Please{" "}
              <Link to={`/draft-agreement`} className="text-white fw-bold text-decoration-underline">
                click here
              </Link>{" "}
              to review the draft agreement.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>What is Business Profile?</strong>
              <br />
              This section is for the details of your company, provided you want to submit your film for distribution
              under the name of the company. Do keep in mind that once you create this company all the films submitted
              from this account will be under this company only and the payments will also be received in the company's
              name only. This is a one-time exercise and the company name cannot be altered later.
              <br />
              Please note, this section is to be filled in only if you want to submit your films for distribution as a
              company.
              <br />
            </li>
            <li className="list-group-item mt-4">
              <strong>
                What is a Professional Profile? Since it is not mandatory, what benefits will I derive by submitting
                this information?
              </strong>
              <br />
              This is like your resume / CV. You may update your details here. This is a one-time exercise and not
              mandatory.
              <br />
              Pocket Films’ product pipeline includes creating an extensive database of independent content creators and
              talent with a view to enhancing their visibility and job opportunities.
              <br />
            </li>
          </ol>
          {/* <p>
            The Pocket Films journey started in 2009 with a firm belief in the
            potential of short format content on digital media and with the
            singular intent to encourage indie creators.
          </p>

          <p>
            At a time when short films weren't taken seriously by the industry,
            we pioneered monetization of content for short film makers, enabling
            them to find an audience for their creativity and at the same time,
            helping them reap the financial rewards of their films' success.
            Over the years, we have earned the trust and goodwill of creators,
            acting as facilitators for their journey from short films to indie
            feature films.
          </p>

          <p>
            Our distribution network started out with our flagship channel on
            YouTube, but we quickly expanded to other mediums as well, including
            being the first in India to have a weekly TV show on NDTV Prime
            called ‘Prime Talkies with Pocket Films' featuring short films from
            our catalogue and interviews with promising short film makers.
          </p>

          <p>
            Our distribution reach extends to internet, mobile, OTT, satellite,
            TV, theatres, in-transport, etc. As the industry leaders in short
            film distribution, our library consists of more than 5,000 videos,
            ~4 million plus subscribers and more than 1.75 billion views on
            YouTube and growing every day.
          </p>

          <p>
            In addition to these platforms, we have also pioneered theatrical
            screenings of short films in multiple Indian cities in association
            with Vkaao (a PVR Cinemas and BookMyShow joint venture), in our
            quest to shine the spotlight on indie film makers even more.
          </p>

          <p>
            Curated short films from our library are regularly screened at
            prestigious international film festivals all over the world.
          </p>

          <h4>What's new?</h4>

          <p>
            Pocket Films is now a complete ecosystem for the independent short
            film industry. In addition to distribution services, we have
            expanded to festival hosting and submission services (coming soon)
            and a profile database that aims to connect film makers with
            industry professionals.
          </p>

          <p>
            The Festivals section (coming soon) serves two functions - on one
            hand, it enables film makers to win laurels by participating in
            global film festivals and showcasing their talent to domestic and
            international audiences.
          </p>

          <p>
            On the other hand, it allows reputed film festivals to list
            themselves with us, giving them an easy access to the pool of indie
            talent, while providing them with tools to completely manage the
            festival.
          </p>

          <p>
            The Profiles section of our website aims to enable independent
            creators to find resources, build their teams, network with industry
            professionals and explore career opportunities.
          </p> */}
        </div>
        <Footer />
      </div>
    </RecoilRoot>
  );
};

export default FAQsPage;
