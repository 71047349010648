import { Button, Modal, Space, message } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import config from "src/Config";
import { EpisodeSeasonSeriesProjectPageForm, SeasonSeriesProjectPageForm, SeriesProjectPageForm } from "..";
import { LoadingSpinner, EpisodeProfile } from "src/Components";
import { useNavigate, useParams } from "react-router-dom";

const ViewEpisodeSeasonSeriesProject = () => {
  const { seriesId, seasonId, episodeId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [series, setSeries] = useState<SeriesProjectPageForm>();
  const [season, setSeason] = useState<SeasonSeriesProjectPageForm>();
  const [episode, setEpisode] = useState<EpisodeSeasonSeriesProjectPageForm>();
  const { confirm } = Modal;

  // Fetch profile data if already present
  useEffect(() => {
    (() => {
      axios.get(`${config.api_url}/projects/series/${seriesId}`).then(
        (res) => {
          setSeries(res.data);
          let season = (res.data as SeriesProjectPageForm)?.seasons.find((s) => s._id == seasonId);
          if (season) {
            setSeason(season);
            let episode = season.episodes.find((e) => e._id == episodeId);
            setEpisode(episode);
          } else {
            message.error(`Season not found`);
            console.debug(res.data);
            setError("Season not found");
          }
          setLoading(false);
        },
        (err) => {
          message.error(`${err.message} - ${err.response.data.message}`);
          setError("Series not found");
          setLoading(false);
        }
      );
    })();
    return () => {};
  }, []);

  const showDeleteEpisodeConfirm = () => {
    confirm({
      title: "Are you sure delete this content?",
      icon: <ExclamationCircleOutlined />,
      content: "Delete this Episode?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoading(true);
        let tmpProfile = series!;
        if (series) {
          tmpProfile.seasons = series?.seasons?.map((s: SeasonSeriesProjectPageForm) => {
            if (s._id === seasonId) {
              s.episodes = s?.episodes.filter((ep: EpisodeSeasonSeriesProjectPageForm) => ep._id !== episodeId);
            }
            return s;
          });
          delete tmpProfile.__v;
          axios.post(config.api_url + "/projects/series", tmpProfile).then(
            (res) => {
              setLoading(false);
              navigate(`/partner/projects/series/view/${seriesId}/season/${seasonId}`);
            },
            (err) => {
              setLoading(false);
              message.info("Error: " + err.message);
              console.error(err);
            }
          );
        } else {
          message.error("Series not found");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const editEpisode = () =>
    navigate(`/partner/projects/series/edit/${seriesId}/season/${seasonId}/episode/${episodeId}`);

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
        <div>
          {error ? (
            <p className="text-danger text-center">{error}</p>
          ) : (
            <>
              <div className="d-flex justify-content-between mb-2">
                <Button
                  className="btn-outline-primary"
                  onClick={() => {
                    navigate(`/partner/projects/series/view/${seriesId}/season/${seasonId}`);
                  }}
                  icon={<ArrowLeftOutlined />}
                >
                  Back to Season
                </Button>
                <Space>
                  {season?.distribution ? null : (
                    <>
                      <Button className="btn-outline-primary" onClick={editEpisode}>
                        Edit
                      </Button>
                      <Button className="btn-outline-danger" onClick={showDeleteEpisodeConfirm}>
                        Delete
                      </Button>
                    </>
                  )}
                </Space>
              </div>
              <EpisodeProfile series={series!} season={season!} profile={episode!} edit={true} />
            </>
          )}
        </div>
      </div>
    );
  }
};

export default ViewEpisodeSeasonSeriesProject;
