import { Form, FormInstance, Input } from "antd";
import { ImageUploader } from "src/Components";
import config from "src/Config";
import { useEffect, useState } from "react";
import { UploadFile } from "antd/lib/upload/interface";

const CreateEpisodePosterSection = (props: {
  id: string | undefined;
  form: FormInstance<any>;
  verticalPosterURL: string;
  horizontalPosterURL: string;
}) => {
  const [imageURLs, setImageURLs] = useState<{
    verticalPosterURL: string | undefined;
    horizontalPosterURL: string | undefined;
  }>();
  const [verticalPosterURL, setVerticalPosterURL] = useState<
    string | undefined
  >(props.verticalPosterURL);
  const [horizontalPosterURL, setHorizontalPoster] = useState<
    string | undefined
  >(props.horizontalPosterURL);

  useEffect(() => {
    setImageURLs({
      verticalPosterURL: verticalPosterURL,
      horizontalPosterURL: horizontalPosterURL,
    });
    props.form.setFieldsValue({
      ["verticalPosterURL"]: verticalPosterURL || "",
      ["horizontalPosterURL"]: horizontalPosterURL || "",
    });
  }, [verticalPosterURL, horizontalPosterURL]);

  return (
    <>
      {
        <Form.Item
          name="verticalPosterURL"
          rules={[
            {
              required: true,
              message: "Upload Vertical Poster",
            },
          ]}
          hidden
        >
          <Input
            type="hidden"
            name="verticalPosterURL"
            value={imageURLs?.verticalPosterURL}
          />
        </Form.Item>
      }
      <Form.Item
        label="Vertical Poster"
        rules={[
          {
            required: true,
            message: "Upload Vertical Poster",
          },
        ]}
      >
        <ImageUploader
          name="verticalPoster"
          aspect={3 / 4}
          url={verticalPosterURL ? [verticalPosterURL] : []}
          action={config.api_url + "/file"}
          onRemove={(file: UploadFile) => setVerticalPosterURL(undefined)}
          onComplete={(url: UploadFile[]) => {
            setVerticalPosterURL(url[0].response.url);
          }}
        />
      </Form.Item>
      {
        <Form.Item
          name="horizontalPosterURL"
          rules={[
            {
              required: true,
              message: "Upload Horizontal Poster",
            },
          ]}
          hidden
        >
          <Input
            type="hidden"
            name="horizontalPosterURL"
            value={imageURLs?.horizontalPosterURL}
          />
        </Form.Item>
      }
      <Form.Item
        label="Horizontal Poster"
        rules={[
          {
            required: true,
            message: "Upload Horizontal Poster",
          },
        ]}
      >
        <ImageUploader
          name="horizontalPoster"
          aspect={16 / 9}
          url={horizontalPosterURL ? [horizontalPosterURL] : []}
          action={config.api_url + "/file"}
          onRemove={(file: UploadFile) => setHorizontalPoster(undefined)}
          onComplete={(url: UploadFile[]) => {
            setHorizontalPoster(url[0].response.url);
          }}
        />
      </Form.Item>
    </>
  );
};

export default CreateEpisodePosterSection;
