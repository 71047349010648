import { AutoComplete, Button, DatePicker, Form, Input, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState, useEffect } from "react";
import { MasterService } from "src/Services/master.service";
import { Subject, debounceTime } from "rxjs";
import config from "src/Config";

const searchSubject$ = new Subject<string>();

const CreateSeasonAwardSection = (props: { id: string | undefined }) => {
  const [awardCategory, setAwardCategory] = useState<string[]>();
  const [festivalStatus, setFestivalStatus] = useState<string[]>();
  const [countries, setCountries] = useState<string[]>();
  const [crewRole, setCrewRole] = useState<string[]>();
  const [profilesResults, setProfilesResults] = useState<{ label: string; value: string; _id: string }[]>([]);

  useEffect(() => {
    const listener = searchSubject$.pipe(debounceTime(500)).subscribe(async (search) => {
      const response = await axios.get<{ _id: string; name: string; customURL: string }[]>(
        `${config.api_url}/professional-profile/search/${search}`
      );

      const options: { value: string; label: any; _id: string }[] = response.data.map((result) => ({
        value: `${result.name} (${result.customURL})`,
        label: (
          <span>
            {result.name} (
            <a
              href={`${config.base_url}/profile/${result.customURL}`}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                window.open(`${config.base_url}/profile/${result.customURL}`);
              }}
            >
              {result.customURL}
            </a>
            )
          </span>
        ),
        _id: result._id,
      }));

      const businessResponse = await axios.get<{ _id: string; companyName: string; customURL: string }[]>(
        `${config.api_url}/business-profile/search/${search}`
      );

      options.push(
        ...businessResponse.data.map((result) => ({
          value: `${result.companyName} (${result.customURL}) - Business`,
          label: (
            <span>
              {result.companyName} - Business (
              <a
                href={`${config.base_url}/company/${result.customURL}`}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`${config.base_url}/company/${result.customURL}`);
                }}
              >
                {result.customURL}
              </a>
              )
            </span>
          ),
          _id: result._id,
        }))
      );

      setProfilesResults(options);
    });

    return () => listener.unsubscribe();
  }, []);

  useEffect(() => {
    MasterService.getList(["awardCategory", "festivalStatus", "countries", "crewRole"]).then(
      axios.spread((awardCategory, festivalStatus, countries, crewRole) => {
        setAwardCategory(awardCategory.values);
        setFestivalStatus(festivalStatus.values);
        setCountries(countries.values);
        setCrewRole(crewRole.values);
      })
    );
  }, []);

  return (
    <>
      <Form.Item name="awards" label="Awards" className="d-flex">
        <Form.List name="awards">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }) => (
                <div className="mb-3" key={key}>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "name"]}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Enter name of the Festival",
                        },
                        {
                          pattern: new RegExp("^[0-9a-zA-Z,. ]*$"),
                          message: "Only ,. are allowed as special characters",
                        },
                        {
                          max: 100,
                          message: "Max. limit is 100 characters",
                        },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input size="large" placeholder="Enter name of the Festival" />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "country"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Enter country of award reception",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        placeholder="Please select Award country"
                        showSearch
                      >
                        {countries?.map((country) => (
                          <Select.Option key={country} value={country}>
                            {country}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[name, "year"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Enter date of award",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"YYYY"}
                        size="large"
                        style={{ width: "100%" }}
                        picker="year"
                        placeholder="Select your Award Year"
                      />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "category"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Enter country of award category",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select Award Category"
                        showSearch
                      >
                        {awardCategory?.map((category) => (
                          <Select.Option key={category} value={category}>
                            {category}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item
                    name={[name, "role"]}
                    style={{ flex: 1 }}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: "Award winner name is mandatory",
                      },
                    ]}
                  >
                    <AutoComplete
                      options={profilesResults}
                      onSearch={(searchString) => searchSubject$.next(searchString)}
                    >
                      <Input.Search size="large" placeholder="Winner Name" />
                    </AutoComplete>
                    {/* <Select
                        size="large"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select Crew's Role"
                        showSearch
                      >
                        {crewRole?.map((role) => (
                          <Select.Option key={role} value={role}>
                            {role}
                          </Select.Option>
                        ))}
                      </Select> */}
                  </Form.Item>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "status"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Select status of award at Festival",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select status at Festival"
                        showSearch
                      >
                        {festivalStatus?.map((status) => (
                          <Select.Option key={status} value={status}>
                            {status}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                  </div>
                </div>
              ))}
              <Form.Item>
                {fields.length < 5 ? (
                  <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                    Add an Award
                  </Button>
                ) : null}
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

export default CreateSeasonAwardSection;
