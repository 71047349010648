import { Avatar } from "antd";
import moment from "moment";

const ChatBox = (props: { actor: string, content: string, attachment: string, timestamp: string }) => {
    let timestamp = moment(props.timestamp);
    return (
        <div className="float-end my-3">
            <Avatar className={props.actor === "admin" ? "float-start me-2" : "float-end ms-2"} src={<img src={props.actor === "admin" ? "/img/admin-picture.jpg" : "/img/user-picture.png"} width={32} height={32} />} />
            <div className={props.actor === "admin" ? "col-6 msg left" : "col-6 msg right"} style={{ whiteSpace: "pre-line" }}>
                {props.content}
                <div>
                    {props.attachment ?
                        <div className="float-start"><a className="text-light text-decoration-underline" href={props.attachment} target="_blank">Attachment</a></div>
                        : null
                    }
                    <div className="time text-end">{timestamp.format('llll')}</div>
                </div>
            </div>
        </div>
    )
}

export default ChatBox;