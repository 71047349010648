import { Button, Row, Tag, message } from "antd";
import { startCase, camelCase } from "lodash";
import { FilmProjectPageForm } from "../../Pages/Partner/Projects";
import axios from "axios";
import config from "src/Config";
import { handleError } from "src/utils/handleError";
import { LinkedProfile } from "../LinkedProfile";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useModal from "antd/lib/modal/useModal";
import { wait } from "src/utils/wait";

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

const FilmProfile = (props: { profile: FilmProjectPageForm; edit?: boolean }) => {
  const [search] = useSearchParams();
  const [user, setUser] = useState<Record<string, any>>({});
  const [modal, modalConfig] = useModal();
  const linkingRequests = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    axios.get(config.api_url + "/user/me").then((res) => {
      setUser(res.data);
    });
  }, []);

  useEffect(() => {
    if (linkingRequests.current && search.get("linkingRequests") === "true") {
      linkingRequests.current.scrollIntoView();
    }
  }, [search, linkingRequests]);

  const resend = (
    category: "director" | "producer" | "crew" | "cast",
    entry: { resent?: number; user: string; _id: string; roleId?: string; name: string }
  ) => {
    modal.confirm({
      title: "Resend link request",
      content: `You have ${2 - (entry.resent ?? 0)} resends left for this linking request. Would you like to resend?`,
      okText: "Resend",
      onOk: () => {
        axios
          .post(
            `${config.api_url}/projects/resend-link-request/${props.profile._id}/${category}?user=${
              entry.user
            }&roleId=${entry._id ?? entry.roleId}` + (entry.name?.includes(" - Business") ? "&business=true" : "")
          )
          .then(() => {
            message.success("Request resent successfully!");

            return wait(500);
          })
          .then(() => window.location.reload())
          .catch((e) => message.error(handleError(e)));
      },
    });
  };

  return (
    <div className="text-light" style={{ overflowWrap: "break-word" }}>
      {modalConfig}
      <div className="text-center">
        <img style={{ width: "100%", height: 400, objectFit: "cover" }} src={props.profile.horizontalPosterURL} />
        <img
          style={{ width: 160, marginTop: -100, borderRadius: 8 }}
          className="border border-dark"
          src={props.profile.verticalPosterURL || process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"}
          alt={props.profile.customURL}
        />
        <h1 className="fw-bold my-3">
          {props.profile.title} {props.profile.englishTitle ? `(${props.profile.englishTitle})` : null}
        </h1>
        <div className="my-4">
          <Tag color="#108ee9" style={{ fontSize: "18px" }}>
            {startCase(camelCase(props.profile.category))}
          </Tag>
        </div>
        <div className="my-4">
          {props.profile.genre?.map((g, i) => (
            <Tag key={i} color="#108ee9" style={{ fontSize: "18px" }}>
              {startCase(camelCase(g))}
            </Tag>
          ))}
        </div>
      </div>
      <div className="my-2">
        Duration: {`${props.profile.duration?.mm} minute ${props.profile.duration?.ss} seconds`}
      </div>
      <div className="my-2">Tagline: {props.profile.tagline}</div>
      <div className="my-2">Language: {props.profile.language}</div>
      <div className="my-2">Synopsis: {props.profile.synopsis}</div>
      <div className="my-2">
        Censor Rating:
        <div className="m-2">Self Censor: {props.profile.censor}</div>
        <div className="m-2">Language rating: {props.profile.languageRating}</div>
        <div className="m-2">Violence rating: {props.profile.violenceRating}</div>
        <div className="m-2">Adult Content: {props.profile.adultContentRating}</div>
        <div className="m-2">Drugs: {props.profile.drugsRating}</div>
        <div className="m-2">Harmful / Dangerous Act: {props.profile.harmfulRating}</div>
        <div className="m-2">Hateful and Derogatory: {props.profile.hatefulRating}</div>
      </div>
      <div className="my-2">Director: {props.profile.director?.map((d) => d.name)?.join(", ")}</div>
      <div className="my-2">Producer: {props.profile.producer?.map((p) => p.name).join(", ")}</div>

      <div className="my-2">
        <strong>Awards</strong>
        <table className="table table-dark table-responsive">
          <thead>
            <tr>
              <th scope="col">Award</th>
              <th scope="col">Country</th>
              <th scope="col">Year</th>
              <th scope="col">Category</th>
              <th scope="col">Role</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {props.profile.awards?.map((a, i) => (
              <tr key={i}>
                <td>{startCase(camelCase(a.name))}</td>
                <td>{a.country || "NA"}</td>
                <td>{a.year || "NA"}</td>
                <td>{a.category?.join(", ") || "NA"}</td>
                <td>{a.role || "NA"}</td>
                <td>{a.status || "NA"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="my-2">
        <strong>Crew</strong>
        <ol>
          {props.profile.crew?.map(
            (
              c: {
                name?: string;
                role?: string;
              },
              key: number
            ) => (
              <li className="my-2" key={key}>
                <div className="my-2">
                  <strong>{c.name} </strong>: {c.role || "NA"}
                </div>
              </li>
            )
          )}
        </ol>
      </div>
      <div className="my-2">
        <strong>Cast</strong>
        <ol>
          {props.profile.cast?.map(
            (
              c: {
                name?: string;
                character?: string;
              },
              key: number
            ) => (
              <li className="my-2" key={key}>
                <div className="my-2">
                  <strong>{c.name} </strong>: {c.character || "NA"}
                </div>
              </li>
            )
          )}
        </ol>
      </div>
      {(props.profile as any).user === user._id ? (
        <div className="my-2" ref={linkingRequests}>
          <strong>Linking Requests</strong>
          <table className="table table-dark table-responsive">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Category</th>
                <th scope="col">Details</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {((props.profile.director ?? []) as any[])
                .filter((el) => el.user)
                .map((director) => (
                  <tr key={(director as any)._id ?? Math.random()}>
                    <td>
                      <LinkedProfile name={director.name} />
                    </td>
                    <td>Director</td>
                    <td>
                      {(director.biography?.slice(0, 10) ?? "") + (director.biography?.length > 10 ? "..." : "") || "-"}
                    </td>
                    <td>
                      {director.approved ? (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Approved
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              modal.confirm({
                                title: "Are you sure you want to remove this project from your profile?",
                                content:
                                  "If you wish to add this project back to your profile, the creator will need to send another linking request.",
                                okText: "Unlink",
                                onOk: () => {
                                  axios
                                    .get(
                                      config.api_url +
                                        "/projects/remove-link/" +
                                        props.profile._id +
                                        "/director?id=" +
                                        (director as any)._id +
                                        "&user=" +
                                        director.user
                                    )
                                    .then(() => {
                                      message.success("Unlinked successfully!");
                                      window.location.reload();
                                    })
                                    .catch((e) => message.error(handleError(e)));
                                },
                              });
                            }}
                          >
                            Unlink
                          </Button>
                        </div>
                      ) : director.rejected ? (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Rejected
                          {(director.resent ?? 0) < 2 && director.sentBy === "project" ? (
                            <Button size="small" type="link" onClick={() => resend("director", director)}>
                              Resend
                            </Button>
                          ) : null}
                        </div>
                      ) : director.sentBy === "professional" ? (
                        <Row>
                          <Button
                            size="small"
                            style={{ marginRight: "10px" }}
                            type="primary"
                            onClick={() => {
                              axios
                                .get(
                                  config.api_url +
                                    "/projects/approve-link/" +
                                    props.profile._id +
                                    "/director/" +
                                    director._id +
                                    "?user=" +
                                    director.user
                                )
                                .then(() => {
                                  message.success("Approved successfully!");
                                  window.location.reload();
                                })
                                .catch((e) => message.error(handleError(e)));
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            size="small"
                            type="dashed"
                            onClick={() => {
                              axios
                                .get(
                                  config.api_url +
                                    "/projects/reject-link/" +
                                    props.profile._id +
                                    "/director/" +
                                    director._id +
                                    "?user=" +
                                    director.user
                                )
                                .then(() => {
                                  message.success("Rejected successfully!");
                                  window.location.reload();
                                })
                                .catch((e) => message.error(handleError(e)));
                            }}
                          >
                            Reject
                          </Button>
                        </Row>
                      ) : (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Pending Approval
                          {(director.resent ?? 0) < 2 && director.sentBy === "project" ? (
                            <Button size="small" type="link" onClick={() => resend("director", director)}>
                              Resend
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              {((props.profile.producer || []) as any[])
                .filter((el) => el.user)
                .map((producer) => (
                  <tr key={(producer as any)._id ?? Math.random()}>
                    <td>
                      <LinkedProfile name={producer.name} />
                    </td>
                    <td>Producer</td>
                    <td>
                      {producer.filmography?.slice(0, 10) + (producer.filmography?.length > 10 ? "..." : "") || "-"}
                    </td>
                    <td>
                      {producer.approved ? (
                        "Approved"
                      ) : producer.rejected ? (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Rejected
                          {(producer.resent ?? 0) < 2 && producer.sentBy === "project" ? (
                            <Button size="small" type="link" onClick={() => resend("producer", producer)}>
                              Resend
                            </Button>
                          ) : null}
                        </div>
                      ) : producer.sentBy === "professional" ? (
                        <Row>
                          <Button
                            size="small"
                            style={{ marginRight: "10px" }}
                            type="primary"
                            onClick={() => {
                              axios
                                .get(
                                  config.api_url +
                                    "/projects/approve-link/" +
                                    props.profile._id +
                                    "/producer/" +
                                    producer._id +
                                    "?user=" +
                                    producer.user
                                )
                                .then(() => {
                                  message.success("Approved successfully!");
                                  window.location.reload();
                                })
                                .catch((e) => message.error(handleError(e)));
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            size="small"
                            type="dashed"
                            onClick={() => {
                              axios
                                .get(
                                  config.api_url +
                                    "/projects/reject-link/" +
                                    props.profile._id +
                                    "/producer/" +
                                    producer._id +
                                    "?user=" +
                                    producer.user
                                )
                                .then(() => {
                                  message.success("Rejected successfully!");
                                  window.location.reload();
                                })
                                .catch((e) => message.error(handleError(e)));
                            }}
                          >
                            Reject
                          </Button>
                        </Row>
                      ) : (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Pending Approval
                          {(producer.resent ?? 0) < 2 && producer.sentBy === "project" ? (
                            <Button size="small" type="link" onClick={() => resend("producer", producer)}>
                              Resend
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              {((props.profile.cast ?? []) as any[])
                .filter((el) => el.user)
                .map((cast) => (
                  <tr key={(cast as any)._id ?? Math.random()}>
                    <td>
                      <LinkedProfile name={cast.name} />
                    </td>
                    <td>Cast</td>
                    <td>{cast.character ?? "-"}</td>
                    <td>
                      {cast.approved ? (
                        "Approved"
                      ) : cast.rejected ? (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Rejected
                          {(cast.resent ?? 0) < 2 && cast.sentBy === "project" ? (
                            <Button size="small" type="link" onClick={() => resend("cast", cast)}>
                              Resend
                            </Button>
                          ) : null}
                        </div>
                      ) : cast.sentBy === "professional" ? (
                        <Row>
                          <Button
                            size="small"
                            style={{ marginRight: "10px" }}
                            type="primary"
                            onClick={() => {
                              axios
                                .get(
                                  config.api_url +
                                    "/projects/approve-link/" +
                                    props.profile._id +
                                    "/cast/" +
                                    cast._id +
                                    "?user=" +
                                    cast.user
                                )
                                .then((res) => {
                                  console.log(res.data);
                                  message.success("Approved successfully!");
                                  // window.location.reload();
                                })
                                .catch((e) => message.error(handleError(e)));
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            size="small"
                            type="dashed"
                            onClick={() => {
                              axios
                                .get(
                                  config.api_url +
                                    "/projects/reject-link/" +
                                    props.profile._id +
                                    "/cast/" +
                                    cast._id +
                                    "?user=" +
                                    cast.user
                                )
                                .then(() => {
                                  message.success("Rejected successfully!");
                                  window.location.reload();
                                })
                                .catch((e) => message.error(handleError(e)));
                            }}
                          >
                            Reject
                          </Button>
                        </Row>
                      ) : (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Pending Approval
                          {(cast.resent ?? 0) < 2 && cast.sentBy === "project" ? (
                            <Button size="small" type="link" onClick={() => resend("cast", cast)}>
                              Resend
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              {((props.profile.crew ?? []) as any[])
                .filter((el) => el.user)
                .map((crew) => (
                  <tr key={(crew as any)._id ?? Math.random()}>
                    <td>
                      <LinkedProfile name={crew.name} />
                    </td>
                    <td>Crew</td>
                    <td>{crew.role ?? "-"}</td>
                    <td>
                      {crew.approved ? (
                        "Approved"
                      ) : crew.rejected ? (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Rejected
                          {(crew.resent ?? 0) < 2 && crew.sentBy === "project" ? (
                            <Button size="small" type="link" onClick={() => resend("crew", crew)}>
                              Resend
                            </Button>
                          ) : null}
                        </div>
                      ) : crew.sentBy === "professional" ? (
                        <Row>
                          <Button
                            size="small"
                            style={{ marginRight: "10px" }}
                            type="primary"
                            onClick={() => {
                              axios
                                .get(
                                  config.api_url +
                                    "/projects/approve-link/" +
                                    props.profile._id +
                                    "/crew/" +
                                    crew._id +
                                    "?user=" +
                                    crew.user
                                )
                                .then(() => {
                                  message.success("Approved successfully!");
                                  window.location.reload();
                                })
                                .catch((e) => message.error(handleError(e)));
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            size="small"
                            type="dashed"
                            onClick={() => {
                              axios
                                .get(
                                  config.api_url +
                                    "/projects/reject-link/" +
                                    props.profile._id +
                                    "/crew/" +
                                    crew._id +
                                    "?user=" +
                                    crew.user
                                )
                                .then(() => {
                                  message.success("Rejected successfully!");
                                  window.location.reload();
                                })
                                .catch((e) => message.error(handleError(e)));
                            }}
                          >
                            Reject
                          </Button>
                        </Row>
                      ) : (
                        <div className="d-flex" style={{ gap: "10px" }}>
                          Pending Approval
                          {(crew.resent ?? 0) < 2 && crew.sentBy === "project" ? (
                            <Button size="small" type="link" onClick={() => resend("crew", crew)}>
                              Resend
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default FilmProfile;
