import { message } from "antd";
import axios, { AxiosError } from "axios";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import config from "src/Config";
import { wait } from "src/utils/wait";

export const ApproveLinkPage = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();

  useEffect(() => {
    const token = query.get("token");
    if (!token) navigate("/");

    axios
      .post(`${config.api_url}/projects/approve-link`, { token })
      .then((res) => {
        message.success("Link approved successfully! Redirecting...");

        return wait(1000);
      })
      .then(() => {
        navigate("/partner/professional-profile");
      })
      .catch((e) => {
        if (e instanceof AxiosError) {
          message.error(
            e.response?.data?.message ?? e.response?.data?.error ?? e.response?.data?.status ?? "There was an error!"
          );
        }
      });
  }, [query]);

  return (
    <div className="container-fluid my-5">
      <div className="col-md-4 offset-md-4 col-sm-12 d-flex flex-column align-items-center">
        <img
          className="my-5"
          src={process.env.PUBLIC_URL + "/pocketfilms_logo.png"}
          alt="Pocket Films"
          width={220}
          // height={88}
        ></img>

        <h1 className="mb-4">Approve Link</h1>
        <p className="mb-10" style={{ color: "#fff" }}>
          Approving your link to the project...
        </p>
      </div>
    </div>
  );
};
