import { FormInstance, message } from "antd";
import { useEffect } from "react";
import { Moment } from "moment";

const CreateFilmReviewSection = (props: {
  id: string | undefined;
  form: FormInstance<any>;
  formErrors: {
    name: (string | number)[];
    errors: string[];
    warnings: string[];
  }[];
  navigate: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useEffect(() => {
    // props.form.validateFields();
    if (props.formErrors.length > 0) {
      message.error(props.formErrors[0].errors[0]);
    }
  }, [props.form]);

  return (
    <>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(1);
        }}
      >
        <h6 className="fw-bold">Basic Details</h6>
        <div className="row">
          <div className="col-sm-2">
            Film Title Original<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("title")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("title")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Film Title (English) :</div>
          <div className="col">
            {props.form.getFieldValue("englishTitle")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("englishTitle")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Custom URL<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("customURL")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("customURL")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Category<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("category")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("category")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Genre<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("genre")?.join(", ")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("genre")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Original Language<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("language")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("language")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Tagline :</div>
          <div className="col">
            {props.form.getFieldValue("tagline")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("tagline")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Synopsis<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("synopsis")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("synopsis")}
            </span>
          </div>
        </div>
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(2);
        }}
      >
        <h6 className="fw-bold">Technical Details</h6>
        <div className="row">
          <div className="col-sm-2">
            Duration in mm:ss<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("duration")
              ? props.form.getFieldValue("duration")["mm"]
              : null}{" "}
            minutes{" "}
            {props.form.getFieldValue("duration")
              ? props.form.getFieldValue("duration")["ss"]
              : null}{" "}
            seconds{" "}
            <span className="text-danger">
              {props.form.getFieldError(["duration", "mm"])}{" "}
              {props.form.getFieldError(["duration", "ss"])}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Censor / Self Censor<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("censor")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("censor")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Date of Completion<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("completionDate")?.format("DD-MM-YYYY")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("completionDate")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Shooting Format<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("shootFormat")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("shootFormat")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Original Aspect Ratio<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("aspectRatio")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("aspectRatio")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Country :</div>
          <div className="col">
            {props.form.getFieldValue("country")?.join(", ")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("country")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Keywords :</div>
          <div className="col">
            {props.form.getFieldValue("keywords")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("keywords")}
            </span>
          </div>
        </div>
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(3);
        }}
      >
        <h6 className="fw-bold">Censor Ratings</h6>
        <div className="row">
          <div className="col-sm-2">
            Censor / Self Censor<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("censor")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("censor")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Language<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("languageRating")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("languageRating")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Violence<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("violenceRating")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("violenceRating")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Adult Content<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("adultContentRating")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("adultContentRating")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Drugs<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("drugsRating")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("drugsRating")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Harmful / Dangerous Act<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("harmfulRating")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("harmfulRating")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Hateful and Derogatory<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("hatefulRating")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("hatefulRating")}
            </span>
          </div>
        </div>
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(4);
        }}
      >
        <h6 className="fw-bold">Cast and Crew Details</h6>
        <div className="row">
          <div className="col-sm-2">
            Director<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form
              .getFieldValue("director")
              ?.map((d: { name: string }) => d?.name)
              .join(", ")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("director")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Director's Biography :</div>
          <div className="col">
            {props.form.getFieldValue("director")?.map(
              (
                director: {
                  name?: string;
                  biography?: string;
                },
                i: number
              ) => {
                return (
                  <div className="mb-2" key={i}>
                    <div className="row">
                      <div className="col-sm-2">Name<sup className="text-danger">*</sup> :</div>
                      <div className="col">
                        {director?.name}{" "}
                        <span className="text-danger">
                          {props.form.getFieldError(["director", i, "name"])}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2">Biography :</div>
                      <div className="col">
                        {director?.biography}{" "}
                        <span className="text-danger">
                          {props.form.getFieldError(["director", i, "biography"])}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Producer<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("producer")?.map(
              (
                producer: {
                  name?: string;
                  filmography?: string;
                },
                i: number
              ) => {
                return (
                  <div className="mb-2" key={i}>
                    <div className="row">
                      <div className="col-sm-2">Name<sup className="text-danger">*</sup> :</div>
                      <div className="col">
                        {producer?.name}{" "}
                        <span className="text-danger">
                          {props.form.getFieldError(["producer", i, "name"])}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2">Filmography :</div>
                      <div className="col">
                        {producer?.filmography}{" "}
                        <span className="text-danger">
                          {props.form.getFieldError(["producer", i, "filmography"])}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Producer's Filmography :</div>
          <div className="col">
            {props.form.getFieldValue("producerFilmography")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("producerFilmography")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1">Crew :</div>
          <div className="col">
            {props.form.getFieldValue("crew")?.map(
              (
                crew: {
                  name?: string;
                  role?: string;
                },
                i: number
              ) => {
                return (
                  <div className="mb-2" key={i}>
                    <div className="row">
                      <div className="col-sm-2 text-center">
                        Name<sup className="text-danger">*</sup> :
                      </div>
                      <div className="col">
                        {crew?.name}{" "}
                        <span className="text-danger">
                          {props.form.getFieldError(["crew", i, "name"])}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 text-center">
                        Role<sup className="text-danger">*</sup> :
                      </div>
                      <div className="col">
                        {crew?.role}{" "}
                        <span className="text-danger">
                          {props.form.getFieldError(["crew", i, "role"])}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1">Cast :</div>
          <div className="col">
            {props.form.getFieldValue("cast")?.map(
              (
                cast: {
                  name?: string;
                  character?: string;
                },
                i: number
              ) => {
                return (
                  <div className="mb-2" key={i}>
                    <div className="row">
                      <div className="col-sm-2 text-center">Name<sup className="text-danger">*</sup> :</div>
                      <div className="col">
                        {cast?.name}{" "}
                        <span className="text-danger">
                          {props.form.getFieldError(["cast", i, "name"])}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 text-center">Character<sup className="text-danger">*</sup> :</div>
                      <div className="col">
                        {cast?.character}{" "}
                        <span className="text-danger">
                          {props.form.getFieldError(["cast", i, "character"])}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(5);
        }}
      >
        <h6 className="fw-bold">Awards</h6>
        {props.form.getFieldValue("awards")?.map(
          (
            award: {
              name?: string;
              country?: string;
              year?: Moment;
              category?: string[];
              role?: string;
              status: string;
            },
            i: number
          ) => {
            return (
              <div key={i}>
                <div className="row">
                  <div className="col-sm-2">
                    Award Name<sup className="text-danger">*</sup> :
                  </div>
                  <div className="col">
                    {award?.name}{" "}
                    <span className="text-danger">
                    {props.form.getFieldError(["awards", i, "name"])}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    Award Country<sup className="text-danger">*</sup> :
                  </div>
                  <div className="col">
                    {award?.country}{" "}
                    <span className="text-danger">
                    {props.form.getFieldError(["awards", i, "country"])}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    Award Year<sup className="text-danger">*</sup> :
                  </div>
                  <div className="col">
                    {award?.year?.format("YYYY")}{" "}
                    <span className="text-danger">
                    {props.form.getFieldError(["awards", i, "year"])}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    Award Category<sup className="text-danger">*</sup> :
                  </div>
                  <div className="col">
                    {award?.category?.join(", ")}{" "}
                    <span className="text-danger">
                    {props.form.getFieldError(["awards", i, "category"])}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    Role Awarded<sup className="text-danger">*</sup> :
                  </div>
                  <div className="col">
                    {award?.role}{" "}
                    <span className="text-danger">
                    {props.form.getFieldError(["awards", i, "role"])}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">Award Status :</div>
                  <div className="col">
                    {award?.status}{" "}
                    <span className="text-danger">
                    {props.form.getFieldError(["awards", i, "status"])}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(6);
        }}
      >
        <h6 className="fw-bold">Posters and Other Details</h6>
        <div className="row mb-2">
          <div className="col-sm-2">
            Vertical Poster<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            <span className="text-danger">
              {props.form.getFieldError("verticalPosterURL")}
            </span>
            <img
              height={86}
              src={props.form.getFieldValue("verticalPosterURL") || null}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Horizontal Poster<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            <span className="text-danger">
              {props.form.getFieldError("horizontalPosterURL")}
            </span>
            <img
              width={86}
              src={props.form.getFieldValue("horizontalPosterURL") || null}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">Screened :</div>
          <div className="col">
            {props.form.getFieldValue("screened") ? "Yes" : "No"}{" "}
            <span className="text-danger">
              {props.form.getFieldError("screened")}
            </span>
          </div>
        </div>
        {/* <div className="row mb-2">
          <div className="col-sm-2">News and Review Links :</div>
          <div className="col">{props.form.getFieldValue("links")?.map((l: { link: string }, key: number) => {
            return <a href={l.link} target="_blank" key={key}>{l.link}</a>
          })} <span className="text-danger">{props.form.getFieldError("links")}</span></div>
        </div> */}
        <div className="row mb-2">
          <div className="col-sm-2">Production Stills :</div>
          <div className="col">
            <span className="text-danger">
              {props.form.getFieldError("stills")!}
            </span>
            {props.form
              .getFieldValue("stills")
              ?.map((s: string, key: number) => (
                <img className="mx-1" width={86} src={s} key={key} />
              ))}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">Director's Statement :</div>
          <div className="col">
            {props.form.getFieldValue("directorStatement")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("directorStatement")}
            </span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">Producer's Statement :</div>
          <div className="col">
            {props.form.getFieldValue("producerStatement")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("producerStatement")}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Other :</div>
          <div className="col">
            {props.form.getFieldValue("other")}{" "}
            <span className="text-danger">
              {props.form.getFieldError("other")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateFilmReviewSection;
