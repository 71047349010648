import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, message, Tabs } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { LoadingSpinner } from "src/Components";
import config from "src/Config";
import axios from "axios";
import moment, { Moment } from "moment";
import { SeasonSeriesProjectPageForm, SeriesProjectPageForm } from "../..";
import CreateSeriesSeasonSection from "./seasonDetails";
import CreateSeasonPosterSection from "./posterDetails";
import CreateSeasonAwardSection from "./awardDetails";
import CreateSeasonReviewSection from "./review";
import confirm from "antd/lib/modal/confirm";

const AddSeasonSeriesProject = () => {
  let { seriesId, seasonId } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState<any[]>([]);
  const [error, setError] = useState<boolean>(true);
  const [loading, setLoading] = useState<Boolean>(true);
  const [submitText, _setSubmitText] = useState<string>("Update Season");
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [currentTabKey, setCurrentTabKey] = useState<number>(1);
  const [series, setSeries] = useState<SeriesProjectPageForm>();
  const [season, setSeason] = useState<SeasonSeriesProjectPageForm>();
  const [notAllowNumbers, setNotAllowNumbers] = useState<number[]>();

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      if (seriesId === undefined) {
        return setLoading(false);
      }
      const res = await axios.get(config.api_url + `/projects/Series/${seriesId}`);
      let tmpSeason = res.data.seasons.filter((s: SeasonSeriesProjectPageForm) => s._id === seasonId)[0];
      if (tmpSeason) {
        if (tmpSeason.firstReleaseYear) {
          tmpSeason.firstReleaseYear = moment(tmpSeason.firstReleaseYear);
        }
        setSeason(tmpSeason);
      } else {
        // find max number from res.data.seasons.number
        let number = 0;
        for (let i in res.data.seasons) {
          if (res.data.seasons[i].number > number) {
            number = res.data.seasons[i].number;
          }
        }
        number++;
        form.setFieldsValue({ number });
      }

      // Add existing season numbers to not allow for current season number
      setNotAllowNumbers(
        res.data.seasons
          .map((s: SeasonSeriesProjectPageForm) => s.number)
          .filter((n: number) => n !== tmpSeason?.number)
      );

      if (tmpSeason?.awards?.length > 0) {
        for (let i in tmpSeason.awards) {
          tmpSeason.awards[i].year = moment(tmpSeason.awards[i].year);
        }
      }
      setSeries(res.data);
      setLoading(false);
    })();
    return () => {};
  }, []);

  useEffect(() => {
    formErrors.length > 0 ? setError(true) : setError(false);
    console.log(formErrors);
  }, [formErrors]);

  // Scroll to top on tab change
  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentTabKey > 4) {
      setTimeout(() => {
        let formErrorsTmp: {
          name: (string | number)[];
          errors: string[];
          warnings: string[];
        }[] = form.getFieldsError().filter((err) => err.errors.length);
        setFormErrors(formErrorsTmp);
        setError(formErrorsTmp.length > 0 ? true : false);
      }, 1000);
    }
  }, [currentTabKey]);

  const deleteSeason = () => {
    confirm({
      title: "Are you sure delete this content?",
      icon: <ExclamationCircleOutlined />,
      content: "Delete this Season? This will also delete all the episodes in this season",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoading(true);
        let tmpProfile = series!;
        if (series) {
          tmpProfile.seasons = series.seasons.filter((s: SeasonSeriesProjectPageForm) => s._id !== seasonId);
          delete tmpProfile.__v;
          axios.post(config.api_url + "/projects/series", tmpProfile).then(
            (res) => {
              setLoading(false);
              navigate(`/partner/projects/series/view/${tmpProfile._id}`);
            },
            (err) => {
              setLoading(false);
              message.info("Error: " + err.message);
              console.error(err);
            }
          );
        } else {
          message.error("Series not found");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onFinish = async (values: any) => {
    setSubmitLoading(true);
    let formErrorsTmp: {
      name: (string | number)[];
      errors: string[];
      warnings: string[];
    }[] = form.getFieldsError().filter((err) => err.errors.length);
    setFormErrors(formErrorsTmp);
    setError(formErrorsTmp.length > 0 ? true : false);
    form.validateFields();
    onNext();

    console.debug("Received values of form: ", values);
    setSubmitLoading(false);
  };

  const onNext = async () => {
    window.scrollTo(0, 0);
    setSubmitLoading(true);
    if (currentTabKey > 1) {
      let formErrorsTmp: {
        name: (string | number)[];
        errors: string[];
        warnings: string[];
      }[] = form.getFieldsError().filter((err) => err.errors.length);
      setFormErrors(formErrorsTmp);
      setError(formErrorsTmp.length > 0 ? true : false);
      // console.log(formErrorsTmp);
    }
    const values = { ...form.getFieldsValue() };

    if (values?.firstReleaseYear) {
      values.firstReleaseYear = values.firstReleaseYear?.format("YYYY");
    }
    if (values?.awards?.length > 0) {
      let awards = [];
      for (let i in values.awards) {
        awards.push({
          ...values.awards[i],
          ...{ year: values.awards[i].year?.format("YYYY") },
        });
      }
      values.awards = awards;
    }
    //     if (values?.episodes?.length > 0) {
    //         let episodes = []
    //         for (let i in values.episodes) {
    //             episodes.push({ ...values.episodes[i], ...{ releasedate: values.episodes[i].releaseDate?.format("DD-MM-YYYY") } });
    //         }
    //         values.episodes = episodes;
    //     }
    //     values.director = values.director?.filter((d: { name: string }) => d.name !== ("" || undefined))
    //     values.producer = values.producer?.filter((d: { name: string }) => d.name !== ("" || undefined))
    //     values.crew = values.crew?.filter((d: { name: string; role: string }) => (d.name !== ("" || undefined) || d.role !== ("" || undefined)))
    //     values.cast = values.cast?.filter((d: { name: string; character: string }) => (d.name !== ("" || undefined) || d.character !== ("" || undefined)))
    //     values.episodes = values.episodes?.filter((d: {
    //         number: number;
    //         title?: string;
    //         synopsis: string;
    //         shootingFormat: string;
    //         aspectRatio: string;
    //     }) => (d.number !== ("" || undefined) || d.title !== ("" || undefined) || d.synopsis !== ("" || undefined) || d.shootingFormat !== ("" || undefined) || d.aspectRatio !== ("" || undefined)))
    values.awards = values.awards?.filter(
      (d: { name?: string; country?: string; year?: string; category?: string[]; role?: string; status: string }) =>
        d.name !== ("" || undefined) ||
        d.country !== ("" || undefined) ||
        d.year !== ("" || undefined) ||
        d.role !== ("" || undefined) ||
        d.status !== ("" || undefined)
    );
    //     values.links = values.links?.filter((d: { link: string }) => d.link !== ("" || undefined))
    if (currentTabKey === 4) {
      values.status = "complete";
    }
    if (season?._id) {
      series?.seasons?.map((s: any) => {
        if (s._id === season?._id) {
          for (let key of Object.keys(values)) {
            s[key] = values[key];
          }
        }
        return s;
      });
    } else {
      series?.seasons.push(values);
    }
    delete series?.__v;
    axios.post(config.api_url + "/projects/series", series).then(
      (res) => {
        setSeries(res.data);
        if (!season?._id) {
          let l = res.data?.seasons.length || 1;
          setSeason(res.data?.seasons[l - 1]);
        }
        setSubmitLoading(false);
        if (season?._id) {
          message.success("Season updated!");
        } else {
          message.success("Season created!");
        }
        currentTabKey === 4
          ? navigate(`/partner/projects/series/view/${seriesId}/season/${season?._id}`)
          : setCurrentTabKey(currentTabKey + 1);
      },
      (err) => {
        setSubmitLoading(false);
        message.info("Error: " + err.message);
        console.error(err);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        <div className="d-flex justify-content-between">
          <h1 className="title">
            {seasonId === undefined ? "Add a Season" : `Edit Season (Season ${season?.number})`} {` - ${series?.title}`}
          </h1>
          {seasonId ? (
            <Button onClick={deleteSeason} danger>
              Delete
            </Button>
          ) : null}
        </div>
        <Form
          form={form}
          // validateTrigger={["onChange", "onClick"]}
          onFinish={onFinish}
          // onFieldsChange={handleFormChange}
          layout="horizontal"
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          labelAlign="left"
          initialValues={season}
          scrollToFirstError={true}
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={currentTabKey.toString()}
            onTabClick={(key) => {
              return parseInt(key) < 4 ? setCurrentTabKey(parseInt(key)) : null;
            }}
          >
            <Tabs.TabPane forceRender={true} tab="Season Details" key="1">
              Season Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateSeriesSeasonSection
                  id={season?._id || ""}
                  seriesId={seriesId || ""}
                  releaseStatus={season?.releaseStatus || ""}
                  notAllowNumbers={notAllowNumbers || []}
                />
                <div className="text-end">
                  <Button type="primary" htmlType="button" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Awards" key="2">
              Awards
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateSeasonAwardSection id={season?._id} />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => setCurrentTabKey(currentTabKey - 1)}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Posters and Other Details" key="3">
              Posters and Other Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateSeasonPosterSection
                  id={season?._id}
                  form={form}
                  stills={season?.stills || []}
                  verticalPosterURL={season?.verticalPosterURL || ""}
                  horizontalPosterURL={season?.horizontalPosterURL || ""}
                />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => setCurrentTabKey(currentTabKey - 1)}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Review" key="4">
              Review
              <div className="site-layout-background" style={{ paddingTop: 24, minHeight: 360 }}>
                <CreateSeasonReviewSection
                  id={season?._id}
                  form={form}
                  formErrors={formErrors}
                  navigate={setCurrentTabKey}
                />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => setCurrentTabKey(currentTabKey - 1)}
                  >
                    Previous
                  </Button>
                  <SubmitButton onNext={onNext} error={error} submitLoading={submitLoading} submitText={submitText} />
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Content>
    );
};

const SubmitButton = (props: {
  error: boolean;
  onNext: MouseEventHandler<HTMLElement>;
  submitLoading: boolean;
  submitText: String;
}) => {
  return props.error ? null : (
    <Button
      key={props.error ? 1 : 0}
      type="primary"
      htmlType="button"
      onClick={props.onNext}
      loading={props.submitLoading}
    >
      {props.submitLoading ? "Saving" : props.submitText}
    </Button>
  );
};

export default AddSeasonSeriesProject;
