import { RecoilRoot } from "recoil";
import { Footer, Navbar } from "src/Components";

const ContactPage = () => {
  return (
    <RecoilRoot>
      <div className="container-fluid">
        <Navbar />

        <div
          className="container my-5 text-light"
          style={{
            backgroundColor: "#13131A",
            borderRadius: "20px",
            padding: "40px",
          }}
        >
          <h1 className="mb-5">Contact Us</h1>

          <div className="row">
            <div className="col-md-6">
              <address>
                Pocket Films,
                <br />
                106, Tirupati Udyog, I B Patel Road,
                <br />
                Off Western Express Highway, Opp. HP Petrol Pump,
                <br />
                Goregaon East, Mumbai 400063
                <br />
              </address>
              {/* <p>Phone: +91 22 28772090</p> */}
              <p>Email: info@pocketfilms.in</p>
            </div>
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.761908833171!2d72.85462867625674!3d19.161896549268317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b655e45d8c85%3A0x2b6703191c823468!2sPocket%20Films!5e0!3m2!1sen!2sin!4v1685964896113!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </RecoilRoot>
  );
};

export default ContactPage;
