import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CreateFilmDistribution from "./film";
import CreateSeriesDistribution from "./series";
import { LoadingSpinner } from "src/Components";
import { useEffect, useState } from "react";
import { Modal, message } from "antd";
import axios from "axios";
import config from "src/Config";
import { Project, FilmProjectPageForm, SeriesProjectPageForm } from "src/Pages/Partner/Projects";

const CreateDistribution = () => {
  let { id } = useParams();
  const [searchParams, _setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<Project | FilmProjectPageForm | SeriesProjectPageForm>();

  useEffect(() => {
    axios.get(`${config.api_url}/projects/${id}`).then((res: { data: SeriesProjectPageForm }) => {
      if (res.data?.kind === "series") {
        if (res.data.seasons.length == 0) {
          message.error("No seasons found");
          return navigate(`/partner/projects`, {
            replace: true,
          });
        }
        res.data.seasons = res.data.seasons.filter((s) => s._id == searchParams.get("season"));
        res.data.seasons[0].episodes.sort((a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0));
      }
      setProject(res.data);
      let projecttmp = res.data;
      if (projecttmp?.distribution) {
        return navigate(`/partner/distributions/${projecttmp?.distribution}`, {
          replace: true,
        });
      }
      if (projecttmp?.kind === "series") {
        const seriesDistribution = (projecttmp as SeriesProjectPageForm).seasons[0]?.distribution;
        if (seriesDistribution) {
          return navigate(`/partner/distributions/${seriesDistribution}`, {
            replace: true,
          });
        }
      }
    });
    // Check if User has completed profile and has set submitAsBusiness value
    axios.get(config.api_url + "/user/me").then(
      (res) => {
        let user: {
          complete: boolean;
          submitAsBusiness: boolean;
        } = res.data;
        // Navigate User to fill profile if not already complete
        if (user.complete === false) {
          setLoading(false);
          message.warning("Please complete your profile first!", 5);
          return navigate(`/account?next=/partner/distributions/create/${id}`);
        }
        // If submitAsBusiness value is not available, ask user over a modal
        else if (!("submitAsBusiness" in user)) {
          setLoading(false);
          confirm({
            title: "Create distributions as Individual or Business?",
            icon: <ExclamationCircleOutlined />,
            content:
              "Do you wish to create your distributions as an Individual or as a Business? This option cannot be changed once selected. All your distributions will be created accordingly! Please select carefully",
            okText: "Business",
            okType: "danger",
            cancelText: "Individual",
            onOk() {
              setLoading(true);
              // User wants to submit as Business. Check if Business profile already created
              axios.get(config.api_url + "/business-profile").then(
                (res) => {
                  // Check if Business profile already created, if yes then update submitAsBusiness to true and navigate to create distribution
                  if (res.data) {
                    // Update submitAsBusiness to true and navigate to create distribution
                    axios
                      .post(config.api_url + "/user/me", {
                        submitAsBusiness: true,
                      })
                      .then(
                        (res) => {
                          setLoading(false);
                        },
                        (err) => {
                          setLoading(false);
                          message.error(`${err.message} - ${err.response.data.message}`);
                        }
                      );
                  }
                  // As Business profile is not created, navigate user to create it
                  else {
                    setLoading(false);
                    message.warning("Please create your Business profile first!", 5);
                    return navigate(`/partner/business-profile/edit?next=/partner/distributions/create/${id}`);
                  }
                },
                (err) => {
                  message.error(`${err.message} - ${err.response.data.message}`);
                  setLoading(false);
                }
              );
            },
            onCancel() {
              setLoading(true);
              // User wants to submit as Individual. Update submitAsBusiness to false and navigate to create distribution
              axios.post(config.api_url + "/user/me", { submitAsBusiness: false }).then(
                (res) => {
                  setLoading(false);
                },
                (err) => {
                  message.error(`${err.message} - ${err.response.data.message}`);
                  setLoading(false);
                }
              );
            },
          });
        }
        // User Profile is complete and submitAsBusiness value is available, hence proceed with creating distribution
        else {
          setLoading(false);
        }
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setLoading(false);
      }
    );
  }, []);

  if (loading || !project) return <LoadingSpinner />;
  return project?.kind == "film" ? (
    <CreateFilmDistribution film={project as FilmProjectPageForm} />
  ) : (
    <CreateSeriesDistribution series={project as SeriesProjectPageForm} />
  );
};

export default CreateDistribution;
