import { FormInstance, message } from "antd";
import { useEffect, useState } from "react";
import { Moment } from "moment";
import { capitalize } from "lodash";

const CreateSeasonReviewSection = (props: {
  id: string | undefined;
  form: FormInstance<any>;
  formErrors: {
    name: (string | number)[];
    errors: string[];
    warnings: string[];
  }[];
  navigate: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useEffect(() => {
    // props.form.validateFields();
    if (props.formErrors.length > 0) {
      message.error(props.formErrors[0].errors[0]);
    }
  }, [props.form]);

  return (
    <>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(1);
        }}
      >
        <h6 className="fw-bold">Season Details</h6>
        <div className="row">
          <div className="col-sm-2">
            Season Number<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("number")}{" "}
            <span className="text-danger">{props.form.getFieldError("number")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Season Title:</div>
          <div className="col">
            {props.form.getFieldValue("title")} <span className="text-danger">{props.form.getFieldError("title")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Synopsis<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("synopsis")}{" "}
            <span className="text-danger">{props.form.getFieldError("synopsis")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Release Status<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {capitalize(props.form.getFieldValue("releaseStatus") || "")}{" "}
            <span className="text-danger">{props.form.getFieldError("releaseStatus")}</span>
          </div>
        </div>
        {props.form.getFieldValue("releaseStatus") === "unreleased" ? (
          ""
        ) : (
          <>
            <div className="row">
              <div className="col-sm-2">
                Year of First Release<sup className="text-danger">*</sup> :
              </div>
              <div className="col">
                {props.form.getFieldValue("firstReleaseYear")?.format("YYYY")}{" "}
                <span className="text-danger">{props.form.getFieldError("releaseDate")}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2">Release Medium :</div>
              <div className="col">
                {props.form.getFieldValue("releaseMedium")}{" "}
                <span className="text-danger">{props.form.getFieldError("releaseMedium")}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2">Release Platform :</div>
              <div className="col">
                {props.form.getFieldValue("platform")}{" "}
                <span className="text-danger">{props.form.getFieldError("platform")}</span>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(2);
        }}
      >
        <h6 className="fw-bold">Awards</h6>
        {props.form.getFieldValue("awards")?.map(
          (
            award: {
              name?: string;
              country?: string;
              year?: Moment;
              category?: string[];
              role?: string;
              status: string;
            },
            i: number
          ) => {
            return (
              <div key={i}>
                <div className="row">
                  <div className="col-sm-2">Award Name :</div>
                  <div className="col">
                    {award?.name} <span className="text-danger">{props.form.getFieldError("duration")}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">Award Country :</div>
                  <div className="col">
                    {award?.country} <span className="text-danger">{props.form.getFieldError("censor")}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">Award Year :</div>
                  <div className="col">
                    {award?.year?.format("YYYY")}{" "}
                    <span className="text-danger">{props.form.getFieldError("completionDate")}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">Award Category :</div>
                  <div className="col">
                    {award?.category?.join(", ")}{" "}
                    <span className="text-danger">{props.form.getFieldError("shootFormat")}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">Role Awarded :</div>
                  <div className="col">
                    {award?.role} <span className="text-danger">{props.form.getFieldError("aspectRatio")}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">Award Status :</div>
                  <div className="col">
                    {award?.status} <span className="text-danger">{props.form.getFieldError("country")}</span>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(3);
        }}
      >
        <h6 className="fw-bold">Posters and Other Details</h6>
        <div className="row mb-2">
          <div className="col-sm-2">
            Vertical Poster<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            <span className="text-danger">{props.form.getFieldError("verticalPosterURL")}</span>
            <img height={86} src={props.form.getFieldValue("verticalPosterURL") || null} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Horizontal Poster<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            <span className="text-danger">{props.form.getFieldError("horizontalPosterURL")}</span>
            <img width={86} src={props.form.getFieldValue("horizontalPosterURL") || null} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">Production Stills :</div>
          <div className="col">
            <span className="text-danger">{props.form.getFieldError("stills")!}</span>
            {props.form.getFieldValue("stills")?.map((s: string) => (
              <img className="mx-1" width={86} src={s} />
            ))}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">Director's Statement :</div>
          <div className="col">
            {props.form.getFieldValue("directorStatement")}{" "}
            <span className="text-danger">{props.form.getFieldError("directorStatement")}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">Producer's Statement :</div>
          <div className="col">
            {props.form.getFieldValue("producerStatement")}{" "}
            <span className="text-danger">{props.form.getFieldError("producerStatement")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Other :</div>
          <div className="col">
            {props.form.getFieldValue("other")} <span className="text-danger">{props.form.getFieldError("other")}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSeasonReviewSection;
