import { Form, Input, Button, Select, DatePicker, message } from "antd";
import moment, { Moment } from "moment";
import { useAuth } from "../../auth";
import { LoadingSpinner } from "../../Components";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import config from "../../Config";
import { startCase, camelCase } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import lookup from "country-code-lookup";

interface EditAccountForm {
  email?: string;
  mobile?: { code: string; phone: string };
  birthdate?: string;
  firstname?: string;
  lastname?: string;
  gender?: string;
  address1?: string;
  address2?: string;
  country: string;
  state?: string;
  city?: string;
  pincode?: string;
  complete: boolean;
  submitAsBusiness: boolean;
}

const EditAccount = () => {
  const [searchParams, _setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState<EditAccountForm>();

  const countries = Country.getAllCountries();
  const [country, setCountry] = useState("");
  const [states, setStates] = useState(State.getStatesOfCountry(country));
  const [cities, setCities] = useState(City.getCitiesOfCountry(country) || []);

  useEffect(() => {
    const fetchStatesAndCities = async () => {
      const statesOfCountry = State.getStatesOfCountry(country);
      const citiesOfCountry = City.getCitiesOfCountry(country) || [];
      setStates(statesOfCountry);
      setCities(citiesOfCountry);
    };

    if (country) {
      fetchStatesAndCities();
    }
  }, [country]);

  useEffect(() => {
    setLoading(true);
    let countryCode = "";
    axios.get(config.api_url + "/user/me").then(
      (res) => {
        let data = res.data;
        data.birthdate = data.birthdate ? moment(data.birthdate, "YYYY-MM-DD") : "";
        const currentCountry = lookup.byCountry(data.country || "");
        if (currentCountry != null) {
          countryCode = currentCountry.iso2;
          setCountry(Country.getCountryByCode(countryCode)?.isoCode ?? "");
        }
        if (data.mobile == null){
          delete data.mobile
        }
        setAccount(data);
        setLoading(false);
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`, 5);
        setLoading(false);
      }
    );
  }, []);

  // Configure dates to be disabled when selecting birthdate
  const disabledDate = (current: Moment) => {
    // Can not select days after (this year - 4)
    return current && current > moment(`${moment().year() - 4}-01-01`).endOf("day");
  };

  const onFinish = (values: any) => {
    // If email already exists in the account, then delete the field for any update
    if (account?.email) {
      delete values.email;
    }
    // If mobile already exists in the account, then delete the field for any update
    if (account?.mobile) {
      delete values.mobile;
    }
    values.complete = true; // Set the profile as complete
    console.debug("Received values of form: ", values);

    values.firstname = startCase(camelCase(values.firstname));
    values.lastname = startCase(camelCase(values.lastname));
    // Format birthdate object to string
    values.birthdate = values.birthdate.format("YYYY-MM-DD");

    // Make POST request to submit form data and create user in the database
    axios.post(config.api_url + "/user/me", values).then(
      (res) => {
        message.success(`Profile updated successfully`);
        auth.user = res.data;
        if (searchParams.has("next")) {
          return navigate(searchParams.get("next")!, { replace: true });
        } else {
          navigate("/partner", { replace: true });
        }
      },
      (err) => {
        message.error(`Failed to update your profile! ${err.response?.data?.error}`, 5);
        console.log(err.response?.data?.error);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <Form
        initialValues={account}
        onFinish={onFinish}
        layout="horizontal"
        wrapperCol={{ span: 14 }}
        labelCol={{ span: 4 }}
        labelAlign="left"
        form={form}>
        <Form.Item
          name="firstname"
          label="First Name"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please input your first name!"
            },
            {
              max: 100,
              message: "Max. limit is 100 characters"
            },
            {
              pattern: new RegExp("^[a-zA-Z ]+$"),
              message: "Please input your first name as alphabets only!"
            }
          ]}>
          <Input size="large" placeholder="First Name" disabled={account?.complete} />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Last Name"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please input your last name!"
            },
            {
              max: 100,
              message: "Max. limit is 100 characters"
            },
            {
              pattern: new RegExp("^[a-zA-Z. ]+$"),
              message: "Please input your last name as alphabets only!"
            }
          ]}>
          <Input size="large" placeholder="Last Name" disabled={account?.complete} />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Gender"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select your gender!"
            }
          ]}>
          <Select size="large">
            <Select.Option value="male">Male</Select.Option>
            <Select.Option value="female">Female</Select.Option>
            <Select.Option value="other">Other</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "Please input your Email address!",
              transform: (value) => value.toLowerCase()
            },
            {
              max: 100,
              message: "Max. limit is 100 characters"
            },
            {
              required: !account?.email
            }
          ]}
        >
          <Input size="large" placeholder="Email" disabled={!!account?.email} />
        </Form.Item>
        <ConfigProvider locale={en}>
          <Form.Item
            name="mobile"
            initialValue={{ short: "IN" }}
            label="Mobile Number"
            rules={[
              {
                required: !account?.mobile
              }
            ]}
          >
            <CountryPhoneInput size="large" />
          </Form.Item>
        </ConfigProvider>
        <Form.Item
          name="birthdate"
          label="Date of Birth"
          rules={[
            {
              type: "date",
              required: true,
              message: "Please input your birth date!",
              transform: (value) => value.toString()
            }
          ]}
        >
          <DatePicker
            format={"DD-MM-YYYY"}
            size="large"
            placeholder="Select your Birthdate"
            style={{ width: "100%" }}
            disabledDate={disabledDate}
            disabled={account?.complete}
          />
        </Form.Item>
        <Form.Item
          name="address1"
          label="Address"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please enter your address!"
            },
            {
              max: 500,
              message: "Max. limit is 100 characters"
            },
            {
              pattern: new RegExp("^[a-zA-Z0-9.,-/ ]+$"),
              message: "Only .,-/ are allowed as special characters!"
            }
          ]}>
          <Input size="large" placeholder="Address line 1" />
        </Form.Item>
        <Form.Item
          name="address2"
          label=" "
          colon={false}
          rules={[
            {
              pattern: new RegExp("^[a-zA-Z.,-/ ]+$"),
              message: "Only .,-/ are allowed as special characters!"
            },
            {
              max: 100,
              message: "Max. limit is 100 characters"
            }
          ]}>
          <Input size="large" placeholder="Address line 2" />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select your country!"
            }
          ]}>
          <Select<string | number, { value: string; children: string }>
            showSearch
            placeholder="Select your Country"
            size="large"
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(_, obj: any) => {
              form.setFieldsValue({pincode: null, city: null, state: null});
              setStates(State.getStatesOfCountry(obj.key));
              setCountry(obj.key);
            }}>
            {countries.map((c) => {
              return (
                <Select.Option key={c.isoCode} value={c.name}>
                  {c.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="state"
          label="State"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select your state!"
            }
          ]}>
          <Select<string | number, { value: string; children: string }>
            showSearch
            placeholder="Select your State"
            size="large"
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(_, obj: any) => {
              form.setFieldsValue({pincode: null, city: null});
              let cities = City.getCitiesOfState(country, obj.key);
              if (cities.length == 0) {
                cities = City.getCitiesOfCountry(country) || [];
              }
              setCities(cities);
            }}>
            {states.map((c) => {
              return (
                <Select.Option key={c.isoCode} value={c.name}>
                  {c.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="city"
          label="City"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select your city!"
            }
          ]}>
          <Select<string | number, { value: string; children: string }>
            showSearch
            placeholder="Select your City"
            size="large"
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(_, obj: any) => {
              form.setFieldsValue({pincode: null});
            }}>
            {cities.map((c, key) => {
              return (
                <Select.Option key={key} value={c.name}>
                  {c.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="pincode"
          label="Pincode"
          rules={[
            {
              type: "string",
              pattern: new RegExp("[0-9a-zA-Z]{4,7}$"),
              required: true,
              message: "Please input your pincode!"
            }
          ]}>
          <Input size="large" placeholder="Pincode" maxLength={7} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    );
  }
};

export default EditAccount;
