import "./App.css";
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AuthProvider, RequireAuth } from "./auth";
import Pages from "./Pages";
import OTTPage from "./Pages/OTT";
import AccountPage from "./Pages/Account";

import {
  CreateFilmProject,
  CreateSeriesProject,
  ViewProjects,
  ViewFilmProject,
  ViewSeriesProject,
  ViewSeasonSeriesProject,
  ViewEpisodeSeasonSeriesProject,
  AddSeasonSeriesProject,
  AddEpisodeSeasonSeriesProject,
} from "./Pages/Partner/Projects";
import { CreateProfessionalProfile, Home, ViewProfessionalProfile } from "./Pages/Partner";
import { ViewBusinessProfile, CreateBusinessProfile } from "./Pages/Partner/BusinessProfile";
import { ProfessionalProfilePage, BusinessProfilePage, FilmProfilePage } from "./Pages/Profile";
import { TermsPage, AboutPage, ContactPage, PrivacyPage, FAQsPage } from "./Pages/Static";
import { CreateDistribution, EditDistribution, ViewDistribution, ListDistribution } from "./Pages/Partner/Distribution";
import ListAvailableProjects from "./Pages/Partner/Distribution/list-available";
import ViewDistributionTicket from "./Pages/Partner/Distribution/view-ticket";
import { useLayoutEffect } from "react";
import DraftAgreementPage from "./Pages/Static/draft-agreement";
import { ApproveLinkPage } from "./Pages/Partner/ApproveLink";
import { LinkProjectPage } from "./Pages/Partner/ProfessionalProfile/link-project";

const ScrollToTop = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <AuthProvider>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<OTTPage />} />
          <Route path="/login" element={<Navigate to="/" />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/profile/:customURL" element={<ProfessionalProfilePage />} />
          <Route path="/company/:customURL" element={<BusinessProfilePage />} />
          <Route path="/film/:id" element={<FilmProfilePage />} />
          <Route path="/approve-link" element={<ApproveLinkPage />} />
          <Route
            path="/partner"
            element={
              <RequireAuth>
                <Pages />
              </RequireAuth>
            }
          >
            <Route path="" element={<Home />} />
            <Route path="projects" element={<ViewProjects />} />
            <Route path="projects/film/create" element={<CreateFilmProject />} />
            <Route path="projects/film/edit/:id" element={<CreateFilmProject />} />
            <Route path="projects/film/view/:id" element={<ViewFilmProject />} />
            <Route path="projects/series/create" element={<CreateSeriesProject />} />
            <Route path="projects/series/view/:id" element={<ViewSeriesProject />} />
            <Route path="projects/series/edit/:id" element={<CreateSeriesProject />} />
            <Route path="projects/series/edit/:seriesId/season" element={<AddSeasonSeriesProject />} />
            <Route path="projects/series/view/:seriesId/season/:seasonId" element={<ViewSeasonSeriesProject />} />
            <Route path="projects/series/edit/:seriesId/season/:seasonId" element={<AddSeasonSeriesProject />} />
            <Route
              path="projects/series/edit/:seriesId/season/:seasonId/episode"
              element={<AddEpisodeSeasonSeriesProject />}
            />
            <Route
              path="projects/series/view/:seriesId/season/:seasonId/episode/:episodeId"
              element={<ViewEpisodeSeasonSeriesProject />}
            />
            <Route
              path="projects/series/edit/:seriesId/season/:seasonId/episode/:episodeId"
              element={<AddEpisodeSeasonSeriesProject />}
            />
            {/* Professional Profile routes */}
            <Route path="professional-profile" element={<ViewProfessionalProfile />} />
            <Route path="professional-profile/edit" element={<CreateProfessionalProfile />} />
            <Route path="professional-profile/add-film-credit" element={<LinkProjectPage />} />
            {/* Business Profile routes */}
            <Route path="business-profile" element={<ViewBusinessProfile />} />
            <Route path="business-profile/edit" element={<CreateBusinessProfile />} />
            <Route path="distributions" element={<ListDistribution />} />
            <Route path="distributions/:id">
              <Route path="" element={<ViewDistribution />} />
              <Route path="view-ticket/:tid" element={<ViewDistributionTicket />} />
            </Route>
            <Route path="distributions/create" element={<ListAvailableProjects />} />
            <Route path="distributions/create/:id" element={<CreateDistribution />} />
            <Route path="distributions/edit/:id" element={<EditDistribution />} />
          </Route>
          <Route path="/terms-of-use" element={<TermsPage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/faqs" element={<FAQsPage />} />
          <Route path="/draft-agreement" element={<DraftAgreementPage />} />
        </Routes>
      </ScrollToTop>
    </AuthProvider>
  );
}

export default App;
