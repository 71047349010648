import { AutoComplete, Button, DatePicker, Form, Input, message, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../Config";
import moment from "moment";
import { BusinessProfilePageForm } from ".";
import { LoadingSpinner, ImageUploader } from "../../../Components";
import "antd-country-phone-input/dist/index.css";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { MasterService } from "../../../Services/master.service";
import { UploadFile } from "antd/lib/upload/interface";
import { camelCase, debounce, kebabCase, startCase } from "lodash";
import { Subject } from "rxjs";
import { removeSocialMediaRoot } from "src/utils/removeSocialMediaRoot";

const searchSubject$ = new Subject<string>();

const CreateBusinessProfile = () => {
  const [searchParams, _setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [imageURL, setImageURL] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<Boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean | { delay?: number | undefined }>(false);
  const [filmResults, setFilmResults] = useState<{ value: string; label: string; _id: string }[]>();
  const [businessProfile, setBusinessProfile] = useState<BusinessProfilePageForm>({
    primaryContact: { short: "in", phone: "", code: 0 },
    secondaryContact: { short: "in", phone: "", code: 0 },
  });
  const [urlAvailableError, setURLAvailableError] = useState<
    ["" | "validating" | "success" | "warning" | "error" | undefined, string]
  >(["", ""]);

  const [business, setBusiness] = useState<string[]>();
  const [companyTypes, setCompanyTypes] = useState<string[]>();
  const [filmCategory, setFilmCategory] = useState<string[]>();
  const [crewRole, setCrewRole] = useState<string[]>();
  const [social, setSocial] = useState<string[]>();
  const [socialMediaPlatforms, setSocialMediaPlatforms] = useState<{ index: number; name: string }[]>([]);

  useEffect(() => {
    const listener = searchSubject$.subscribe(async (query) => {
      const result = await axios.get(config.api_url + "/projects/search/" + query);

      setFilmResults(result.data.map((el: any) => ({ value: el.englishTitle, label: el.englishTitle, _id: el._id })));
    });

    return () => listener.unsubscribe();
  }, [setFilmResults]);

  useEffect(() => {
    MasterService.getList(["business", "companyTypes", "filmCategory", "crewRole", "social"]).then(
      axios.spread((business, companyTypes, filmCategory, crewRole, social) => {
        setBusiness(business.values);
        setCompanyTypes(companyTypes.values);
        setFilmCategory(filmCategory.values);
        setCrewRole(crewRole.values);
        setSocial(social.values);
      })
    );
  }, []);

  useEffect(() => {
    setImageURL(imageURL);
    form.setFieldsValue({
      ["imageURL"]: imageURL || "",
    });
  }, [imageURL]);

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      const res = await axios.get(config.api_url + "/business-profile");
      let tmpBusinessProfile = res.data;
      if (typeof tmpBusinessProfile === "string") {
        tmpBusinessProfile = {};
      }

      await tmpBusinessProfile.film?.map((film: any) => {
        film.year = moment(film.year);
      });
      const platforms: { index: number; name: string }[] = [];
      (tmpBusinessProfile as BusinessProfilePageForm).social = (
        (tmpBusinessProfile as BusinessProfilePageForm).social ?? []
      ).map((social, index) => {
        platforms.push({ index, name: social.platform.toLowerCase() });
        return { ...social, link: removeSocialMediaRoot(social.link) };
      });
      console.log(platforms);
      setSocialMediaPlatforms(platforms);

      if (tmpBusinessProfile.imageURL) {
        setImageURL(tmpBusinessProfile.imageURL);
      }
      setBusinessProfile(tmpBusinessProfile);
      form.resetFields();
      setLoading(false);
    })();
    return () => {};
  }, []);

  const checkCustomURL = useCallback(
    debounce(async (value) => {
      if (value.length === 0) {
        setURLAvailableError(["error", "Custom URL is Mandatory!"]);
        return message.error("Custom URL is Mandatory!");
      }
      if (value.length > 25) {
        setURLAvailableError(["error", "Custom URL is greater than 25 characters!"]);
        return message.error("Custom URL is Mandatory!");
      }
      const pattern = new RegExp("^[0-9a-zA-Z-]+$");
      if (!pattern.test(value)) {
        setURLAvailableError(["error", "Special characters not allowed!"]);
        return message.error("Special characters not allowed!");
      }

      axios.get(config.api_url + `/business-profile/${value}?present=${businessProfile._id}`).then(
        (res) => {
          if (res.data.available === false) {
            setURLAvailableError(["error", "URL already taken, please try another url!"]);
          } else {
            setURLAvailableError(["success", "URL is available"]);
          }
        },
        (err) => {
          message.error(err.message);
          console.error(err);
        }
      );
    }, 1000),
    [businessProfile]
  );

  const getPrefix = (platform: string) => {
    switch (platform) {
      case "facebook":
        return "https://facebook.com/";
      case "linkedin":
        return "https://linkedin.com/company/";
      case "instagram":
        return "https://instagram.com/";
      case "x":
        return "https://x.com/";
      case "whatsapp":
        return "https://wa.me/";
      default:
        return "";
    }
  };

  const generateCustomURL = useCallback(
    debounce(async (event: ChangeEvent<HTMLInputElement>) => {
      let customURL = kebabCase(event.target.value?.length ? event.target.value : "");
      checkCustomURL(customURL);
      form.setFieldsValue({
        ["customURL"]: customURL,
      });
    }, 200),
    []
  );

  const updateDoingBusinessAs = (event: ChangeEvent<HTMLInputElement>) => {
    if (form.getFieldValue("displayName") === undefined || form.getFieldValue("displayName").length === 0) {
      form.setFieldsValue({
        ["displayName"]: startCase(camelCase(form.getFieldValue("companyName"))),
      });
    }
  };

  const onFinish = async (formValues: BusinessProfilePageForm) => {
    // console.debug(values)
    setSubmitLoading(true);
    // const values: BusinessProfilePageForm = {};

    const values = { ...formValues };
    values.imageURL = imageURL ?? "";
    values.customURL = values.customURL?.toLocaleLowerCase();
    values.companyName = startCase(camelCase(values?.companyName));
    values.displayName = startCase(camelCase(values?.displayName));
    values.social = (values.social ?? []).map((social, i) => {
      return {
        platform: social.platform,
        link: getPrefix(social.platform.toLowerCase()) + social.link,
      };
    });

    // console.debug('Received values of form: ', values);

    console.log(values);
    // Make POST request to submit form data and create business profile in the database
    axios.post(config.api_url + "/business-profile", values).then(
      (_res) => {
        message.success(`Business Profile updated successfully`);
        setSubmitLoading(false);
        if (searchParams.has("next")) {
          return navigate(searchParams.get("next")!, { replace: true });
        } else {
          navigate("/partner/business-profile", { replace: true });
        }
      },
      (err) => {
        message.error(`Failed to update your business profile! ${err.response?.data?.error}`);
        setSubmitLoading(false);
        console.log(err.response?.data?.error);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        <h1 className="title">Business Profile</h1>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <ConfigProvider locale={en}>
            <Form
              form={form}
              onFinish={onFinish}
              layout="horizontal"
              wrapperCol={{ span: 20 }}
              labelCol={{ span: 4 }}
              labelAlign="left"
              initialValues={businessProfile}
            >
              <Form.Item name="imageURL" hidden>
                <Input type="hidden" name="imageURL" value={imageURL} />
              </Form.Item>
              <Form.Item label="Logo">
                <ImageUploader
                  name="imageURL"
                  url={imageURL ? [imageURL] : []}
                  action={config.api_url + "/file"}
                  onRemove={(file: UploadFile) => setImageURL(undefined)}
                  onComplete={(url: UploadFile[]) => {
                    setImageURL(url[0].response.url);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="companyName"
                label="Name"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your Company name",
                  },
                  {
                    min: 3,
                    message: "Min. requirement is 3 characters",
                  },
                  {
                    max: 100,
                    message: "Max. limit is 100 characters",
                  },
                  {
                    pattern: new RegExp("^[0-9a-zA-Z.\\- ]*$"),
                    message: "Only .- are allowed as special characters",
                  },
                ]}
              >
                <Input size="large" placeholder="Name" onChange={generateCustomURL} onBlur={updateDoingBusinessAs} />
              </Form.Item>
              <Form.Item
                name="displayName"
                label="Doing Business As"
                rules={[
                  {
                    type: "string",
                    message: "Please input your Business name",
                  },
                  {
                    max: 100,
                    message: "Max. limit is 100 characters",
                  },
                  {
                    pattern: new RegExp("^[0-9a-zA-Z.\\- ]*$"),
                    message: "onyl .- are allowed as special characters",
                  },
                ]}
              >
                <Input size="large" placeholder="Business name" />
              </Form.Item>
              <Form.Item
                name="customURL"
                label="Custom URL"
                validateStatus={urlAvailableError[0]}
                help={urlAvailableError[1]}
                hasFeedback
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your custom url!",
                  },
                  {
                    min: 3,
                    message: "Min. requirement is 3 characters!",
                  },
                  {
                    max: 25,
                    message: "Kindly restrict your custom url to 25 characters!",
                  },
                  {
                    validator: (_, value) => {
                      setURLAvailableError(["validating", ""]);
                      checkCustomURL(value);
                      return Promise.resolve();
                    },
                    message: "Please input your custom url!",
                  },
                  {
                    pattern: new RegExp("^[0-9a-zA-Z-]+$"),
                    message: "Only alphanumeric characters allowed for custom url!",
                  },
                ]}
              >
                <Input size="large" prefix="https://pocketfilms.in/org/" placeholder="Custom URL" />
              </Form.Item>
              <Form.Item
                name="primaryActivity"
                label="Primary Business Activity"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please select your Primary Business activity",
                  },
                ]}
              >
                <Select size="large" placeholder="Select the main business activity of your company" showSearch>
                  {business?.map((b) => (
                    <Select.Option key={b} value={b}>
                      {b}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="otherActivity" label="Other Business Activity">
                <Select
                  size="large"
                  placeholder="Select the other activities of your company"
                  mode="multiple"
                  showSearch
                  allowClear
                >
                  {business?.map((b) => (
                    <Select.Option key={b} value={b}>
                      {b}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="email"
                label="Company Email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                  {
                    required: true,
                    message: "Please input your company email",
                  },
                ]}
              >
                <Input size="large" placeholder="Company email address" />
              </Form.Item>
              <Form.Item
                name="primaryContact"
                label="Primary Contact Number"
                initialValue={{ short: "IN" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter your primary contact number!",
                  },
                  {
                    validator: (_, value) => {
                      const phone = (value.phone || "").trim();
                      return phone.length === 0 || (phone.length >= 7 && phone.length < 14)
                        ? Promise.resolve()
                        : Promise.reject("Please enter a valid phone number!");
                    },
                  },
                ]}
              >
                {/* <Input size="large" addonBefore={prefixCountryCodeSelector} style={{ width: '100%' }} /> */}
                <CountryPhoneInput size="large" />
              </Form.Item>
              <Form.Item
                name="secondaryContact"
                label="Company Mobile Number"
                initialValue={{ short: "IN" }}
                rules={[
                  {
                    validator: (_, value) => {
                      const phone = (value.phone || "").trim();
                      return phone.length === 0 || (phone.length >= 7 && phone.length < 14)
                        ? Promise.resolve()
                        : Promise.reject("Please enter a valid phone number!");
                    },
                  },
                ]}
              >
                {/* <Input size="large" addonBefore={prefixCountryCodeSelector} style={{ width: '100%' }} /> */}
                <CountryPhoneInput size="large" />
              </Form.Item>
              <Form.Item name="companyType" label="Company Type">
                <Select size="large" style={{ width: "100%" }} placeholder="Please select your company type" showSearch>
                  {companyTypes?.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="about"
                label="About Company"
                rules={[
                  {
                    type: "string",
                  },
                  {
                    max: 500,
                    message: "Max. limit is 500 characters",
                  },
                  {
                    pattern: new RegExp("^[0-9a-zA-Z,.?!()'-:;\"$`~_# \n]*$"),
                    message: "Only ,.?!()'-:;\"$`~_#` are allowed as special characters",
                  },
                ]}
              >
                <TextArea
                  showCount
                  size="large"
                  placeholder="Something about your company you would like to share"
                  rows={4}
                ></TextArea>
              </Form.Item>

              {/* Film Credits section start */}
              {/* <Form.Item name="film" label="Film Credits" className="d-flex">
                <Form.List name="film">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name }) => (
                        <div className="mb-2" key={key}>
                          <div
                            style={{
                              display: "flex",
                              gap: 8,
                              alignItems: "baseline",
                            }}
                          >
                            <Form.Item
                              style={{ flex: 1 }}
                              name={[name, "title"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Film title",
                                },
                                {
                                  max: 75,
                                  message: "Max. limit is 75 characters",
                                },
                              ]}
                            >
                              <AutoComplete
                                options={filmResults}
                                onSearch={(searchString) => searchSubject$.next(searchString)}
                                placeholder="Film Name"
                              >
                                <Input.Search size="large" placeholder="Film Name" />
                              </AutoComplete>
                            </Form.Item>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: 8,
                              alignItems: "baseline",
                            }}
                          >
                            <Form.Item
                              name={[name, "category"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Select Film Category",
                                },
                              ]}
                            >
                              <Select size="large" placeholder="Select Film Category" showSearch allowClear>
                                {filmCategory?.map((category) => (
                                  <Select.Option key={category} value={category}>
                                    {category}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              style={{ flex: 1 }}
                              name={[name, "role"]}
                              rules={[{ required: true, message: "Select your Role" }]}
                            >
                              <Select
                                size="large"
                                placeholder="Select your Role in the Film"
                                mode="multiple"
                                showSearch
                                allowClear
                              >
                                {crewRole?.map((role) => (
                                  <Select.Option key={role} value={role}>
                                    {role}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[name, "year"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Select Year of Film",
                                },
                              ]}
                            >
                              <DatePicker
                                format={"YYYY"}
                                size="large"
                                picker="year"
                                placeholder="Select Year of Film"
                              />
                            </Form.Item>
                            <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        {fields.length < 5 ? (
                          <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                            Add Film Credit
                          </Button>
                        ) : null}
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item> */}
              {/* Film Credits section end */}

              <Form.Item
                name="website"
                label="Company Website URL"
                rules={[
                  {
                    type: "url",
                    message: "Please input your website url",
                  },
                  {
                    pattern: new RegExp("^[0-9a-zA-Z.@:/-]*$"),
                    message: "Only .@:/- are allowed as special characters",
                  },
                  {
                    max: 100,
                    message: "Max. limit is 100 characters",
                  },
                ]}
              >
                <Input size="large" placeholder="Company Website URL" />
              </Form.Item>
              {/* Social Media Links section start */}
              <Form.Item name="social" label="Social Media Links" className="d-flex">
                <Form.List name="social">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name }, i) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            gap: 8,
                            alignItems: "baseline",
                          }}
                        >
                          <Form.Item
                            name={[name, "platform"]}
                            rules={[
                              {
                                required: true,
                                message: "Select social media platform",
                              },
                            ]}
                          >
                            {/* <Select size="large" onChange={handleSocialPlatformChange} style={{ width: 150 }}> */}
                            <Select
                              size="large"
                              style={{ width: 150 }}
                              onSelect={(value: string) => {
                                const newSocialMediaPlatforms = [...socialMediaPlatforms];
                                const idx = newSocialMediaPlatforms.findIndex((platform) => platform.index === i);
                                if (idx < 0) {
                                  newSocialMediaPlatforms.push({ index: i, name: value.toLowerCase() });
                                } else {
                                  newSocialMediaPlatforms[idx].name = value.toLowerCase();
                                }
                                setSocialMediaPlatforms(newSocialMediaPlatforms);
                              }}
                            >
                              {social?.map((s) => (
                                <Select.Option key={s} value={s}>
                                  {s}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{ flex: 1 }}
                            name={[name, "link"]}
                            rules={[
                              {
                                required: true,
                                message: "Enter your social media profile link",
                              },
                            ]}
                          >
                            {/* <Input size="large" prefix={socialPrefix} placeholder="Link" disabled={socialPrefix === ""} /> */}
                            <Input
                              size="large"
                              placeholder="Link"
                              prefix={getPrefix(
                                socialMediaPlatforms.find((platform) => platform.index === i)?.name ?? ""
                              )}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(name);
                              const newSocialMediaPlatforms = [...socialMediaPlatforms]
                                .filter(({ index }) => index !== i)
                                .map((el) => ({ ...el, index: el.index > i ? el.index - 1 : el.index }));

                              setSocialMediaPlatforms(newSocialMediaPlatforms);
                            }}
                          />
                        </div>
                      ))}
                      <Form.Item>
                        {fields.length < 5 ? (
                          <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                            Add Social Media Link
                          </Button>
                        ) : null}
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
              {/* Social Media Links section end */}
              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </ConfigProvider>
        </div>
      </Content>
    );
};

export default CreateBusinessProfile;
