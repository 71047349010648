import { Button, Empty, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/auth";
import config from "src/Config";
import { Card, LoadingSpinner } from "src/Components";
import { Project } from "..";
import ViewSeriesProject from "./viewSeries";
import ViewSeasonSeriesProject from "./viewSeason";
import ViewEpisodeSeasonSeriesProject from "./viewEpisode";
import ViewFilmProject from "./viewFilm";

const ViewProjects = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<Project[]>();

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      const res = await axios.get(config.api_url + "/projects");
      setProjects(res.data);
      setLoading(false);
    })();
    return () => {};
  }, []);

  const createFilmProject: VoidFunction = () => {
    setLoading(true);
    axios.get(config.api_url + "/user/me").then(
      (res) => {
        let user = res.data;
        if (user.complete === false) {
          setLoading(false);
          message.warning("Please complete your profile first!", 5);
          return navigate("/account?next=/partner/projects/film/create");
        }
        setLoading(false);
        return navigate("/partner/projects/film/create");
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setLoading(false);
      }
    );
  };

  const createSeriesProject: VoidFunction = () => {
    setLoading(true);
    axios.get(config.api_url + "/user/me").then(
      (res) => {
        let user = res.data;
        if (user.complete === false) {
          setLoading(false);
          message.warning("Please complete your profile first!", 5);
          return navigate("/account?next=/partner/projects/series/create");
        }
        setLoading(false);
        return navigate("/partner/projects/series/create");
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setLoading(false);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 16px 0" }}>
        <div className="d-flex justify-content-between mb-2">
          <h1 className="title">My Projects</h1>
          {projects?.length === 0 ? null : (
            <div>
              <Button size="large" className="me-2" type="primary" onClick={createFilmProject}>
                Add a Film
              </Button>
              <Button size="large" className="me-2" type="primary" onClick={createSeriesProject}>
                Add a Series
              </Button>
            </div>
          )}
        </div>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <div className="row mt-3">
            {projects?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <Button size="large" className="me-2" type="primary" onClick={createFilmProject}>
                  Add a Film
                </Button>
                <Button size="large" className="me-2" type="primary" onClick={createSeriesProject}>
                  Add a Series
                </Button>
              </div>
            ) : (
              projects?.map((project) => (
                <div className="col-md-3 mb-4" key={project._id}>
                  <Card
                    backgroundImg={project.horizontalPosterURL || `${process.env.PUBLIC_URL}/img/project_card_bg.png`}
                    link={
                      project.status === "published"
                        ? `/partner/projects/${project.kind}/view/` + project._id
                        : `/partner/projects/${project.kind}/edit/` + project._id
                    }
                    status={project.status}
                    title={project.title}
                    subtitle={project.kind === "series" ? "Series" : project.category}
                    content={
                      project.kind === "series"
                        ? ""
                        : project.distribution
                        ? `Distribution ${
                            project.distribution.status === "new" || project.distribution.status === "in-review"
                              ? "under review"
                              : project.distribution.status
                          }`
                        : "Distribution not created"
                    }
                  ></Card>
                </div>
              ))
            )}
          </div>
        </div>
      </Content>
    );
};

export { ViewProjects, ViewFilmProject, ViewSeriesProject, ViewSeasonSeriesProject, ViewEpisodeSeasonSeriesProject };
