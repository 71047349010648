import { message } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import config from "src/Config";
import { ProfessionalProfilePageForm } from "src/Pages/Partner/ProfessionalProfile";
import { LoadingSpinner, Navbar, ProfessionalProfile } from "src/Components";
import { useParams } from "react-router-dom";
import { RecoilRoot } from "recoil";

const ProfessionalProfilePage = () => {
  const { customURL } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [professionalProfile, setProfessionalProfile] = useState<ProfessionalProfilePageForm>({} as any);

  // Fetch profile data if already present
  useEffect(() => {
    (() => {
      axios.get(`${config.api_url}/professional-profile/customURL/${customURL}`).then(
        (res) => {
          setProfessionalProfile(res.data);
          setLoading(false);
        },
        (err) => {
          message.error(`${err.message} - ${err.response.data.message}`);
          setError("Profile not found");
          setLoading(false);
        }
      );
    })();
    return () => {};
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <RecoilRoot>
        <div className="container-fluid">
          <Navbar></Navbar>
          <div className="container" style={{ minHeight: "100vh", paddingBottom: "50px" }}>
            <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
              <div>
                {error ? (
                  <p className="text-danger text-center">{error}</p>
                ) : (
                  <ProfessionalProfile isOwner={false} profile={professionalProfile} />
                )}
              </div>
            </div>
          </div>
        </div>
      </RecoilRoot>
    );
  }
};

export default ProfessionalProfilePage;
