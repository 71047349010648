import { message, Modal, Upload } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import axios, { AxiosRequestConfig } from "axios";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { UtilsService } from "../../Services/utils.service";

interface ImageUploaderProps extends UploadProps {
  aspect?: number;
  name: string;
  url: string[];
  action: string;
  onComplete: Function;
  maxCount?: number;
}

const ImageUploader = (props: ImageUploaderProps) => {
  let files: UploadFile[] =
    props.url?.map((u, i) => {
      return {
        url: u,
        status: "done",
        uid: i.toString(),
        name: u.split("/").pop() || "",
      };
    }) || [];
  const [fileList, setFileList] = useState<UploadFile[]>(files);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadLoading, setUploadLoading] = useState<Boolean>(false);

  const handleCancel = () => setPreviewVisible(false);

  const beforeUpload = (file: File) => {
    const isJpgOrPng =
      ["image/jpeg", "image/png"].indexOf(file.type) > -1 ? true : false;
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      console.error("You can only upload JPG/PNG file!");
      return isJpgOrPng || Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      console.error("Image must smaller than 10MB!");
      return isLt2M || Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleImageChange: UploadProps["onChange"] = ({
    file: newFile,
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
    newFile.percent = uploadProgress;
    if (newFile.status === "uploading") {
      setUploadLoading(true);
      return;
    }
    if (newFile.status === "done") {
      // Get this url from response in real world.
      // const imageURL = await UtilsService.getBase64(newFile.originFileObj as RcFile);
      setUploadLoading(false);
      setTimeout(() => {
        props.onComplete(newFileList);
      }, 200);
    }
  };

  const handleImageUpload = async (options: any) => {
    const { onSuccess, onError, file, onProgress, action } = options;
    const fmData = new FormData();
    const config: AxiosRequestConfig<FormData> = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: { loaded: number; total: number }) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setUploadProgress(percent);
        if (percent === 100) {
          setTimeout(() => setUploadProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append(
      props.name +
        ((props.maxCount || 1) > 1 ? `-${new Date().getTime()}` : ""),
      file
    );
    try {
      const res = await axios.post(action, fmData, config);

      onSuccess(res.data);
      // console.debug("Success: ", res);
    } catch (err) {
      console.log("Error: ", err);
      message.error(`Failed to upload image! ${err}`);
      // const error = new Error("Could not update image");
      onError({ err });
    }
  };

  const onPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await UtilsService.getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <ImgCrop aspect={props.aspect || 1} rotate>
        <Upload
          name={props.name}
          listType="picture-card"
          showUploadList={true}
          maxCount={props.maxCount || 1}
          fileList={fileList}
          className="avatar-uploader"
          action={props.action}
          onChange={handleImageChange}
          beforeUpload={beforeUpload}
          onPreview={onPreview}
          customRequest={handleImageUpload}
          onRemove={props.onRemove}
          // headers={{ "Authorization": "Bearer " + token }}
        >
          {fileList.length >= (props.maxCount || 1) ? null : uploadButton}
        </Upload>
      </ImgCrop>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default ImageUploader;
