import {  Tag } from "antd";
import {
  SeasonSeriesProjectPageForm,
  SeriesProjectPageForm,
} from "../../Pages/Partner/Projects";
import { startCase, camelCase } from "lodash";
import Card from "../Card";

const SeriesProfile = (props: {
  profile: SeriesProjectPageForm;
  edit?: boolean;
}) => {
  return (
    <div className="text-light" style={{ overflowWrap: "break-word" }}>
      <div className="text-center">
        <img
          style={{ width: "100%", height: 400, objectFit: "cover" }}
          src={props.profile.horizontalPosterURL}
        />
        <img
          style={{ width: 160, marginTop: -100, borderRadius: 8 }}
          className="border border-dark"
          src={
            props.profile.verticalPosterURL ||
            process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"
          }
          alt={props.profile.customURL}
        />
        <h1 className="fw-bold my-3">
          {props.profile.title}{" "}
          {props.profile.englishTitle
            ? `(${props.profile.englishTitle})`
            : null}
        </h1>
        <div className="my-4">
          {props.profile.genre?.map((g, i) => (
            <Tag key={i} color="#108ee9" style={{ fontSize: "18px" }}>
              {startCase(camelCase(g))}
            </Tag>
          ))}
        </div>
      </div>
      <div className="my-2">Language: {props.profile.language}</div>
      <div className="my-2">Synopsis: {props.profile.synopsis}</div>
      <div className="mt-4">
        <h4>Seasons</h4>
        <div className="d-flex flex-wrap">
          {props.profile.seasons?.map(
            (s: SeasonSeriesProjectPageForm, key: number) => {
              return props.edit || s.status === "complete" ? (
                <div className="m-2" key={key}>
                  <Card
                    layout="vertical"
                    title={
                      s.status !== "complete"
                        ? `Season ${s.number} (draft)`
                        : "Season " + s.number
                    }
                    content={s.title}
                    link={
                      s.status && s.status === "complete"
                        ? `/partner/projects/series/view/${props.profile._id}/season/${s._id}`
                        : `/partner/projects/series/edit/${props.profile._id}/season/${s._id}`
                    }
                    backgroundImg={
                      s.verticalPosterURL ||
                      process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"
                    }
                  ></Card>
                </div>
              ) : null;
            }
            // <Link to={`/partner/projects/series/edit/${props.profile._id}/season/${s._id}`}>
            //     <div className="m-2" key={key}>
            //         <img style={{ width: 160, height: 212 }} className="rounded border border-dark" src={s.verticalPosterURL || process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"} alt={s.title || "season poster"} />
            //         <div>Season {s.number} {s.title ? `- ${s.title}` : null}</div>
            //     </div>
            // </Link>
          )}
          {props.edit ? (
            <div className="m-2">
              <Card
                layout="vertical"
                title={"Add a Season"}
                // content={s.title}
                link={`/partner/projects/series/edit/${props.profile._id}/season/`}
                backgroundImg={process.env.PUBLIC_URL + "/img/add.png"}
              ></Card>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className="my-2">
          <h4>Episodes</h4>
          {props.profile.seasons?.map(
            (s: SeasonSeriesProjectPageForm, key: number) => (
              <div className="my-2" key={key}>
                <strong>
                  {"Season " +
                    s.number +
                    (s.title ? " - " + s.title : null) +
                    ` (${s.episodes?.length} Episodes)`}{" "}
                </strong>
                <div className="d-flex flex-wrap">
                  {s.episodes?.map(
                    (ep: EpisodeSeasonSeriesProjectPageForm, key: number) => (
                      <div className="m-2" key={key}>
                        <Card
                          layout="vertical"
                          title={"Episode " + ep.number}
                          content={ep.title}
                          link={`/partner/projects/series/edit/${props.profile._id}/season/${s._id}/episode/${ep._id}`}
                          backgroundImg={
                            ep.verticalPosterURL ||
                            process.env.PUBLIC_URL +
                              "/img/dummy-image-square.jpg"
                          }
                          action={() => {
                            props.edit
                              ? showDeleteEpisodeConfirm(s._id, ep._id)
                              : (function () {})();
                          }}
                          actionType={props.edit ? "delete" : ""}
                        />
                      </div>
                    )
                  )}
                  {props.edit ? (
                    <div className="m-2">
                      <Card
                        layout="vertical"
                        title={"Add an episode"}
                        link={`/partner/projects/series/edit/${props.profile._id}/season/${s._id}/episode`}
                        backgroundImg={process.env.PUBLIC_URL + "/img/add.png"}
                      ></Card>
                    </div>
                  ) : null}
                </div>
                {s.awards?.length ? (
                  <div className="my-2">
                    <strong>Awards</strong>
                    <table className="table table-dark table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">Award</th>
                          <th scope="col">Country</th>
                          <th scope="col">Year</th>
                          <th scope="col">Category</th>
                          <th scope="col">Role</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {s.awards?.map((a, i) => (
                          <tr key={i}>
                            <td>{startCase(camelCase(a.name))}</td>
                            <td>{a.country || "NA"}</td>
                            <td>{a.year || "NA"}</td>
                            <td>{a.category?.join(", ") || "NA"}</td>
                            <td>{a.role || "NA"}</td>
                            <td>{a.status || "NA"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            )
          )}
        </div> */}
    </div>
  );
};

export default SeriesProfile;
