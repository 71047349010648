import { Button, Empty, message, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import config from "../../../Config";
import { Card, LoadingSpinner } from "src/Components";
import { FilmDistributionForm, SeriesDistributionForm } from ".";

const ListDistribution = () => {
  const auth = useAuth();
  const { confirm } = Modal;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [distributions, setDistributions] = useState<FilmDistributionForm[] | SeriesDistributionForm[]>();

  // Fetch distributions data if already present
  useEffect(() => {
    setLoading(true);
    axios.get(config.api_url + "/distributions").then(
      (res) => {
        if (res.data) setDistributions(res.data);
        else setError("No distributions found");
        setLoading(false);
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setError("Distribution not found");
        setLoading(false);
      }
    );
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  const createDistribution = () => {
    setLoading(true);
    // Check if User has completed profile and has set submitAsBusiness value
    axios.get(config.api_url + "/user/me").then(
      (res) => {
        let user: {
          complete: boolean;
          submitAsBusiness: boolean;
        } = res.data;
        // Navigate User to fill profile if not already complete
        if (user.complete === false) {
          setLoading(false);
          message.warning("Please complete your profile first!", 5);
          return navigate("/account");
        }
        // If submitAsBusiness value is not available, ask user over a modal
        else if (!("submitAsBusiness" in user)) {
          setLoading(false);
          confirm({
            title: "Create distributions as Individual or Business?",
            icon: <ExclamationCircleOutlined />,
            content:
              "Do you wish to create your distributions as an Individual or as a Business? This option cannot be changed once selected. All your distributions will be created accordingly! Please select carefully",
            okText: "Business",
            okType: "danger",
            cancelText: "Individual",
            onOk() {
              setLoading(true);
              // User wants to submit as Business. Check if Business profile already created
              axios.get(config.api_url + "/business-profile").then(
                (res) => {
                  // Check if Business profile already created, if yes then update submitAsBusiness to true and navigate to create distribution
                  if (res.data) {
                    // Update submitAsBusiness to true and navigate to create distribution
                    axios
                      .post(config.api_url + "/user/me", {
                        submitAsBusiness: true,
                      })
                      .then(
                        (res) => {
                          setLoading(false);
                          return navigate("/partner/distributions/create");
                        },
                        (err) => {
                          setLoading(false);
                          message.error(`${err.message} - ${err.response.data.message}`);
                        }
                      );
                  }
                  // As Business profile is not created, navigate user to create it
                  else {
                    setLoading(false);
                    message.warning("Please create your Business profile first!", 5);
                    return navigate("/partner/business-profile");
                  }
                },
                (err) => {
                  message.error(`${err.message} - ${err.response.data.message}`);
                  setLoading(false);
                }
              );
            },
            onCancel() {
              setLoading(true);
              // User wants to submit as Individual. Update submitAsBusiness to false and navigate to create distribution
              axios.post(config.api_url + "/user/me", { submitAsBusiness: false }).then(
                (res) => {
                  setLoading(false);
                  return navigate("/partner/distributions/create");
                },
                (err) => {
                  message.error(`${err.message} - ${err.response.data.message}`);
                  setLoading(false);
                }
              );
            },
          });
        }
        // User Profile is complete and submitAsBusiness value is available, hence proceed with creating distribution
        else {
          setLoading(false);
          navigate("/partner/distributions/create");
        }
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setLoading(false);
      }
    );
  };

  return (
    <Content style={{ margin: "24px 0" }}>
      <div className="d-flex justify-content-between mb-2">
        <h1 className="title">Distributions</h1>
        <Button size="large" type="primary" onClick={createDistribution}>
          Create Distribution
        </Button>
      </div>
      <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
        <div className="row mt-3">
          {error ? (
            <p className="text-danger text-center">{error}</p>
          ) : distributions !== undefined && distributions.length === 0 ? (
            <Empty className="mt-5" image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Distributions"} />
          ) : (
            distributions?.map((dist: any) => (
              <div className="col-md-3" key={dist._id}>
                {dist.kind == "seriesDistribution" && dist.project.seasons.length ? (
                  <Card
                    backgroundImg={
                      dist.project.seasons?.find((s: any) => s._id == dist.season).horizontalPosterURL ||
                      `${process.env.PUBLIC_URL}/img/dist_card_bg.png`
                    }
                    link={`/partner/distributions/${dist._id}`}
                    status={dist.status}
                    title={
                      dist.project.seasons.length
                        ? dist.project.title +
                          ` - S${dist.project.seasons?.find((s: any) => s._id == dist.season).number} ` +
                          dist.project.seasons?.find((s: any) => s._id == dist.season).title
                        : ""
                    }
                    subtitle="Series"
                    content={`Distribution ${
                      dist.status === "new" || dist.status === "in-review" ? "under review" : dist.status
                    }`}
                  ></Card>
                ) : (
                  <Card
                    backgroundImg={
                      `${dist.project.horizontalPosterURL}?width=300` ||
                      `${process.env.PUBLIC_URL}/img/dist_card_bg.png`
                    }
                    link={`/partner/distributions/${dist._id}`}
                    status={dist.status}
                    title={dist.project.title}
                    subtitle={dist.project.category}
                    content={`Distribution ${
                      dist.status === "new" || dist.status === "in-review" ? "under review" : dist.status
                    }`}
                  ></Card>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </Content>
  );
};

export default ListDistribution;
