import { Button, Modal, Space, message } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import config from "src/Config";
import { ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { SeasonSeriesProjectPageForm, SeriesProjectPageForm } from "..";
import { LoadingSpinner, SeasonProfile } from "src/Components";
import { useNavigate, useParams } from "react-router-dom";

const ViewSeasonSeriesProject = () => {
  const { seriesId, seasonId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [series, setSeries] = useState<SeriesProjectPageForm>();
  const [season, setSeason] = useState<SeasonSeriesProjectPageForm>();
  const [ready, setReady] = useState<boolean>(false);
  const { confirm } = Modal;

  // Fetch profile data if already present
  useEffect(() => {
    (() => {
      axios.get(`${config.api_url}/projects/series/${seriesId}`).then(
        (res) => {
          setSeries(res.data);
          let profile = (res.data as SeriesProjectPageForm)?.seasons.find((s) => s._id == seasonId);
          if (profile) {
            profile.episodes.sort((a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0));
            setSeason(profile);
            if (profile.episodes && profile.episodes.length > 1) {
              if (!profile.episodes.find((ep) => ep.status !== "complete")) {
                setReady(true);
              }
            }
          } else {
            message.error(`Season not found`);
            console.debug(res.data);
            setError("Series not found");
          }
          setLoading(false);
        },
        (err) => {
          message.error(`${err.message} - ${err.response.data.message}`);
          setError("Series not found");
          setLoading(false);
        }
      );
    })();
    return () => {};
  }, []);

  const showDeleteSeasonConfirm = () => {
    confirm({
      title: "Are you sure delete this content?",
      icon: <ExclamationCircleOutlined />,
      content: "Delete this Season? This will also delete all the episodes in this season",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoading(true);
        let tmpProfile = series!;
        if (series) {
          tmpProfile.seasons = series?.seasons.filter((s: SeasonSeriesProjectPageForm) => s._id !== seasonId);
          delete tmpProfile.__v;
          axios.post(config.api_url + "/projects/series", tmpProfile).then(
            (res) => {
              setLoading(false);
              navigate(`/partner/projects/series/view/${tmpProfile._id}`);
            },
            (err) => {
              setLoading(false);
              message.info("Error: " + err.message);
              console.error(err);
            }
          );
        } else {
          message.error("Series not found");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const submitForDistribution = () => {
    confirm({
      title: "Do you wish to submit for Distribution?",
      icon: <ExclamationCircleOutlined />,
      width: 600,
      content: (
        <>
          <p>
            Please ensure that you are Submitting for Distribution only after completing the details of the entire
            Season and all its episodes.
          </p>
          <p>
            <b>
              After submission you will not be able to add the details of additional episodes in this season. You will
              have to create another season.
            </b>
          </p>
          <p>
            Keep in mind that you will be able to upload the videos, trailers, subtitles for all episodes only after you
            select Submit for Distribution.
          </p>
        </>
      ),
      okText: "Proceed to Distribution",
      // okType: "danger",
      cancelText: "Cancel",
      onOk() {
        navigate(`/partner/distributions/create/${seriesId}?season=${seasonId}`);
      },
      onCancel() {},
    });
  };

  const editSeason = () => navigate(`/partner/projects/series/edit/${seriesId}/season/${seasonId}`);

  const addEpisode = () => navigate(`/partner/projects/series/edit/${seriesId}/season/${seasonId}/episode`);
  const viewDistribution = () => navigate(`/partner/distributions/${season?.distribution}`);

  // const addSeason = () => navigate(`/partner/projects/series/edit/${id}/season`);

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
        <div>
          {error ? (
            <p className="text-danger text-center">{error}</p>
          ) : (
            <>
              <div className="d-flex justify-content-between mb-2">
                <Button
                  className="btn-outline-primary"
                  onClick={() => {
                    navigate(`/partner/projects/series/view/${seriesId}`);
                  }}
                  icon={<ArrowLeftOutlined />}
                >
                  Back to Series
                </Button>
                <Space>
                  {!season?.distribution && ready ? (
                    <Button className="btn-primary" onClick={submitForDistribution}>
                      Submit for Distribution
                    </Button>
                  ) : null}
                  {season?.distribution ? (
                    <Button className="btn-primary" onClick={viewDistribution}>
                      View Distribution
                    </Button>
                  ) : (
                    <>
                      <Button className="btn-primary" onClick={addEpisode}>
                        Add Episode
                      </Button>
                      <Button className="btn-outline-primary" onClick={editSeason}>
                        Edit
                      </Button>
                      <Button className="btn-outline-danger" onClick={showDeleteSeasonConfirm}>
                        Delete
                      </Button>
                    </>
                  )}
                </Space>
              </div>
              <SeasonProfile series={series!} profile={season!} edit={season?.distribution ? false : true} />
            </>
          )}
        </div>
      </div>
    );
  }
};

export default ViewSeasonSeriesProject;
