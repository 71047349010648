import { Button, Modal, Space, message } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import config from "src/Config";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SeasonSeriesProjectPageForm, SeriesProjectPageForm } from "../";
import { LoadingSpinner, SeriesProfile } from "src/Components";
import { useNavigate, useParams } from "react-router-dom";

const ViewSeriesProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [series, setSeries] = useState<SeriesProjectPageForm>();
  const { confirm } = Modal;

  // Fetch profile data if already present
  useEffect(() => {
    (() => {
      axios.get(`${config.api_url}/projects/series/${id}`).then(
        (res) => {
          res.data.seasons.sort(
            (a: SeasonSeriesProjectPageForm, b: SeasonSeriesProjectPageForm) =>
              a.number > b.number ? 1 : b.number > a.number ? -1 : 0
          );
          setSeries(res.data);
          setLoading(false);
        },
        (err) => {
          message.error(`${err.message} - ${err.response.data.message}`);
          setError("Series not found");
          setLoading(false);
        }
      );
    })();
    return () => {};
  }, []);

  const showDeleteSeriesConfirm = () => {
    if (series?.seasons && series.seasons.length > 0) {
      return message.error(
        "Kindly delete all the seasons first before deleting the series",
        10
      );
    } else {
      confirm({
        title: "Are you sure delete this content?",
        icon: <ExclamationCircleOutlined />,
        content: "Delete this Series?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          setLoading(true);
          axios.delete(`${config.api_url}/projects/${id}`).then(
            (res) => {
              setLoading(false);
              navigate(`/partner/projects`);
            },
            (err) => {
              setLoading(false);
              message.info("Error: " + err.message);
              console.error(err);
            }
          );
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  const editSeries = () => navigate(`/partner/projects/series/edit/${id}`);

  const addSeason = () =>
    navigate(`/partner/projects/series/edit/${id}/season`);

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div
        className="site-layout-background"
        style={{ padding: "24px 0", minHeight: 360 }}
      >
        <div>
          {error ? (
            <p className="text-danger text-center">{error}</p>
          ) : (
            <>
              <div className="d-flex justify-content-end mb-2">
                <Space>
                  <Button className="btn-primary" onClick={addSeason}>
                    Add Season
                  </Button>
                  <Button className="btn-outline-primary" onClick={editSeries}>
                    Edit
                  </Button>
                  {series?.seasons && series?.seasons.length > 0 ? null : (
                    <Button
                      className="btn-outline-danger"
                      onClick={showDeleteSeriesConfirm}
                    >
                      Delete
                    </Button>
                  )}
                </Space>
              </div>
              <SeriesProfile profile={series!} edit={true} />
            </>
          )}
        </div>
      </div>
    );
  }
};

export default ViewSeriesProject;
