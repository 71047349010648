import { AutoComplete, Button, Form, FormInstance, Input, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { useState, useEffect } from "react";
import { MasterService } from "src/Services/master.service";
import { Subject, debounceTime } from "rxjs";
import config from "src/Config";

const searchSubject$ = new Subject<{ query: string; type: "director" | "producer" | "cast" | "crew" }>();

const CreateFilmCrewSection = (props: { id: string | undefined; form: FormInstance }) => {
  const [crewRole, setCrewRole] = useState<string[]>();
  const [directorResults, setDirectorResults] = useState<{ value: string; label: any; _id: string }[]>([]);
  const [producerResults, setProducerResults] = useState<{ value: string; label: any; _id: string }[]>([]);
  const [castResults, setCastResults] = useState<{ value: string; label: any; _id: string }[]>([]);
  const [crewResults, setCrewResults] = useState<{ value: string; label: any; _id: string }[]>([]);

  useEffect(() => {
    const listener = searchSubject$.pipe(debounceTime(500)).subscribe(async (search) => {
      const response = await axios.get<{ _id: string; name: string; customURL: string }[]>(
        `${config.api_url}/professional-profile/search/${search.query}`
      );

      const options: { value: string; label: any; _id: string }[] = response.data.map((result) => ({
        value: `${result.name} (${result.customURL})`,
        label: (
          <span>
            {result.name} (
            <a
              href={`${config.base_url}/profile/${result.customURL}`}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                window.open(`${config.base_url}/profile/${result.customURL}`);
              }}
            >
              {result.customURL}
            </a>
            )
          </span>
        ),
        _id: result._id,
      }));

      if (search.type !== "director") {
        const businessResponse = await axios.get<{ _id: string; companyName: string; customURL: string }[]>(
          `${config.api_url}/business-profile/search/${search.query}`
        );

        options.push(
          ...businessResponse.data.map((result) => ({
            value: `${result.companyName} (${result.customURL}) - Business`,
            label: (
              <span>
                {result.companyName} - Business (
                <a
                  href={`${config.base_url}/company/${result.customURL}`}
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${config.base_url}/company/${result.customURL}`);
                  }}
                >
                  {result.customURL}
                </a>
                )
              </span>
            ),
            _id: result._id,
          }))
        );
      }

      switch (search.type) {
        case "director":
          setDirectorResults(options);
          break;
        case "producer":
          setProducerResults(options);
          break;
        case "cast":
          setCastResults(options);
          break;
        case "crew":
          setCrewResults(options);
          break;
      }
    });

    return () => {
      listener.unsubscribe();
    };
  }, [setDirectorResults, setProducerResults]);

  useEffect(() => {
    MasterService.getList(["crewRole"]).then(
      axios.spread((crewRole) => {
        setCrewRole(crewRole.values);
      })
    );
  }, []);

  return (
    <>
      <Form.Item
        name="director"
        label="Director"
        className="d-flex"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Form.List
          name="director"
          initialValue={[{ name: "", biography: "" }]}
          rules={[
            {
              validator: (_, value = []) =>
                value.length > 0 ? Promise.resolve() : Promise.reject(new Error("Please add a director!")),
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }) => (
                <div key={key} className="d-flex" style={{ marginBottom: 8, gap: 8, alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Form.Item
                      name={[name, "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter director name",
                        },
                        {
                          max: 75,
                          message: "Max. limit is 75 characters",
                        },
                        // prettier-ignore
                        {
                          pattern: new RegExp("^[0-9a-zA-Z. ()-]*$"),
                          message: "No special characters allowed, only A-Z, Period and Space are allowed",
                        },
                      ]}
                    >
                      <AutoComplete
                        options={directorResults}
                        onSearch={(searchString) => searchSubject$.next({ query: searchString, type: "director" })}
                      >
                        <Input.Search size="large" placeholder="Full Name" />
                      </AutoComplete>
                      {/* <Input size="large" placeholder="Full Name" /> */}
                    </Form.Item>
                    <Form.Item
                      name={[name, "biography"]}
                      rules={[
                        {
                          max: 500,
                          message: "Max. limit is 500 character",
                        },
                        {
                          pattern: new RegExp("^[0-9a-zA-Z,.?!()'-:;\"$`~_# \n]*$"),
                          message: "Only ,.?!()'-:;\"$`~_#` are allowed as special characters",
                        },
                      ]}
                    >
                      <TextArea
                        size="large"
                        placeholder="Please share details of the work the director has done"
                        rows={4}
                      ></TextArea>
                    </Form.Item>
                  </div>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined className="dynamic-delete-button text-danger" onClick={() => remove(name)} />
                  ) : null}
                </div>
              ))}
              <Form.Item>
                {fields.length < 5 ? (
                  <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                    Add a Director
                  </Button>
                ) : null}
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item
        name="producer"
        label="Producer"
        className="d-flex"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Form.List
          name="producer"
          initialValue={[{ name: "", filmography: "" }]}
          rules={[
            {
              validator: (_, value = "") =>
                value.length > 0 ? Promise.resolve() : Promise.reject(new Error("Please add a Producer!")),
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }) => (
                <div key={key} className="d-flex" style={{ marginBottom: 8, gap: 8, alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Form.Item
                      name={[name, "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter producer name",
                        },
                        {
                          max: 75,
                          message: "Max. limit is 75 characters",
                        },
                        {
                          pattern: new RegExp("^[0-9a-zA-Z. ()-]*$"),
                          message: "No special characters allowed, only A-Z, period and Space allowed",
                        },
                      ]}
                    >
                      <AutoComplete
                        options={producerResults}
                        onSearch={(searchString) => searchSubject$.next({ query: searchString, type: "producer" })}
                      >
                        <Input.Search size="large" placeholder="Full Name" />
                      </AutoComplete>
                    </Form.Item>
                    <Form.Item
                      name={[name, "filmography"]}
                      rules={[
                        {
                          max: 500,
                          message: "Max. limit is 500 characters",
                        },
                        {
                          pattern: new RegExp("^[0-9a-zA-Z,.?!()'-:;\"$`~_# \n]*$"),
                          message: "Only ,.?!()'-:;\"$`~_#` are allowed as special characters",
                        },
                      ]}
                    >
                      <TextArea
                        size="large"
                        placeholder="Please share details of the other films the Producer has produced"
                        rows={4}
                      ></TextArea>
                    </Form.Item>
                  </div>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined className="dynamic-delete-button text-danger" onClick={() => remove(name)} />
                  ) : null}
                </div>
              ))}
              <Form.Item>
                {fields.length < 5 ? (
                  <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                    Add a Producer
                  </Button>
                ) : null}
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item name="crew" label="Crew" className="d-flex">
        <Form.List name="crew">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }) => (
                <div className="mb-2" key={key}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 8,
                      gap: 8,
                      alignItems: "baseline",
                    }}
                  >
                    <Form.Item
                      style={{ flex: 1 }}
                      name={[name, "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Crew Name is required",
                        },
                        {
                          max: 75,
                          message: "Max. limit is 75 characters",
                        },
                        {
                          pattern: new RegExp("^[0-9a-zA-Z. ()-]*$"),
                          message: "No special characters allowed, only A-Z, Period and Space allowed",
                        },
                      ]}
                    >
                      <AutoComplete
                        options={crewResults}
                        onSearch={(searchString) => searchSubject$.next({ query: searchString, type: "crew" })}
                      >
                        <Input.Search size="large" placeholder="Full Name" />
                      </AutoComplete>
                      {/* <Input size="large" placeholder="Full Name" /> */}
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "role"]}
                      style={{ flex: 1 }}
                      rules={[
                        {
                          required: true,
                          message: "Crew Role is required",
                        },
                      ]}
                    >
                      <Select size="large" style={{ width: "100%" }} placeholder="Please select Crew's Role" showSearch>
                        {crewRole?.map((role) => (
                          <Select.Option key={role} value={role}>
                            {role}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined className="dynamic-delete-button text-danger" onClick={() => remove(name)} />
                  </div>
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                  Add a Crew member
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item name="cast" label="Cast" className="d-flex">
        <Form.List name="cast">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }) => (
                <div className="mb-2" key={key}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 8,
                      gap: 8,
                      alignItems: "baseline",
                    }}
                  >
                    <Form.Item
                      style={{ flex: 1 }}
                      name={[name, "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Cast Name is required",
                        },
                        {
                          max: 75,
                          message: "Max. limit is 75 characters",
                        },
                        {
                          pattern: new RegExp("^[0-9a-zA-Z. ()-]*$"),
                          message: "No special characters allowed, only A-Z, Period and Space allowed",
                        },
                      ]}
                    >
                      <AutoComplete
                        options={castResults}
                        onSearch={(searchString) => searchSubject$.next({ query: searchString, type: "cast" })}
                      >
                        <Input.Search size="large" placeholder="Full Name" />
                      </AutoComplete>
                      {/* <Input size="large" placeholder="Full Name" /> */}
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "character"]}
                      style={{ flex: 1 }}
                      rules={[
                        {
                          required: true,
                          message: "Cast Character Name is required",
                        },
                        {
                          max: 75,
                          message: "Max. limit is 75 characters",
                        },
                        {
                          pattern: new RegExp("^[a-zA-Z. ]*$"),
                          message: "No special characters allowed, only A-Z, Period and Space allowed",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Character Name" />
                    </Form.Item>
                    <MinusCircleOutlined className="dynamic-delete-button text-danger" onClick={() => remove(name)} />
                  </div>
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                  Add a Cast member
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

export default CreateFilmCrewSection;
