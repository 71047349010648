import _ from "lodash";
import { BusinessProfilePageForm } from "../../Pages/Partner/BusinessProfile";
import moment from "moment";
import axios from "axios";
import config from "src/Config";
import { Button, Row, message } from "antd";
import { wait } from "src/utils/wait";
import { handleError } from "src/utils/handleError";
import modal from "antd/lib/modal";
import { useNavigate } from "react-router-dom";

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

const BusinessProfile = (props: { profile: BusinessProfilePageForm; isOwner?: boolean }) => {
  const navigate = useNavigate();

  const renderIcon = (platform: string) => {
    switch (platform.toLocaleLowerCase()) {
      case "facebook":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/facebook.png"}
            alt="Facebook"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "instagram":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/instagram.png"}
            alt="Instagram"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "x":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/x.svg"}
            alt="X"
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#fff",
              borderRadius: "50%",
              border: "1px solid #fff",
            }}
          />
        );
      case "linkedin":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/linkedin.png"}
            alt="Linkedin"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "whatsapp":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/whatsapp.png"}
            alt="Whatsapp"
            style={{ width: "32px", height: "32px" }}
          />
        );
      default:
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/facebook.png"}
            alt="Facebook"
            style={{ width: "32px", height: "32px" }}
          />
        );
    }
  };

  return (
    <div className="text-light">
      <div className="text-center">
        <img
          style={{ width: 160, height: "auto" }}
          src={props.profile.imageURL || process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"}
          alt={props.profile.customURL}
        />
        <h2 className="fw-bold my-3">{props.profile.companyName}</h2>
        {props.profile.displayName ? <h3 className="fw-bold my-3">({props.profile.displayName})</h3> : null}
        <div className="fw-bold my-3">{props.profile.primaryActivity}</div>
        <div className="my-4">
          {props.profile.social?.map((social, i) => (
            <a className="btn btn-link" key={i} href={social.link}>
              {renderIcon(social.platform)}
            </a>
          ))}
        </div>
      </div>
      <div className="my-3">{props.profile.about}</div>
      <div className="my-2">
        <strong className="me-2">Doing Business as:</strong> {props.profile.displayName || props.profile.companyName}
      </div>
      <div className="my-2">
        <strong className="me-2">Company Name:</strong> {props.profile.companyName}
      </div>
      <div className="my-2">
        <strong className="me-2">Company Type:</strong> {props.profile.companyType || "NA"}
      </div>
      <div className="my-2">
        <strong className="me-2">Primary Business Activity:</strong> {props.profile.primaryActivity}
      </div>
      <div className="my-2">
        <strong className="me-2">Other Business Activity:</strong> {props.profile.otherActivity?.join(", ") || "NA"}
      </div>
      <div className="my-2">
        <strong className="me-2">Email:</strong> {props.profile.email}
      </div>
      <div className="my-2">
        <strong className="me-2">Primary Contact Number:</strong>{" "}
        {props.profile.primaryContact?.code
          ? `+${props.profile.primaryContact?.code}${props.profile.primaryContact?.phone} (${props.profile.primaryContact?.short})`
          : "NA"}
      </div>
      <div className="my-2">
        <strong className="me-2">Company Mobile Number:</strong>{" "}
        {props.profile.secondaryContact?.code
          ? `+${props.profile.secondaryContact?.code}${props.profile.secondaryContact?.phone} (${props.profile.secondaryContact?.short})`
          : "NA"}
      </div>
      <div className="my-2">
        <strong className="me-2">Website:</strong> {props.profile.website || "NA"}
      </div>
      {props.profile.awards ? (
        <div className="my-3">
          <strong>Awards / Nominations: </strong> {props.profile.awards}
        </div>
      ) : null}
      {/* {
                props.profile.film?.length ?
                    <>
                        <h5 className="mt-4">Film Credits</h5>
                        <table className="table table-dark table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Film Title</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Year</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.profile.film.map((f, i) =>
                                    <tr key={i}>
                                        <td>{f.title}</td>
                                        <td>{f.category}</td>
                                        <td>{f.role}</td>
                                        <td>{f.year}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </>
                    : null
            } */}
      <>
        <h5 className="mt-4">Film Credits</h5>
        <table className="table table-dark table-responsive">
          <thead>
            <tr>
              <th scope="col">Film Title</th>
              <th scope="col">Category</th>
              <th scope="col">Role</th>
              <th scope="col">Year</th>
              <th scope="col">Sent On</th>
              {props.isOwner ? <th scope="col">Status</th> : null}
              {props.isOwner ? <th scope="col"></th> : null}
            </tr>
          </thead>
          <tbody>
            {_.orderBy(
              (props.profile.film ?? []).map((el) => {
                if (el.approved) {
                  return { ...el, sort: 0, award: false };
                } else if (!el.rejected) {
                  return { ...el, sort: 1, award: false };
                } else {
                  return { ...el, sort: 2, award: false };
                }
              }),
              ["sort", "sentOn"],
              ["asc", "desc"]
            ).map((f, i) => {
              return (
                <tr key={i}>
                  <td>{f.title}</td>
                  <td>{f.category}</td>
                  <td>{f.role}</td>
                  <td>{f.year}</td>
                  <td>{moment(f.sentOn).format("DD/MM/YYYY")}</td>
                  {props.isOwner ? (
                    <>
                      <td>
                        {f.sentBy === "professional" ? (
                          f.approved ? (
                            "Approved By Creator"
                          ) : f.rejected ? (
                            "Rejected By Creator"
                          ) : (
                            <>
                              <span>Pending from Creator</span>{" "}
                              {f.resent ? null : (
                                <span
                                  onClick={() => {
                                    axios
                                      .post(
                                        `${config.api_url}/projects/resend-link-request/${f.project}/${f.category}?business=true`
                                      )
                                      .then(() => {
                                        message.success("Resent successfully!");
                                        return wait(500);
                                      })
                                      .then(() => window.location.reload())
                                      .catch((e) => message.error(handleError(e)));
                                  }}
                                  style={{
                                    color: "#177DDC",
                                    textDecoration: "underlins",
                                    transform: "scale(0.8)",
                                    cursor: "pointer",
                                  }}
                                >
                                  Resend
                                </span>
                              )}
                            </>
                          )
                        ) : f.approved ? (
                          "Approved By You"
                        ) : f.rejected ? (
                          "Rejected By You"
                        ) : (
                          "Pending from You"
                        )}
                      </td>
                      <td>
                        {f.approved && +new Date() - +new Date(f.approvedOn as any as string) < ONE_WEEK ? (
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              modal.confirm({
                                title: "Are you sure you want to remove this project from your profile?",
                                content:
                                  "If you wish to add this project back to your profile, the creator will need to send another linking request.",
                                okText: "Unlink",
                                onOk: () => {
                                  axios
                                    .get(
                                      config.api_url +
                                        "/projects/remove-link/" +
                                        f.project +
                                        "/" +
                                        f.category +
                                        "?business=true"
                                    )
                                    .then(() => {
                                      message.success("Unlinked successfully!");
                                      window.location.reload();
                                    })
                                    .catch((e) => message.error(handleError(e)));
                                },
                              });
                            }}
                          >
                            Unlink
                          </Button>
                        ) : null}
                        {!f.approved && !f.rejected && f.sentBy === "project" ? (
                          <Row>
                            <Button
                              size="small"
                              type="primary"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                axios
                                  .get(
                                    config.api_url +
                                      "/projects/approve-link/" +
                                      f.project +
                                      "/" +
                                      f.category +
                                      "/" +
                                      (f as any)._id +
                                      "?roleId=professional&business=true"
                                  )
                                  .then((response) => {
                                    message.success("Link approved successfully!");
                                    window.location.reload();
                                  })
                                  .catch((e) => {
                                    message.error(handleError(e));
                                  });
                              }}
                            >
                              Approve
                            </Button>
                            <Button
                              size="small"
                              type="dashed"
                              onClick={() => {
                                axios
                                  .get(
                                    config.api_url +
                                      "/projects/reject-link/" +
                                      f.project +
                                      "/" +
                                      f.category +
                                      "/" +
                                      (f as any)._id +
                                      "?roleId=professional&business=true"
                                  )
                                  .then((response) => {
                                    message.success("Link rejected successfully!");
                                    window.location.reload();
                                  })
                                  .catch((e) => {
                                    message.error(handleError(e));
                                  });
                              }}
                            >
                              Reject
                            </Button>
                          </Row>
                        ) : null}
                      </td>
                    </>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    </div>
  );
};

export default BusinessProfile;
