import { useParams } from "react-router-dom";
import EditFilmDistribution from "./film";
import EditSeriesDistribution from "./series";
import { LoadingSpinner } from "src/Components";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "src/Config";
import {
  FilmDistributionForm,
  SeriesDistributionForm,
} from "src/Pages/Partner/Distribution";

const EditDistribution = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [distribution, setDistribution] = useState<
    FilmDistributionForm | SeriesDistributionForm
  >();

  useEffect(() => {
    axios.get(`${config.api_url}/distributions/${id}`).then((res) => {
      setDistribution(res.data);
      setLoading(false);
    });
  }, []);

  if (loading || !distribution) return <LoadingSpinner />;
  return distribution?.kind == "seriesDistribution" ? (
    <EditSeriesDistribution
      distribution={distribution as SeriesDistributionForm}
    />
  ) : (
    <EditFilmDistribution distribution={distribution as FilmDistributionForm} />
  );
};

export default EditDistribution;
