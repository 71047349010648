import { Button } from "antd";
import React from "react";
import { deconstructProfile } from "src/utils/deconstructProfile";

export const LinkedProfile = ({ name }: { name: string }) => {
  const deconstructed = deconstructProfile(name);

  return deconstructed.isLinked ? (
    <a href={deconstructed.link} target="_blank">
      {deconstructed.rawName}
    </a>
  ) : (
    <span>{name}</span>
  );
};
