import { FormInstance, message } from "antd";
import { useEffect, useState } from "react";
import { Moment } from "moment";

const CreateSeriesReviewSection = (props: {
  id: string | undefined;
  form: FormInstance<any>;
  formErrors: {
    name: (string | number)[];
    errors: string[];
    warnings: string[];
  }[];
  navigate: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useEffect(() => {
    // props.form.validateFields();
    if (props.formErrors.length > 0) {
      message.error(props.formErrors[0].errors[0]);
    }
  }, [props.form]);

  return (
    <>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(1);
        }}
      >
        <h6 className="fw-bold">Episode Details</h6>
        <div className="row">
          <div className="col-sm-2">
            Episode Number<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("number")}{" "}
            <span className="text-danger">{props.form.getFieldError("number")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Episode Title:</div>
          <div className="col">
            {props.form.getFieldValue("title")} <span className="text-danger">{props.form.getFieldError("title")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Duration in mm:ss<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("duration") ? props.form.getFieldValue("duration")["mm"] : null} minutes{" "}
            {props.form.getFieldValue("duration") ? props.form.getFieldValue("duration")["ss"] : null} seconds{" "}
            <span className="text-danger">
              {props.form.getFieldError(["duration", "mm"])} {props.form.getFieldError(["duration", "ss"])}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Tagline<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("tagline")}{" "}
            <span className="text-danger">{props.form.getFieldError("tagline")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Synopsis<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("synopsis")}{" "}
            <span className="text-danger">{props.form.getFieldError("synopsis")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Release Date<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("releaseDate")?.format("DD-MM-YYYY")}{" "}
            <span className="text-danger">{props.form.getFieldError("releaseDate")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Shooting Format<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("shootFormat")}{" "}
            <span className="text-danger">{props.form.getFieldError("shootFormat")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Aspect Ratio<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("aspectRatio")}{" "}
            <span className="text-danger">{props.form.getFieldError("aspectRatio")}</span>
          </div>
        </div>
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(2);
        }}
      >
        <h6 className="fw-bold">Censor Ratings</h6>
        <div className="row mb-2">
          <div className="col-sm-2">
            Censor / Self Censor<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("censor")}{" "}
            <span className="text-danger">{props.form.getFieldError("censor")}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Language<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("languageRating")}{" "}
            <span className="text-danger">{props.form.getFieldError("languageRating")}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Violence<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("violenceRating")}{" "}
            <span className="text-danger">{props.form.getFieldError("violenceRating")}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Adult Content<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("adultContentRating")}{" "}
            <span className="text-danger">{props.form.getFieldError("adultContentRating")}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Drugs<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("drugsRating")}{" "}
            <span className="text-danger">{props.form.getFieldError("drugsRating")}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Harmful / Dangerous Act<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("harmfulRating")}{" "}
            <span className="text-danger">{props.form.getFieldError("harmfulRating")}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Hateful and Derogatory<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form.getFieldValue("hatefulRating")}{" "}
            <span className="text-danger">{props.form.getFieldError("hatefulRating")}</span>
          </div>
        </div>
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(3);
        }}
      >
        <h6 className="fw-bold">Cast and Crew Details</h6>
        <div className="row">
          <div className="col-sm-2">
            Director<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form
              .getFieldValue("director")
              ?.map((d: { name: string }) => d?.name)
              .join(", ")}{" "}
            <span className="text-danger">{props.form.getFieldError("director")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Director's Biography :</div>
          <div className="col">
            {props.form.getFieldValue("directorBiography")}{" "}
            <span className="text-danger">{props.form.getFieldError("directorBiography")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">
            Producer<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            {props.form
              .getFieldValue("producer")
              ?.map((d: { name: string }) => d?.name)
              .join(", ")}{" "}
            <span className="text-danger">{props.form.getFieldError("producer")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Producer's Filmography :</div>
          <div className="col">
            {props.form.getFieldValue("producerFilmography")}{" "}
            <span className="text-danger">{props.form.getFieldError("producerFilmography")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Crew :</div>
          <div className="col">
            {props.form.getFieldValue("crew")?.map(
              (
                crew: {
                  name?: string;
                  role?: string;
                },
                i: number
              ) => {
                return (
                  <div className="mb-2" key={i}>
                    <div className="row">
                      <div className="col-sm-1">Name :</div>
                      <div className="col">
                        {crew?.name} <span className="text-danger">{props.form.getFieldError("crew")}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-1">Role :</div>
                      <div className="col">
                        {crew?.role} <span className="text-danger">{props.form.getFieldError("crew")}</span>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2">Cast :</div>
          <div className="col">
            {props.form.getFieldValue("cast")?.map(
              (
                cast: {
                  name?: string;
                  character?: string;
                },
                i: number
              ) => {
                return (
                  <div className="mb-2" key={i}>
                    <div className="row">
                      <div className="col-sm-1">Name :</div>
                      <div className="col">
                        {cast?.name} <span className="text-danger">{props.form.getFieldError("cast")}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-1">Role :</div>
                      <div className="col">
                        {cast?.character} <span className="text-danger">{props.form.getFieldError("cast")}</span>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      <div
        className="p-3 my-2"
        style={{ backgroundColor: "rgb(38, 68, 100)", borderRadius: "10px" }}
        onClick={() => {
          props.navigate(4);
        }}
      >
        <h6 className="fw-bold">Posters Details</h6>
        <div className="row mb-2">
          <div className="col-sm-2">
            Vertical Poster<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            <span className="text-danger">{props.form.getFieldError("verticalPosterURL")}</span>
            <img height={86} src={props.form.getFieldValue("verticalPosterURL")} />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            Horizontal Poster<sup className="text-danger">*</sup> :
          </div>
          <div className="col">
            <span className="text-danger">{props.form.getFieldError("horizontalPosterURL")}</span>
            <img width={86} src={props.form.getFieldValue("horizontalPosterURL")} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSeriesReviewSection;
