import { DatePicker, Form, FormInstance, Input, InputNumber, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { MasterService } from "src/Services/master.service";
import { Moment } from "moment";
import TextArea from "antd/lib/input/TextArea";

const CreateSeasonEpisodeSection = (props: { id: string; seasonId: string; notAllowNumbers: number[] }) => {
  const disabledDate = (current: Moment) => {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  };

  const [shootingFormat, setShootingFormat] = useState<string[]>();
  const [aspectRatio, setAspectRatio] = useState<string[]>();
  useEffect(() => {
    MasterService.getList(["shootingFormat", "aspectRatio"]).then(
      axios.spread((shootingFormat, aspectRatio) => {
        setShootingFormat(shootingFormat.values);
        setAspectRatio(aspectRatio.values);
      })
    );
  }, []);

  return (
    <div className="mb-3 border-bottom border-dark">
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"number"}
          labelCol={{ span: 5 }}
          label="Episode Number"
          rules={[
            {
              type: "number",
              message: "Only number is acceptable",
            },
            {
              required: true,
              message: "Enter the Episode Number",
            },
            {
              validator: (rule, value) => {
                if (value && props.notAllowNumbers.includes(value)) {
                  return Promise.reject("This Episode number is already taken");
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ flex: 1 }}
        >
          <InputNumber min={1} max={100} style={{ width: "100px" }} placeholder="" />
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"title"}
          labelCol={{ span: 5 }}
          label="Title"
          rules={[
            {
              pattern: new RegExp("^[0-9a-zA-Z.?:# ]*$"),
              message: "Only .?:# are allowed as special characters",
            },
            {
              max: 75,
              message: "Max. limit is 75 characters",
            },
          ]}
          style={{ flex: 1 }}
        >
          <Input size="large" placeholder="Enter the Episode Title" />
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          labelCol={{ span: 5 }}
          label="Duration"
          className="required"
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Group compact>
            <Form.Item
              name={["duration", "mm"]}
              noStyle
              rules={[
                {
                  type: "number",
                  required: true,
                  message: "Minutes field is required",
                },
              ]}
            >
              <InputNumber min={0} max={300} style={{ width: "100px" }} placeholder="mm" />
            </Form.Item>
            <span className="mx-2">:</span>
            <Form.Item
              name={["duration", "ss"]}
              noStyle
              rules={[
                {
                  type: "number",
                  required: true,
                  message: "Seconds field is required",
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputNumber min={0} max={59} style={{ width: "100px" }} placeholder="ss" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"tagline"}
          labelCol={{ span: 5 }}
          label="Tagline"
          rules={[
            {
              pattern: new RegExp("^[0-9a-zA-Z,. ]*$"),
              message: "Only ,. are allowed as special characters",
            },
            {
              max: 100,
              message: "Max. limit is 100 characters",
            },
          ]}
          style={{ flex: 1 }}
        >
          <Input size="large" placeholder="Enter the Episode tagline" />
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"synopsis"}
          labelCol={{ span: 5 }}
          label="Synopsis"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please enter Film Synopsis!",
            },
            {
              pattern: new RegExp("^[0-9a-zA-Z,.'\"`()& \n]*$"),
              message: "Only ,.'\"`()& are allowed as special characters",
            },
            {
              max: 500,
              message: "Max. limit is 500 characters",
            },
          ]}
          style={{ flex: 1 }}
        >
          <TextArea showCount size="large" placeholder="Film Synopsis" rows={4}></TextArea>
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"releaseDate"}
          labelCol={{ span: 5 }}
          label="Release Date"
          style={{ flex: 1 }}
          rules={[
            {
              type: "date",
            },
          ]}
        >
          <DatePicker
            format={"DD-MM-YYYY"}
            size="large"
            style={{ width: "50%" }}
            disabledDate={disabledDate}
            placeholder="Select your Episode Release Date"
          />
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"shootFormat"}
          labelCol={{ span: 5 }}
          label="Shooting Format"
          style={{ flex: 1 }}
          rules={[
            {
              type: "string",
              required: true,
              message: "Please input your Episode Shooting Format",
            },
          ]}
        >
          <Select
            size="large"
            style={{ width: "100%" }}
            placeholder="Please select your Episode Shooting Format"
            showSearch
          >
            {shootingFormat?.map((format) => (
              <Select.Option key={format} value={format}>
                {format}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"aspectRatio"}
          labelCol={{ span: 5 }}
          label="Original Aspect Ratio"
          style={{ flex: 1 }}
          rules={[
            {
              type: "string",
              required: true,
              message: "Please input your Episode Original Aspect Ratio",
            },
          ]}
        >
          <Select size="large" placeholder="Please select your Episode Original Aspect Ratio" showSearch>
            {aspectRatio?.map((ratio) => (
              <Select.Option key={ratio} value={ratio}>
                {ratio}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

export default CreateSeasonEpisodeSection;
