import "./distribution.css";
import axios from "axios";
import config from "src/Config";
import { useState, useEffect } from "react";
import { ChatBox, Uploader, LoadingSpinner } from "src/Components";
import { Button, Form, Input, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { UploadFile } from "antd/lib/upload/interface";

const ViewDistributionTicket = () => {
  let { id, tid } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [distribution, setDistribution] = useState<any>(null);
  const [ticket, setTicket] = useState<{
    _id: string;
    title: string;
    discussion: any;
    status: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [attachment, setAttachment] = useState<string | undefined>(undefined);

  // Fetch distribution data
  useEffect(() => {
    axios.get(config.api_url + "/distributions/" + id).then(
      (res) => {
        if (res.data) {
          setDistribution(res.data);
          // Fetch ticket
          axios.get(config.api_url + "/tickets/" + tid).then(
            (res) => {
              if (res.data) {
                setTicket(res.data);
              } else message.error(`Ticket not found`);
              setLoading(false);
            },
            (err) => {
              message.error(`${err.message} - ${err.response.data.message}`);
              setLoading(false);
            }
          );
        }
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    setAttachment(attachment);
    form.setFieldsValue({
      ["attachment"]: attachment,
    });
  }, [attachment]);

  const onDiscussionSubmit = (value: {
    actor: string;
    content: string;
    attachment?: string;
    distribution?: string;
  }) => {
    setLoading(true);
    console.debug(`Received values: ${JSON.stringify(value)}`);
    value.actor = "user";
    value.distribution = id;
    if (value.attachment?.length === 0) {
      delete value.attachment;
    }
    axios.post(`${config.api_url}/tickets/${tid}`, value).then((res) => {
      console.debug(res);
      message.success("Reply Added");
      distribution.discussion.push({
        ...value,
        ...{ timestamp: new Date() },
      });
      form.resetFields();
      setAttachment(undefined);
      form.setFieldsValue({ attachment: undefined });
      setLoading(false);
      // console.debug(attachment);
      // navigate(`/partner/distributions/${id}/view-ticket/${tid}`, {
      //   replace: true,
      // });
      window.location.reload();
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        <div className="d-flex justify-content-between mb-2">
          <h1 className="title">
            <Link className="text-light" to={"/partner/distributions"}>
              Distributions
            </Link>{" "}
            &gt;&nbsp;
            <Link className="text-light" to={`/partner/distributions/${id}`}>
              {distribution.project.title}
            </Link>{" "}
            &gt; Ticket - {tid}
          </h1>
        </div>
        <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
          <div>
            <h4 className="bg-dark p-2 mb-4">{ticket?.title}</h4>
            {ticket?.discussion.map(
              (
                d: {
                  actor: string;
                  content: string;
                  attachment: string;
                  timestamp: string;
                },
                key: number
              ) => {
                return (
                  <div className="row" key={key}>
                    <ChatBox
                      key={key}
                      actor={d.actor}
                      content={d.content}
                      attachment={d.attachment}
                      timestamp={d.timestamp}
                    />
                  </div>
                );
              }
            )}
            <div className="row">
              <Form form={form} onFinish={onDiscussionSubmit} layout="horizontal" className="mt-5">
                <Form.Item
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your message",
                    },
                  ]}
                  className="m-0"
                >
                  <TextArea showCount rows={3} placeholder="Enter your message" />
                </Form.Item>
                <Form.Item name="attachment" hidden>
                  <Input type="hidden" name="attachment" value={attachment} />
                </Form.Item>
                <Form.Item label="Attachment">
                  <Uploader
                    name="attachment"
                    url={attachment ? [attachment] : []}
                    action={config.api_url + "/file"}
                    onComplete={(url: UploadFile[]) => {
                      setAttachment(url[0].response.url);
                    }}
                  />
                </Form.Item>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Send
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </Content>
    );
};

export default ViewDistributionTicket;
