import axios from "axios";
import config from "src/Config";
import { useState, useEffect } from "react";
import { LoadingSpinner } from "src/Components";
import { Button, Form, Input, InputNumber, message, Modal, Row, Space, Tabs, Tooltip, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/lib/table";
import { capitalize } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { SeriesDistributionForm } from "..";
import { SeasonSeriesProjectPageForm } from "../../Projects";

const ViewSeriesDistribution = ({ distribution }: { distribution: SeriesDistributionForm }) => {
  let { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modalForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tickets, setTickets] = useState<any>([]);
  const [season, setSeason] = useState<SeasonSeriesProjectPageForm | undefined>(
    distribution?.project?.seasons.find((s) => s._id == distribution.season)
  );
  const [defaultActiveKey, setDefaultActiveKey] = useState<string>(
    searchParams.get("section") === "ticket" ? "4" : "1"
  );

  const platforms: any = {
    pf: "PF owned platforms (its website, mobile apps, apps on other platforms, app-in-app, integrations, etc.)",
    channels: "PF branded channels on other platforms including but not limited to YouTube, Facebook, etc.",
    syndication: "Syndication across platforms excluding YouTube and Facebook.",
    tv: "TV and broadcast",
    theatrical: "Theatrical, festival, public/private screenings",
    all: "All digital platforms including but not limited to internet, mobile, IPTV, etc.",
  };

  const subtitles: any = {
    embeded: "Embedded on the video",
    yes: "Subtitle file is available",
    no: "No subtitle available",
  };

  const territories: any = {
    worldwide: "Worldwide",
    exclude: "Worldwide, excluding these countries",
    include: "Only selected countries",
  };

  interface TicketTableDataType {
    id: string;
    title: string;
    pending: string;
    status: string;
  }
  const ticketColumns: ColumnsType<TicketTableDataType> = [
    {
      title: "Ticket Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  // Fetch data
  useEffect(() => {
    // Fetch tickets
    axios.get(config.api_url + "/tickets/project/" + distribution.project?._id).then(
      (res) => {
        if (res.data) {
          setTickets(
            res.data.map((d: { _id: string; title: string; discussion: [{ actor: string }]; status: string }) => {
              return {
                id: d._id,
                title: d.title,
                pending: d.discussion[0].actor == "admin" ? "User" : "Admin",
                status: capitalize(d.status),
              };
            })
          );
          // if (res.data.status === "userPending") {
          //   setDefaultActiveKey("3");
          // }
        } else setTickets([]);
        setLoading(false);
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setLoading(false);
      }
    );
    setLoading(false);
  }, []);

  const onTicketSubmit = (value: { title: string; content: string }) => {
    setLoading(true);
    console.debug(`Received values: ${JSON.stringify(value)}`);
    const data = {
      title: value.title,
      discussion: [
        {
          actor: "user",
          content: value.content,
        },
      ],
      project: distribution.project?._id,
      user: distribution.user?._id,
      distribution: id,
    };
    axios.post(config.api_url + "/tickets", data).then(
      (res) => {
        // console.debug(res);
        setIsModalOpen(false);
        message.success("Ticket created");
        setLoading(false);
        navigate(`/partner/distributions/${id}/view-ticket/${res.data._id}`);
      },
      (err) => {
        setIsModalOpen(false);
        setLoading(false);
        console.error(err);
        message.error(`${err.message} - ${err.response.data.message}`);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        <div className="d-flex justify-content-between mb-2">
          <h1 className="title">
            <Link className="text-light" to={"/partner/distributions"}>
              Distributions
            </Link>{" "}
            &gt; {distribution?.project?.title} - Season {season?.number} {season?.title}
          </h1>
          {distribution.status !== "active" ? (
            <Button
              className="btn-outline-danger"
              onClick={() => {
                navigate(`/partner/distributions/edit/${id}`);
              }}
            >
              Edit
            </Button>
          ) : null}
        </div>
        <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
          <div>
            <Modal
              title={`Open New Ticket`}
              visible={isModalOpen}
              onOk={() => {
                modalForm.submit();
              }}
              okText={`Open Ticket`}
              onCancel={() => {
                modalForm.resetFields();
                setIsModalOpen(false);
              }}
              width={1000}
            >
              <Form
                form={modalForm}
                onFinish={onTicketSubmit}
                layout="horizontal"
                wrapperCol={{ span: 20 }}
                labelCol={{ span: 4 }}
                labelAlign="left"
              >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the ticket title",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter the ticket title" />
                </Form.Item>
                <Form.Item
                  name="content"
                  label="Content"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your ticket content",
                    },
                  ]}
                >
                  <TextArea showCount rows={3} placeholder="Enter your message" />
                </Form.Item>
              </Form>
            </Modal>
            <Tabs defaultActiveKey={defaultActiveKey}>
              <Tabs.TabPane forceRender={true} tab="Upload" key="1">
                <Form>
                  <Form.Item label="Trailer">
                    {distribution.trailer ? (
                      <div style={{ position: "relative", paddingTop: "300px" }}>
                        <iframe
                          src={`https://iframe.mediadelivery.net/embed/${config.bunny_stream_id}/${distribution.bunnyTrailerId}?autoplay=false`}
                          loading="lazy"
                          style={{
                            border: "none",
                            position: "absolute",
                            top: 0,
                            height: "300px",
                            width: "500px",
                          }}
                          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    ) : (
                      <p className="mb-0">Trailer not available</p>
                    )}
                  </Form.Item>
                  <Form.Item label="Vertical Trailer">
                    {distribution.verticalTrailer ? (
                      // <video
                      //   controls
                      //   style={{ width: "auto", height: "300px" }}
                      // >
                      //   <source src={distribution.verticalTrailer} />
                      // </video>
                      <div style={{ position: "relative", paddingTop: "300px" }}>
                        <iframe
                          src={`https://iframe.mediadelivery.net/embed/${config.bunny_stream_id}/${distribution.bunnyVerticalTrailerId}?autoplay=false`}
                          loading="lazy"
                          style={{
                            border: "none",
                            position: "absolute",
                            top: 0,
                            height: "300px",
                            width: "500px",
                          }}
                          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    ) : (
                      <p className="mb-0">Vertical Trailer not available</p>
                    )}
                  </Form.Item>
                  <Form.Item label="Censor Certificate">
                    {distribution.censor ? <Link to={distribution.censor}>Censor Certificate</Link> : null}
                  </Form.Item>
                  <Form.Item name="musicCueSheet" label="Music Cue Sheet">
                    {distribution.musicCueSheet ? <Link to={distribution.musicCueSheet}>Music Cue Sheet</Link> : null}
                  </Form.Item>
                </Form>
              </Tabs.TabPane>
              <Tabs.TabPane forceRender={true} tab={`Episodes`} key={"2"}>
                <Tabs>
                  {distribution.episodes.map((episode, key) => (
                    <Tabs.TabPane forceRender={true} tab={`Episode ${episode.episode}`} key={key}>
                      <p className="mt-5">{`Episode ${episode.episode}`}:</p>
                      <div className="mt-2">
                        <p>{`Video ${episode.episode}`}:</p>
                        <video controls style={{ width: "auto", height: "300px" }}>
                          <source src={episode.video} />
                        </video>
                      </div>
                      {episode.verticalVideo ? (
                        <div className="mt-2">
                          <p>{`Video ${episode.episode}`}:</p>
                          <video controls style={{ width: "auto", height: "300px" }}>
                            <source src={episode.video} />
                          </video>
                        </div>
                      ) : null}
                      <Form.Item label="Subtitles">
                        <Input size="large" readOnly={true} value={subtitles[episode.subtitles]} />
                      </Form.Item>
                      {episode.subtitles === "yes" ? (
                        <>
                          <Form.Item label="Subtitle Files">
                            {episode?.subtitleFiles?.map(
                              (subtitle: { language: string; file: string }, key: number) => (
                                <div key={key}>
                                  <a target="_blank" href={subtitle.file}>
                                    {subtitle.language}
                                  </a>
                                </div>
                              )
                            )}
                          </Form.Item>
                        </>
                      ) : null}
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane forceRender={true} tab="Rights Management" key="3">
                <Form
                  layout="horizontal"
                  wrapperCol={{ span: 20 }}
                  labelCol={{ span: 4 }}
                  labelAlign="left"
                  initialValues={distribution}
                >
                  <Form.Item label="Territory">
                    <Input size="large" readOnly={true} value={territories[distribution.territory]} />
                  </Form.Item>
                  {distribution.territory !== "worldwide" ? (
                    <Form.Item name="territoryCountries" label={`Countries to ${distribution.territory}`}>
                      <Input size="large" readOnly={true} />
                    </Form.Item>
                  ) : null}
                  <Form.Item label="Platforms">
                    {distribution.platforms.map((p: string, key: number) => (
                      <Input size="large" key={key} value={platforms[p]} readOnly={true} />
                    ))}
                  </Form.Item>
                  <Form.Item name="monetisation" label="Monetisation Model">
                    <Input size="large" readOnly={true} />
                  </Form.Item>
                  <Form.Item
                    name="signature"
                    label="Signature"
                    hasFeedback
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please enter your signature",
                      },
                      {
                        min: 3,
                        message: "Please enter your signature",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Sign here"
                      style={{ border: "1px solid white", fontFamily: "Mina" }}
                      readOnly={true}
                    />
                  </Form.Item>
                </Form>
              </Tabs.TabPane>
              <Tabs.TabPane forceRender={true} tab="Tickets" key="4">
                <div className="mb-3">
                  <div className="d-flex justify-content-end mb-2">
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      New Ticket
                    </Button>
                  </div>
                  <Table
                    columns={ticketColumns}
                    dataSource={tickets}
                    sticky={true}
                    rowClassName={"clickable"}
                    onRow={(record, rowIndex) => ({
                      onClick: (event: any) => navigate(`/partner/distributions/${id}/view-ticket/${record.id}`),
                    })}
                  />
                </div>
              </Tabs.TabPane>
              {distribution.agreementFiles?.length > 0 ? (
                <Tabs.TabPane forceRender={true} tab="Agreement" key="4">
                  <Form.Item label="Agreement">
                    {distribution.agreementFiles.map((file: string, key: number) => {
                      return (
                        <div className="my-2" key={key}>
                          <Button href={file} target="_blank">
                            View Agreement - {file.split("/").at(-1)}
                          </Button>
                        </div>
                      );
                    })}
                  </Form.Item>
                </Tabs.TabPane>
              ) : null}
            </Tabs>
          </div>
        </div>
      </Content>
    );
};

export default ViewSeriesDistribution;
