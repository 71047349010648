import { Button, DatePicker, Form, FormInstance, Input, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState, useEffect } from "react";
import { MasterService } from "src/Services/master.service";

const CreateFilmAwardSection = (props: { id: string | undefined; form: FormInstance<any> }) => {
  const [awardCategory, setAwardCategory] = useState<string[]>();
  const [festivalStatus, setFestivalStatus] = useState<string[]>();
  const [countries, setCountries] = useState<string[]>();
  const [candidates, setCandidates] = useState<string[]>();
  useEffect(() => {
    MasterService.getList(["awardCategory", "festivalStatus", "countries"]).then(
      axios.spread((awardCategory, festivalStatus, countries) => {
        setAwardCategory(awardCategory.values);
        setFestivalStatus(festivalStatus.values);
        setCountries(countries.values);
      })
    );
  }, []);

  const fetchCandidates = () => {
    let candidates = new Set<string>();
    props.form.getFieldValue(["director"]).forEach((c: any) => candidates.add(c.name));
    props.form.getFieldValue(["producer"]).forEach((c: any) => candidates.add(c.name));
    props.form.getFieldValue(["crew"]).forEach((c: any) => candidates.add(c.name));
    props.form.getFieldValue(["cast"]).forEach((c: any) => candidates.add(c.name));
    setCandidates(Array.from(candidates.values()));
  };

  return (
    <>
      <Form.Item name="awards" label="Awards" className="d-flex">
        <Form.List name="awards">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }) => (
                <div className="mb-3" key={key}>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "name"]}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Enter name of the Festival",
                        },
                        {
                          pattern: new RegExp("^[0-9a-zA-Z,. ]*$"),
                          message: "Only ,. are allowed as special characters",
                        },
                        {
                          max: 100,
                          message: "Max. limit is 100 characters",
                        },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input size="large" placeholder="Enter name of the Festival" />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "country"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Enter country of award reception",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        placeholder="Please select Award country"
                        showSearch
                      >
                        {countries?.map((country) => (
                          <Select.Option key={country} value={country}>
                            {country}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[name, "year"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Enter date of award",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"YYYY"}
                        size="large"
                        style={{ width: "100%" }}
                        picker="year"
                        placeholder="Select your Award Year"
                      />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "category"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Enter country of award category",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select Award Category"
                        showSearch
                      >
                        {awardCategory?.map((category) => (
                          <Select.Option key={category} value={category}>
                            {category}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[name, "role"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Award winner name is mandatory, please select from the list",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select Award winner"
                        onClick={fetchCandidates}
                        showSearch
                      >
                        {candidates?.map((candidate) => (
                          <Select.Option key={candidate} value={candidate}>
                            {candidate}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                    <Form.Item
                      name={[name, "status"]}
                      style={{ flex: 1 }}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Select status of award at Festival",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select status at Festival"
                        showSearch
                      >
                        {festivalStatus?.map((status) => (
                          <Select.Option key={status} value={status}>
                            {status}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined className="dynamic-delete-button text-danger" onClick={() => remove(name)} />
                  </div>
                </div>
              ))}
              <Form.Item>
                {fields.length < 5 ? (
                  <Button type="dashed" onClick={() => add()} style={{ width: "60%" }} icon={<PlusOutlined />}>
                    Add an Award
                  </Button>
                ) : null}
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

export default CreateFilmAwardSection;
