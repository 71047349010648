import { useEffect, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import axios from "axios";
import { Moment } from "moment";
import TextArea from "antd/lib/input/TextArea";
import { MasterService } from "src/Services/master.service";

const CreateSeriesSeasonSection = (props: {
  id: string;
  seriesId: string;
  releaseStatus: string;
  notAllowNumbers: number[];
}) => {
  const medium = ["TV", "Web", "Mobile", "other"];
  const [screenedPlatform, setScreenedPlatform] = useState<string[]>();
  const [releaseStatus, setReleaseStatus] = useState<string>(props.releaseStatus);

  const disabledDate = (current: Moment) => {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  };

  useEffect(() => {
    MasterService.getList(["screenedPlatform"]).then(
      axios.spread((screenedPlatform) => {
        setScreenedPlatform(screenedPlatform.values);
      })
    );
  }, []);

  return (
    <div className="mb-3 border-bottom border-dark">
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"number"}
          labelCol={{ span: 4 }}
          label="Season Number"
          style={{ flex: 1 }}
          rules={[
            {
              type: "number",
              message: "Only number is acceptable",
            },
            {
              required: true,
              message: "Please input your Season number",
            },
            {
              validator: (rule, value) => {
                if (value && props.notAllowNumbers.includes(value)) {
                  return Promise.reject("This Season number is already taken");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber min={1} max={59} style={{ width: "100px" }} placeholder="" />
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"title"}
          labelCol={{ span: 4 }}
          label="Season Title"
          style={{ flex: 1 }}
          rules={[
            {
              type: "string",
            },
            {
              pattern: new RegExp("^[0-9a-zA-Z.?:# ]*$"),
              message: "Only .?:# are allowed as special characters",
            },
            {
              max: 75,
              message: "Max. limit is 75 characters",
            },
          ]}
        >
          <Input size="large" placeholder="Enter your Season Title" />
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"synopsis"}
          labelCol={{ span: 4 }}
          label="Season Synopsis"
          style={{ flex: 1 }}
          rules={[
            {
              type: "string",
              required: true,
              message: "Please enter Season Synopsis!",
            },
            {
              pattern: new RegExp("^[0-9a-zA-Z,.'\"`()& \n]*$"),
              message: "Only ,.'\"`()& are allowed as special characters",
            },
            {
              max: 500,
              message: "Max. limit is 500 characters",
            },
          ]}
        >
          <TextArea showCount size="large" placeholder="Season Synopsis" rows={4}></TextArea>
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
        <Form.Item
          name={"releaseStatus"}
          labelCol={{ span: 4 }}
          label="Released Status"
          style={{ flex: 1 }}
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select the Release status of this Season",
            },
          ]}
        >
          <Select
            size="large"
            style={{ width: "100%" }}
            placeholder="Please select the Release status of this Season"
            showSearch
            onChange={(value) => {
              setReleaseStatus(value);
            }}
          >
            <Select.Option value={"released"}>Released</Select.Option>
            <Select.Option value={"unreleased"}>Unreleased</Select.Option>
            <Select.Option value={"under-production"}>Under Production</Select.Option>
          </Select>
        </Form.Item>
      </div>
      {releaseStatus === "released" && (
        <>
          <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
            <Form.Item
              name={"firstReleaseYear"}
              labelCol={{ span: 4 }}
              label="Year of First Release"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  type: "date",
                  message: "Year of First Release is required",
                },
              ]}
            >
              <DatePicker
                format={"YYYY"}
                size="large"
                picker="year"
                style={{ width: "50%" }}
                disabledDate={disabledDate}
                placeholder="Select the Year of Release for this Season"
              />
            </Form.Item>
          </div>
          <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
            <Form.Item
              name={"releaseMedium"}
              labelCol={{ span: 4 }}
              label="Medium of release"
              style={{ flex: 1 }}
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Please input your Season Medium of release",
                },
              ]}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="Please select your Season Medium of release"
                showSearch
              >
                {medium?.map((m) => (
                  <Select.Option key={m} value={m}>
                    {m}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
            <Form.Item
              name={"platform"}
              labelCol={{ span: 4 }}
              label="Name of the Platform"
              style={{ flex: 1 }}
              rules={[
                {
                  type: "string",
                  message: "Please input your Series Release Platform name",
                },
              ]}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="Please select your Series Release Platform name"
                showSearch
              >
                {screenedPlatform?.map((platform) => (
                  <Select.Option key={platform} value={platform}>
                    {platform}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateSeriesSeasonSection;
