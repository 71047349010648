import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { MasterService } from "src/Services/master.service";
import { Moment } from "moment";

const CreateFilmTechnicalSection = (props: { id: string | undefined; addendumMode: boolean }) => {
  const disabledDate = (current: Moment) => {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  };

  // const getDurationInString = (value: moment.Moment | null, dateString: string) => {
  //   const hour = value?.format('HH');
  //   const minute = value?.format('mm');
  //   const second = value?.format('ss');
  //   let str = "";
  //   if (hour != "00") {
  //     str += `${hour} [Hours] `
  //   }
  //   if (minute != "00") {
  //     str += `${minute} [minutes] `
  //   }
  //   if ((hour != "00" || minute != "00") && second != "00") {
  //     str += `[and] `
  //   }
  //   if (second != "00") {
  //     str += `${second} [seconds]`
  //   }
  //   setDuration(value?.format(`${str}`));
  // }

  // Get data from Master Service
  const [shootingFormat, setShootingFormat] = useState<string[]>();
  const [aspectRatio, setAspectRatio] = useState<string[]>();
  const [countries, setCountries] = useState<string[]>();
  useEffect(() => {
    MasterService.getList(["shootingFormat", "aspectRatio", "countries"]).then(
      axios.spread((shootingFormat, aspectRatio, countries) => {
        setShootingFormat(shootingFormat.values);
        setAspectRatio(aspectRatio.values);
        setCountries(countries.values);
      })
    );
  }, []);

  return (
    <>
      <Form.Item
        name="duration"
        label="Duration in mm:ss"
        className="required"
        rules={[
          {
            // type: 'string',
            required: true,
            message: "Please input your Film Duration",
            // transform: (value) => value.toString(),
          },
        ]}
      >
        <Input.Group compact>
          <Form.Item
            name={["duration", "mm"]}
            noStyle
            rules={[
              {
                type: "number",
                required: true,
                message: "Minutes field is required",
              },
            ]}
          >
            <InputNumber min={0} max={300} style={{ width: "100px" }} placeholder="mm" readOnly={props.addendumMode} />
          </Form.Item>
          <span className="mx-2">:</span>
          <Form.Item
            name={["duration", "ss"]}
            noStyle
            rules={[
              {
                type: "number",
                required: true,
                message: "Seconds field is required",
              },
            ]}
          >
            <InputNumber min={0} max={59} style={{ width: "100px" }} placeholder="ss" readOnly={props.addendumMode} />
          </Form.Item>
        </Input.Group>
        {/* <Form.Item
          name="duration"
          rules={[
            {
              type: 'string',
              required: true,
              message: 'Please input your Film Duration',
              transform: (value) => value.toString(),
            },
          ]}
          noStyle> */}
        {/* <TimePicker size="large" placeholder="Duration in hh:mm:ss format" style={{ width: '50%' }} className="me-2" /> */}
        {/* </Form.Item> */}
      </Form.Item>
      <Form.Item
        name="completionDate"
        label="Date of Completion"
        style={{ flex: 1 }}
        rules={[
          {
            type: "string",
            required: true,
            message: "Please input your Film Completion date",
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error("Please input your Film Completion date!")),
          },
        ]}
      >
        <DatePicker
          format={"DD-MM-YYYY"}
          size="large"
          style={{ width: "50%" }}
          disabled={props.addendumMode}
          disabledDate={disabledDate}
          placeholder="Select your Film Date of Completion"
        />
      </Form.Item>
      <Form.Item
        name="shootFormat"
        label="Shooting Format"
        rules={[
          {
            type: "string",
            required: true,
            message: "Please input your Film Shooting Format",
          },
        ]}
      >
        <Select size="large" style={{ width: "100%" }} placeholder="Please select your Film Shooting Format" showSearch>
          {shootingFormat?.map((format) => (
            <Select.Option key={format} value={format}>
              {format}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="aspectRatio"
        label="Original Aspect Ratio"
        rules={[
          {
            type: "string",
            required: true,
            message: "Please input your Film Original Aspect Ratio",
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: "100%" }}
          placeholder="Please select your Film Original Aspect Ratio"
          showSearch
        >
          {aspectRatio?.map((ratio) => (
            <Select.Option key={ratio} value={ratio}>
              {ratio}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="country" label="Country of Production">
        <Select
          mode="multiple"
          size="large"
          style={{ width: "100%" }}
          placeholder="Please select your Country of Production"
          allowClear
          showSearch
        >
          {countries?.map((country) => (
            <Select.Option key={country} value={country}>
              {country}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="keywords" label="Keywords">
        <Select
          size="large"
          mode="tags"
          style={{ width: "100%" }}
          tokenSeparators={[","]}
          placeholder="Enter keywords related to you film"
        ></Select>
      </Form.Item>
    </>
  );
};

export default CreateFilmTechnicalSection;
