import { Form, FormInstance, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ImageUploader } from "src/Components";
import config from "src/Config";
import { useEffect, useState } from "react";
import { UploadFile } from "antd/lib/upload/interface";

const CreateSeasonPosterSection = (props: {
  id: string | undefined;
  form: FormInstance<any>;
  stills: string[];
  verticalPosterURL: string;
  horizontalPosterURL: string;
}) => {
  const [imageURLs, setImageURLs] = useState<{
    verticalPosterURL: string | undefined;
    horizontalPosterURL: string | undefined;
    stillPosterURLs: string[];
  }>();
  const [verticalPosterURL, setVerticalPosterURL] = useState<string | undefined>(props.verticalPosterURL);
  const [horizontalPosterURL, setHorizontalPoster] = useState<string | undefined>(props.horizontalPosterURL);
  const [stillPosterURLs, setStillPosterURLs] = useState<string[]>(props.stills);

  useEffect(() => {
    setImageURLs({
      verticalPosterURL: verticalPosterURL,
      horizontalPosterURL: horizontalPosterURL,
      stillPosterURLs: stillPosterURLs,
    });
    props.form.setFieldsValue({
      ["verticalPosterURL"]: verticalPosterURL || "",
      ["horizontalPosterURL"]: horizontalPosterURL || "",
      ["stills"]: stillPosterURLs || "",
    });
  }, [verticalPosterURL, horizontalPosterURL, stillPosterURLs]);

  return (
    <>
      {
        <Form.Item
          name="verticalPosterURL"
          rules={[
            {
              required: true,
              message: "Upload Vertical Poster",
            },
          ]}
          // initialValue={verticalPosterURL}
          hidden
        >
          <Input type="hidden" name="verticalPosterURL" value={imageURLs?.verticalPosterURL} />
        </Form.Item>
      }
      <Form.Item
        label="Vertical Poster"
        rules={[
          {
            required: true,
            message: "Upload Vertical Poster",
          },
        ]}
      >
        <ImageUploader
          name="verticalPoster"
          aspect={3 / 4}
          url={verticalPosterURL ? [verticalPosterURL] : []}
          action={config.api_url + "/file"}
          onRemove={(file: UploadFile) => setVerticalPosterURL(undefined)}
          onComplete={(url: UploadFile[]) => {
            setVerticalPosterURL(url[0].response.url);
          }}
        />
      </Form.Item>
      {
        <Form.Item
          name="horizontalPosterURL"
          rules={[
            {
              required: true,
              message: "Upload Horizontal Poster",
            },
          ]}
          // initialValue={horizontalPosterURL}
          hidden
        >
          <Input type="hidden" name="horizontalPosterURL" value={imageURLs?.horizontalPosterURL} />
        </Form.Item>
      }
      <Form.Item
        label="Horizontal Poster"
        rules={[
          {
            required: true,
            message: "Upload Horizontal Poster",
          },
        ]}
      >
        <ImageUploader
          name="horizontalPoster"
          aspect={16 / 9}
          url={horizontalPosterURL ? [horizontalPosterURL] : []}
          action={config.api_url + "/file"}
          onRemove={(file: UploadFile) => setHorizontalPoster(undefined)}
          onComplete={(url: UploadFile[]) => {
            setHorizontalPoster(url[0].response.url);
          }}
        />
      </Form.Item>
      <Form.Item name="stills" hidden>
        <Input type="hidden" name="stills" value={imageURLs?.stillPosterURLs} />
      </Form.Item>
      <Form.Item label="Production Stills">
        <ImageUploader
          name="stills"
          maxCount={5}
          url={stillPosterURLs ? stillPosterURLs : []}
          action={config.api_url + "/file"}
          onRemove={(file: UploadFile) => setStillPosterURLs(stillPosterURLs.filter((f) => f != file.url))}
          onComplete={(urls: UploadFile[]) => {
            let newurl = urls.map((url) => url.url || url.response.url);
            setStillPosterURLs(newurl);
          }}
        />
      </Form.Item>
      <Form.Item
        name="directorStatement"
        label="Director's Statement"
        rules={[
          {
            pattern: new RegExp("^[0-9a-zA-Z,.?!()'-:;\"$`~_# \n]*$"),
            message: "Only ,.?!()'-:;\"$`~_#` are allowed as special characters",
          },
          {
            max: 500,
            message: "Max. limit is 500 characters",
          },
        ]}
      >
        <TextArea showCount size="large" placeholder="" rows={4}></TextArea>
      </Form.Item>
      <Form.Item
        name="producerStatement"
        label="Producer's Statement"
        rules={[
          {
            pattern: new RegExp("^[0-9a-zA-Z,.?!()'-:;\"$`~_# \n]*$"),
            message: "Only ,.?!()'-:;\"$`~_#` are allowed as special characters",
          },
          {
            max: 500,
            message: "Max. limit is 500 characters",
          },
        ]}
      >
        <TextArea showCount size="large" placeholder="" rows={4}></TextArea>
      </Form.Item>
      <Form.Item
        name="other"
        label="Other Information"
        rules={[
          {
            pattern: new RegExp("^[0-9a-zA-Z,.?!()'-:;\"$`~_# \n]*$"),
            message: "Only ,.?!()'-:;\"$`~_#` are allowed as special characters",
          },
          {
            max: 500,
            message: "Max. limit is 500 characters",
          },
        ]}
      >
        <TextArea showCount size="large" placeholder="" rows={4}></TextArea>
      </Form.Item>
    </>
  );
};

export default CreateSeasonPosterSection;
