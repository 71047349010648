import axios, { AxiosResponse } from "axios"
import config from "../Config"

interface MasterType {
    _id: string,
    name: string,
    values: string[]
}

const MasterService = {

    // private static instance: MasterService;
    // //Assign "new MasterService()" here to avoid lazy initialisation

    // constructor() {
    //     if (MasterService.instance) {
    //         return MasterService.instance;
    //     }
    //     MasterService.instance = this;
    // }

    async getList(name: string[]): Promise<MasterType[]> {
        const res: AxiosResponse<MasterType, any>[] | PromiseLike<AxiosResponse<MasterType, any>[]>[] = await axios.all(name.map(n => axios.get<MasterType>(config.api_url + "/master/" + n)));
        return res.map(res => res.data);
    }

}

Object.freeze(MasterService);
export {
    MasterService
}