import { DatePicker, Form, Input, InputNumber, Select } from "antd"
import axios from "axios";
import { useEffect, useState } from "react";
import { MasterService } from "src/Services/master.service";


const CreateFilmCensorSection = (props: { id: string | undefined }) => {

  // Get data from Master Service
  const [censor, setCensor] = useState<string[]>()
  const [languageRatings, setLanguageRatings] = useState<string[]>()
  const [violenceRatings, setViolenceRatings] = useState<string[]>()
  const [adultContentRatings, setAdultContentRatings] = useState<string[]>()
  const [drugsRatings, setDrugsRatings] = useState<string[]>()
  const [harmfulRatings, setHarmfulRatings] = useState<string[]>()
  const [hatefulRatings, setHatefulRatings] = useState<string[]>()
  useEffect(() => {
    MasterService.getList(["censor", "languageRatings", "violenceRatings", "adultContentRatings", "drugsRatings", "harmfulRatings", "hatefulRatings"]).then(axios.spread((censor, languageRatings, violenceRatings, adultContentRatings, drugsRatings, harmfulRatings, hatefulRatings) => {
      setCensor(censor.values);
      setLanguageRatings(languageRatings.values);
      setViolenceRatings(violenceRatings.values);
      setAdultContentRatings(adultContentRatings.values);
      setDrugsRatings(drugsRatings.values);
      setHarmfulRatings(harmfulRatings.values);
      setHatefulRatings(hatefulRatings.values);
    }));
  }, []);

  return (
    <>
      <Form.Item
        name="censor"
        label="Censor / Self Censor"
        rules={[
          {
            type: 'string',
            required: true,
            message: 'Please input your Film Censor Ratings'
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Please select your Film Censor Ratings"
          showSearch
        >
          {
            censor?.map(cens => <Select.Option key={cens} value={cens}>{cens}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="languageRating"
        label="Language"
        rules={[
          {
            type: 'string',
            required: true,
            message: 'Please input your Film Language Censor Rating'
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Please select your Film Language Censor Rating"
          showSearch
        >
          {
            languageRatings?.map(cens => <Select.Option key={cens} value={cens}>{cens}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="violenceRating"
        label="Violence"
        rules={[
          {
            type: 'string',
            required: true,
            message: 'Please input your Film Violence Censor Rating'
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Please select your Film Violence Censor Rating"
          showSearch
        >
          {
            violenceRatings?.map(cens => <Select.Option key={cens} value={cens}>{cens}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="adultContentRating"
        label="Adult Content"
        rules={[
          {
            type: 'string',
            required: true,
            message: 'Please input your Film Adult Content Censor Rating'
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Please select your Film Adult Content Censor Rating"
          showSearch
        >
          {
            adultContentRatings?.map(cens => <Select.Option key={cens} value={cens}>{cens}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="drugsRating"
        label="Drugs"
        rules={[
          {
            type: 'string',
            required: true,
            message: 'Please input your Film Drugs Censor Rating'
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Please select your Film Drugs Censor Rating"
          showSearch
        >
          {
            drugsRatings?.map(cens => <Select.Option key={cens} value={cens}>{cens}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="harmfulRating"
        label="Harmful / Dangerous Act"
        rules={[
          {
            type: 'string',
            required: true,
            message: 'Please input your Film Harmful / Dangerous Act Censor Rating'
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Please select your Film Harmful / Dangerous Act Censor Rating"
          showSearch
        >
          {
            harmfulRatings?.map(cens => <Select.Option key={cens} value={cens}>{cens}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        name="hatefulRating"
        label="Hateful and Derogatory"
        rules={[
          {
            type: 'string',
            required: true,
            message: 'Please input your Film Hateful and Derogatory Censor Rating'
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Please select your Film Hateful and Derogatory Censor Rating"
          showSearch
        >
          {
            hatefulRatings?.map(cens => <Select.Option key={cens} value={cens}>{cens}</Select.Option>)
          }
        </Select>
      </Form.Item>
    </>
  )
}

export default CreateFilmCensorSection;