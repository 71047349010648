import { MinusCircleOutlined } from "@ant-design/icons";
import { Checkbox, Row, Space } from "antd";
import { Button, Form, Input, message, Select, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../Config";
import { MasterService } from "src/Services/master.service";
import { LoadingSpinner, Uploader, VideoUploader } from "src/Components";
import { UploadFile } from "antd/lib/upload/interface";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SeasonSeriesProjectPageForm, SeriesProjectPageForm } from "src/Pages/Partner/Projects";
import { SeriesDistributionForm } from "..";
import useModal from "antd/lib/modal/useModal";

const CreateSeriesDistribution = (props: { series: SeriesProjectPageForm }) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams, _setSearchParams] = useSearchParams();

  const [modal, modalConfig] = useModal();

  const [season, setSeason] = useState<SeasonSeriesProjectPageForm>();
  let initialEpisodes: {
    episode: number;
    video: string;
    verticalVideo: string;
    subtitles: string;
    subtitleFiles?: { language: string; file: string }[];
  }[] = [];
  props.series?.seasons
    .find((s) => s._id == searchParams.get("season"))
    ?.episodes.map((e) => {
      initialEpisodes.push({
        episode: e.number,
        video: "",
        verticalVideo: "",
        subtitles: "",
        subtitleFiles: [{ language: "", file: "" }],
      });
    });

  const [currentTabKey, setCurrentTabKey] = useState<number>(1);
  const [currentEpisodeTabKey, setCurrentEpisodeTabKey] = useState<number>(0);
  const [submitLoading, setSubmitLoading] = useState<boolean | { delay?: number | undefined }>(false);
  const [territory, setTerritory] = useState<string>("worldwide");

  const [trailer, setTrailer] = useState<string | undefined>(undefined);
  const [video, setVideo] = useState<
    {
      episode: Number;
      video: string;
      verticalVideo: string;
      subtitles: string;
      subtitlesFile?: { language: string; file: string }[];
    }[]
  >(initialEpisodes);
  const [verticalTrailer, setVerticalTrailer] = useState<string | undefined>(undefined);
  const [censor, setCensor] = useState<string | undefined>(undefined);
  const [musicCueSheet, setMusicCueSheet] = useState<string | undefined>(undefined);
  const [countries, setCountries] = useState<string[]>();
  const [languages, setLanguages] = useState<string[]>();

  const [disablePlatform, setDisablePlatform] = useState<boolean>(false);
  const [platforms, setPlatforms] = useState<("all" | "pf" | "channels" | "syndication" | "tv" | "theatrical")[]>([
    "all",
  ]);

  const [subtitleFile, setSubtitleFile] = useState<string[]>([]);

  useEffect(() => {
    MasterService.getList(["countries", "languages"]).then(
      axios.spread((countries, languages) => {
        setCountries(countries.values);
        setLanguages(languages.values);
        setDisablePlatform(false)
      })
    );
    let seasonTmp = props.series.seasons.find((s) => s._id == searchParams.get("season"));
    seasonTmp?.episodes.sort((a, b) => (a.number > b.number ? 1 : b.number > a.number ? -1 : 0));
    setSeason(seasonTmp);
  }, []);

  useEffect(() => {
    setTrailer(trailer);
    setVerticalTrailer(verticalTrailer);
    setCensor(censor);
    setMusicCueSheet(musicCueSheet);
    form.setFieldsValue({
      ["trailer"]: trailer || "",
      ["verticalTrailer"]: verticalTrailer || "",
      ["censor"]: censor || "",
      ["musicCueSheet"]: musicCueSheet || "",
    });
  }, [trailer, verticalTrailer, censor, musicCueSheet]);

  useEffect(() => {
    setVideo(video);
    form.setFieldsValue({
      ["episodes"]: video,
    });
  }, [video]);

  const updateSubtitleFile = (i: number, file: string) => {
    let files = subtitleFile;
    files[i] = file;
    setSubtitleFile(files);
  };

  const showError = () => {
    let formErrors = form.getFieldsError().filter((err) => err.errors.length);
    if (formErrors.length > 0) {
      message.error(formErrors[0]["errors"][0]);
    }
  };

  const onFinish = (values: SeriesDistributionForm) => {
    if (currentTabKey < 3) return;

    setSubmitLoading(true);

    // Make POST request to submit form data and create distribution in the database
    axios
      .post(config.api_url + `/distributions/${id}`, {
        ...values,
        ...{ season: searchParams.get("season") },
      })
      .then(
        (_res) => {
          message.success(`Distribution created successfully`);
          setSubmitLoading(false);
          navigate("/partner/distributions", { replace: true });
        },
        (err) => {
          message.error(`Failed to create distribution! ${err.response?.data?.error}`);
          setSubmitLoading(false);
          console.log(err.response?.data?.error);
        }
      );
  };

  if (!season) return <LoadingSpinner />;
  return (
    <Content style={{ margin: "24px 0" }}>
      {modalConfig}
      <Space>
        <h1 className="title">Create Distribution</h1>
        <p className="text-white">
          - {props.series.title} (Season {season?.number} - {season?.title})
        </p>
      </Space>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          initialValues={{platforms: ["all", "pf"]}}
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          labelAlign="left"
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={currentTabKey.toString()}
            onTabClick={(key) => {
              setCurrentTabKey(parseInt(key));
            }}
          >
            <Tabs.TabPane forceRender={true} tab="Upload" key="1">
              <Form.Item name="trailer" hidden>
                <Input type="hidden" name="trailer" value={trailer} />
              </Form.Item>
              <Form.Item label="Season Trailer">
                <VideoUploader
                  name="trailer"
                  format={["video/mp4", "video/mov", "video/quicktime", "video/avi", "video/mpeg"]}
                  url={trailer ? [trailer] : []}
                  action={config.api_url + "/file/trailer"}
                  onComplete={(url: UploadFile[]) => {
                    setTrailer(url[0].response.url);
                  }}
                />
                <p className="text-muted mb-0">
                  <sup>*</sup>Aspect Ratio - 16:9 / 4:3 / 16:10
                </p>
              </Form.Item>
              <Form.Item name="verticalTrailer" hidden>
                <Input type="hidden" name="verticalTrailer" value={verticalTrailer} />
              </Form.Item>
              <Form.Item label="Vertical Season Trailer">
                <VideoUploader
                  name="verticalTrailer"
                  format={["video/mp4", "video/mov", "video/quicktime", "video/avi", "video/mpeg"]}
                  url={verticalTrailer ? [verticalTrailer] : []}
                  action={config.api_url + "/file/trailer"}
                  onComplete={(url: UploadFile[]) => {
                    setVerticalTrailer(url[0].response.url);
                  }}
                />
                <p className="text-muted mb-0">
                  <sup>*</sup>Aspect Ratio - 9:16 / 4:5 / 1:1
                </p>
              </Form.Item>
              <Form.Item name="censor" hidden>
                <Input type="hidden" name="censor" value={censor} />
              </Form.Item>
              <Form.Item label="Censor Certificate">
                <Uploader
                  name="censor"
                  format={["application/pdf"]}
                  url={censor ? [censor] : []}
                  action={config.api_url + "/file"}
                  onComplete={(url: UploadFile[]) => {
                    setCensor(url[0].response.url);
                  }}
                />
              </Form.Item>
              <Form.Item name="musicCueSheet" hidden>
                <Input type="hidden" name="musicCueSheet" value={musicCueSheet} />
              </Form.Item>
              <Form.Item name="musicCueSheet" label="Music Cue Sheet">
                <Uploader
                  name="musicCueSheet"
                  format={["application/pdf"]}
                  url={musicCueSheet ? [musicCueSheet] : []}
                  action={config.api_url + "/file"}
                  onComplete={(url: UploadFile[]) => {
                    setMusicCueSheet(url[0].response.url);
                  }}
                />
              </Form.Item>
              <div className="text-end">
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentTabKey(2);
                  }}
                >
                  Next
                </Button>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab={`Episodes`} key={"2"}>
              <Form.Item name="episodes">
                <Form.List name="episodes" initialValue={video}>
                  {(fields, { add, remove }, { errors }) => (
                    <Tabs
                      defaultActiveKey="0"
                      activeKey={currentEpisodeTabKey.toString()}
                      onTabClick={(key) => {
                        setCurrentEpisodeTabKey(parseInt(key));
                      }}
                    >
                      {fields.map(({ key, name }) => (
                        <Tabs.TabPane forceRender={true} tab={`Episode ${season.episodes[key].number}`} key={key}>
                          <div
                            key={key}
                            className="d-flex"
                            style={{
                              marginBottom: 32,
                              gap: 4,
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Form.Item
                                name={[name, "episode"]}
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                                hidden
                              >
                                <Input type="hidden" name="episode" />
                              </Form.Item>
                              <Form.Item
                                name={[name, "video"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please upload your episode video",
                                  },
                                ]}
                                hidden
                              >
                                <Input type="hidden" name="video" value={video[key].video} />
                              </Form.Item>
                              <Form.Item
                                label={`Episode ${season.episodes[key].number} Video (required)`}
                                labelCol={{ span: 6 }}
                              >
                                <VideoUploader
                                  name="video"
                                  format={["video/mp4", "video/mov", "video/quicktime", "video/avi", "video/mpeg"]}
                                  url={video[key].video ? [video[key].video] : []}
                                  action={config.api_url + "/file/video"}
                                  onComplete={(url: UploadFile[]) => {
                                    let tmp = [...video];
                                    tmp[key].video = url[0].response.url;
                                    setVideo(tmp);
                                  }}
                                />
                                <p className="text-muted mb-0">
                                  <sup>*</sup>Aspect Ratio - 16:9 / 4:3 / 16:10
                                </p>
                              </Form.Item>
                              <Form.Item name={[name, "verticalVideo"]} hidden>
                                <Input type="hidden" name="verticalVideo" value={video[key].verticalVideo} />
                              </Form.Item>
                              <Form.Item
                                label={`Episode ${season.episodes[key].number} Vertical Video`}
                                labelCol={{ span: 6 }}
                              >
                                <VideoUploader
                                  name="verticalVideo"
                                  format={["video/mp4", "video/mov", "video/quicktime", "video/avi", "video/mpeg"]}
                                  url={video[key].verticalVideo ? [video[key].verticalVideo] : []}
                                  action={config.api_url + "/file/video"}
                                  onComplete={(url: UploadFile[]) => {
                                    let tmp = [...video];
                                    tmp[key].verticalVideo = url[0].response.url;
                                    setVideo(tmp);
                                  }}
                                />
                                <p className="text-muted mb-0">
                                  <sup>*</sup>Aspect Ratio - 9:16 / 4:5 / 1:1
                                </p>
                              </Form.Item>
                              <Form.Item
                                name={[name, "subtitles"]}
                                label="Subtitles"
                                labelCol={{ span: 6 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select your subtitle status",
                                  },
                                ]}
                              >
                                <Select
                                  size="large"
                                  style={{ width: "100%" }}
                                  placeholder="Please select your subtitle"
                                  onSelect={(value: string) => {
                                    let tmp = [...video];
                                    tmp[key].subtitles = value;
                                  }}
                                >
                                  <Select.Option value="embeded">Embedded on the video</Select.Option>
                                  <Select.Option value="yes">Subtitle file is available</Select.Option>
                                  <Select.Option value="no">No subtitle available</Select.Option>
                                </Select>
                              </Form.Item>
                              {video[key].subtitles == "yes" ? (
                                <Form.Item name={[name, "subtitleFiles"]} labelCol={{ span: 6 }} label="Subtitle Files">
                                  <Form.List name={[name, "subtitleFiles"]}>
                                    {(fields, { add, remove }, { errors }) => (
                                      <>
                                        {fields.map(({ key, name }) => (
                                          <div
                                            key={key}
                                            className="d-flex"
                                            style={{
                                              marginBottom: 8,
                                              gap: 8,
                                              alignItems: "center",
                                            }}
                                          >
                                            <div style={{ flex: 1 }}>
                                              <Form.Item
                                                name={[name, "language"]}
                                                label="Subtitle Language"
                                                validateTrigger="onBlur"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Select Subtitle Language",
                                                  },
                                                ]}
                                                style={{ flex: 1 }}
                                              >
                                                <Select
                                                  size="large"
                                                  style={{ width: "100%" }}
                                                  placeholder="Please select subtitle language"
                                                  showSearch
                                                >
                                                  {languages?.map((lang) => (
                                                    <Select.Option key={lang} value={lang}>
                                                      {lang}
                                                    </Select.Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                              <Form.Item
                                                label={"Subtitle File (required)"}
                                                name={[name, "file"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Upload Subtitle File",
                                                  },
                                                ]}
                                              >
                                                <Uploader
                                                  name="subtitleFile"
                                                  url={subtitleFile[key] ? [subtitleFile[key]] : []}
                                                  extension={["srt", "vtt"]}
                                                  action={config.api_url + "/file"}
                                                  onComplete={(url: UploadFile[]) => {
                                                    updateSubtitleFile(key, url[0].response.url);
                                                  }}
                                                />
                                              </Form.Item>
                                            </div>
                                            {fields.length > 1 ? (
                                              <MinusCircleOutlined
                                                className="dynamic-delete-button text-danger"
                                                onClick={() => remove(name)}
                                              />
                                            ) : null}
                                          </div>
                                        ))}
                                        <Form.Item>
                                          {fields.length < 5 ? (
                                            <Button
                                              type="dashed"
                                              onClick={() => add()}
                                              style={{ width: "60%" }}
                                              // icon={<PlusOutlined />}
                                            >
                                              Add Another Subtile File
                                            </Button>
                                          ) : null}

                                          <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                      </>
                                    )}
                                  </Form.List>
                                </Form.Item>
                              ) : null}
                            </div>
                          </div>
                          <div className="text-end">
                            <Button
                              type="primary"
                              htmlType="button"
                              onClick={() => {
                                const errors = form
                                  .getFieldsError()
                                  .filter(
                                    (error) =>
                                      error.errors.length &&
                                      error.name[0] !== "a" &&
                                      error.name[0] !== "b" &&
                                      error.name[0] !== "c" &&
                                      error.name[0] !== "signature" &&
                                      error.name[0] !== "monetisation"
                                  );
                                if (
                                  errors.filter(
                                    (error) => !["monetisation", "territory"].includes(error.name[0].toString())
                                  ).length === 0 &&
                                  form.isFieldTouched("subtitleFiles")
                                ) {
                                  window.scrollTo(0, 0);
                                  currentEpisodeTabKey + 1 == season.episodes.length
                                    ? setCurrentTabKey(currentTabKey + 1)
                                    : setCurrentEpisodeTabKey(currentEpisodeTabKey + 1);
                                } else {
                                  form.submit();
                                  modal.confirm({
                                    title: "You have errors on this page.",
                                    content: (
                                      <p>
                                        {errors
                                          .filter(
                                            (error) => !["monetisation", "territory"].includes(error.name[0].toString())
                                          )
                                          .map((el) => (
                                            <span>
                                              {el.errors.join(", ")}
                                              <br />
                                            </span>
                                          ))}
                                        <br />
                                        Would you like to continue?
                                      </p>
                                    ),
                                    okText: "Continue",
                                    onOk: () => {
                                      window.scrollTo(0, 0);
                                      currentEpisodeTabKey + 1 == season.episodes.length
                                        ? setCurrentTabKey(currentTabKey + 1)
                                        : setCurrentEpisodeTabKey(currentEpisodeTabKey + 1);
                                    },
                                  });
                                }
                              }}
                            >
                              Next
                            </Button>
                          </div>
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  )}
                </Form.List>
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Rights Management" key={"3"}>
              <Form.Item
                name="territory"
                label="Territory"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please select territory",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select your territory"
                  onChange={(value) => {
                    setTerritory(value);
                  }}
                >
                  <Select.Option value="worldwide">Worldwide</Select.Option>
                  <Select.Option value="exclude">Worldwide, excluding these countries</Select.Option>
                  <Select.Option value="include">Only selected countries</Select.Option>
                </Select>
              </Form.Item>
              {territory !== "worldwide" ? (
                <Form.Item
                  name="territoryCountries"
                  label={`Countries to ${territory}`}
                  rules={[
                    {
                      type: "array",
                      required: true,
                      message: "Please select list of countries",
                    },
                  ]}
                >
                  <Select size="large" placeholder="Select your country" mode="multiple" showSearch>
                    {countries?.map((c) => (
                      <Select.Option key={c} value={c}>
                        {c}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
              <Form.Item
                name="platforms"
                label="Platforms"
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "Please select from the list of platforms",
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox.Group
                  value={platforms}
                  onChange={(values) =>
                    setPlatforms(values as ("all" | "pf" | "channels" | "syndication" | "tv" | "theatrical")[])
                  }
                >
                  <Row>
                    <Checkbox value="pf" style={{ lineHeight: "32px" }} disabled={true}>
                      PF owned platforms (its website, mobile apps, apps on other platforms, app-in-app, integrations,
                      etc.)
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox
                      value="all"
                      style={{ lineHeight: "32px" }}
                      onChange={(e: CheckboxChangeEvent) => {
                        setDisablePlatform(e.target.checked);
                        setPlatforms(e.target.checked ? ["pf", "channels", "syndication", "tv", "theatrical"] : ["pf"]);
                      }}
                      checked={platforms.length === 5}
                      indeterminate={platforms.length !== 5 && platforms.length !== 0}
                    >
                      All digital platforms including but not limited to internet, mobile, IPTV, etc.
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="channels" style={{ lineHeight: "32px" }} disabled={disablePlatform}>
                      PF branded channels on other platforms including but not limited to YouTube, Facebook, etc.
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="syndication" style={{ lineHeight: "32px" }} disabled={disablePlatform}>
                      Syndication across platforms excluding YouTube and Facebook.
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="tv" style={{ lineHeight: "32px" }} disabled={disablePlatform}>
                      TV and broadcast
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="theatrical" style={{ lineHeight: "32px" }} disabled={disablePlatform}>
                      Theatrical, festival, public/private screenings
                    </Checkbox>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                name="monetisation"
                label="Monetisation Model"
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "Please select Monetisation model",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select your Monetisation Model"
                  mode="multiple"
                  onChange={(value) => {
                    if (value.includes("all")) form.setFieldsValue({ ["monetisation"]: ["all"] });
                  }}
                  showSearch
                >
                  <Select.Option value="AVOD">AVOD - advertising-based video on demand</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="exclusivity" label="exclusivity" hidden>
                <Input type={"hidden"} value="exclusive" readOnly />
              </Form.Item>
              <Form.Item
                name={"a"}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error("Please accept terms")),
                  },
                ]}
                valuePropName="checked"
                className="mt-5"
              >
                <Checkbox>
                  All information submitted above is true, correct and to the best of my/our knowledge. I/We further
                  accept responsibility for the accuracy of all the information provided by me/us.
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={"b"}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error("Please accept terms")),
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox>
                  I/We hereby declare that we have read the rules, terms and regulations of the Distribution and hereby
                  agree and accept the same.
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={"c"}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error("Please accept terms")),
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox>
                  I/We hereby declare that the content is original and I/We am/are authorised to deal with the above
                  content, enter into this agreement by accepting all the terms and rules including on behalf of the
                  other right holders, if any.
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="signature"
                label="Signature"
                hasFeedback
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter your signature",
                  },
                  {
                    min: 3,
                    message: "Please enter your signature",
                  },
                ]}
              >
                <Input size="large" placeholder="Sign here" style={{ border: "1px solid white", fontFamily: "Mina" }} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    const errors = form.getFieldsError().filter((error) => error.errors.length);

                    if (
                      errors.filter((error) => !["monetisation", "territory"].includes(error.name[0].toString())).length
                    ) {
                      if (errors.findIndex((error) => error.name.includes("video")) >= 0) {
                        window.scrollTo(0, 0);
                      } else {
                        window.scrollTo(0, window.innerHeight * 0.75);
                      }
                      setCurrentTabKey(2);
                    } else {
                      window.scrollTo(0, 0);
                    }

                    message.error(errors.map((error) => error.errors.join(", ")).join("; "));
                  }}
                  size="large"
                  htmlType="submit"
                  loading={submitLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </div>
    </Content>
  );
};

export default CreateSeriesDistribution;
