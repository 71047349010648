import { Content } from "antd/lib/layout/layout"
import { OverlayCard } from "src/Components"

const Home = () => {
  return (
    <Content style={{ margin: '24px 0' }}>
      <h1 className="title">Dashboard</h1>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="row my-4">
          <div className="col-md-6">
            <OverlayCard
              link="/partner/projects"
              title="Create a new project"
              content="Create a profile to show case your Film / Episode"
              backgroundImg={process.env.PUBLIC_URL + "/img/project_card_bg.png"}
            />
          </div>
          <div className="col-md-6">
            <OverlayCard
              link="/partner/projects"
              title="Upload for distribution"
              content="Upload your content and make it available on our platform and other platforms"
              backgroundImg={process.env.PUBLIC_URL + "/img/distribute_card_bg.png"}
            />
          </div>
        </div>
        {/* <div className="row my-4">
          <div className="col-md-6">
            <OverlayCard
              link="/partner/projects"
              title="Submit to festival"
              content="Upload your content and make it available on our platform and other platforms"
              backgroundImg={process.env.PUBLIC_URL + "/img/festival_card_bg.png"}
            />
          </div>
        </div> */}
      </div>
    </Content>
  )
}

export default Home
