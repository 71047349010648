import { RecoilRoot } from "recoil";
import { Footer, Navbar } from "../../Components";
import { Button } from "antd";

const OTTPage = () => {
  return (
    <RecoilRoot>
      <div className="container-fluid">
        <Navbar></Navbar>
        <div className="px-4 pt-5 my-5 text-center border-bottom text-light">
          <h1 className="display-4 fw-bold">
            COME BE A PART OF SHORT FILM REVOLUTION
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              Partner with us and discover the world of opportunities for your
              film with our distribution network.
            </p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
              <Button
                href={"/partner"}
                size="large"
                type="primary"
                shape="round"
                className="mb-4"
              >
                Submit Distribution
              </Button>
            </div>
          </div>
          <div className="overflow-hidden" style={{ maxHeight: "40vh" }}>
            <div className="container px-5">
              <img
                src="img/mockup.png"
                className="img-fluid rounded-3 shadow-lg mb-4"
                alt="Example image"
                loading="lazy"
                width="800"
                height="500"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </RecoilRoot>
  );
};

export default OTTPage;
