import config from "src/Config";

export const deconstructProfile = (name: string) => {
  const rawName = name.split("(")[0].trimEnd();
  const customURL = name.split("(")[1]?.replace(")", "");

  return {
    rawName,
    isLinked: name.includes("(") && name.includes(")"),
    link: `${config.base_url}/${customURL?.includes(" - Business") ? "company" : "profile"}/${customURL?.replace(
      " - Business",
      ""
    )}`,
  };
};
