import { Button, Space, message } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import config from "src/Config";
import { FilmProjectPageForm } from "../";
import { LoadingSpinner, SeriesProfile } from "src/Components";
import { useNavigate, useParams } from "react-router-dom";
import FilmProfile from "src/Components/FilmProfile";

const ViewFilmProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [filmProfile, setFilmProfile] = useState<FilmProjectPageForm>();

  // Fetch profile data if already present
  useEffect(() => {
    (() => {
      axios.get(`${config.api_url}/projects/films/${id}`).then(
        (res) => {
          setFilmProfile(res.data);
          setLoading(false);
        },
        (err) => {
          message.error(`${err.message} - ${err.response.data.message}`);
          setError("Series not found");
          setLoading(false);
        }
      );
    })();
    return () => {};
  }, []);

  const editFilm = () => navigate(`/partner/projects/film/edit/${id}`);
  const viewtDistribution = () =>
    navigate(`/partner/distributions/${filmProfile!.distribution}`);
  const submitDistribution = () =>
    navigate(`/partner/distributions/create/${id}`);

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div
        className="site-layout-background"
        style={{ padding: "24px 0", minHeight: 360 }}
      >
        <div>
          {error ? (
            <p className="text-danger text-center">{error}</p>
          ) : (
            <>
              <div className="d-flex justify-content-end mb-2">
                <Space>
                  {filmProfile!.distribution ? (
                    <Button type="primary" onClick={viewtDistribution}>
                      View Distribution
                    </Button>
                  ) : (
                    <Button type="primary" onClick={submitDistribution}>
                      Submit for Distribution
                    </Button>
                  )}
                  <Button className="btn-outline-danger" onClick={editFilm}>
                    Edit
                  </Button>
                </Space>
              </div>
              <FilmProfile profile={filmProfile!} edit={true} />
            </>
          )}
        </div>
      </div>
    );
  }
};

export default ViewFilmProject;
