import { RcFile } from "antd/lib/upload/interface";


const UtilsService = {

    getBase64(file: RcFile): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        })
    }
}

Object.freeze(UtilsService);
export { UtilsService }