import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';

const container: HTMLElement = document.getElementById('root') || new HTMLElement();
const root = createRoot(container);

axios.interceptors.request.use(
  (req: AxiosRequestConfig) => {
    req.withCredentials = true;
    return req;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
