import { Dropdown, Avatar, Space, Menu, Button, Popover, Badge, Empty } from "antd";
import { UserOutlined, DownOutlined, BellOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "src/Config";

function Navbar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(auth.user?.notifications.reverse() || []);

  const getUserData = () => {
    axios.get(config.api_url + "/user/me").then(
      (res) => {
        let data = res.data;
        setNotifications(data?.notifications.reverse() || []);
      },
      (err) => {
        console.error(err);
      }
    );
  };

  useEffect(() => {
    getUserData();
  }, []);

  const menu = (
    <Menu
      items={[
        {
          label: (
            <Link rel="noopener noreferrer" to="/account">
              My Account
            </Link>
          ),
          key: "0",
        },
        {
          label: (
            <Link rel="noopener noreferrer" to="/account">
              Preferences
            </Link>
          ),
          key: "1",
        },
        {
          type: "divider",
        },
        {
          label: (
            <a href={process.env.REACT_APP_AUTH_FQDN + "/logout"}>
              Logout
            </a>
          ),
          key: "3",
        },
      ]}
    />
  );

  let notices =
    notifications.length > 0 ? (
      <ul className="list-group list-group-flush" style={{ width: 320, maxHeight: 600, overflowY: "scroll" }}>
        {notifications.map(
          (
            item: {
              title: string;
              content: string;
              link: string;
              status: string;
            },
            key: number
          ) => {
            return (
              <li
                key={key}
                onClick={() => navigate(item.link)}
                className="list-group-item list-group-item-dark clickable py-3"
              >
                <div className="fw-bold">{item.title}</div>
                <small>{item.content}</small>
                {item.status === "unread" ? <Badge dot className="position-absolute top-0 start-0 ms-2 mt-2" /> : null}
              </li>
            );
          }
        )}
      </ul>
    ) : (
      <Empty description={"No notifications"} />
    );

  const clearNotifications = () => {
    let unread = notifications?.filter((n: any) => n.status === "unread").length;
    if (unread > 0) axios(config.api_url + `/user/read-notifications`);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-black shadow-sm py-0">
      <div className="container-fluid">
        <Link className="navbar-brand" to={"/"}>
          <img src={process.env.PUBLIC_URL + "/pocketfilms_logo.png"} alt="Pocket Films" width={160} height={56}></img>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* <li className="nav-item">
              <Link className="nav-link" to={"/"}>
                Play
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to={"/partner"} className="nav-link active">
                Distribute
              </Link>
            </li>
          </ul>
        </div>
        {auth.user ? (
          <>
            <div className="nav-item me-4">
              <Popover
                content={notices}
                title="Notifications"
                trigger="click"
                className="my-auto"
                onVisibleChange={(visible) => {
                  if (visible) clearNotifications();
                }}
              >
                <Badge count={notifications?.filter((n: any) => n.status === "unread").length}>
                  <Button
                    size="large"
                    shape="circle"
                    onClick={(e) => e.preventDefault()}
                    icon={<BellOutlined />}
                  ></Button>
                </Badge>
              </Popover>
            </div>
            <div className="nav-item dropdown">
              <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                  <Avatar className="mx-2" icon={<UserOutlined style={{ fontSize: "24px" }} />} />
                  <Space>
                    {auth.user?.firstname ? auth.user.firstname : "User"}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </>
        ) : (
          <Button type="primary" href={process.env.REACT_APP_AUTH_FQDN}>
            Login
          </Button>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
