import { MinusCircleOutlined } from "@ant-design/icons";
import { Checkbox, Modal, Row } from "antd";
import { Button, Form, Input, message, Upload, Select, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../Config";
import { FilmDistributionForm } from "..";
import { MasterService } from "src/Services/master.service";
import { LoadingSpinner, Uploader, VideoUploader } from "src/Components";
import { UploadFile } from "antd/lib/upload/interface";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const EditFilmDistribution = ({ distribution }: { distribution: FilmDistributionForm }) => {
  let { id } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [currentTabKey, setCurrentTabKey] = useState<number>(1);
  const [submitLoading, setSubmitLoading] = useState<boolean | { delay?: number | undefined }>(false);
  // const [addedumMode, setAddedumMode] = useState(false);
  const [territory, setTerritory] = useState<string>("worldwide");

  const [trailer, setTrailer] = useState<string | undefined>(distribution.trailer);
  const [video, setVideo] = useState<string | undefined>(distribution.video);
  const [verticalTrailer, setVerticalTrailer] = useState<string | undefined>(distribution.verticalTrailer);
  const [verticalVideo, setVerticalVideo] = useState<string | undefined>(distribution.verticalVideo);
  const [subtitleFile, setSubtitleFile] = useState<string[]>([]);
  const [censor, setCensor] = useState<string | undefined>(undefined);
  const [musicCueSheet, setMusicCueSheet] = useState<string | undefined>(undefined);
  const [subtitle, setSubtitle] = useState<string>("no");
  const [updateSubtitle, setUpdateSubtitle] = useState<boolean>(false);
  const [countries, setCountries] = useState<string[]>();
  const [languages, setLanguages] = useState<string[]>();

  const [disablePlatform, setDisablePlatform] = useState<boolean>(false);
  const [platforms, setPlatforms] = useState<("pf" | "all" | "channels" | "syndication" | "tv" | "theatrical")[]>([
    "pf",
  ]);

  useEffect(() => {
    MasterService.getList(["countries", "languages"]).then(
      axios.spread((countries, languages) => {
        setCountries(countries.values);
        setLanguages(languages.values);
      })
    );

    // if editing distribution, fetch the details and load the form
    if (id === undefined) {
      setLoading(false);
      message.error("Distribution not found", 5);
      return navigate("/partner/distributions");
    }
    form.setFieldsValue({
      ["trailer"]: distribution.trailer || "",
      ["video"]: distribution.video || "",
      ["verticalTrailer"]: distribution.verticalTrailer || "",
      ["verticalVideo"]: distribution.verticalVideo || "",
      ["subtitleFile"]: distribution.subtitleFiles || [],
      ["censor"]: censor || "",
      ["musicCueSheet"]: musicCueSheet || "",
    });
    setTrailer(distribution.trailer!);
    setVideo(distribution.video!);
    setVerticalTrailer(distribution.verticalTrailer!);
    setVerticalVideo(distribution.verticalVideo!);
    setCensor(distribution.censor!);
    setMusicCueSheet(distribution.musicCueSheet!);
    setSubtitle(distribution.subtitles!);
    // setSubtitleFile(
    //   distribution.subtitleFiles.map(
    //     (subtitle: { language: string; file: string }) => {
    //       return subtitle.file;
    //     }
    //   )
    // );
    setTerritory(distribution.territory);
    form.setFieldsValue({
      ["territory"]: distribution.territory,
      ["platforms"]: distribution.platforms || ["all"],
      ["a"]: true,
      ["b"]: true,
      ["c"]: true,
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    setTrailer(trailer);
    setVideo(video);
    setVerticalTrailer(verticalTrailer);
    setVerticalVideo(verticalVideo);
    setSubtitleFile(subtitleFile);
    setCensor(censor);
    setMusicCueSheet(musicCueSheet);
    form.setFieldsValue({
      ["trailer"]: trailer || "",
      ["video"]: video || "",
      ["verticalTrailer"]: verticalTrailer || "",
      ["verticalVideo"]: verticalVideo || "",
      ["subtitleFile"]: subtitleFile || [],
      ["censor"]: censor || "",
      ["musicCueSheet"]: musicCueSheet || "",
    });
  }, [trailer, video, verticalTrailer, verticalVideo, subtitleFile, censor, musicCueSheet]);

  const showError = () => {
    let formErrors = form.getFieldsError().filter((err) => err.errors.length);
    if (formErrors.length > 0) {
      message.error(formErrors[0]["errors"][0]);
    }
  };

  const updateSubtitleFile = (i: number, file: string) => {
    let files = subtitleFile;
    files[i] = file;
    setSubtitleFile(files);
    setUpdateSubtitle(true);
  };

  const updateBunny = (replace: string) => {
    axios.post(`${config.api_url}/distributions/${id}/replace`, { replace }).then(
      (_res) => {
        console.debug(`${replace} uploaded to CDN`);
      },
      (err) => {
        message.error(`Failed to upload the ${replace} to cdn`);
        console.error(err);
      }
    );
  };

  const onFinish = async (values: FilmDistributionForm) => {
    setSubmitLoading(true);
    if (updateSubtitle) {
      updateBunny("subtitle");
    }

    if (values.subtitles === "yes") {
      values.subtitleFiles = values.subtitleFiles!.map((elem: { language: string; file: string }, i: number) => {
        elem.file = subtitleFile[i];
        return elem;
      });

      for (let sub of values.subtitleFiles!) {
        if (sub.file === undefined) {
          setSubmitLoading(false);
          setCurrentTabKey(1);
          return message.error("Please upload the subtitle file");
        }
      }
    }
    // console.debug("Received values of form: ", values);

    // Make POST request to submit form data and create distribution in the database
    axios.post(config.api_url + `/distributions/${id}`, values).then(
      (res) => {
        message.success(`Distribution updated successfully`);
        setSubmitLoading(false);
        navigate("/partner/distributions", { replace: true });
      },
      (err) => {
        message.error(`Failed to update your Distribution! ${err.response?.data?.error}`);
        setSubmitLoading(false);
        console.log(err.response?.data?.error);
      }
    );
  };

  if (loading) return <LoadingSpinner />;
  return (
    <Content style={{ margin: "24px 0" }}>
      <h1 className="title">Edit Distribution</h1>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          initialValues={distribution}
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          labelAlign="left"
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={currentTabKey.toString()}
            onTabClick={(key) => {
              setCurrentTabKey(parseInt(key));
            }}
          >
            <Tabs.TabPane forceRender={true} tab="Upload" key="1">
              <Form.Item name="trailer" hidden>
                <Input type="hidden" name="trailer" value={trailer} />
              </Form.Item>
              <Form.Item label="Film Trailer">
                <VideoUploader
                  name="trailer"
                  format={["video/mp4", "video/mov", "video/quicktime", "video/avi", "video/mpeg"]}
                  url={trailer ? [trailer] : []}
                  action={config.api_url + "/file/trailer"}
                  onComplete={(url: UploadFile[]) => {
                    setTrailer(url[0].response.url);
                    updateBunny("trailer");
                  }}
                />
                <p className="text-muted mb-0">
                  <sup>*</sup>Aspect Ratio - 16:9 / 4:3 / 16:10
                </p>
              </Form.Item>
              <Form.Item
                name="video"
                rules={[
                  {
                    required: true,
                    message: "Please upload your film video",
                  },
                ]}
                hidden
              >
                <Input type="hidden" name="video" value={video} />
              </Form.Item>
              <Form.Item label="Film Video (required)" className="required">
                <VideoUploader
                  name="video"
                  format={["video/mp4", "video/mov", "video/quicktime", "video/avi", "video/mpeg"]}
                  url={video ? [video] : []}
                  action={config.api_url + "/file/video"}
                  onComplete={(url: UploadFile[]) => {
                    setVideo(url[0].response.url);
                    updateBunny("video");
                  }}
                />
                <p className="text-muted mb-0">
                  <sup>*</sup>Aspect Ratio - 16:9 / 4:3 / 16:10
                </p>
              </Form.Item>
              <Form.Item name="verticalTrailer" hidden>
                <Input type="hidden" name="verticalTrailer" value={verticalTrailer} />
              </Form.Item>
              <Form.Item label="Vertical Film Trailer">
                <VideoUploader
                  name="verticalTrailer"
                  format={["video/mp4", "video/mov", "video/quicktime", "video/avi", "video/mpeg"]}
                  url={verticalTrailer ? [verticalTrailer] : []}
                  action={config.api_url + "/file/trailer"}
                  onComplete={(url: UploadFile[]) => {
                    setVerticalTrailer(url[0].response.url);
                    updateBunny("verticalTrailer");
                  }}
                />
                <p className="text-muted mb-0">
                  <sup>*</sup>Aspect Ratio - 9:16 / 4:5 / 1:1
                </p>
              </Form.Item>
              <Form.Item name="verticalVideo" hidden>
                <Input type="hidden" name="verticalVideo" value={verticalVideo} />
              </Form.Item>
              <Form.Item label="Vertical Film Video">
                <VideoUploader
                  name="verticalVideo"
                  format={["video/mp4", "video/mov", "video/quicktime", "video/avi", "video/mpeg"]}
                  url={verticalVideo ? [verticalVideo] : []}
                  action={config.api_url + "/file/video"}
                  onComplete={(url: UploadFile[]) => {
                    setVerticalVideo(url[0].response.url);
                    updateBunny("verticalVideo");
                  }}
                />
                <p className="text-muted mb-0">
                  <sup>*</sup>Aspect Ratio - 9:16 / 4:5 / 1:1
                </p>
              </Form.Item>
              <Form.Item
                name="subtitles"
                label="Subtitles"
                rules={[
                  {
                    required: true,
                    message: "Please select your subtitle status",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Please select your subtitle"
                  onChange={(value) => {
                    setSubtitle(value);
                  }}
                >
                  <Select.Option value="embeded">Embedded on the video</Select.Option>
                  <Select.Option value="yes">Subtitle file is available</Select.Option>
                  <Select.Option value="no">No subtitle available</Select.Option>
                </Select>
              </Form.Item>
              {subtitle === "yes" ? (
                <Form.Item name="subtitleFiles" label="Subtitle Files">
                  <Form.List name="subtitleFiles" initialValue={[{ language: "", file: "" }]}>
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map(({ key, name }) => (
                          <div
                            key={key}
                            className="d-flex"
                            style={{
                              marginBottom: 8,
                              gap: 8,
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Form.Item
                                name={[name, "language"]}
                                label="Subtitle Language"
                                validateTrigger="onBlur"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Subtitle Language",
                                  },
                                ]}
                                style={{ flex: 1 }}
                              >
                                <Select
                                  size="large"
                                  style={{ width: "100%" }}
                                  placeholder="Please select subtitle language"
                                  showSearch
                                >
                                  {languages?.map((lang) => (
                                    <Select.Option key={lang} value={lang}>
                                      {lang}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                label={"Subtitle File (required)"}
                                className="required"
                                valuePropName="fileList"
                                rules={[
                                  {
                                    required: true,
                                    message: "Upload Subtitle File",
                                  },
                                ]}
                              >
                                <Uploader
                                  name="subtitleFile"
                                  url={subtitleFile[key] ? [subtitleFile[key]] : []}
                                  extension={["srt", "vtt"]}
                                  action={config.api_url + "/file"}
                                  onComplete={(url: UploadFile[]) => {
                                    updateSubtitleFile(key, url[0].response.url);
                                  }}
                                />
                              </Form.Item>
                            </div>
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button text-danger"
                                onClick={() => remove(name)}
                              />
                            ) : null}
                          </div>
                        ))}
                        <Form.Item>
                          {fields.length < 5 ? (
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: "60%" }}
                              // icon={<PlusOutlined />}
                            >
                              Add Another Subtile File
                            </Button>
                          ) : null}

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              ) : null}
              <Form.Item name="censor" hidden>
                <Input type="hidden" name="censor" value={censor} />
              </Form.Item>
              <Form.Item label="Censor Certificate">
                <Uploader
                  name="censor"
                  format={["application/pdf"]}
                  url={censor ? [censor] : []}
                  action={config.api_url + "/file"}
                  onComplete={(url: UploadFile[]) => {
                    setCensor(url[0].response.url);
                  }}
                />
              </Form.Item>
              <Form.Item name="musicCueSheet" hidden>
                <Input type="hidden" name="musicCueSheet" value={musicCueSheet} />
              </Form.Item>
              <Form.Item name="musicCueSheet" label="Music Cue Sheet">
                <Uploader
                  name="musicCueSheet"
                  format={["application/pdf"]}
                  url={musicCueSheet ? [musicCueSheet] : []}
                  action={config.api_url + "/file"}
                  onComplete={(url: UploadFile[]) => {
                    setMusicCueSheet(url[0].response.url);
                  }}
                />
              </Form.Item>
              <div className="text-end">
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentTabKey(2);
                  }}
                >
                  Next
                </Button>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Rights Management" key="2">
              <Form.Item
                name="territory"
                label="Territory"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please select territory",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select your territory"
                  onChange={(value) => {
                    setTerritory(value);
                  }}
                >
                  <Select.Option value="worldwide">Worldwide</Select.Option>
                  <Select.Option value="exclude">Worldwide, excluding these countries</Select.Option>
                  <Select.Option value="include">Only selected countries</Select.Option>
                </Select>
              </Form.Item>
              {territory !== "worldwide" ? (
                <Form.Item
                  name="territoryCountries"
                  label={`Countries to ${territory}`}
                  rules={[
                    {
                      type: "array",
                      required: true,
                      message: "Please select list of countries",
                    },
                  ]}
                >
                  <Select size="large" placeholder="Select your country" mode="multiple" showSearch>
                    {countries?.map((c) => (
                      <Select.Option key={c} value={c}>
                        {c}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
              <Form.Item
                name="platforms"
                label="Platforms"
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "Please select from the list of platforms",
                  },
                ]}
                valuePropName="value"
              >
                <Row>
                  <Checkbox
                    value="all"
                    style={{ lineHeight: "32px" }}
                    onChange={(e: CheckboxChangeEvent) => {
                      setDisablePlatform(e.target.checked);
                      setPlatforms(e.target.checked ? ["pf", "channels", "syndication", "tv", "theatrical"] : ["pf"]);
                    }}
                    checked={platforms.length === 5}
                    indeterminate={platforms.length !== 5 && platforms.length !== 0}
                  >
                    All digital platforms including but not limited to internet, mobile, IPTV, etc.
                  </Checkbox>
                </Row>
                <Checkbox.Group
                  value={platforms}
                  onChange={(values) =>
                    setPlatforms(values as ("pf" | "all" | "channels" | "syndication" | "tv" | "theatrical")[])
                  }
                >
                  <Row>
                    <Checkbox value="pf" style={{ lineHeight: "32px" }} disabled={true}>
                      PF owned platforms (its website, mobile apps, apps on other platforms, app-in-app, integrations,
                      etc.)
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="channels" style={{ lineHeight: "32px" }} disabled={disablePlatform}>
                      PF branded channels on other platforms including but not limited to YouTube, Facebook, etc.
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="syndication" style={{ lineHeight: "32px" }} disabled={disablePlatform}>
                      Syndication across platforms excluding YouTube and Facebook.
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="tv" style={{ lineHeight: "32px" }} disabled={disablePlatform}>
                      TV and broadcast
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="theatrical" style={{ lineHeight: "32px" }} disabled={disablePlatform}>
                      Theatrical, festival, public/private screenings
                    </Checkbox>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                name="monetisation"
                label="Monetisation Model"
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "Please select Monetisation model",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select your Monetisation Model"
                  mode="multiple"
                  onChange={(value) => {
                    if (value.includes("all")) form.setFieldsValue({ ["monetisation"]: ["all"] });
                  }}
                  showSearch
                >
                  {/* <Select.Option value="all">
                    All (AVOD, SVOD, TVOD)
                  </Select.Option> */}
                  <Select.Option value="AVOD">AVOD - advertising-based video on demand</Select.Option>
                  {/* <Select.Option value="SVOD">
                    SVOD - subscription video on demand
                  </Select.Option>
                  <Select.Option value="TVOD">
                    TVOD - transactional video on demand{" "}
                  </Select.Option> */}
                </Select>
              </Form.Item>
              {/* <Form.Item
                label="TVOD Rate"
                rules={[
                  {
                    type: 'number',
                    required: true,
                    message: 'Please select territory'
                  }
                ]}
              >
                <Space>
                  <Form.Item
                    name="tvodRate"
                    rules={[
                      {
                        type: 'number',
                        required: true,
                        message: 'Please select TVOD Rate'
                      }
                    ]}
                  >
                    <InputNumber size='large' addonBefore="INR" precision={0} />
                  </Form.Item>
                  <Tooltip title="Rights reserved by Pocketfilms">
                    <Typography.Text style={{ lineHeight: '2px', verticalAlign: 'top' }} type='warning'><sup>*</sup>Pocketfilm reserves the right to modify the rate(s) if it deems necessary.</Typography.Text>
                  </Tooltip>
                </Space>
              </Form.Item> */}
              <Form.Item name="exclusivity" label="exclusivity" hidden>
                <Input type={"hidden"} value="exclusive" readOnly />
              </Form.Item>
              <Form.Item
                name={"a"}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error("Please accept terms")),
                  },
                ]}
                valuePropName="checked"
                className="mt-5"
              >
                <Checkbox>
                  All information submitted above is true, correct and to the best of my/our knowledge. I/We further
                  accept responsibility for the accuracy of all the information provided by me/us.
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={"b"}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error("Please accept terms")),
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox>
                  I/We hereby declare that we have read the rules, terms and regulations of the Distribution and hereby
                  agree and accept the same.
                </Checkbox>
              </Form.Item>
              <Form.Item
                name={"c"}
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error("Please accept terms")),
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox>
                  I/We hereby declare that the content is original and I/We am/are authorised to deal with the above
                  content, enter into this agreement by accepting all the terms and rules including on behalf of the
                  other right holders, if any.
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="signature"
                label="Signature"
                hasFeedback
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter your signature",
                  },
                  {
                    min: 3,
                    message: "Please enter your signature",
                  },
                ]}
              >
                <Input size="large" placeholder="Sign here" style={{ border: "1px solid white", fontFamily: "Mina" }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" size="large" onClick={showError} htmlType="submit" loading={submitLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </div>
    </Content>
  );
};

// const SubtitleFileField = (props: { name: string, key: string, languages: string[] }) => {

//   const [subtitleFile, setSubtitleFile] = useState<string | undefined>(undefined);

//   return (
//     <>
//       <Form.Item
//         name={[props.name, 'language']}
//         rules={[
//           {
//             required: true,
//             message: "Please select language of the subtitle file"
//           }
//         ]}
//       >
//         <Select
//           size="large"
//           style={{ width: '100%' }}
//           placeholder="Please select Subtitle's Language"
//           showSearch
//         >
//           {
//             props.languages?.map(l => <Select.Option key={l} value={l}>{l}</Select.Option>)
//           }
//         </Select>
//       </Form.Item>
//       <Form.Item
//         name={[props.name, 'file']}
//         rules={[
//           {
//             required: true,
//             message: 'Please upload your subtitles file'
//           }
//         ]}
//         hidden
//       >
//         <Input type="hidden" name="subtitleFile" value={subtitleFile} />
//       </Form.Item>
//       <Form.Item
//         label="Subtitle File (required)"
//       >
//         <Uploader
//           name='subtitleFile'
//           url={subtitleFile ? [subtitleFile] : []}
//           action={config.api_url + "/file"}
//           onComplete={(url: UploadFile[]) => { setSubtitleFile(url[0].response.url) }}
//         />
//       </Form.Item>
//     </>
//   )
// }

export default EditFilmDistribution;
