import { Tag } from "antd";
import {
  EpisodeSeasonSeriesProjectPageForm,
  SeasonSeriesProjectPageForm,
  SeriesProjectPageForm,
} from "../../Pages/Partner/Projects";
import { Link } from "react-router-dom";
import { camelCase, startCase } from "lodash";

const EpisodeProfile = (props: {
  series: SeriesProjectPageForm;
  season: SeasonSeriesProjectPageForm;
  profile: EpisodeSeasonSeriesProjectPageForm;
  edit?: boolean;
}) => {
  return (
    <div className="text-light" style={{ overflowWrap: "break-word" }}>
      <div className="text-center">
        <img
          style={{ width: "100%", height: 400, objectFit: "cover" }}
          src={props.profile.horizontalPosterURL}
        />
        <img
          style={{ width: 160, marginTop: -100, borderRadius: 8 }}
          className="border border-dark"
          src={
            props.profile.verticalPosterURL ||
            process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"
          }
          alt={props.series.customURL}
        />
        <Link to={`/partner/projects/series/view/${props.series._id}`}>
          <h1 className="fw-bold my-3">
            {props.series.title}{" "}
            {props.series.englishTitle
              ? `(${props.series.englishTitle})`
              : null}
          </h1>
        </Link>
        <Link
          to={`/partner/projects/series/view/${props.series._id}/season/${props.season._id}`}
        >
          <h2 className="fw-bold my-3">
            {`Season ${props.season.number} `}
            {props.season.title ? ` - ${props.season.title}` : null}
          </h2>
        </Link>
        <h3 className="fw-bold my-3">
          {`Episode ${props.profile.number} `}
          {props.profile.title ? ` - ${props.profile.title}` : null}
        </h3>
        <div className="my-4">
          {props.series.genre?.map((g, i) => (
            <Tag key={i} color="#108ee9" style={{ fontSize: "18px" }}>
              {startCase(camelCase(g))}
            </Tag>
          ))}
        </div>
      </div>
      <div className="my-2">Language: {props.series.language}</div>
      <div className="my-2">
        Duration:{" "}
        {`${props.profile.duration?.mm} minute ${props.profile.duration?.ss} seconds`}
      </div>
      <div className="my-2">Tagline: {props.profile.tagline}</div>
      <div className="my-2">Synopsis: {props.profile.synopsis}</div>
      <div className="my-2">Release Date: {props.profile.releaseDate}</div>
      <div className="my-2">Shooting Format: {props.profile.shootFormat}</div>
      <div className="my-2">
        Original Aspect ratio: {props.profile.aspectRatio}
      </div>
      <div className="my-2">
        Censor Rating:
        <div className="m-2">Self Censor: {props.profile.censor}</div>
        <div className="m-2">
          Language rating :{props.profile.languageRating}
        </div>
        <div className="m-2">
          Violence rating: {props.profile.violenceRating}
        </div>
        <div className="m-2">
          Adult Content: {props.profile.adultContentRating}
        </div>
        <div className="m-2">Drugs: {props.profile.drugsRating}</div>
        <div className="m-2">
          Harmful / Dangerous Act: {props.profile.harmfulRating}
        </div>
        <div className="m-2">
          Hateful and Derogatory: {props.profile.hatefulRating}
        </div>
      </div>
      <div className="my-2">
        Director: {props.profile.director?.map((d) => d.name)?.join(", ")}
      </div>
      <div className="my-2">
        Producer: {props.profile.producer?.map((p) => p.name).join(", ")}
      </div>
      <div className="my-2">
        <strong>Crew</strong>
        <ol>
          {props.profile.crew?.map(
            (
              c: {
                name?: string;
                role?: string;
              },
              key: number
            ) => (
              <li className="my-2" key={key}>
                <div className="my-2">
                  <strong>{c.name} </strong>: {c.role || "NA"}
                </div>
              </li>
            )
          )}
        </ol>
      </div>
      <div className="my-2">
        <strong>Cast</strong>
        <ol>
          {props.profile.cast?.map(
            (
              c: {
                name?: string;
                character?: string;
              },
              key: number
            ) => (
              <li className="my-2" key={key}>
                <div className="my-2">
                  <strong>{c.name} </strong>: {c.character || "NA"}
                </div>
              </li>
            )
          )}
        </ol>
      </div>
    </div>
  );
};

export default EpisodeProfile;
