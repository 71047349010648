import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, message, Modal, Tabs } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { LoadingSpinner } from "src/Components";
import config from "src/Config";
import axios from "axios";
import CreateSeasonEpisodeSection from "./episodeDetails";
import CreateEpisodeCensorSection from "./ratingDetails";
import CreateEpisodeCrewSection from "./crewDetails";
import CreateEpisodePosterSection from "./posterDetails";
import CreateEpisodeReviewSection from "./review";
import moment, { Moment } from "moment";
import {
  EpisodeSeasonSeriesProjectPageForm,
  SeasonSeriesProjectPageForm,
  SeriesProjectPageForm,
} from "src/Pages/Partner/Projects";
import { startCase, camelCase } from "lodash";

const AddEpisodeSeasonSeriesProject = () => {
  let { seriesId, seasonId, episodeId } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState<any[]>([]);
  const [error, setError] = useState<boolean>(true);
  const [currentTabKey, setCurrentTabKey] = useState<number>(1);
  const [loading, setLoading] = useState<Boolean>(true);
  const [submitText, setSubmitText] = useState<string>("Update Episode");
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [series, setSeries] = useState<SeriesProjectPageForm>();
  const [season, setSeason] = useState<SeasonSeriesProjectPageForm>();
  const [episode, setEpisode] = useState<EpisodeSeasonSeriesProjectPageForm>();
  const [notAllowNumbers, setNotAllowNumbers] = useState<number[]>();
  const { confirm } = Modal;

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      if (seriesId === undefined) {
        return setLoading(false);
      }
      const res = await axios.get(config.api_url + `/projects/Series/${seriesId}`);
      let tmpSeason = res.data.seasons.filter((s: SeasonSeriesProjectPageForm) => s._id === seasonId)[0];
      if (tmpSeason) {
        setSeason(tmpSeason);
        let tmpEpisode: any = tmpSeason?.episodes.find(
          (ep: EpisodeSeasonSeriesProjectPageForm) => ep._id === episodeId
        );
        if (tmpEpisode) {
          tmpEpisode.releaseDate = moment(tmpEpisode.releaseDate, "DD-MM-YYYY");
          setEpisode(tmpEpisode);
        } else {
          // find max number from tmpSeason.episodes.number
          let number = 0;
          for (let i in tmpSeason.episodes) {
            if (tmpSeason.episodes[i].number > number) {
              number = tmpSeason.episodes[i].number;
            }
          }
          number++;
          form.setFieldsValue({ number });

          // auto fill episode details if previous episode exists
          if (tmpSeason.episodes.length > 0) {
            confirm({
              title: "Auto fill episode details?",
              icon: <ExclamationCircleOutlined />,
              content: "Do you wish to auto fill details from previous episode",
              okText: "Auto-fill",
              okType: "primary",
              cancelText: "No",
              onOk() {
                // get last episode
                let lastEpisode = { ...tmpSeason?.episodes.at(-1)! };
                lastEpisode.releaseDate = moment(lastEpisode?.releaseDate, "DD-MM-YYYY");
                // set episode number to next number
                lastEpisode.number = number;
                // do not fill these fields
                delete lastEpisode._id;
                delete lastEpisode.title;
                delete lastEpisode.duration;
                delete lastEpisode.tagline;
                delete lastEpisode.synopsis;
                delete lastEpisode.verticalPosterURL;
                delete lastEpisode.horizontalPosterURL;
                console.log(lastEpisode);
                // fill values
                form.setFieldsValue(lastEpisode);
              },
              onCancel() {
                console.debug("Episode not auto-filled");
              },
            });
          }
        }

        // Add existing episode numbers to not allow for current episode number
        setNotAllowNumbers(
          tmpSeason.episodes
            .map((ep: EpisodeSeasonSeriesProjectPageForm) => ep.number)
            .filter((n: number) => n !== tmpEpisode?.number)
        );
      }

      setSeries(res.data);
      setLoading(false);
    })();
    return () => {};
  }, []);

  useEffect(() => {
    formErrors.length > 0 ? setError(true) : setError(false);
    console.log(formErrors);
  }, [formErrors]);

  // Scroll to top on tab change
  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentTabKey > 5) {
      setTimeout(() => {
        let formErrorsTmp: {
          name: (string | number)[];
          errors: string[];
          warnings: string[];
        }[] = form.getFieldsError().filter((err) => err.errors.length);
        setFormErrors(formErrorsTmp);
        setError(formErrorsTmp.length > 0 ? true : false);
      }, 1000);
    }
  }, [currentTabKey]);

  const deleteEpisode = () => {
    confirm({
      title: "Are you sure delete this content?",
      icon: <ExclamationCircleOutlined />,
      content: "Delete this Episode?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoading(true);
        let tmpProfile = series!;
        if (series) {
          tmpProfile.seasons = series?.seasons?.map((s: SeasonSeriesProjectPageForm) => {
            if (s._id === seasonId) {
              s.episodes = s?.episodes.filter((ep: EpisodeSeasonSeriesProjectPageForm) => ep._id !== episodeId);
            }
            return s;
          });
          delete tmpProfile.__v;
          axios.post(config.api_url + "/projects/series", tmpProfile).then(
            (res) => {
              setLoading(false);
              navigate(`/partner/projects/series/view/${seriesId}/season/${seasonId}`);
            },
            (err) => {
              setLoading(false);
              message.info("Error: " + err.message);
              console.error(err);
            }
          );
        } else {
          message.error("Series not found");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onFinish = async (values: any) => {
    setSubmitLoading(true);
    let formErrorsTmp: {
      name: (string | number)[];
      errors: string[];
      warnings: string[];
    }[] = form.getFieldsError().filter((err) => err.errors.length);
    setFormErrors(formErrorsTmp);
    setError(formErrorsTmp.length > 0 ? true : false);
    form.validateFields();
    onNext();

    console.debug("Received values of form: ", values);
    setSubmitLoading(false);
  };

  const onNext = async () => {
    window.scrollTo(0, 0);
    setSubmitLoading(true);
    if (currentTabKey > 3) {
      let formErrorsTmp: {
        name: (string | number)[];
        errors: string[];
        warnings: string[];
      }[] = form.getFieldsError().filter((err) => err.errors.length);
      setFormErrors(formErrorsTmp);
      setError(formErrorsTmp.length > 0 ? true : false);
      // console.log(formErrorsTmp);
    }
    const values = { ...form.getFieldsValue() };

    if (values?.releaseDate) {
      values.releaseDate = values.releaseDate?.format("DD-MM-YYYY");
    }
    values.director = values.director?.filter((d: { name: string }) => d.name !== ("" || undefined));
    values.producer = values.producer?.filter((d: { name: string }) => d.name !== ("" || undefined));
    values.crew = values.crew
      ?.filter((d: { name: string; role: string }) => d.name !== ("" || undefined) || d.role !== ("" || undefined))
      .map((d: { name: string; role: string }) => {
        d.name = startCase(camelCase(d.name));
        d.role = startCase(camelCase(d.role));
        return d;
      });
    values.cast = values.cast
      ?.filter(
        (d: { name: string; character: string }) => d.name !== ("" || undefined) || d.character !== ("" || undefined)
      )
      .map((d: { name: string; character: string }) => {
        d.name = startCase(camelCase(d.name));
        d.character = startCase(camelCase(d.character));
        return d;
      });
    if (currentTabKey === 4) {
      values.status = "complete";
    }
    if (episode?._id) {
      series?.seasons?.map((s: SeasonSeriesProjectPageForm) => {
        if (s._id === seasonId) {
          s.episodes.map((ep: any) => {
            if (ep._id === episode?._id) {
              for (let key of Object.keys(values)) {
                ep[key] = values[key];
              }
            }
          });
        }
        return s;
      });
    } else {
      series?.seasons?.map((s: SeasonSeriesProjectPageForm) => {
        if (s._id === seasonId) {
          s.episodes.push(values);
        }
        return s;
      });
    }
    delete series?.__v;
    axios.post(config.api_url + "/projects/series", series).then(
      (res) => {
        setSeries(res.data);
        let tmpSeason = res.data.seasons.filter((s: SeasonSeriesProjectPageForm) => s._id === seasonId)[0];
        setSeason(tmpSeason);
        if (episode?._id) {
          message.success("Episode updated!");
        } else {
          let l = tmpSeason?.episodes.length || 1;
          episodeId = tmpSeason?.episodes.at(-1)?._id;
          setEpisode(tmpSeason?.episodes[l - 1]);
          message.success("Episode created!");
        }
        setSubmitLoading(false);
        currentTabKey === 5
          ? navigate(`/partner/projects/series/view/${seriesId}/season/${seasonId}`)
          : setCurrentTabKey(currentTabKey + 1);
      },
      (err) => {
        setSubmitLoading(false);
        message.info("Error: " + err.message);
        console.error(err);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        <div className="d-flex justify-content-between">
          <h1 className="title">
            {episodeId === undefined ? "Add an Episode" : `Edit Episode (Episode ${episode?.number})`}{" "}
            {` - ${series?.title}, Season ${season?.number}`}
          </h1>
          {episodeId ? (
            <Button onClick={deleteEpisode} danger>
              Delete
            </Button>
          ) : null}
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          labelAlign="left"
          initialValues={episode}
          scrollToFirstError={true}
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={currentTabKey.toString()}
            onTabClick={(key) => {
              return parseInt(key) < 5 ? setCurrentTabKey(parseInt(key)) : null;
            }}
          >
            <Tabs.TabPane forceRender={true} tab="Episode Details" key="1">
              Episode Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateSeasonEpisodeSection
                  id={episode?._id || ""}
                  seasonId={seasonId || ""}
                  notAllowNumbers={notAllowNumbers || []}
                />
                <div className="text-end">
                  <Button type="primary" htmlType="button" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Censor Ratings" key="2">
              Censor Ratings
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateEpisodeCensorSection id={episode?._id} />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      setCurrentTabKey(currentTabKey - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="button" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Cast and Crew Details" key="3">
              Cast and Crew Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateEpisodeCrewSection />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => setCurrentTabKey(currentTabKey - 1)}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Poster Details" key="4">
              Poster Details
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <CreateEpisodePosterSection
                  id={episode?._id}
                  form={form}
                  verticalPosterURL={episode?.verticalPosterURL || ""}
                  horizontalPosterURL={episode?.horizontalPosterURL || ""}
                />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => setCurrentTabKey(currentTabKey - 1)}
                  >
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit" onClick={onNext} loading={submitLoading}>
                    {submitLoading ? "Saving" : "Next"}
                  </Button>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender={true} tab="Review" key="5">
              Review
              <div className="site-layout-background" style={{ paddingTop: 24, minHeight: 360 }}>
                <CreateEpisodeReviewSection
                  id={episode?._id}
                  form={form}
                  formErrors={form.getFieldsError().filter((err) => err.errors.length)}
                  navigate={setCurrentTabKey}
                />
                <div className="text-end">
                  <Button
                    className="me-2"
                    type="primary"
                    htmlType="button"
                    onClick={() => setCurrentTabKey(currentTabKey - 1)}
                  >
                    Previous
                  </Button>
                  <SubmitButton onNext={onNext} error={error} submitLoading={submitLoading} submitText={submitText} />
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Content>
    );
};

const SubmitButton = (props: {
  error: boolean;
  onNext: MouseEventHandler<HTMLElement>;
  submitLoading: boolean;
  submitText: String;
}) => {
  return props.error ? null : (
    <Button
      key={props.error ? 1 : 0}
      type="primary"
      htmlType="button"
      onClick={props.onNext}
      loading={props.submitLoading}
    >
      {props.submitLoading ? "Saving" : props.submitText}
    </Button>
  );
};

export default AddEpisodeSeasonSeriesProject;
