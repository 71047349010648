import { Button, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useState, useEffect } from "react";
import { useAuth } from "../../../auth";
import config from "../../../Config";
import { Card, LoadingSpinner, SeasonProfile } from "../../../Components";
import {
  Project,
  SeasonSeriesProjectPageForm,
  ViewEpisodeSeasonSeriesProject,
} from "../Projects";

const ListAvailableProjects = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [projects, setProjects] = useState<Project[]>();

  // Fetch projects which do not have distribution already present
  useEffect(() => {
    axios.get(config.api_url + "/projects/available").then(
      (res) => {
        if (res.data) setProjects(res.data);
        else setError("No projects found");
        setLoading(false);
      },
      (err) => {
        message.error(`${err.message} - ${err.response.data.message}`);
        setError("Projects not found");
        setLoading(false);
      }
    );
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Content style={{ margin: "24px 0" }}>
      <h1 className="title">Distribution pending for the following Projects</h1>
      <div
        className="site-layout-background"
        style={{ padding: "24px 0", minHeight: 360 }}
      >
        {/* <Button size="large" type="primary" onClick={() => { navigate("/partner/distributions/create") }}>Create Distribution</Button> */}
        <div className="row mt-3">
          {error ? (
            <p className="text-danger text-center">{error}</p>
          ) : (
            projects?.map((project: any) =>
              project.kind === "series" ? (
                project.seasons.map((season: SeasonSeriesProjectPageForm) =>
                  season.episodes.length > 0 && !Object.keys(season).includes("distribution") &&
                  !season.episodes.find((ep) => ep.status !== "complete") ? (
                    <div className="col-md-3" key={season._id}>
                      <Card
                        backgroundImg={
                          season.horizontalPosterURL ||
                          `${process.env.PUBLIC_URL}/img/dist_card_bg.png`
                        }
                        link={`/partner/distributions/create/${project._id}?season=${season._id}`}
                        status={"available"}
                        title={`${project.title} - Season ${season.number} ${season.title}`}
                        // content={dist.kind === 'series' ? 'Series' : dist.category}
                      ></Card>
                    </div>
                  ) : null
                )
              ) : (
                <div className="col-md-3" key={project._id}>
                  <Card
                    backgroundImg={
                      project.horizontalPosterURL ||
                      `${process.env.PUBLIC_URL}/img/dist_card_bg.png`
                    }
                    link={`/partner/distributions/create/${project._id}`}
                    status={"available"}
                    title={project.title}
                    // content={dist.kind === 'series' ? 'Series' : dist.category}
                  ></Card>
                </div>
              )
            )
          )}
        </div>
      </div>
    </Content>
  );
};

export default ListAvailableProjects;
