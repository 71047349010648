import { Tag } from "antd";
import {
  EpisodeSeasonSeriesProjectPageForm,
  SeasonSeriesProjectPageForm,
  SeriesProjectPageForm,
} from "../../Pages/Partner/Projects";
import { startCase, camelCase } from "lodash";
import Card from "../Card";
import { Link } from "react-router-dom";

const SeasonProfile = (props: {
  series: SeriesProjectPageForm;
  profile: SeasonSeriesProjectPageForm;
  edit?: boolean;
}) => {
  return (
    <div className="text-light" style={{ overflowWrap: "break-word" }}>
      <div className="text-center">
        <img style={{ width: "100%", height: 400, objectFit: "cover" }} src={props.profile.horizontalPosterURL} />
        <img
          style={{ width: 160, marginTop: -100, borderRadius: 8 }}
          className="border border-dark"
          src={props.profile.verticalPosterURL || process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"}
          alt={props.series.customURL}
        />
        <Link to={`/partner/projects/series/view/${props.series._id}`}>
          <h1 className="fw-bold my-3">
            {props.series.title} {props.series.englishTitle ? `(${props.series.englishTitle})` : null}
          </h1>
        </Link>
        <h2 className="fw-bold my-3">
          {`Season ${props.profile.number} `}
          {props.profile.title ? ` - ${props.profile.title}` : null}
        </h2>
        <div className="my-4">
          {props.series.genre?.map((g, i) => (
            <Tag key={i} color="#108ee9" style={{ fontSize: "18px" }}>
              {startCase(camelCase(g))}
            </Tag>
          ))}
        </div>
      </div>
      <div className="my-2">Language: {props.series.language}</div>
      <div className="my-2">Synopsis: {props.profile.synopsis}</div>
      {props.profile.releaseStatus === "unreleased" ? (
        ""
      ) : (
        <>
          <div className="my-2">Release Year: {props.profile.firstReleaseYear}</div>
          <div className="my-2">Release Medium: {props.profile.releaseMedium}</div>
          <div className="my-2">Release Platform: {props.profile.platform}</div>
        </>
      )}
      {props.profile.awards!.length ? (
        <div className="my-2">
          <strong>Awards</strong>
          <table className="table table-dark table-responsive">
            <thead>
              <tr>
                <th scope="col">Award</th>
                <th scope="col">Country</th>
                <th scope="col">Year</th>
                <th scope="col">Category</th>
                <th scope="col">Role</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {props.profile.awards?.map((a, i) => (
                <tr key={i}>
                  <td>{startCase(camelCase(a.name))}</td>
                  <td>{a.country || "NA"}</td>
                  <td>{a.year || "NA"}</td>
                  <td>{a.category?.join(", ") || "NA"}</td>
                  <td>{a.role || "NA"}</td>
                  <td>{a.status || "NA"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
      <div className="my-2">Director's Statement: {props.profile.directorStatement}</div>
      <div className="my-2">Producer's Statement: {props.profile.producerStatement}</div>
      <div className="my-2">Other Information: {props.profile.other}</div>
      <div className="mt-4">
        <h4>Episodes</h4>
        <strong>
          {`Season ${props.profile.number} ${props.profile.title ? " - " + props.profile.title : ""} (${
            props.profile.episodes?.length
          } Episodes)`}
        </strong>
        <div className="my-2">
          <div className="d-flex flex-wrap">
            {props.profile.episodes?.map((ep: EpisodeSeasonSeriesProjectPageForm, key: number) => (
              <div className="m-2" key={key}>
                <Card
                  layout="vertical"
                  title={ep.status !== "complete" ? `Episode ${ep.number} (draft)` : "Episode " + ep.number}
                  content={ep.title}
                  link={
                    ep.status && ep.status === "complete"
                      ? `/partner/projects/series/view/${props.series._id}/season/${props.profile._id}/episode/${ep._id}`
                      : `/partner/projects/series/edit/${props.series._id}/season/${props.profile._id}/episode/${ep._id}`
                  }
                  backgroundImg={ep.verticalPosterURL || process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"}
                />
              </div>
            ))}
            {props.edit ? (
              <div className="m-2">
                <Card
                  layout="vertical"
                  title={"Add an episode"}
                  link={`/partner/projects/series/edit/${props.series._id}/season/${props.profile._id}/episode`}
                  backgroundImg={process.env.PUBLIC_URL + "/img/add.png"}
                ></Card>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeasonProfile;
