import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, message, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import { ImageUploader, LoadingSpinner } from "src/Components";
import config from "src/Config";
import axios from "axios";
import { SeriesProjectPageForm } from "..";
import { MasterService } from "src/Services/master.service";
import { debounce, kebabCase } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { UploadFile } from "antd/lib/upload/interface";

const CreateSeriesProject = () => {
  let { id } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<Boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean | { delay?: number | undefined }>(false);
  const [series, setSeries] = useState<SeriesProjectPageForm>();
  const [imageURLs, setImageURLs] = useState<{
    verticalPosterURL: string | undefined;
    horizontalPosterURL: string | undefined;
  }>();
  const [verticalPosterURL, setVerticalPosterURL] = useState<string | undefined>();
  const [horizontalPosterURL, setHorizontalPosterURL] = useState<string | undefined>();
  const [disabledSave, setDisabledSave] = useState(true);

  useEffect(() => {
    setImageURLs({
      verticalPosterURL: verticalPosterURL,
      horizontalPosterURL: horizontalPosterURL,
    });
    form.setFieldsValue({
      ["verticalPosterURL"]: verticalPosterURL || "",
      ["horizontalPosterURL"]: horizontalPosterURL || "",
    });
  }, [verticalPosterURL, horizontalPosterURL]);

  // Fetch profile data if already present
  useEffect(() => {
    (async () => {
      if (id === undefined) {
        return setLoading(false);
      }
      const res = await axios.get(config.api_url + `/projects/series/${id}`);
      let tmpSeries: SeriesProjectPageForm = res.data;
      if (tmpSeries?.kind !== "series") {
        message.error(`The Project is of type ${tmpSeries.kind}`);
        navigate("/partner/projects");
        return setLoading(false);
      }
      setSeries(tmpSeries);
      setVerticalPosterURL(tmpSeries.verticalPosterURL);
      setHorizontalPosterURL(tmpSeries.horizontalPosterURL);
      // setImageURLs({ verticalPosterURL: tmpSeries.verticalPosterURL, horizontalPosterURL: tmpSeries.horizontalPosterURL })
      // console.log(imageURLs);
      // form.setFieldsValue({
      //     ["verticalPosterURL"]: verticalPosterURL || "",
      //     ["horizontalPosterURL"]: horizontalPosterURL || "",
      // })
      setLoading(false);
    })();
    return () => {};
  }, []);

  const [urlAvailableError, setURLAvailableError] = useState<
    ["" | "validating" | "success" | "warning" | "error" | undefined, string]
  >(["", ""]);

  // Get data from Master Service
  const [seriesGenre, setSeriesGenre] = useState<string[]>();
  const [languages, setLanguages] = useState<string[]>();
  const [censor, setCensor] = useState<string[]>();
  useEffect(() => {
    MasterService.getList(["filmGenre", "languages", "censor"]).then(
      axios.spread((seriesGenre, languages, censor) => {
        setSeriesGenre(seriesGenre.values);
        setLanguages(languages.values);
        setCensor(censor.values);
      })
    );
  }, []);

  const checkCustomURL = useCallback(
    debounce(async (value) => {
      if (value?.length > 25) {
        setURLAvailableError(["error", "Custom URL cannot be greater than 25 characters!"]);
        return message.error("Custom URL is too long!");
      }
      if (value.length === 0) {
        setURLAvailableError(["error", "Custom URL is Mandatory!"]);
        message.error("Custom URL is Mandatory!");
      }
      const pattern = new RegExp("^[0-9a-zA-Z-]+$");
      if (!pattern.test(value)) {
        setURLAvailableError(["error", "Special characters not allowed!"]);
        return message.error("Special characters not allowed!");
      }
      axios.get(config.api_url + `/projects/series/${value.toLowerCase()}?present=${id}`).then(
        (res) => {
          if (res.data.available === false) {
            setURLAvailableError(["error", "URL already taken, please try another url!"]);
          } else {
            setURLAvailableError(["success", "URL is available"]);
          }
        },
        (err) => {
          message.error(err.message);
          console.error(err);
        }
      );
      return Promise.resolve();
    }, 1000),
    []
  );

  const generateCustomURL = useCallback(
    debounce(async (event: ChangeEvent<HTMLInputElement>) => {
      let customURL = kebabCase(event.target.value?.length ? event.target.value : "");
      checkCustomURL(customURL);
      form.setFieldsValue({
        ["customURL"]: customURL.substring(0, 25),
      });
    }, 200),
    []
  );

  const handleMissingPosters = () => {
    const values = { ...form.getFieldsValue() };
    if (!("verticalPosterURL" in values) || values.verticalPosterURL.length === 0) {
      setSubmitLoading(false);
      return message.error("Kindly upload vertical poster");
    } else if (!("horizontalPosterURL" in values) || values.horizontalPosterURL.length === 0) {
      setSubmitLoading(false);
      return message.error("Kindly upload horizontal poster");
    }
  };

  const onFinish = async (values: any) => {
    setSubmitLoading(true);

    // const values = { ...form.getFieldsValue() };
    // console.debug(values);
    if (!("verticalPosterURL" in values) || values.verticalPosterURL.length === 0) {
      setSubmitLoading(false);
      return message.error("Kindly upload vertical poster");
    } else if (!("horizontalPosterURL" in values) || values.horizontalPosterURL.length === 0) {
      setSubmitLoading(false);
      return message.error("Kindly upload horizontal poster");
    }
    values.kind = "series";
    values.customURL = values.customURL.toLowerCase();
    if (values?.completionDate) {
      values.completionDate = values.completionDate?.format("DD-MM-YYYY");
    }

    values.status = "published";
    axios.post(config.api_url + "/projects/series", { ...series, ...values }).then(
      (res) => {
        setSeries(res.data);
        setSubmitLoading(false);
        message.success("Series published!");
        navigate(`/partner/projects/series/view/${res.data._id}`);
      },
      (err) => {
        setSubmitLoading(false);
        message.info("Error: " + err.message);
        console.error(err);
      }
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  } else
    return (
      <Content style={{ margin: "24px 0" }}>
        <h1 className="title">{series === undefined ? "Add a Series" : `Edit Series (${series?.status})`}</h1>
        <Form
          form={form}
          validateTrigger={["onChange", "onClick"]}
          onFinish={onFinish}
          // onFieldsChange={handleFormChange}
          layout="horizontal"
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 4 }}
          labelAlign="left"
          initialValues={series}
          scrollToFirstError={true}
        >
          <Form.Item
            name="title"
            label="Series Title Original"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please input your Series Title Original",
              },
              {
                pattern: new RegExp("^[0-9a-zA-Z.?:# ]*$"),
                message: "Only .?:# are allowed as special characters",
              },
              {
                max: 75,
                message: "Max. limit is 75 characters",
              },
            ]}
          >
            <Input size="large" placeholder="Series Title Original" onChange={generateCustomURL} />
          </Form.Item>
          <Form.Item
            name="englishTitle"
            label="Series Title (English)"
            rules={[
              {
                type: "string",
                message: "Please input your Series Title in English",
              },
              {
                pattern: new RegExp("^[0-9a-zA-Z.?:# ]*$"),
                message: "Only .?:# are allowed as special characters",
              },
              {
                max: 75,
                message: "Max. limit is 75 characters",
              },
            ]}
          >
            <Input size="large" placeholder="Series Title (English Translation, if any)" />
          </Form.Item>
          <Form.Item
            name="customURL"
            label="Custom URL"
            validateStatus={urlAvailableError[0]}
            help={urlAvailableError[1]}
            hasFeedback
            rules={[
              {
                type: "string",
                required: true,
                message: "Please input your custom url!",
              },
              {
                validator: (_, value) => {
                  setURLAvailableError(["validating", ""]);
                  checkCustomURL(value);
                  return Promise.resolve();
                },
                message: "Please input your custom url!",
              },
              {
                min: 3,
                message: "Min. requirement is 3 characters!",
              },
              {
                max: 25,
                message: "Kindly restrict your custom url to 25 characters!",
              },
              {
                pattern: new RegExp("^[0-9a-zA-Z-]+$"),
                message: "Please input your custom url, only alphanumeric characters and hypens are allowed!",
              },
            ]}
          >
            <Input size="large" prefix="https://pocketfilms.in/series/" placeholder="Custom URL" />
          </Form.Item>
          <Form.Item
            name="genre"
            label="Genre"
            rules={[
              {
                type: "array",
                required: true,
                message: "Please select Series Genre",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="Please select your Series genre"
              mode="multiple"
              showSearch
              allowClear
            >
              {seriesGenre?.map((genre) => (
                <Select.Option key={genre} value={genre}>
                  {genre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="language"
            label="Original Language"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please select Series Original Language!",
              },
            ]}
          >
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder="Please select your Series original language"
              showSearch
            >
              {languages?.map((lang) => (
                <Select.Option key={lang} value={lang}>
                  {lang}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="synopsis"
            label="Synopsis"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please enter Series Synopsis!",
              },
              {
                pattern: new RegExp("^[0-9a-zA-Z,.'\"`()& \n]*$"),
                message: "Only ,.'\"`()& are allowed as special characters",
              },
              {
                max: 500,
                message: "Max. limit is 500 characters",
              },
            ]}
          >
            <TextArea showCount size="large" placeholder="Series Synopsis" rows={4}></TextArea>
          </Form.Item>
          {
            <Form.Item
              name="verticalPosterURL"
              // initialValue={verticalPosterURL}
              rules={[
                {
                  required: true,
                  message: "Upload Vertical Poster",
                },
              ]}
              hidden
            >
              <Input type="hidden" name="verticalPosterURL" value={imageURLs?.verticalPosterURL} />
            </Form.Item>
          }
          <Form.Item
            label="Vertical Poster"
            rules={[
              {
                required: true,
                message: "Upload Vertical Poster",
              },
            ]}
          >
            <ImageUploader
              name="verticalPoster"
              aspect={3 / 4}
              url={verticalPosterURL ? [verticalPosterURL] : []}
              action={config.api_url + "/file"}
              onRemove={(file: UploadFile) => setVerticalPosterURL(undefined)}
              onComplete={(url: UploadFile[]) => {
                setVerticalPosterURL(url[0].response.url);
              }}
            />
          </Form.Item>
          {
            <Form.Item
              name="horizontalPosterURL"
              // initialValue={horizontalPosterURL}
              rules={[
                {
                  required: true,
                  message: "Upload Horizontal Poster",
                },
              ]}
              hidden
            >
              <Input type="hidden" name="horizontalPosterURL" value={imageURLs?.horizontalPosterURL} />
            </Form.Item>
          }
          <Form.Item
            label="Horizontal Poster"
            rules={[
              {
                required: true,
                message: "Upload Horizontal Poster",
              },
            ]}
          >
            <ImageUploader
              name="horizontalPoster"
              aspect={16 / 9}
              url={horizontalPosterURL ? [horizontalPosterURL] : []}
              action={config.api_url + "/file"}
              onRemove={(file: UploadFile) => setHorizontalPosterURL(undefined)}
              onComplete={(url: UploadFile[]) => {
                setHorizontalPosterURL(url[0].response.url);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              onClick={handleMissingPosters}
              loading={submitLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Content>
    );
};

export default CreateSeriesProject;
