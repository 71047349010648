import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios, { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";

interface UploaderProps {
  name: string;
  maxCount?: number;
  url: string[];
  action: string;
  onComplete: Function;
  onChange?: (fileList: UploadFile[]) => void;
  onRemove?:
    | ((file: UploadFile<any>) => boolean | void | Promise<boolean | void>)
    | undefined;
  format?: string[];
  extension?: string[];
}

const Uploader = (props: UploaderProps) => {
  let files: UploadFile[] =
    props.url?.map((u, i) => {
      return {
        url: u,
        status: "done",
        uid: i.toString(),
        name: u.split("/").pop() || "",
      };
    }) || [];
  const [fileList, setFileList] = useState<UploadFile[]>(files);
  const [previewVisible, setPreviewVisible] = useState(true);
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewTitle, setPreviewTitle] = useState('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  // const handleCancel = () => setPreviewVisible(false);

  const beforeUpload = (file: File) => {
    if (props.format) {
      // Check if input file is one of the expected mime types
      let isValid = props.format.indexOf(file.type) > -1 ? true : false;
      if (!isValid) {
        message.error(`You can only upload ${props.format.join(", ")} files!`);
        return isValid || Upload.LIST_IGNORE;
      }
    }

    if (props.extension) {
      // Check if input file is one of the expected extensions
      let isValid =
        props.extension.indexOf(file.name.split(".").at(-1)!) > -1
          ? true
          : false;
      if (!isValid) {
        setFileList([]);
        message.error(
          `You can only upload ${props.extension.join(", ")} extension files!`
        );
        return isValid || Upload.LIST_IGNORE;
      }
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //     message.error('Image must smaller than 2MB!');
    // }
    // return isValid && isLt2M;
    return true;
  };

  const handleFileChange: UploadProps["onChange"] = ({
    file: newFile,
    fileList: newFileList,
  }) => {
    newFile.percent = uploadProgress;

    if (newFile.status === "uploading") {
      setFileList(newFileList);
      setUploadLoading(true);
    } else if (newFile.status === "removed") {
      // ToDo
    } else if (newFile.status === "done") {
      // Get this url from response in real world.
      // const imageURL = await UtilsService.getBase64(newFile.originFileObj as RcFile);
      //   setFileList(
      //     newFileList?.map((u, i) => {
      //       return {
      //         url: u.response.url,
      //         status: "done",
      //         uid: u.uid.toString(),
      //         name: u.name.split("/").pop() || "",
      //       };
      //     }) || []
      //   );
      setUploadLoading(false);
      setTimeout(() => {
        if (newFileList.length === 1) {
          props.onChange?.(newFileList[0].response.url);
        } else if (newFileList.length > 1) {
          props.onChange?.(newFileList.map((fl) => fl.response.url));
        }
        props.onComplete(newFileList);
      }, 200);
    }
  };

  const handleFileUpload = async (options: any) => {
    const { onSuccess, onError, file, onProgress, action } = options;

    const fmData = new FormData();
    const config: AxiosRequestConfig<FormData> = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event: { loaded: number; total: number }) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setUploadProgress(percent);
        if (percent === 100) {
          setPreviewVisible(false);
          setTimeout(() => setUploadProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append(
      props.name +
        ((props.maxCount || 1) > 1 ? `-${new Date().getTime()}` : ""),
      file
    );
    try {
      const res = await axios.post(action, fmData, config);

      onSuccess(res.data);
      // console.debug("Success: ", res);
    } catch (err) {
      console.log("Error: ", err);
      message.error(`Failed to upload file! ${err}`);
      // const error = new Error("Could not update image");
      onError({ err });
    }
  };

  // const onPreview = async (file: UploadFile) => {
  //     if (!file.url && !file.preview) {
  //         file.preview = await UtilsService.getBase64(file.originFileObj as RcFile);
  //     }
  //     setPreviewImage(file.url || (file.preview as string));
  //     setPreviewVisible(true);
  //     setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  // };

  const uploadButton = (text: String = "Upload") => (
    <div>
      {/* {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <Button
        disabled={uploadLoading}
        icon={uploadLoading ? <LoadingOutlined /> : <UploadOutlined />}
      >
        {text}
      </Button>
    </div>
  );

  return (
    <Upload
      name={props.name}
      listType="picture"
      showUploadList={true}
      maxCount={1}
      // className="avatar-uploader"
      action={props.action}
      onChange={handleFileChange}
      beforeUpload={beforeUpload}
      // onPreview={onPreview}
      customRequest={handleFileUpload}
      onRemove={props.onRemove}
      // headers={{ "Authorization": "Bearer " + token }}
    >
      {fileList.length >= 1 ? (
        <>
          {uploadButton("Replace")}
          {previewVisible ? (
            <Button href={fileList[0].url}>
              File Uploaded, click to download
            </Button>
          ) : null}
        </>
      ) : (
        uploadButton()
      )}
    </Upload>
  );
};

export default Uploader;
