import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import config from "src/Config";
import _ from "lodash";

interface UploaderProps extends UploadProps {
  url: string[];
  action: string;
  onComplete: Function;
  format?: string[];
}

const VideoUploader = (props: UploaderProps) => {
  let files: UploadFile[] =
    props.url?.map((u, i) => {
      return {
        url: u,
        status: "done",
        uid: i.toString(),
        name: u.split("/").pop() || "",
      };
    }) || [];
  const [fileList, setFileList] = useState<UploadFile[]>(files);
  const [previewVisible, setPreviewVisible] = useState(true);
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewTitle, setPreviewTitle] = useState('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  // const handleCancel = () => setPreviewVisible(false);

  const beforeUpload = (file: File) => {
    if (!props.format) {
      // Allow any file as input
      return true;
    }
    // Check if input file is one of the expected mime types
    // console.log(file.type);
    const isValid = props.format.indexOf(file.type) > -1 ? true : false;
    if (!isValid) {
      message.error(`You can only upload ${props.format.join(", ")} files!`);
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //     message.error('Image must smaller than 2MB!');
    // }
    // return isValid && isLt2M;
    return isValid || Upload.LIST_IGNORE;
  };

  const handleFileChange: UploadProps["onChange"] = ({ file: newFile, fileList: newFileList }) => {
    setFileList(newFileList);
    newFile.percent = uploadProgress;
    if (newFile.status === "uploading") {
      setUploadLoading(true);
      return;
    }
    if (newFile.status === "done") {
      // Get this url from response in real world.
      // const imageURL = await UtilsService.getBase64(newFile.originFileObj as RcFile);
      //   setFileList(
      //     newFileList?.map((u, i) => {
      //       return {
      //         url: u.response.url,
      //         status: "done",
      //         uid: u.uid.toString(),
      //         name: u.name.split("/").pop() || "",
      //       };
      //     }) || []
      //   );
      setUploadLoading(false);
      setTimeout(() => {
        props.onComplete(newFileList);
      }, 200);
    }
  };

  const handleFileUpload = async (options: any) => {
    const { onSuccess, onError, file, onProgress, action } = options;

    const partSize = 1024 * 1024 * 200; // 200MB
    const fileSize = file.size;
    let presignedAPI = `${action}?originalName=${file.name}`;

    if (props.url && props.url.length > 0) {
      let url = props.url[0].split("/");
      presignedAPI = `${action}/${url[url.length - 1]}?originalName=${file.name}`;
    }

    if (fileSize > partSize * 20) {
      // Upload as multi-part to Pre-signed S3 urls
      const numParts = Math.ceil(fileSize / partSize);
      presignedAPI = `${presignedAPI}&parts=${numParts}`;
      let res = await axios.get(presignedAPI);
      const uploadId = res.data.UploadId;
      const signedUrlList = res.data.signedUrlList;
      const publicURL = res.data.url;

      const uploadedParts: any[] = [];
      // let remainingBytes = fileSize;
      const promises: Promise<AxiosResponse<any, any>>[] = [];
      signedUrlList.map(async (partUrl: string, index: number) => {
        const start = index * partSize;
        const end = Math.min((index + 1) * partSize, fileSize);
        const blob = file.slice(start, end);

        const requestConfig: AxiosRequestConfig<FormData> = {
          transformRequest: (data, headers) => {
            delete headers?.Authorization;
            delete (headers?.put as any)["Content-Type"];
            return data;
          },
          // headers: { "content-type": "multipart/form-data" },
          onUploadProgress: (event: { loaded: number; total: number }) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setUploadProgress(Math.floor((uploadedParts.length / numParts) * 100));
            if (percent === 100) {
              uploadedParts.push({ PartNumber: index });
              //   setPreviewVisible(false);
              //   setTimeout(() => setUploadProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        };

        promises.push(axios.put(partUrl, blob, requestConfig));
        // uploadedParts.push({ PartNumber: index, ETag: response.headers.etag });
        // console.log(uploadedParts);
      });

      try {
        const resolvedArray = await Promise.all(promises);
        console.log(resolvedArray, " resolvedArray");

        const uploadPartsArray: { ETag: string; PartNumber: number }[] = [];
        resolvedArray.forEach((resolvedPromise, index) => {
          uploadPartsArray.push({
            ETag: resolvedPromise.headers.etag,
            PartNumber: index + 1,
          });
        });

        let url = new URL(publicURL);
        let completeAPI = `${config.api_url}/file/complete`;
        let result = await axios.post(completeAPI, {
          partsWithETag: _.orderBy(uploadPartsArray, ["PartNumber"], ["asc"]),
          uploadId,
          path: url.pathname.substring(1, url.pathname.length),
        });
        // console.debug(result);
        onSuccess({ url: publicURL });
      } catch (err) {
        console.log("Error: ", err);
        message.error(`Failed to upload video! ${err}`);
        // const error = new Error("Could not update image");
        onError({ err });
      }
      setPreviewVisible(false);
      setTimeout(() => setUploadProgress(0), 1000);
    } else {
      // Upload as single part to Pre-signed S3 url
      let presignedAPI = `${action}?originalName=${file.name}`;
      if (props.url && props.url.length > 0) {
        let url = props.url[0].split("/");
        presignedAPI = `${action}/${url[url.length - 1]}?originalName=${file.name}`;
      }
      let res = await axios.get(presignedAPI);
      const actionURL = res.data.uploadURL;
      const publicURL = res.data.url;

      const requestConfig: AxiosRequestConfig<FormData> = {
        transformRequest: (data, headers) => {
          delete headers?.Authorization;
          delete (headers?.put as any)["Content-Type"];
          return data;
        },
        // headers: { "content-type": "multipart/form-data" },
        onUploadProgress: (event: { loaded: number; total: number }) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setUploadProgress(percent);
          if (percent === 100) {
            setPreviewVisible(false);
            setTimeout(() => setUploadProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      try {
        const res = await axios.put(actionURL, file, requestConfig);
        onSuccess({ url: publicURL });
        // console.debug("Success: ", res);
      } catch (err) {
        console.log("Error: ", err);
        message.error(`Failed to upload video! ${err}`);
        // const error = new Error("Could not update image");
        onError({ err });
      }
    }
  };

  // const onPreview = async (file: UploadFile) => {
  //     if (!file.url && !file.preview) {
  //         file.preview = await UtilsService.getBase64(file.originFileObj as RcFile);
  //     }
  //     setPreviewImage(file.url || (file.preview as string));
  //     setPreviewVisible(true);
  //     setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  // };

  const uploadButton = (text: String = "Upload") => (
    <div>
      {/* {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <Button disabled={uploadLoading} icon={uploadLoading ? <LoadingOutlined /> : <UploadOutlined />}>
        {text}
      </Button>
    </div>
  );

  return (
    <>
      <Upload
        name={props.name}
        listType="picture"
        showUploadList={true}
        maxCount={1}
        // className="avatar-uploader"
        action={props.action}
        onChange={handleFileChange}
        beforeUpload={beforeUpload}
        // onPreview={onPreview}
        customRequest={handleFileUpload}
        onRemove={
          props.onRemove
            ? props.onRemove
            : () => {
                setUploadLoading(false);
              }
        }
        // headers={{ "Authorization": "Bearer " + token }}
      >
        {fileList.length >= 1 ? (
          <>
            {uploadButton("Replace")}
            {previewVisible ? (
              <video controls style={{ width: "auto", height: "300px" }}>
                <source src={fileList[0].url} />
              </video>
            ) : null}
          </>
        ) : (
          uploadButton()
        )}
      </Upload>
      {/* <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal> */}
    </>
  );
};

export default VideoUploader;
