import { Link, useLocation } from "react-router-dom";
import { HomeOutlined, UserOutlined, FolderAddOutlined, AccountBookOutlined, BookOutlined, VideoCameraAddOutlined, GiftOutlined } from '@ant-design/icons';
import "./Sidebar.css";

const sidebarItems = [
    { slug: "/partner", title: "Home", icon: <HomeOutlined className="mx-3" style={{ fontSize: '24px', color: 'inherit' }} /> },
    { slug: "/account", title: "My Account", icon: <UserOutlined className="mx-3" style={{ fontSize: '24px', color: 'inherit' }} /> },
    { slug: "/partner/projects", title: "My Projects", icon: <FolderAddOutlined className="mx-3" style={{ fontSize: '24px', color: 'inherit' }} /> },
    { slug: "/partner/business-profile", title: "Manage Business Profile", icon: <AccountBookOutlined className="mx-3" style={{ fontSize: '24px', color: 'inherit' }} /> },
    { slug: "/partner/professional-profile", title: "Manage Professional Profile", icon: <BookOutlined className="mx-3" style={{ fontSize: '24px', color: 'inherit' }} /> },
    { slug: "/partner/distributions", title: "Manage Distributions", icon: <VideoCameraAddOutlined className="mx-3" style={{ fontSize: '24px', color: 'inherit' }} /> },
    // { slug: "/festival", title: "Festivals", icon: <GiftOutlined className="mx-3" style={{ fontSize: '24px', color: 'inherit' }} /> }
]

function Sidebar() {

    const location = useLocation();

    return (
        <div className="sidebar mx-4 mt-4">
            <ul className="list-group">
                {
                    sidebarItems.map(item => {
                        return (
                            <Link to={item.slug} key={item.slug}>
                                <li className={location.pathname === item.slug ? "list-group-item active" : "list-group-item"}>
                                    {item.icon}
                                    {item.title}
                                </li>
                            </Link>
                        );
                    })
                }
            </ul>
        </div >
    );
}

export default Sidebar;