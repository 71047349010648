import { Button, Row, Tag, message } from "antd";
import { ProfessionalProfilePageForm } from "../../Pages/Partner/ProfessionalProfile";
import _, { startCase, camelCase } from "lodash";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "src/Config";
import { handleError } from "src/utils/handleError";
import useModal from "antd/lib/modal/useModal";
import { wait } from "src/utils/wait";
import moment from "moment";

const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

const ProfessionalProfile = (props: { profile: ProfessionalProfilePageForm; isOwner?: boolean }) => {
  const [modal, modalConfig] = useModal();

  const navigate = useNavigate();

  const renderIcon = (platform: string) => {
    switch (platform.toLocaleLowerCase()) {
      case "facebook":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/facebook.png"}
            alt="Facebook"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "instagram":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/instagram.png"}
            alt="Instagram"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "x":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/x.svg"}
            alt="X"
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#fff",
              borderRadius: "50%",
              border: "1px solid #fff",
            }}
          />
        );
      case "linkedin":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/linkedin.png"}
            alt="Linkedin"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "whatsapp":
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/whatsapp.png"}
            alt="Whatsapp"
            style={{ width: "32px", height: "32px" }}
          />
        );
      default:
        return (
          <img
            src={process.env.PUBLIC_URL + "/img/facebook.png"}
            alt="Facebook"
            style={{ width: "32px", height: "32px" }}
          />
        );
    }
  };

  return (
    <div className="text-light">
      {modalConfig}
      <div className="text-center">
        <img
          style={{ width: 160, height: 160 }}
          className="rounded-circle border border-light"
          src={props.profile.imageURL || process.env.PUBLIC_URL + "/img/dummy-image-square.jpg"}
          alt={props.profile.customURL}
        />
        <h2 className="fw-bold my-3">
          {props.profile.user.firstname} {props.profile.user.lastname}
        </h2>
        <div className="my-4">
          {props.profile.social?.map((social, i) => (
            <a className="btn btn-link" key={i} href={social.link}>
              {renderIcon(social.platform)}
            </a>
          ))}
        </div>
        <div className="my-4">
          {props.profile.skills?.map((skill, i) => (
            <Tag key={i} color="#108ee9" style={{ fontSize: "18px" }}>
              {skill}
            </Tag>
          ))}
        </div>
      </div>
      <div className="my-3">{props.profile.about}</div>
      {/* {(props.profile.awards) ? <div className="my-3"><strong>Awards / Nominations: </strong> {props.profile.awards}</div> : null} */}
      <div className="my-3">
        <strong>Skills: </strong> {props.profile.skills?.join(", ")}
      </div>
      {props.profile.qualification?.length ? (
        <>
          <h5 className="mt-4">Qualifications</h5>
          <table className="table table-dark table-responsive">
            <thead>
              <tr>
                <th scope="col">Institute</th>
                <th scope="col">Degree</th>
                <th scope="col">Year</th>
              </tr>
            </thead>
            <tbody>
              {props.profile.qualification.map((q, i) => (
                <tr key={i}>
                  <td>{q.institute}</td>
                  <td>{q.degree}</td>
                  <td>{q.year}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
      {props.profile.experience?.length ? (
        <>
          <h5 className="mt-4">Work Experience</h5>
          <table className="table table-dark table-responsive">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Company</th>
                <th scope="col">Position</th>
                <th scope="col">Period</th>
              </tr>
            </thead>
            <tbody>
              {props.profile.experience.map((e, i) => (
                <tr key={i}>
                  <td>{startCase(camelCase(e.workType))}</td>
                  <td>{e.company}</td>
                  <td>{e.position}</td>
                  <td>
                    {e.period[0]} - {e.current ? "Currently Working" : e.period[1]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}

      <>
        <h5 className="mt-4">Film Credits</h5>
        <table className="table table-dark table-responsive">
          <thead>
            <tr>
              <th scope="col">Film Title</th>
              <th scope="col">Category</th>
              <th scope="col">Role</th>
              <th scope="col">Year</th>
              <th scope="col">Sent On</th>
              {props.isOwner ? <th scope="col">Status</th> : null}
              {props.isOwner ? <th scope="col"></th> : null}
            </tr>
          </thead>
          <tbody>
            {_.orderBy(
              (props.profile.film ?? []).map((el, i) => {
                if (el.approved) {
                  return { ...el, sort: 0, award: false, index: i };
                } else if (!el.rejected) {
                  return { ...el, sort: 1, award: false, index: i };
                } else {
                  return { ...el, sort: 2, award: false, index: i };
                }
              }),
              ["sort", "sentOn"],
              ["asc", "desc"]
            ).map((f, i) => {
              return (
                <tr key={i}>
                  <td>{f.title}</td>
                  <td>{f.category}</td>
                  <td>{f.role}</td>
                  <td>{f.year}</td>
                  <td>{moment(f.sentOn).format("DD/MM/YYYY")}</td>
                  {props.isOwner ? (
                    <>
                      <td>
                        {!f.sentBy ? (
                          "Not linked"
                        ) : f.sentBy === "professional" ? (
                          f.approved ? (
                            "Approved By Creator"
                          ) : f.rejected || f.resent > 0 ? (
                            <>
                              <span>Rejected by Creator</span>{" "}
                              {f.resent >= 2 ? null : (
                                <span
                                  onClick={() => {
                                    modal.confirm({
                                      title: "Resend link request",
                                      content: `You have ${
                                        2 - (f.resent ?? 0)
                                      } resends left for this linking request. Would you like to resend?`,
                                      okText: "Resend",
                                      onOk: () => {
                                        axios
                                          .post(
                                            `${config.api_url}/projects/resend-link-request/${f.project}/${
                                              f.category
                                            }?user=${props.profile.user}&roleId=${(f as any)._id ?? f.roleId}&index=${
                                              f.index
                                            }`
                                          )
                                          .then(() => {
                                            message.success("Request resent successfully!");

                                            return wait(500);
                                          })
                                          .then(() => window.location.reload())
                                          .catch((e) => message.error(handleError(e)));
                                      },
                                    });
                                  }}
                                  style={{
                                    color: "#177DDC",
                                    textDecoration: "underlins",
                                    transform: "scale(0.8)",
                                    cursor: "pointer",
                                  }}
                                >
                                  Resend
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              <span>Pending from Creator</span>{" "}
                              {f.resent >= 2 ? null : (
                                <span
                                  onClick={() => {
                                    modal.confirm({
                                      title: "Resend link request",
                                      content: `You have ${
                                        2 - (f.resent ?? 0)
                                      } resends left for this linking request. Would you like to resend?`,
                                      okText: "Resend",
                                      onOk: () => {
                                        axios
                                          .post(
                                            `${config.api_url}/projects/resend-link-request/${f.project}/${
                                              f.category
                                            }?user=${props.profile._id}&roleId=${(f as any)._id ?? f.roleId}&index=${
                                              f.index
                                            }`
                                          )
                                          .then(() => {
                                            message.success("Request resent successfully!");

                                            return wait(500);
                                          })
                                          .then(() => window.location.reload())
                                          .catch((e) => message.error(handleError(e)));
                                      },
                                    });
                                  }}
                                  style={{
                                    color: "#177DDC",
                                    textDecoration: "underlins",
                                    transform: "scale(0.8)",
                                    cursor: "pointer",
                                  }}
                                >
                                  Resend
                                </span>
                              )}
                            </>
                          )
                        ) : f.approved ? (
                          "Approved By You"
                        ) : f.rejected ? (
                          "Rejected By You"
                        ) : (
                          "Pending from You"
                        )}
                      </td>
                      <td>
                        {f.approved && +new Date() - +new Date(f.approvedOn as any as string) < ONE_WEEK ? (
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              modal.confirm({
                                title: "Are you sure you want to remove this project from your profile?",
                                content:
                                  "If you wish to add this project back to your profile, the creator will need to send another linking request.",
                                okText: "Unlink",
                                onOk: () => {
                                  axios
                                    .get(config.api_url + "/projects/remove-link/" + f.project + "/" + f.category)
                                    .then(() => {
                                      message.success("Unlinked successfully!");
                                      window.location.reload();
                                    })
                                    .catch((e) => message.error(handleError(e)));
                                },
                              });
                            }}
                          >
                            Unlink
                          </Button>
                        ) : null}
                        {!f.approved && !f.rejected && f.sentBy === "project" ? (
                          <Row>
                            <Button
                              size="small"
                              type="primary"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                axios
                                  .get(
                                    config.api_url +
                                      "/projects/approve-link/" +
                                      f.project +
                                      "/" +
                                      f.category +
                                      "/" +
                                      (f as any)._id +
                                      "?roleId=professional"
                                  )
                                  .then((response) => {
                                    message.success("Link approved successfully!");
                                    window.location.reload();
                                  })
                                  .catch((e) => {
                                    message.error(handleError(e));
                                  });
                              }}
                            >
                              Approve
                            </Button>
                            <Button
                              size="small"
                              type="dashed"
                              onClick={() => {
                                axios
                                  .get(
                                    config.api_url +
                                      "/projects/reject-link/" +
                                      f.project +
                                      "/" +
                                      f.category +
                                      "/" +
                                      (f as any)._id +
                                      "?roleId=professional"
                                  )
                                  .then((response) => {
                                    message.success("Link rejected successfully!");
                                    window.location.reload();
                                  })
                                  .catch((e) => {
                                    message.error(handleError(e));
                                  });
                              }}
                            >
                              Reject
                            </Button>
                          </Row>
                        ) : null}
                      </td>
                    </>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Button size="large" type="primary" onClick={() => navigate("/partner/professional-profile/add-film-credit")}>
          Add Film Credit
        </Button>
      </>
      <>
        <h5 className="mt-4">Awards</h5>
        <table className="table table-dark table-responsive">
          <thead>
            <tr>
              <th scope="col">Film Title</th>
              <th scope="col">Award</th>
              <th scope="col">Position</th>
              <th scope="col">Categories</th>
              <th scope="col">Sent On</th>
              {props.isOwner ? <th scope="col">Status</th> : null}
              {props.isOwner ? <th scope="col"></th> : null}
            </tr>
          </thead>
          <tbody>
            {_.orderBy(
              (props.profile.awards ?? []).map((el) => {
                if (el.approved) {
                  return { ...el, sort: 0, award: true };
                } else if (!el.rejected) {
                  return { ...el, sort: 1, award: true };
                } else {
                  return { ...el, sort: 2, award: true };
                }
              }),
              ["sort", "sentOn"],
              ["asc", "desc"]
            ).map((f, i) => {
              return (
                <tr key={i}>
                  <td>{(f.project as any)?.title ?? "-"}</td>
                  <td>{f.name}</td>
                  <td>{f.position}</td>
                  <td>{f.category.join(",")}</td>
                  <td>{moment(f.sentOn).format("DD/MM/YYYY")}</td>
                  {props.isOwner ? (
                    <>
                      <td>
                        {f.sentBy === "professional" ? (
                          f.approved ? (
                            "Approved By Creator"
                          ) : f.rejected ? (
                            "Rejected By Creator"
                          ) : (
                            <>
                              <span>Pending from Creator</span>{" "}
                              {f.resent ? null : (
                                <span
                                  onClick={() => {
                                    axios
                                      .post(
                                        `${config.api_url}/projects/resend-link-request/${
                                          (f.project as any)._id
                                        }/awards`
                                      )
                                      .then(() => {
                                        message.success("Resent successfully!");
                                        return wait(500);
                                      })
                                      .then(() => window.location.reload())
                                      .catch((e) => message.error(handleError(e)));
                                  }}
                                  style={{
                                    color: "#177DDC",
                                    textDecoration: "underlins",
                                    transform: "scale(0.8)",
                                    cursor: "pointer",
                                  }}
                                >
                                  Resend
                                </span>
                              )}
                            </>
                          )
                        ) : f.approved ? (
                          "Approved By You"
                        ) : f.rejected ? (
                          "Rejected By You"
                        ) : (
                          "Pending from You"
                        )}
                      </td>
                      <td>
                        {f.approved &&
                        f.sentBy === "project" &&
                        +new Date() - +new Date(f.approvedOn as any as string) < ONE_WEEK ? (
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              modal.confirm({
                                title: "Are you sure you want to remove this project from your profile?",
                                content:
                                  "If you wish to add this project back to your profile, the creator will need to send another linking request.",
                                okText: "Unlink",
                                onOk: () => {
                                  axios
                                    .get(
                                      config.api_url +
                                        "/projects/remove-link/" +
                                        (f.project as any)._id +
                                        "/awards?id=" +
                                        (f as any)._id
                                    )
                                    .then(() => {
                                      message.success("Unlinked successfully!");
                                      window.location.reload();
                                    })
                                    .catch((e) => message.error(handleError(e)));
                                },
                              });
                            }}
                          >
                            Unlink
                          </Button>
                        ) : null}
                        {!f.approved && !f.rejected && f.sentBy === "project" ? (
                          <Row>
                            <Button
                              size="small"
                              type="primary"
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                axios
                                  .get(
                                    config.api_url +
                                      "/projects/approve-link/" +
                                      (f.project as any)._id +
                                      "/awards/" +
                                      (f as any)._id +
                                      "?roleId=professional"
                                  )
                                  .then((response) => {
                                    message.success("Link approved successfully!");
                                    window.location.reload();
                                  })
                                  .catch((e) => {
                                    message.error(handleError(e));
                                  });
                              }}
                            >
                              Approve
                            </Button>
                            <Button
                              size="small"
                              type="dashed"
                              onClick={() => {
                                axios
                                  .get(
                                    config.api_url +
                                      "/projects/reject-link/" +
                                      (f.project as any)._id +
                                      "/awards/" +
                                      (f as any)._id +
                                      "?roleId=professional"
                                  )
                                  .then((response) => {
                                    message.success("Link rejected successfully!");
                                    window.location.reload();
                                  })
                                  .catch((e) => {
                                    message.error(handleError(e));
                                  });
                              }}
                            >
                              Reject
                            </Button>
                          </Row>
                        ) : null}
                      </td>
                    </>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <Button size="large" type="primary" onClick={() => navigate("/partner/professional-profile/add-film-credit")}>
          Add Award Credit
        </Button> */}
      </>
    </div>
  );
};

export default ProfessionalProfile;
