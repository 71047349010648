import { AutoComplete, Button, Form, Input, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Subject } from "rxjs";
import config from "src/Config";
import { MasterService } from "src/Services/master.service";
import { handleError } from "src/utils/handleError";

const searchSubject$ = new Subject<string>();

export const LinkProjectPage = () => {
  const [crewRole, setCrewRole] = useState<string[]>();

  const [filmResults, setFilmResults] = useState<{ value: string; label: string; _id: string }[]>();
  const [selectedFilm, setSelectedFilm] = useState<string | null>(null);
  const [role, setRole] = useState<"cast" | "crew" | "director" | "producer" | null>(null);

  useEffect(() => {
    const listener = searchSubject$.subscribe(async (query) => {
      const result = await axios.get(config.api_url + "/projects/search/" + query);

      setFilmResults(
        result.data.map((el: any) => ({
          value: `${el.englishTitle} (${el.customURL})`,
          label: `${el.englishTitle} (${el.customURL})`,
          _id: el._id,
        }))
      );
    });

    return () => listener.unsubscribe();
  }, [setFilmResults]);

  useEffect(() => {
    MasterService.getList(["crewRole"]).then(
      axios.spread((crewRole) => {
        setCrewRole(crewRole.values);
      })
    );
  }, []);

  const [form] = useForm();

  const navigate = useNavigate();

  return (
    <Content style={{ margin: "24px 0" }}>
      <div className="d-flex justify-content-between mb-2">
        <h1 className="title">Add Film Credit</h1>
      </div>
      <div className="site-layout-background" style={{ padding: "24px 0", minHeight: 360 }}>
        <div>
          <Form form={form}>
            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Please enter film name",
                },
                {
                  max: 75,
                  message: "Max. limit is 75 characters",
                },
                // prettier-ignore
                {
                          pattern: new RegExp("^[0-9a-zA-Z. ()-]*$"),
                          message: "No special characters allowed, only A-Z, Period and Space are allowed",
                        },
              ]}
            >
              <AutoComplete
                options={filmResults}
                onSearch={(searchString) => searchSubject$.next(searchString)}
                onSelect={(_: string, option: { label: string; value: string; _id: string }) => {
                  setSelectedFilm(option._id);
                }}
                onDeselect={() => setSelectedFilm(null)}
              >
                <Input.Search size="large" placeholder="Film Name" />
              </AutoComplete>
              {/* <Input size="large" placeholder="Full Name" /> */}
            </Form.Item>
            <Form.Item name="category" style={{ flex: 1 }}>
              <Select
                size="large"
                placeholder="Role"
                options={[
                  { value: "director", label: "Director" },
                  { value: "producer", label: "Producer" },
                  { value: "cast", label: "Cast" },
                  { value: "crew", label: "Crew" },
                ]}
                onSelect={(
                  _: string,
                  { value }: { value: "cast" | "crew" | "director" | "producer"; label: string }
                ) => {
                  setRole(value);
                }}
              />
            </Form.Item>
            {role === null ? null : (
              <Form.Item
                name={
                  role === "cast"
                    ? "character"
                    : role === "crew"
                    ? "role"
                    : role === "director"
                    ? "biography"
                    : "filmography"
                }
                style={{ flex: 1 }}
                rules={[
                  {
                    required: true,
                    message: role === "cast" ? "Character Name is required" : "Role is required",
                  },
                ]}
              >
                {role === "cast" ? (
                  <Input size="large" placeholder="Character Name" />
                ) : role === "crew" ? (
                  <Select size="large" style={{ width: "100%" }} placeholder="Please select Crew's Role" showSearch>
                    {crewRole?.map((role) => (
                      <Select.Option key={role} value={role}>
                        {role}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Input.TextArea placeholder={role === "director" ? "Biography" : "Filmography"} rows={5} />
                )}
              </Form.Item>
            )}
            <Button
              size="large"
              type="primary"
              onClick={() => {
                const values = form.getFieldsValue();
                if (selectedFilm) {
                  axios
                    .post(config.api_url + "/projects/" + selectedFilm + "/add-credit", values)
                    .then(() => {
                      message.success("Request sent successfully!");
                      navigate("/partner/professional-profile");
                    })
                    .catch((e) => {
                      message.error(handleError(e));
                    });
                } else {
                  axios
                    .post(config.api_url + "/professional-profile/add-credit", values)
                    .then(() => {
                      message.success("Added successfully!");
                      navigate("/partner/professional-profile");
                    })
                    .catch((e) => {
                      message.error(handleError(e));
                    });
                }
              }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </Content>
  );
};
