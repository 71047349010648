import { Form, FormInstance, Input, message, Select } from "antd";
import debounce from "lodash/debounce";
import TextArea from "antd/lib/input/TextArea";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import config from "src/Config";
import axios from "axios";
import { MasterService } from "src/Services/master.service";
import { kebabCase } from "lodash";

const CreateFilmBasicSection = (props: { id: string | undefined; form: FormInstance<any>; addendumMode: boolean }) => {
  const [urlAvailableError, setURLAvailableError] = useState<
    ["" | "validating" | "success" | "warning" | "error" | undefined, string]
  >(["", ""]);

  // Get data from Master Service
  const [filmCategory, setFilmCategory] = useState<string[]>();
  const [filmGenre, setFilmGenre] = useState<string[]>();
  const [languages, setLanguages] = useState<string[]>();
  useEffect(() => {
    MasterService.getList(["filmCategory", "filmGenre", "languages"]).then(
      axios.spread((filmCategory, filmGenre, languages) => {
        setFilmCategory(filmCategory.values);
        setFilmGenre(filmGenre.values);
        setLanguages(languages.values);
      })
    );
  }, []);

  const checkCustomURL = useCallback(
    debounce(async (value = "") => {
      if (value?.length > 25) {
        setURLAvailableError(["error", "Custom URL cannot be greater than 25 characters!"]);
        return message.error("Custom URL is too long!");
      }
      if (value?.length === 0) {
        setURLAvailableError(["error", "Custom URL is Mandatory!"]);
        return message.error("Custom URL is Mandatory!");
      }
      const pattern = new RegExp("^[0-9a-zA-Z-]+$");
      if (!pattern.test(value)) {
        setURLAvailableError(["error", "Special characters not allowed!"]);
        return message.error("Special characters not allowed!");
      }
      axios.get(config.api_url + `/projects/films/${value}?present=${props.id}`).then(
        (res) => {
          if (res.data.available === false) {
            setURLAvailableError(["error", "URL already taken, please try another url!"]);
          } else {
            setURLAvailableError(["success", "URL is available"]);
          }
        },
        (err) => {
          message.error(err.message);
          console.error(err);
        }
      );
    }, 1000),
    []
  );

  const generateCustomURL = useCallback(
    debounce(async (event: ChangeEvent<HTMLInputElement>) => {
      let customURL = kebabCase(event.target.value?.length ? event.target.value : "");
      checkCustomURL(customURL);
      props.form.setFieldsValue({
        ["customURL"]: customURL.substring(0, 25),
      });
    }, 200),
    []
  );

  return (
    <>
      <Form.Item
        name="title"
        label="Film Title Original"
        validateTrigger="onBlur"
        rules={[
          {
            type: "string",
            required: true,
            message: "Please input your Film Title Original",
          },
          {
            min: 3,
            message: "Min. requirement is 3 characters",
          },
          {
            max: 75,
            message: "Max. limit is 75 characters",
          },
          {
            pattern: new RegExp("^[0-9a-zA-Z.?:# ]*$"),
            message: "Only .?:# are allowed as special characters",
          },
        ]}
      >
        <Input
          size="large"
          placeholder="Film Title Original"
          readOnly={props.addendumMode}
          onChange={generateCustomURL}
        />
      </Form.Item>
      <Form.Item
        name="englishTitle"
        label="Film Title (English)"
        rules={[
          {
            type: "string",
            required: true,
            message: "Please input your Film Title in English",
          },
          {
            max: 75,
            message: "Max. limit is 75 characters",
          },
          {
            pattern: new RegExp("^[0-9a-zA-Z.?:# ]*$"),
            message: "Only .?:# are allowed as special characters",
          },
        ]}
      >
        <Input size="large" placeholder="Film Title (English Translation, if any)" readOnly={props.addendumMode} />
      </Form.Item>
      <Form.Item
        name="customURL"
        label="Custom URL"
        validateStatus={urlAvailableError[0]}
        help={urlAvailableError[1]}
        hasFeedback
        rules={[
          {
            type: "string",
            required: true,
            message: "Please input your custom url!",
          },
          {
            min: 3,
            message: "Min. requirement is 3 characters!",
          },
          {
            max: 25,
            message: "Kindly restrict your custom url to 25 characters!",
          },
          {
            validator: (_, value) => {
              setURLAvailableError(["validating", ""]);
              checkCustomURL(value);
            },
            message: "Please input your custom url!",
          },
          {
            pattern: new RegExp("^[0-9a-zA-Z-]+$"),
            message: "Please input your custom url, only alphanumeric characters and hypens are allowed!",
          },
        ]}
      >
        <Input size="large" prefix="https://pocketfilms.in/film/" placeholder="Custom URL" />
      </Form.Item>
      <Form.Item
        name="category"
        label="Category"
        rules={[
          {
            type: "string",
            required: true,
            message: "Please select Film Category!",
          },
        ]}
      >
        <Select size="large" style={{ width: "100%" }} placeholder="Please select your film category" showSearch>
          {filmCategory?.map((category) => (
            <Select.Option key={category} value={category}>
              {category}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="genre"
        label="Genre"
        rules={[
          {
            type: "array",
            required: true,
            message: "Please select Film Genre",
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: "100%" }}
          placeholder="Please select your film genre"
          mode="multiple"
          showSearch
          allowClear
        >
          {filmGenre?.map((genre) => (
            <Select.Option key={genre} value={genre}>
              {genre}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="language"
        label="Original Language"
        rules={[
          {
            type: "string",
            required: true,
            message: "Please select Film Original Language!",
          },
        ]}
      >
        <Select
          size="large"
          style={{ width: "100%" }}
          disabled={props.addendumMode}
          placeholder="Please select your film original language"
          showSearch
        >
          {languages?.map((lang) => (
            <Select.Option key={lang} value={lang}>
              {lang}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="tagline"
        label="Tagline"
        rules={[
          {
            type: "string",
            message: "Please input your Film tagline",
          },
          {
            pattern: new RegExp("^[0-9a-zA-Z,. ]*$"),
            message: "Only ,. are allowed as special characters",
          },
          {
            max: 100,
            message: "Max. limit is 100 characters",
          },
        ]}
      >
        <Input size="large" placeholder="Film Tagline" />
      </Form.Item>
      <Form.Item
        name="synopsis"
        label="Synopsis"
        rules={[
          {
            type: "string",
            required: true,
            message: "Please enter Film Synopsis!",
          },
          {
            max: 500,
            message: "Max. character limit is 500",
          },
          // {
          //   pattern: new RegExp("^[0-9a-zA-Z!\"&'-()?:,. \n]*$"),
          //   message:
          //     "Please enter Film Synopsis, no special characters allowed!",
          // },
        ]}
      >
        <TextArea showCount size="large" placeholder="Film Synopsis" rows={4}></TextArea>
      </Form.Item>
    </>
  );
};

export default CreateFilmBasicSection;
