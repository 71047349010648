import { RecoilRoot } from "recoil";
import { Footer, Navbar } from "src/Components";

const DraftAgreementPage = () => {
  return (
    <RecoilRoot>
      <div className="container-fluid">
        <Navbar />

        <div
          className="container my-5 text-light"
          style={{
            backgroundColor: "#13131A",
            borderRadius: "20px",
            padding: "40px",
            userSelect: "none",
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <h1 className="text-center mb-5">
            Draft of the DISTRIBUTION AGREEMENT
          </h1>

          <div>
            <p>
              THIS DISTRIBUTION AGREEMENT (this “Agreement”) is entered into by
              and between Onetakemedia.com Private Limited, a company registered
              under the Companies Act, 1956 having its registered address at
              502, Shiv Tirth No. 2, Bhulabhai Desai Road, Mumbai 400026,
              Maharashtra India (“PocketFilms”), and [NAME OF
              INDIVIDUAL/COMPANY/FIRM/ORGANISATION] an/a [individual / company /
              firm / organization] having its registered address at
              _______________________________________________________________________________
              (“Content Provider”) as of [ ], 20[ ] (the “Effective Date”).
            </p>
            <p>
              WHEREAS, PocketFilms is in the business of distribution of content
              across platforms, and offering other services related to the media
              and entertainment industry. PocketFilms owns and operates the
              website called www.pocketfilms.in and mobile apps.
            </p>
            <p>
              WHEREAS, Content Provider is the producer / owner / authorized
              representative and/or rights holder of the Content (defined in
              detail herein) and such rights including, without limitation the
              right to upload such Content and offer the same for distribution
              and monetisation to PocketFilms by accepting and executing this
              agreement.{" "}
            </p>
            <p>
              WHEREAS, the Content Provider wants to offer the Content and
              PocketFilms wants to obtain the Content for monetization and
              distribution across the Distribution Services as detailed in
              Exhibit A on the terms and conditions set forth in this Agreement;
            </p>

            <p>
              NOW THEREFORE, in consideration of the premises and the mutual
              covenants contained in this Agreement, the parties agree as
              follows:
            </p>
            <p>
              1. <strong>Definitions:</strong>
            </p>
            <p>
              The following definitions apply to capitalized terms in this
              Agreement. All other capitalized terms are defined in the body of
              the Agreement.
            </p>
            <p>
              1.1. <strong>&quot;Advertising Rights&quot;</strong> means the
              right to display advertisements, including but not limited video
              advertisements, within or around the content. It further grants
              PocketFilms and their authorized distribution partners and
              platforms, to place / incorporate ads in-between the Content by
              pausing it, for the purpose of displaying the advertisements,
              which may be streamed from a third-part server / platform and may
              or may not be monetized.
            </p>
            <p>
              1.2. <strong>&quot;Affiliate&quot;</strong> means a Person which,
              directly or indirectly, owns or controls, is owned or is
              controlled by or is under common ownership or control with another
              Person; &quot;control&quot; and its derivatives means the power to
              direct the management or affairs of a Person; and &quot;own&quot;
              and its derivatives means the beneficial ownership of 50% or more
              of the voting equity securities or other equivalent voting
              interests of the Person.
            </p>
            <p>
              1.3. <strong>&quot;Agreement&quot;</strong> means this agreement
              its exhibits, schedules and addendums, the terms of which are
              incorporated by reference.
            </p>
            <p>
              1.4. <strong>&quot;Ancillary Rights&quot;</strong> in relation to
              the Licensed Content and the Rights includes the following: (a)
              the right to promote, advertise and/or market the Licensed Content
              and/or the availability of the Licensed Content on the Platform,
              through various modes, media, platforms, including but not limited
              to television, print and radio including social media; (b) the
              right to create promotional, advertising, and marketing material
              (including but not limited to images, moving images, audio clips,
              video clips, social media posts, banners and hoardings); (c) the
              right to edit the Licensed Content or parts thereof for insertion
              of advertisements including pre-roll, mid-roll, post-roll, or
              overlay advertisements, promotions, links, whether before, during
              or after the Licensed Content, and/or as required to comply with
              the applicable laws including censorship/regulatory requirements
              and/or for time segmentation purposes.
            </p>
            <p>
              1.5. <strong>&quot;Application&quot;</strong> shall mean software
              which enables a subscriber or user to access and / or consume the
              Content on any Device and stream and/or download the Content on
              such Device.
            </p>
            <p>
              1.6. <strong>&quot;AVOD&quot;</strong> stands for Ad-Supported
              Video on Demand (AVOD) and shall mean making available the
              Content, where the End User is granted access for Streaming
              /Viewing/Downloading of such Content free of cost and which may be
              supported by advertising, including placement of ads in-between
              the content.
            </p>
            <p>
              1.7. <strong>&quot;Cable Television Network&quot;</strong> shall
              mean and include any system consisting of a set of closed
              transmission paths and associated signal generation, control and
              distribution equipment designed to provide Cable Service for
              reception by multiple subscribers;
            </p>
            <p>
              1.8. <strong>&quot;Cable Operator&quot;</strong> shall mean any
              Person who provides cable service through a Cable Television
              Network or otherwise controls or is responsible for the management
              and operation of a Cable Television Network;
            </p>
            <p>
              1.9. <strong>&quot;Cable Service&quot;</strong> means the
              transmission of the audiovisual programs including re-transmission
              by cables (eg. fibre optic or coaxial wire) of any satellite
              television channels managed and operated by the Cable Operator or
              Multi System Operator (MSO);
            </p>
            <p>
              1.10. <strong>&quot;Catch Up TV Rights&quot;</strong> shall mean
              the technology which allows the platforms to record and store in
              its servers and/or devices the Content which are first broadcasted
              on such Channel(s) for the purpose of making available to its
              subscribers access to such Content for a certain period from the
              date of broadcast on such Channel(s) and allows the user/viewer to
              exercise substantial control over the timing, sequenceand/or
              configuration of content being viewed where such content is not
              primarily intended to be viewed on a simultaneous basis by all
              users/viewers in a linear, sequential manner;
            </p>
            <p>
              1.11. <strong>&quot;Clip Rights&quot;</strong> shall mean the
              right to utilize and exploit any part/clip of the Content in
              combination with any other content in any program (fiction/
              non-fiction) and/or on ground events and/or award shows
              etc.produced and or distributed by the PocketFilms and/or its
              Affiliates and/or itssub-Licensee/licensees/sub-licensees and/or
              third parties;
            </p>
            <p>
              1.12. <strong>&quot;Confidential Information&quot;</strong> means
              all confidential and proprietary information of a party
              (&quot;DisclosingParty&quot;) disclosed to the other party
              (&quot;Receiving Party&quot;), whether orally or in writing, that
              is either marked or designated as confidential or is identified in
              writing as confidential or proprietary within fifteen (15)days of
              disclosure to the Receiving Party; provided that the following
              shall be deemed to be Confidential Information even if not so
              marked or identified: the terms and conditions of this Agreement
              (including pricing and other terms reflected in all schedules
              hereto), the Disclosing Party’s business and marketing plans,
              technology and technical information, product designs, and
              business processes, any information or materials with the name,
              sign, trade name or trademark of the Disclosing Party and any
              information that a reasonable person would deem confidential or
              proprietary given the nature of the information and the
              circumstances under which it is disclosed.{" "}
              <strong>&quot;Confidential Information&quot;</strong> does not
              include any item of information which (a) is or becomes available
              in the public domain without the fault of the Receiving Party;(b)
              is disclosed or made available to the Receiving Party by a third
              party without restriction and without breach of any relationship
              of confidentiality; (c) is independently developed by the
              Receiving Party without access to the disclosing party’s
              Confidential Information; or (d) is known to the recipient at the
              time of disclosure.
            </p>
            <p>
              1.13. <strong>&quot;Content&quot;</strong> means cinematograph
              films and more particularly defined as audio visual programs, in
              which the Content Provider holds valid legal rights. Content shall
              also mean and include one or more film, video,trailer, promo,
              posters, as well as the image, voice, likeness, statements,
              background and biographicalmaterial of the Content Provider and
              the cast and crew of the Content and any and all information and
              metadata pertaining to the same. Wherever applicable, Content
              shall also mean and include images,script, stories, concept notes,
              ideas, text, etc. shared by the Content Provider to PocketFilms.
            </p>
            <p>
              1.14. <strong>&quot;Delivery Methods&quot;</strong> means delivery
              methods used by the Content Provider or offered by PocketFilms to
              enable the Content Provider to deliver the Content to PocketFilms
              for distribution as permitted by this Agreement. The mode or
              method of delivery could be by way of physical delivery of Content
              and/or through digital mediums or platforms, including but not
              limited to sharing an online link or using such other service with
              the intent of delivering the Content to PocketFilms.
            </p>
            <p>
              1.15. <strong>&quot;Digital Platform Broadcast&quot;</strong>{" "}
              means the delivery of the audio-visual content transmitted as data
              packets over the world wide web/Internet for display using a web
              browser (including but not limited to Windows Internet Explorer,
              Mozilla Firefox, Apple Safari, Google Chrome and Opera) or using
              an Applicable/software client interface which is installed on the
              device receiving such data packets through streaming and shall
              also include delivery of audio visual content by means of
              electronic, digital or other similar modes of transmission for
              delivery on mobile devices and/or any other Internet enabled
              devices including but not limited to laptops, computers, portable
              display devices, etc. which are equipped to receive such
              transmission in the form of data packets using technologies
              including but not limited to GPRS, Edge, 3G, 4Gor any other
              technologies come into existence in future during the Term;
            </p>
            <p>
              1.16. <strong>&quot;Direct to Home (DTH)&quot;</strong> shall mean
              a service to distribute multi-channel television programs by using
              a satellite system directly to subscriber’s premises without
              passing through intermediary such as Cable Operator, MSO or any
              other distributor of the television channels;
            </p>
            <p>
              1.17. <strong>&quot;Distribute&quot;</strong> shall mean the right
              to exhibit, display, broadcast, make available by any means to an
              EndUser the Content, commercially or otherwise.
            </p>
            <p>
              1.18. <strong>&quot;Distribution Platforms&quot;</strong> or{" "}
              <strong>&quot;Platforms&quot;</strong> means all electronic,
              digital and other media platforms including, but not limited to,
              &quot;Windows Media Player&quot;, &quot;RealPlayer&quot;,
              &quot;YouTube&quot;, &quot;Facebook&quot;, &quot;My Space&quot;,
              Internet radio, podcasts, playlists, and other MP3 players and
              services (Winamp, MusicMatch, Amarok, Spotify,Pandora, etc.).
              Content Provider recognizes that additional electronic, digital,
              and other media platforms not enumerated in this listing may exist
              or come to exist and the term Platforms is intended to include
              those platforms as well. It shall mean and include all the
              platforms on which PocketFilms will distribute the Content subject
              to the rights granted herein. It shall mean and include all the
              entities that PocketFilms will engage with for the purposes of
              distributing the Content.
            </p>
            <p>
              1.19. <strong>&quot;Distribution Service&quot;</strong> shall mean
              and refer to the specified rights granted by the Content Provider
              to PocketFilms, to distribute the Content on services which are
              either owned and managed by PocketFilms and/or any third party
              through any of the mediums or means as detailed in Exhibit A to
              users throughout the territory for the term of this Agreement. It
              shall also include such devices/vectors developed and/or invented
              in future.
            </p>
            <p>
              1.20. <strong>&quot;Download&quot;</strong> shall mean the
              transfer of a digital media content file from a content delivery
              network serveror similar service(s) via internet delivery to an
              authorized device such that a copy of the file is stored on the
              device.
            </p>
            <p>
              1.21. <strong>&quot;Dubbing Rights&quot;</strong> shall mean the
              right to dub the Content in the language(s) other than the
              original language of the Content;
            </p>
            <p>
              1.22. <strong>&quot;End User&quot;</strong> shall mean any user
              that can access the Content for personal use and such other
              entities including their users to which or through which
              PocketFilms distributes the Content as permitted under this
              agreement. The term User or Customer shall also mean End User.
            </p>
            <p>
              1.23. <strong>&quot;Editing Rights&quot;</strong> shall mean the
              right to edit the Content in order to (i) comply with local
              censorship and broadcasting / streaming laws, regulations,
              advisories and/or (ii) to comply with the PocketFilms’ internal
              standards and practices, and/or (iii) for synchronizing the timing
              of the Content and scheduling requirements;
            </p>
            <p>
              1.24. <strong>&quot;Exhibition Device(s)&quot;</strong> shall mean
              any device which is capable of receiving and exhibiting content
              either directly or through some other device which is either
              tethered to the first device or wirelessly and shall also include
              mobile and tethered devices including but not limited to
              televisions, mobile devices,computers, laptops and such other
              devices which are presently not in commercial use or existence but
              may come into such use or existence during the Term;
              &quot;Devices&quot; shall also mean and refer to Exhibition
              Devices.
            </p>
            <p>
              1.25. <strong>&quot;Format&quot;</strong> means all electronic,
              digital and other media formats including, but not limited to, the
              following: MP3, MP2, MP4, CD-ROM, Windows Media WMA, RealAudio,
              OGG Vorbis, Samples, AIFF, WAV, Flash, MIDI, Cubase, Sequencer
              file, FLAC, ALAC, ACC, MOV, PDF, JPG, PNG, SampleCell, .OGG, etc.
              Content Provider recognizes that additional electronic, digital
              and other media formats not enumerated in this listing (and, in
              some cases, not yet created or envisioned) may exist or come to
              exist, and the term Formats is intended to include those formats
              as well.
            </p>
            <p>
              1.26. <strong>&quot;Internet&quot;</strong> shall mean a global
              network of interconnected networks, each using technology which is
              currently known as Internet Protocol (&quot;IP&quot;), and which
              is used to transmit content that is directly or indirectly
              delivered to any IP enabled hardware or software device or
              component.
            </p>
            <p>
              1.27. <strong>&quot;Intellectual Property Rights&quot;</strong>{" "}
              means (a) all inventions (whether or not patentable and whether or
              not reduced to practice), all improvements thereto, and all
              patents, patent applications, and patent disclosures, together
              with all reissuances, divisions, continuations,
              continuations-in-part, revisions, renewals, extensions, and
              reexaminations thereof, (b) all works of authorship, including all
              mask work rights, database rights and copyrightable works, all
              copyrights, all applications, registrations and renewals in
              connection there with, and all moral rights, (c) all trade
              secrets, (d) all registered and unregistered trademarks, service
              marks, trade dress, domain names, logos, trade names, and
              corporate names,together with all translations, adaptations,
              derivations, and combinations thereof and including all goodwill
              associated therewith, and all applications, registrations and
              renewals in connection therewith, (e)all derivative works of any
              of the foregoing; (f) any other similar rights or intangible
              assets recognizedunder any laws or international conventions, and
              in any country or jurisdiction in the world, as intellectual
              creations to which rights of ownership accrue, and all
              registrations, applications, disclosures, renewals,extensions,
              continuations or reissues of the foregoing now or hereafter in
              force, and (g) all copies andtangible embodiments of all of the
              foregoing (a) through (f) in any form or medium throughout the
              world.
            </p>
            <p>
              1.28. <strong>&quot;In-Transit rights&quot;</strong> shall mean
              and include the right to distribute the Content in any mode of
              transport/transit including but not limited to aeroplanes
              (In-Flight), ships (In-Ship), vehicles (Cabs, Buses,Trucks) etc.
            </p>
            <p>
              1.29. <strong>&quot;IPTV&quot;</strong> shall mean an Internet
              protocol television system where a digital television service is
              delivered byusing Internet Protocol over a network infrastructure,
              which may include delivery by a broadband connection or DSL cable
              connection with a set-top-box for exhibition on any Exhibition
              Device
            </p>
            <p>
              1.30. <strong>&quot;Linear Streaming&quot;</strong> shall mean and
              include streaming of the Content as a continuous feed using any
              technology on any platform for viewing on an Exhibition Device
              where the user can experience the content akin to watching a
              traditional TV channel.
            </p>
            <p>
              1.31. <strong>&quot;Marks&quot;</strong> means the trademarks and
              service marks owned by the respective parties.
            </p>
            <p>
              1.32. <strong>&quot;Mobile and Digital Devices&quot;</strong>{" "}
              shall mean the mobile and digital devices which have telephony
              capability,either inbuilt or by in conjunction with any device or
              software which allows it to have a telephony usage,accessed by
              wires or wireless which includes but is not limited to GSM, CDMA,
              2G, 3G, 4G, BWA, LTE, WiFi,WiMax or any other protocol or format,
              either existing or invented in the future. It shall also include
              devices capable of executing binary code.
            </p>
            <p>
              1.33. <strong>&quot;Mobile Service Providers&quot;</strong> shall
              mean those entities in the business of providing telephony
              services to their consumers, either via wires or wireless,
              landline or mobile, via GSM, CDMA, 2G, 3G, 4G, BWA and LTE or any
              protocol, either existing or invented in the future.
            </p>
            <p>
              1.34. <strong>&quot;On Demand Rights&quot;</strong> shall mean the
              right to provide the Content using any technologies on any
              platform including but not limited to the Distribution Platforms
              or through Digital Platform Broadcast for viewing on any
              Exhibition Device where the user/viewer is provided the means to
              exercise substantial control overthe selection, timing, sequence
              and/or configuration of content being viewed through
              download,streaming, permanent or temporary transfer of data or any
              other technology where such content is not primarily intended to
              be viewed on a simultaneous basis by all users/viewers in a
              linear, sequential manner. On Demand Rights shall include all the
              On Demand services including but not limited to Pay Per View
              (&quot;PPV&quot;), Near Video On Demand (&quot;NVOD&quot;),
              Subscription Video On Demand (&quot;SVOD&quot;), Transactional
              Video on Demand (&quot;TVOD&quot;), Audio on Demand
              (&quot;AOD&quot;), Free Video On Demand (&quot;FVOD&quot;) and all
              other On Demand rights whether presently in use or which may come
              into commercial use during the Term and regardless of nomenclature
              used;
            </p>
            <p>
              1.35. <strong>&quot;Over-The-Top or (OTT)&quot;</strong>: An
              over-the-top (OTT) application is any app or service that provides
              a product over the Internet and bypasses traditional distribution.
              Services that come over the top are most typically related to
              media and communication
            </p>
            <p>
              1.36. <strong>&quot;Pay TV&quot;</strong> shall mean the linear
              signals of a television service (which includes exploitation of
              the Content)and may be receivable only on payment of subscription
              fees levied from time to time including but notlimited to
              subscribers of any Distribution Platforms.
            </p>
            <p>
              1.37. <strong>&quot;Pay Per View&quot;</strong> means that mode of
              programming distribution by which individual programs are
              delivered through any of the Distribution Platforms to the
              subscriber where a charge on a per Content per-exhibitionbasis
              (i.e. multiple number of Runs) is made to a subscriber in relation
              to the subscriber’s election to view an individual Content
            </p>
            <p>
              1.38. <strong>&quot;Publicity Material&quot;</strong> shall mean
              promos/trailers, artwork, and any other publicity materials of the
              Content including audio-visual or print materials that will be
              provided by the Content Provider to the PocketFilms as and when
              available or created by the PocketFilms and/or its Affiliates/sub-
              licensees or associated third party from the Content;
            </p>
            <p>
              1.39. <strong>&quot;Promotion Rights&quot;</strong> shall mean the
              right to promote the Content and for such purpose use any
              text,information, images, audio, visuals or audio visuals from the
              Content to create such promotional material(&quot;Promos&quot;)
              and to exhibit such Promos on any medium including but not limited
              to through Distribution Platforms;
            </p>
            <p>
              1.40. <strong>&quot;Person&quot;</strong> means an individual, a
              corporation, partnership, limited liability company, association,
              trust,unincorporated organization, or other legal entity or
              organization, or a Government Body
            </p>
            <p>
              1.41. <strong>&quot;Registered User&quot;</strong> means any
              Person who (i) provides at least his or her name and e-mail
              address / mobile phone number to PocketFilms, (ii) becomes legally
              bound by PocketFilms’ Terms of Service and Privacy Policy and
              (iii) has access, by subscription or other arrangement with
              PocketFilms, to any portion of the Content.
            </p>
            <p>
              1.42. <strong>&quot;Satellite Broadcasting&quot;</strong> shall
              mean delivery of services consisting of audio visual content which
              is uplinked and broadcasted (in digital or analogue mode) through
              satellite in extra terrestrial orbit and beamed down to the earth
              where such signals of the services are received by way of a
              satellite antenna and re-broadcast of such signals (in digital or
              analogue mode) by means of wireless diffusion or through cable
              (fibre optic or coaxial wire) through different platforms
              including but not limited to the Distribution Platforms and/ or
              any other platforms devised in future during the Term.
            </p>
            <p>
              1.43. <strong>&quot;Stream&quot;</strong> or &quot;Streaming&quot;
              means the transmission of a digital media content file via
              Internet Delivery to an Authorized Device for viewing
              simultaneously with the delivery of such file, storing small
              portions of the file in a temporary volatile &quot;buffer&quot; as
              it is being received instead of saving a copy of the file to the
              device.
            </p>
            <p>
              1.44. <strong>&quot;SVOD&quot;</strong> Subscription Video on
              Demand Service (&quot;SVOD Service&quot;) means any multiple
              packages that exhibit the Content, where the subscriber is granted
              unlimited access to view the Content by all means and modes of
              SVOD on the Internet Platform, on payment of a monthly fee to
              PocketFilms or its distributors /distribution platforms for such
              subscription.
            </p>
            <p>
              1.45. <strong>&quot;Subtitling Rights&quot;</strong> shall mean
              the right to subtitle the Content in the other languages;
            </p>
            <p>
              1.46. <strong>&quot;Terrestrial Television&quot;</strong> means
              the rights to broadcast the Content as part of the linear
              television programming service/channel(s) which is available for
              reception without the use of any decoding,decrypting, satellite
              receivers and can be received by standard television sets through
              the means of a rooftop antenna or such other device situated at
              the premises of the viewer which is capable of receiving
              terrestrial waves/signals either in analogue or digital format
              and/or via any other technology which may be invented from time to
              time and the expression Terrestrial Television Rights shall be
              construed accordingly.
            </p>
            <p>
              1.47. <strong>&quot;Territory&quot;</strong> shall mean the
              geographical area within which the Content can be distributed
              under this Agreement.
            </p>
            <p>
              1.48. <strong>&quot;Term&quot;</strong> is the period for which
              the agreement is valid and as defined under 6.1
            </p>
            <p>
              1.49. <strong>&quot;Theatrical&quot;</strong> shall mean and refer
              to screening in commercial motion picture theatres or other venues
              where members of the general public can view the Content as a
              collective experience either free or onpayment of a charge.
            </p>
            <p>
              1.50. <strong>&quot;TVOD&quot;</strong> stands for Transaction
              Video on Demand (VOD) means a Transaction whereby a User is
              charged a transaction fee for authorization to receive a Content
              by means of authorized delivery via authorizedtransmission for
              personal, residential viewing at times chosen by the User in the
              User’s sole discretionduring the applicable viewing period and in
              accordance with the usage rules set forth by the respective
              Distribution Platform(s).
            </p>
            <p>
              1.51. <strong>&quot;WAP&quot;</strong> (Wireless Application
              Protocol) shall mean a specification for a set of communication
              protocols to standardize the way that wireless devices, such as
              cellular telephones and radio transceivers, can be used for
              Internet access, including e-mail, the World Wide Web, news
              groups, and instant messaging.
            </p>
            <p>
              1.52. <strong>&quot;WEB&quot;</strong> shall mean a computer
              network consisting of a worldwide network of computer networks
              that use the TCP/IP network protocols to facilitate data
              transmission and exchange.
            </p>
            <p>
              2.<strong>Grant of Rights</strong>
            </p>
            <p>
              The Content Provider hereby grants to PocketFilms throughout the
              Territory, for the Term and subject to the terms and conditions
              contained in this Agreement and more specifically detailed in
              Exhibit B,the rights in the Content to:
            </p>
            <p>2.1. Receive the Content by the Delivery Methods</p>
            <p>
              2.2. Store the Content on PocketFilms’ own servers, devices, third
              party servers and / or cloudplatforms engaged by PocketFilms and /
              or on servers or Platforms authorized by PocketFilms to distribute
              the Content under the terms of this agreement.
            </p>
            <p>
              2.3. Right to commercially exploit, display, distribute and
              monetize the Content by one or more means of the Distribution
              Services detailed in Exhibit A and subject to the specific
              permissions granted for Distribution Services in Exhibit B
            </p>
            <p>
              2.4. Right to distribute the Content to one or more Person(s)
              during the Term of the Agreement
            </p>
            <p>
              2.5. PocketFilms is also granted Advertising Rights, Ancillary
              Rights, Clip Rights, Editing Rights, Dubbing Rights and Subtitling
              Rights
            </p>
            <p>
              2.6. Right to distribute the Content for free across Distribution
              Services at the discretion ofPocketFilms.
            </p>
            <p>
              2.7. Distribute the Content to the End User via Streaming,
              Download, Broadcast or such other means
              <br />
              The Content Provider grants exclusive rights of the content to
              PocketFilms for all Internet and Mobile Platforms, as detailed
              under Distribution Services in Exhibit A and non-exclusive rights
              to the other services detailed in Exhibit A herein
            </p>
            <p>
              3. <strong>Delivery of Content</strong>
            </p>
            <p>
              3.1. <strong>Delivery and Installation:</strong> The Content
              Provider will deliver the content to PocketFilms at its own cost.
              PocketFilms shall bear the expenses after the Content is uploaded
              through their platforms(owned or otherwise).
            </p>
            <p>
              3.2. <strong>Quality of Transmissions:</strong> PocketFilms shall,
              to the best of its ability and / or available infrastructure,
              ensure that each transmission of Content (a) is of high commercial
              quality, (b) is free from errors or defects, and (c) with the
              exception of isolated short-term technical difficulties
            </p>
            <p>
              3.3. <strong>Specifications:</strong> PocketFilms shall adhere to
              the rights granted in this agreement. PocketFilms shall store, and
              / or shall dispose of, any Content it receives from the Content
              Provider hereunder as required under Law and/or as per its
              internal policies.
            </p>
            <p>
              3.4. <strong>Release and Distribution:</strong> The programming,
              scheduling, release and removal of the Content on/ from the
              Distribution Services shall be at the sole and exclusive
              discretion of PocketFilms and shall be binding on the Content
              Provider.
            </p>
            <p>
              3.5. <strong>Approval and Rejection:</strong> PocketFilms is not
              required to approve and/or accept the Content received from the
              Content Provider. PocketFilms shall have the right to reject any
              Content if it does not comply or is not in conformity with the
              laws applicable in India. PocketFilms may reject any Content at
              its discretion without providing any reason whatsoever.
            </p>
            <p>
              4. <strong>Payments and Payment Terms</strong>
            </p>
            <p>
              4.1. Fees: PocketFilms is liable to pay to the Content Provideras
              follows, as per the criteria selected by the Content Provider
              while submitting the Content and detailed in Exhibit B here under
              <br />
              Revenue Share: The Content Provider is entitled to a revenue share
              at the percentage as detailed in Exhibit B, calculated on the
              aggregate of all Gross Revenues received by PocketFilms from the
              distribution of the Content across permitted Distribution Services
              including but not limited to PocketFilms’ owned platforms.
            </p>
            <p>
              4.2. <strong>Gross Revenues:</strong> Gross revenues shall mean
              the actual amounts received by PocketFilms for the Content only.
              If the Content is distributed / monetised as a part of a
              compilation, then &quot;Gross revenues&quot; shall mean the amount
              received by PocketFilms for the Content only and shall be
              calculated as a share proportionate to the weight-age the
              particular Content in the compilation. The weight-age of the
              Content in the compilation as decided by PocketFilms shall be
              final and binding. Expenses for facilities, work spaces,
              utilities, management, clerical and reproduction services,
              supplies, and the like shall be borne by PocketFilms. Only
              expenses which can be directly attributed to the Content or
              Revenue Generation thereof shall be deducted from the Gross
              Revenues before calculating the revenue share for the Content
              Provider including but not limited to the cost of streaming,
              hosting and delivery of the content.With respect to revenues
              generated on PocketFilms’ website and apps, Gross revenue shall
              mean the amount of revenue generated by PocketFilms’ for the
              Content less hosting, streaming, CDN, etc. charges subject to a
              maximum limit for such charges @ 45% of the revenue so generated
              and received by PocketFilms’.
            </p>
            <p>
              4.3. <strong>Payment Terms:</strong> PocketFilms shall generate
              the reports for every calendar quarter and share the same with or
              make the same available (on PocketFilms’ website) to the Content
              Provider within 60-75 days of the end of the quarter. Such reports
              will be based on the reports shared and payments received by
              PocketFilms’ ad-serving partners and distribution platforms, as
              applicable. The revenue share amount will continue to accrue and
              shall become payable to the Content Provider only after such
              amount crosses the threshold limit of INR 5000. The Content
              Provider shall issue an invoice for such amount to PocketFilms.
              Payment will be made by PocketFilms within 15 days from the
              receipt of a legible and valid invoice.
            </p>
            <p>
              4.4. <strong>Taxes:</strong> The Content Provider shall raise the
              invoice along with applicable GST (under Indian laws) charged
              extra. Taxes other than GST, if any, shall be borne by the Content
              Provider. PocketFilms shall deduct tax at source, as legally
              applicable, and submit the necessary deduction certificate to the
              Content Provider. Such certificate will be submitted only if the
              Content provider has shared the required details with PocketFilms.
              Both parties will be liable to their respective income tax
              relating to the revenues earned respectively by virtue of this
              agreement.
            </p>
            <p>
              4.5. <strong>Maintenance and Inspection</strong> of Records
              PocketFilms shall maintain complete and accurate books and
              records, in accordance with generally accepted accounting
              practices, of all matters related to its compliance with its
              obligations hereunder (&quot;Records&quot;). The Content Provider
              shall have the right itself, or through its authorized
              representatives, upon at least 15 days’ prior written notice to
              inspect the Records during normal business hours. Such inspection
              will be limited to once per year; PocketFilms shall be required to
              show and share the details / reports pertaining only to the
              Content Provider’s Content and shall not be required to share any
              other details or reports. The Content Provider will keep
              confidential all information obtained from such inspection and use
              it solely for the purpose of verifying compliance with the terms
              hereof.
            </p>
            <p>
              5. <strong>Advertising and Promotion</strong>
            </p>
            <p>
              PocketFilms and its authorized Distribution Services shall use,
              make, create publish, distribute or cooperate with any third party
              in making, publishing or distributing any public announcements,
              press releases, advertising, marketing, promotional or other
              materials (whether in print, electronically orotherwise) that use
              the Content and / or the Content Provider’s name, logos, or Marks
              with regard to the existence, execution or performance of this
              Agreement.
            </p>
            <p>
              Pocket Films is further authorized to offer content or parts
              thereof on complimentary basis to the distribution platforms and /
              or end users, for the purpose of promotions / marketing, etc. as
              the case may be.
            </p>
            <p>
              6. <strong>Term and Termination</strong>
            </p>
            <p>
              6.1. <strong>Term:</strong>This Agreement shall be for a period of
              ___ years effective as of the Effective Date and shall extend
              until [ , 20 ] (the “Term”) and thereafter shall be automatically
              renewed for successive [NUMBER ( )] year periods, unless, 60 days
              prior to the date on which this Agreement would otherwise
              terminate, either party hereto gives written notice to the other
              party of its election not to renew.
            </p>
            <p>
              6.2. <strong>Right to Terminate on Notice:</strong> Either party
              may terminate this Agreement on 60 days’ written notice to the
              other party in the event of a breach of any provision of this
              Agreement by the other party, provided that during such notice
              period the breaching party fails to cure such breach.
            </p>
            <p>
              6.2.1. <strong>Content Provider’s Right to Terminate:</strong> The
              Content Provider can request PocketFilms to terminate this
              Agreement subject to the following terms and conditions:
            </p>
            <p>
              a) A 60 day written notice is given to PocketFilms detailing the
              specific breach(es) of a provision(s) of this agreement
            </p>
            <p>
              b) A request to terminate may be made by the Content Provider.
              PocketFilms has the right to accept or reject such request at its
              sole discretion, without providing any reason(s) whatsoever.
            </p>
            <p>
              c) Upon acceptance of such request the Content Provider shall pay
              PocketFilms INR 25,000 or the aggregate revenue share earned by
              the Content Provider for the immediate preceding 6 months,
              whichever is higher.
            </p>
            <p>
              d) If PocketFilms has distributed the content on any third-party,
              then the Content shall continue to remain on such third-party
              platform(s) till the expiry of the agreement between PocketFilms
              and the respective third-party platform(s) is valid and
              subsisting.
            </p>
            <p>
              e) Upon such termination, any amount due or payable to the Content
              Provider by PocketFilms will be forfeited.
            </p>
            <p>
              f) Nothwithstanding anything stated hereinabove, such termination
              cannot be requested by the Content Provider if the Content
              Provider has received the compensation or fees for the Content
              upfront (Lump-sum) or in advance from PocketFilms.
            </p>
            <p>
              6.2.2. PocketFilms’s Right to Terminate PocketFilms shall have the
              right to terminate this Agreement at any time on 30 days written
              notice to the Content Provider without providing any reason
              whatsoever.
            </p>
            <p>
              7. <strong>Effects of Termination</strong>
            </p>
            <p>
              7.1. <strong>Payment Upon Expiry or Termination:</strong> Upon
              expiration or termination of this Agreement, all outstanding Fees
              and any other payments due to the Content Provider shall be paid
              as and when they become due and payable under the normal course of
              business and subject to the terms detailed in Clause 6
              hereinabove.
            </p>
            <p>
              7.2. <strong>Termination of License:</strong> Upon the expiration
              or termination of this Agreement for any reason,all rights granted
              to PocketFilms under this Agreement shall forthwith (a) terminate
              and immediately revert to Content Provider and PocketFilms shall
              immediately discontinue all use of the Content and the like, (b)
              discontinue all representations or statements from which it might
              be inferred that any relationship exists between the parties; (c)
              discontinue any use of the Content Provider’s name, logo,
              trademarks, service marks and slogans; (d) cease to promote,
              solicit or distribute the Content. The Termination of License is
              subject to conditions detailed in clause 6 above.
            </p>
            <p>
              7.3. <strong>Survival:</strong> The following provisions shall
              survive the termination or expiration of this Agreement for any
              reason and shall remain in effect after any such termination or
              expiration: Sections 6(Termination), 7 (Effects of Termination), 8
              (Intellectual Property Rights; Confidential Information), 9
              (Noncompetition), 12 (Indemnification), and 15 (Miscellaneous
              Provisions).
            </p>
            <p>
              8.{" "}
              <strong>
                Intellectual Property Rights;Confidential Information
              </strong>
            </p>
            <p>
              8.1. Ownership Content Provider shall retain ownership of all
              Content related Intellectual Property Rights. PocketFilms shall
              retain ownership to all PocketFilms Intellectual Property Rights.
            </p>
            <p>
              8.2. Confidential Information: The Receiving Party shall not
              disclose or use any Confidential Information of the Disclosing
              Party for any purpose outside the scope of this Agreement,except
              with the Disclosing Party’s prior written permission; provided
              that a Receiving Party may disclose any Confidential Information
              of the Disclosing Party to its employees, attorneys and
              accountants who have a need to know such Confidential Information
              for purposes of this Agreement
            </p>
            <p>
              8.3. Protection The Receiving Party agrees to protect the
              confidentiality of the Confidential Information of the Disclosing
              Party in the same manner that it protects the confidentiality of
              its own proprietary and confidential information of like kind, but
              in no event shall either party exercise less than reasonable care
              in protecting such Confidential Information.
            </p>
            <p>
              8.4. Compelled Disclosure: If the Receiving Party is compelled by
              law to disclose Confidential Information of the Disclosing Party,
              it shall provide the Disclosing Party with prior notice of such
              compelled disclosure (to the extent legally permitted) and
              reasonable assistance, at Disclosing Party’s cost, if the
              Disclosing Party wishes to contest the disclosure.
            </p>
            <p>
              8.5. Remedies: If the Receiving Party discloses or uses (or
              threatens to disclose or use) any Confidential Information of the
              Disclosing Party in breach of this Section 8 (Intellectual
              Property Rights; Confidential Information), the Disclosing Party
              shall have the right, in additionto any other remedies available
              to it, to seek injunctive relief to enjoin such acts, without the
              necessity of posting bond, it being specifically acknowledged by
              the parties that any other available remedies are inadequate.
            </p>
            <p>
              9.{" "}
              <strong>
                Content Providers’ Representations, Warranties and Indemnities
              </strong>
            </p>
            <p>
              In order to induce PocketFilms to enter into this Agreement, the
              Content Provider hereby represents and warrants as to PocketFilms,
              as follows:
            </p>
            <p>
              9.1. <strong>Authority:</strong> The Content Provider represents
              that he/she/it are the owner or have the right or have duly
              acquired the right to offer the Content to PocketFilms under the
              present Agreement;
            </p>
            <p>
              9.2. <strong>Authorization:</strong> Content Provider’s execution,
              delivery and performance of this Agreement is as per applicable
              law, and all contracts and agreements are binding upon it;
            </p>
            <p>
              9.3. <strong>Enforceable Agreement:</strong> This Agreement
              constitutes the legal, valid and binding agreement of the Content
              Provider, enforceable against Content Provider in accordance with
              its terms;
            </p>
            <p>
              9.4. <strong>No Violation of Agreements:</strong> Performance by
              the Content Provider of its obligations under this Agreement will
              not violate any obligations of, or restrictions affecting the
              Content Provider under any contract or agreement binding on the
              Content Provider, any judicial or other governmental order or
              judgment binding upon the Content Provider, or any law or
              regulation applicable to it;
            </p>
            <p>
              9.5. <strong>No Approvals or Consents:</strong> No approval or
              consent of, or notice to, or registration with, anyperson,
              including, without limitation, any governmental authority is
              required to be made by the Content Provider in respect of, or as a
              result of, the execution, delivery or performance of this
              Agreement;
            </p>
            <p>
              9.6. <strong>Ownership of Rights in Content(s):</strong> Content
              Provider is the authorised rights holder of all rights granted to
              PocketFilms hereunder for the Territory, and of all rights
              necessarily incidental thereto, and there are no liens, claims,
              encumbrances, restrictions or arrangements of any kind with other
              Person or Persons that might adversely affect PocketFilms’ rights
              hereunder. Content Provider has a valid and legal ownership and /
              or license of the copyright in the Content and hereby represents
              and warrants that it has not, and to its knowledge no other person
              has, transferred, liened, encumbered or otherwise granted rights
              relating to the copyright anywhere in the Territory. Content
              Provider further confirms that no part of the Content shall
              violate or infringe any trademark, trade name, copyright, patent,
              registered design, literary artistic or any other intellectual
              property right of any Person, firm or company,nor personal rights
              of privacy or any other right of any other individual or company.
              The Content Provider further confirms that no part of the Content
              will be defamatory or contribute contempt of Court or breach of
              contract, or breach of any provision of statute, nor hurt the
              sentiments of religious groups or the declared public policy of
              any nation or state;
            </p>
            <p>
              9.7. Content Provider further represents, warrants and undertakes
              that it has the sole, exclusive and unencumbered rights in the
              Content, including but not limited to music performance rights of
              music used in the Content and Content Provider further agrees to
              fully indemnify PocketFilms for any claims or alleged claims that
              PocketFilms or the Distribution Partner’s / Distribution Platforms
              may be subjected to by exhibition of the Content on the
              Distribution Services.
            </p>
            <p>
              9.8.{" "}
              <strong>
                Obligations to Third Parties related to Content(s):
              </strong>{" "}
              The Content(s) are free from any and all commercial and legal
              encumbrances and its exploitation by PocketFilms in accordance
              with the terms of this Agreement shall not violate the rights of
              any third party;
            </p>
            <p>
              9.9. <strong>No Litigation related to Content(s):</strong> To the
              best of the knowledge of the Content Provider there exists no
              action, claim or dispute pending against Content Provider or any
              affiliate or direct or indirect shareholder of Content Provider,
              and, to Content Provider’s knowledge, no threat of any such
              action, claim or dispute, with respect to any Content(s) or that,
              if decided adversely to any such Person, could adversely affect
              Content Provider’s ability to perform its respective obligations
              hereunder, or that could adversely affect PocketFilms’ exercise of
              its rights to exploit any Content(s) in the Territory as
              contemplated hereby. Content Provider shall,upon being notified in
              writing of any claim, action, dispute or litigation of any nature
              whatsoever pertaining to the Content, immediately intimate
              PocketFilms as regards the same and shall take such necessary
              steps to defend such claim, action, dispute or litigation as it
              deems fit;
            </p>
            <p>
              9.10.{" "}
              <strong>
                Information regarding Content(s) True and Correct:
              </strong>{" "}
              All information that Content Provider has given to PocketFilms on,
              before or after the date hereof concerning Content(s) and Content
              Provider’s rights therein is true and correct to Content
              Provider’s best knowledge;
            </p>
            <p>
              9.11. <strong>Product Placements:</strong> Content Provider
              represents and warrants to PocketFilms that the Content shall not
              contain any product placements, verbal references to endorse a
              brand of products/services or any other branding of products and
              services. PocketFilms shall be entitled without restriction to
              refuse any of the Content that contains such product placement,
              verbal reference to endorse a brand of products/services or any
              other branding of products and services whatsoever unless it is
              agreed by PocketFilms;
            </p>
            <p>
              9.12. <strong>Documentation:</strong> Content Provider undertakes
              to maintain all the requisite documentation for the Content which
              shall be provided to PocketFilms as and when requested for the
              purpose of defending and establishing its rights in case of a
              dispute. The requisite documentation shall include valid documents
              to support ownership or license with respect to the Content and
              the actors/technicians participating in the Content. In case of
              failure on Content Provider’s part to provide such documentation
              as and when requested, Content Provider shall indemnify
              PocketFilms for any claim and related costs that has been or
              may/will be incurred by PocketFilms for such failure;
            </p>
            <p>
              9.13. <strong>Royalties:</strong> Content Provider shall be liable
              to pay any and all fees, royalties and charges including without
              limitation, music public performance fees to the creators, author,
              copyright holders, underlying works including but not limited to
              any literary work, musical work and sound recording work used in
              the Content, performers and other holders or neighbouring rights
              thereto as well as copyright management associations and other
              organizations that are necessary for the exhibition of the Content
              on the Distribution Services under this agreement;
            </p>
            <p>
              9.14. <strong>Waiver:</strong> Content Provider represents that
              nothing contained in Section 19(4) of the Copyright Act, 1957 or
              any amendment or reenactment thereof shall apply to this
              Agreement.
            </p>
            <p>
              9.15. <strong>Indemnities:</strong> Content Provider shall
              indemnify and hold PocketFilms, its officers and directors and its
              affiliates harmless from and against any and all claims, damages,
              liabilities, reasonable costs and expenses, including reasonable
              counsel fees (a) arising from the breach of any provisions of this
              Agreement by the Content Provider and/or any third party or
              alleged third party claims; (b) by reason of any claim alleging
              that any of the Content(s) or the exercise of any rights or
              privileges granted herein infringe the copyright, trade name,
              trademark, servicemark, literary or dramatic right, right of
              privacy, right of publicity or contractual right of any claimant
              or violate any law. PocketFilms shall promptly notify the Content
              Provider of anyclaim or litigation to which the indemnity set
              forth in this provision applies. At Content Provider’s option,
              Content Provider may assume the handling, settlement or defense of
              anysuch claim or litigation. If Content Provider assumes the
              handling, settlement or defense of any such claim or litigation,
              PocketFilms shall cooperate in the defense of such claim
              orlitigation and Content Provider’s obligation with respect to
              such claim or litigation shall be limited to holding PocketFilms
              harmless from any final judgment rendered on account of such claim
              or settlement made or approved by Content Provider in connection
              therewith, expenses and reasonable counsel fees of PocketFilms
              incurred in connection with the defense of such claim or
              litigation prior to the assumption thereof by Content Provider and
              any reasonable out-of-pocket expenses for performing such acts as
              Content Provider shall request. If the Content Provider does not
              assume the handling, settlement or defense of any such claim or
              litigation, the Content Provider, in addition to holding
              PocketFilms harmless from the amount of any damages awarded in any
              final judgment entered on account of such claim or settlement made
              by Pocket Films, shall reimburse PocketFilms for PocketFilms’
              reasonable costs and expenses and reasonable counsel fees incurred
              in connection with the defense of any such claim or litigation.
              PocketFilms at its discretion may consent to the entry of any
              final judgmenton account of any such claim without Content
              Provider’s prior approval and the Content Provider hereby
              indemnifies PocketFilms and agrees to reimburse PocketFilms for
              such claimor settlement in its entirety.
            </p>
            <p>
              9.16. <strong>Compliance with Anti-Bribery Laws:</strong> It is
              the policy of both the Parties to comply and require parties with
              whom it contracts to comply with the Indian Prevention of
              Corruption Act, 1988,U.S. Foreign Corrupt Practices Act, 15 U.S.C.
              Section 78dd-1 and 78dd-2, and all other applicable anti- bribery
              laws (collectively, &quot;Anti-Bribery Laws&quot;). Each Party
              represents, warrants and covenants that: (i) it is aware of the
              Anti-Bribery Laws and will advise all persons and parties
              supervised by it of the requirements of the Anti-Bribery Laws;
              (ii) it has not and will not cause any party to be in violation of
              the Anti-Bribery Laws; and (iii) should it learn of, or have
              reasons to know of, any request for payment that is inconsistent
              with the Anti-Bribery Laws, it shall immediately notify the other
              Party. Each Party will indemnify, defend and hold harmless the
              other Party and its representatives for any and all liability
              arising from any violation of the Anti-Bribery Laws caused or
              facilitated by the defaulting Party. In the event either Party
              deems that it has reasonable grounds to suspect that the other has
              violated the Anti-Bribery Laws,both Parties shall mutually appoint
              an independent audit firm amongst any of the four big audit firms
              to independently review and audit only the transactions under the
              Agreement, at such party’s expense, any and all books and
              financial records of the defaulting Party in relation to the
              Agreement at any time, and such Party shall be entitled partially
              or totally to suspend its performance hereunder until such time it
              is proven to such party’s satisfaction that the other party has
              not violated the Anti-Bribery Laws. In the event such Party
              determines, through an audit, that the other Party has violated
              the Anti-Bribery Laws, either in connection with this Agreement or
              otherwise, such Party may terminate this Agreement immediately
              upon written notice to the other Party. In case of termination
              under the present clause, PocketFilms shall be entitled for full
              refund of the Fees and all the rights shall stand reverted back to
              the Content Provider if such termination has resulted due to
              violation of the Anti-Bribery Laws by the Content Provider.
            </p>
            <p>
              10. <strong>Disclaimer of Warranties</strong>
            </p>
            <p>
              10.1 Limitation EXCEPT FOR LIABILITY ARISING FROM INDEMNIFICATION
              OBLIGATIONS SET FORTHIN SECTION 9 (INDEMNIFICATION) OR LIABILITY
              ARISING FROM A BREACH BY EITHER PARTY OFSECTION 8 (INTELLECTUAL
              PROPERTY RIGHTS; CONFIDENTIAL INFORMATION) HEREOF, IN NOEVENT
              SHALL EITHER PARTY’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED
              TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER
              THEORY OF LIABILITY,EXCEED THE AMOUNTS ACTUALLY PAID BY
              POCKETFILMS HEREUNDER.
            </p>
            <p>
              10.2 Exclusion of Consequential and Related Damages EXCEPT FOR
              LIABILITY ARISING FROM INDEMNIFICATION OBLIGATIONS SET FORTH IN
              SECTION 9 (INDEMNIFICATION) OR LIABILITY ARISING FROM A BREACH BY
              EITHER PARTY OF SECTION 8 (INTELLECTUAL PROPERTY
              RIGHTS;CONFIDENTIAL INFORMATION) HEREOF, IN NO EVENT SHALL EITHER
              PARTY OR ANY THIRD PARTY PROVIDER HAVE ANY LIABILITY TO THE OTHER
              PARTY FOR ANY LOST PROFITS, LOSS OF USE, COSTS OF PROCUREMENT OF
              SUBSTITUTE GOODS OR SERVICES, OR FOR ANY INDIRECT,SPECIAL,
              INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES HOWEVER CAUSED
              AND,WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF
              LIABILITY, WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGE. SOME STATES AND JURISDICTIONS DO NOT
              ALLOW LIMITATIONS ON DURATION OR THE EXCLUSION OF AN IMPLIED
              WARRANTY, SO THE ABOVE LIMITATION MAY NOT APPLY. EACH PARTY MAY
              ALSO HAVE ADDITIONAL RIGHTS NOT STATED IN THIS DOCUMENT.
            </p>
            <p>
              11 <strong>FORCE MAJEURE:</strong>
            </p>
            <p>
              Neither party shall be liable to the other for failure or delay in
              the performance of a required obligation if such failure or delay
              is caused by strike, riot, fire, flood, natural disaster, war,
              [actual or threatened] act of terrorism or other similar cause
              beyond such party’s control, provided that such party gives prompt
              written notice of such condition and resumes its performance as
              soon as possible,and provided further that the other party may
              terminate this Agreement if such condition continues for a period
              of 180 days.
            </p>
            <p>
              12 <strong>INTERPRETATION:</strong>
            </p>
            <p>
              12.1 Headings are for ease of reference only and do not affect the
              meaning of this Agreement;
            </p>
            <p>
              12.2 Unless repugnant to the context thereof the singular includes
              the plural and vice versa, and words importing a gender include
              other genders;
            </p>
            <p>
              12.3 Other grammatical forms of defined words or expressions have
              corresponding meanings;
            </p>
            <p>
              12.4 A reference to a Clause, Sub-clause, Annexure, Schedule or
              attachment is a reference to a Clause or Sub-clause of or Annexure
              or Schedule or attachment to this Agreement and a reference to
              this Agreement includes any Schedules and annexures;
            </p>
            <p>
              12.5 A reference to a law includes a reference to that law as
              amended, re-enacted or repealed fromtime to time; and
            </p>
            <p>
              12.6 Words and expressions importing natural persons include
              partnerships, bodies corporate,associations, governments and
              governmental and local authorities and agencies.
            </p>
            <p>
              13 <strong>MISCELLANEOUS:</strong>
            </p>
            <p>
              13.1 <strong>Governing Law</strong>: This Agreement will be
              governed exclusively by and construed exclusively in accordance
              with the laws of India, without giving effect to the conflict of
              law principles of India with an exclusive jurisdiction of the
              Courts at Mumbai.
            </p>
            <p>
              13.2 <strong>Successors and Assigns</strong>: Except as otherwise
              expressly provided in this Agreement, this Agreement will be
              binding on, and will inure to the benefit of, the successors and
              permitted assigns of the parties to this Agreement. Nothing in
              this Agreement is intended to confer upon any party other than the
              parties hereto or their respective successors and assigns any
              rights or obligations under or by reason of this Agreement, except
              as expressly provided in this Agreement.
            </p>
            <p>
              13.3 <strong>Notices</strong>: All notices and other
              communications required or permitted hereunder will be in writing
              and will be delivered by hand or sent by overnight courier, fax or
              e-mail to:
            </p>
            <table className="table table-dark table-bordered">
              <tr>
                <td></td>
                <th>If to Content Provider</th>
                <th>If to Pocketfilms</th>
              </tr>
              <tr>
                <th>Attention</th>
                <td></td>
                <td>Mr. Saameer Mody</td>
              </tr>
              <tr>
                <th>Address</th>
                <td></td>
                <td>
                  502, Shiv Tirth No. 2,
                  <br />
                  Bhulabhai Desai Road,
                  <br />
                  Mumbai 400026
                  <br />
                  Maharashtra, India
                </td>
              </tr>
              <tr>
                <th>Email</th>
                <td></td>
                <td>saameer@pocketfilms.in</td>
              </tr>
            </table>
            <p>
              Each party may furnish an address substituting for the address
              given above by giving notice to the other parties in the manner
              prescribed by this Section 13.3 (Notices). All notices and other
              communications will be deemed to have been given upon actual
              receipt by (or tender to and rejection by) the intended recipient
              or any other person at the specified address of the intended
              recipient.
            </p>
            <p>
              13.4 <strong>Severability</strong>: In the event that any
              provision of this Agreement is held to be unenforceable under
              applicable law, this Agreement will continue in full force and
              effect without such provision and will be enforceable in
              accordance with its terms.
            </p>
            <p>
              13.5 <strong>Construction</strong>: The titles of the sections of
              this Agreement are for convenience of reference only and are not
              to be considered in construing this Agreement. Unless the context
              of this Agreement clearly requires otherwise: (a) references to
              the plural include the singular, the singular the plural, and the
              part the whole, (b) references to one gender include all genders,
              (c)“or” has the inclusive meaning frequently identified with the
              phrase “and/or,” (d) “including”has the inclusive meaning
              frequently identified with the phrase “including but not limited
              to”or “including without limitation,” and (e) references to
              “hereunder,” “herein” or “hereof” relate to this Agreement as a
              whole. Any reference in this Agreement to any statute,
              rule,regulation or agreement, including this Agreement, shall be
              deemed to include such statute,rule, regulation or agreement as it
              may be modified, varied, amended or supplemented from time to
              time.
            </p>
            <p>
              13.6 <strong>Entire Agreement</strong>: This Agreement, including
              all schedules and exhibits attached hereto,embodies the entire
              agreement and understanding between the parties hereto with
              respect tothe subject matter of this Agreement and supersedes all
              prior or contemporaneous agreements and understandings other than
              this Agreement relating to the subject matter hereof.
            </p>
            <p>
              13.7 <strong>Amendment and Waiver</strong>: This Agreement may be
              amended only by a written agreement executed by the parties
              hereto. No provision of this Agreement may be waived except by a
              written document executed by the party entitled to the benefits of
              the provision. No waiver of a provision will be deemed to be or
              will constitute a waiver of any other provision of this Agreement.
              A waiver will be effective only in the specific instance and for
              the purpose for which it was given and will not constitute a
              continuing waiver.
            </p>
            <p>
              13.8 <strong>Cumulative Remedies</strong>: Other than as expressly
              stated herein, the remedies provided herein are in addition to,
              and not exclusive of, any other remedies of a party at law or in
              equity.
            </p>
            <p>
              13.9 <strong>Assignment</strong>: PocketFilms may assign any of
              its rights or obligations hereunder, whether by operation of law
              or otherwise, without the prior express written consent of the
              Content Provider. Content Providers’ rights and obligations under
              this Agreement may not be assigned without the prior written
              consent of PocketFilms. Any attempt by the Content Provider to
              assign its rights or obligations under this Agreement in breach of
              this Section 13(Miscellaneous Provisions) shall be void and of no
              effect. Subject to the foregoing, this Agreement shall bind and
              inure to the benefit of PocketFilms, its respective successors and
              permitted assigns.
            </p>
            <p>
              13.10 <strong>Disputes</strong>: Any controversy, claim or dispute
              arising out of or relating to this Agreement, shall be settled by
              binding arbitration in MUMBAI. Such arbitration shall be conducted
              in accordance with the Arbitration and Conciliation Act, 1996. The
              parties agree to abide by all decisions and awards rendered in
              such proceedings. Such decisions and awards rendered bythe
              arbitrator shall be final and conclusive and may be entered in any
              court having jurisdiction thereof as a basis of judgment and of
              the issuance of execution for its collection. All such
              controversies, claims or disputes shall be settled in this manner
              in lieu of any action at law or equity, provided however, that
              nothing in this subsection shall be construed as precluding
              bringing an action for injunctive relief or other equitable
              relief. The arbitrator shall not have the right to award punitive
              damages or speculative damages to either party and shall not have
              the power to amend this Agreement. IF FOR ANY REASON THIS
              ARBITRATION CLAUSE BECOMES NOT APPLICABLE, THEN EACH PARTY, CAN
              PROTECT THEIR RIGHTS TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
              LAW.
            </p>
            <p>
              13.11 <strong>Copyright claims</strong>: PocketFilms reserves the
              right to share the contact details of the Content Provider with
              any third party who may approach PocketFilms with regards to
              alleged or potential violations or infringement of copyright or
              intellectual property rights pertaining to the Content provided by
              the Content Provider to PocketFilms. The Content Provider will be
              required to address and resolve the matter directly keeping
              PocketFilms indemnified against all claims, etc. and ensuring that
              such resolution does not adversely affect PocketFilms or its
              rights to the Content granted under this Agreement.
            </p>
            <p>
              13.12{" "}
              <strong>Compliance with Applicable Laws and Jurisdiction</strong>:
              Laws of Republic of India shall be applicable and the courts in
              MUMBAI shall have the competent Jurisdiction. Each party shall,at
              its own expense, comply with all applicable laws and make, obtain,
              and maintain in force at all times during the term of this
              Agreement, all filings, registrations, reports, licenses,
              permitsand authorizations required under applicable law,
              regulation or order required for such party to perform its
              obligations under this Agreement.
            </p>
            <p>
              13.13 <strong>No Benefit to Others</strong>: There are no intended
              third party beneficiaries of this Agreement. Therepresentations,
              warranties, covenants, and agreements contained in this Agreement
              are for the sole benefit of the parties and their respective
              successors and permitted assigns, and they are not to be construed
              as conferring any rights on any other persons
            </p>
            <p>
              13.14 <strong>Counterparts</strong>: This Agreement may be in any
              number of counterparts, each of which will be deemed an original,
              but all of which together will constitute one instrument.
            </p>

            <p>
              <strong>
                IN WITNESS WHEREOF, the undersigned have executed this Content
                Distribution Agreement as of the date first written above.
              </strong>
            </p>

            <table className="table table-dark table-bordered">
              <tr>
                <td>
                  (Name of the Content Provider or Company, as applicable){" "}
                </td>
                <td>Onetakemedia.com Private Limited</td>
              </tr>
              <tr>
                <td className="py-5">(sign block)</td>
                <td>(Rubber Stamp)</td>
              </tr>
              <tr>
                <td>Name:</td>
                <td>Mr. Saameer Mody</td>
              </tr>
              <tr>
                <td></td>
                <td>Title: Managing Director</td>
              </tr>
              <tr>
                <td>
                  Date of Submission:
                  <br />
                  IP Address
                  <br />
                  Location
                </td>
                <td>Effective Date:</td>
              </tr>
            </table>

            <div className="text-center mb-2">
              <strong>EXHIBIT A</strong>
            </div>
            <div className="text-center">DISTRIBUTION SERVICE</div>
            <p className="mt-4">
              The different Distribution Services shall be classified as
              follows:
            </p>
            <p>
              1. <strong>INTERNET AND MOBILE RIGHTS</strong>
              <br />
              These rights would mean and include the following rights:
              <br />
              1.1. Internet / Web
              <br />
              1.2. Mobile and Digital Devices / Mobile Service Providers / WAP
              <br />
              1.3. IPTV
              <br />
              1.4. On Demand Rights and OTT including but not limited to
              Streaming Rights, AVoD, SVod and TVod.
              <br />
              1.5. Linear Streaming
              <br />
              1.6. Distribution Platforms / Digital Platforms Broadcast
              <br />
              1.7. Multiple Formats
              <br />
              1.8. Catch Up TV Rights
              <br />
              1.9. In-Transit including In-flight Entertainment, In-Ships
              Entertainment, In-Cabs/taxis, In-Railways,
              <br />
            </p>
            <p>
              2.{" "}
              <strong>BROADCAST – Linear / Non – linear and related VoD</strong>
              <br />
              2.1. Cable Television Network / Cable Service / Cable Operator
              2.2. Catch-up TV Rights
              <br />
              2.3. Direct To Home
              <br />
              2.4. Pay TV
              <br />
              2.5. Satellite Broadcast
              <br />
              2.6. Terrestrial Television
              <br />
            </p>
            <p>
              3. <strong>THEATRICAL AND OTHER RIGHTS</strong>
              <br />
              3.1. Theatrical screening
              <br />
              3.2. DVD or other means of physical storage media
              <br />
            </p>

            <div className="text-center mb-2">
              <strong>EXHIBIT B</strong>
            </div>
            <div className="text-center text-decoration-underline mb-4">
              STATEMENT OF WORK
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </RecoilRoot>
  );
};

export default DraftAgreementPage;
