import { RecoilRoot } from "recoil";
import { Footer, Navbar } from "src/Components";

const PrivacyPage = () => {
  return (
    <RecoilRoot>
      <div className="container-fluid">
        <Navbar />

        <div
          className="container my-5 text-light"
          style={{
            backgroundColor: "#13131A",
            borderRadius: "20px",
            padding: "40px",
          }}
        >
          <h1 className="mb-5">Privacy Policy</h1>

          <p>
            The Pocket Films journey started in 2009 with a firm belief in the potential of short format content on
            digital media and with the singular intent to encourage indie creators.
          </p>

          <p>
            At a time when short films weren't taken seriously by the industry, we pioneered monetization of content for
            short film makers, enabling them to find an audience for their creativity and at the same time, helping them
            reap the financial rewards of their films' success. Over the years, we have earned the trust and goodwill of
            creators, acting as facilitators for their journey from short films to indie feature films.
          </p>

          <p>
            Our distribution network started out with our flagship channel on YouTube, but we quickly expanded to other
            mediums as well, including being the first in India to have a weekly TV show on NDTV Prime called ‘Prime
            Talkies with Pocket Films' featuring short films from our catalogue and interviews with promising short film
            makers.
          </p>

          <p>
            Our distribution reach extends to internet, mobile, OTT, satellite, TV, theatres, in-transport, etc. As the
            industry leaders in short film distribution, our library consists of more than 5,000 videos, ~4 million plus
            subscribers and more than 1.75 billion views on YouTube and growing every day.
          </p>

          <p>
            In addition to these platforms, we have also pioneered theatrical screenings of short films in multiple
            Indian cities in association with Vkaao (a PVR Cinemas and BookMyShow joint venture), in our quest to shine
            the spotlight on indie film makers even more.
          </p>

          <p>
            Curated short films from our library are regularly screened at prestigious international film festivals all
            over the world.
          </p>

          <h4>What's new?</h4>

          <p>
            Pocket Films is now a complete ecosystem for the independent short film industry. In addition to
            distribution services, we have expanded to festival hosting and submission services (coming soon) and a
            profile database that aims to connect film makers with industry professionals.
          </p>

          <p>
            The Festivals section (coming soon) serves two functions - on one hand, it enables film makers to win
            laurels by participating in global film festivals and showcasing their talent to domestic and international
            audiences.
          </p>

          <p>
            On the other hand, it allows reputed film festivals to list themselves with us, giving them an easy access
            to the pool of indie talent, while providing them with tools to completely manage the festival.
          </p>

          <p>
            The Profiles section of our website aims to enable independent creators to find resources, build their
            teams, network with industry professionals and explore career opportunities.
          </p>
        </div>
        <Footer />
      </div>
    </RecoilRoot>
  );
};

export default PrivacyPage;
